(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScale')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScale',
                config: {
                    url: '/tenesseeSelfImageScale',
                    templateUrl: 'app/athletes/athletesManagement/athleteModify/psychology/tenesseeSelfImageScale/tenesseeSelfImageScale.html',
                    controller: 'TenesseeSelfImageScaleController',
                    controllerAs: 'vm',
                    title: 'tenesseeSelfImageScale'
                }
            }
        ];
    }
})();
