(function () {
    'use strict';

    angular
        .module('app.reports.raceResultsReport')
        .controller('RaceResultsReportController', RaceResultsReportController);

    function RaceResultsReportController(log, logger, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $filter, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        vm.params = [];
        $rootScope.title = gettextCatalog.getString('RaceResultsReport');

        // Create variables
        vm.searched = '';
        vm.requestedData = {
            Csapat: {CsapatId: undefined, CsapatNev: undefined}, // Csapat neve
            Szakosztaly: {SzakosztalyId: 0, SzakosztalyNev: undefined},
            Korcsoport: {KorcsoportId: undefined, KorcsoportNev: undefined},
            Edzo: {EdzoId: undefined, EdzoNev: undefined}, // int Edző (UserId)
            Sportolo: {SportoloId: undefined, SportoloNev: undefined, SzuletesiIdo: undefined}, // Sportoló adatai
            EredmenySzoveg: undefined, // Eredmény (szöveg input mező)
            DatumTol: undefined, // DateTime? Dátumtól
            DatumIg: undefined, // DateTime? Dátumig
            Helyszin: undefined, // string Helyszín
            Helyezes: undefined, // string Helyezés
            Ertekeles: undefined, // string Értékelés
            Jellege: undefined, // string Jellege
            Verseny: undefined, // string Verseny
            Eredmeny: undefined, // int Edzői értékelés (Eredmeny = -2 => 1; Eredmeny = 2 => 5 )
            Foto: undefined, // (megjeleníteni kell kiválaszás esetén)
            SportoloKereses: true
        };
        // For print
        vm.reportParameters = {};
        vm.reportParameters2 = {};
        // section
        vm.sectionList = [];
        // age group
        vm.ageGroupList = [];
        // team
        vm.teamsList = [];
        vm.selectedTeamId = undefined;
        // coach
        vm.coachList = [];
        vm.selectedCoachList;
        vm.birthDate = undefined;
        vm.selectdateTo = new Date();
        vm.requestedData.DatumIg = new Date();

        vm.coachRating = undefined; // Edzo ertekelese
        vm.athleteData = {
            Id: undefined,
            IdSzakosztaly: undefined, // Int32
            Korcsoport: undefined, // Int32
            JelenlegiEdzo: undefined, // int
            Nev: undefined, // String
            SzuletesiIdo: undefined // DateTime
        };

        vm.reportDatasGrid = [];
        vm.reportDatasGrid[0] = vm.reportData1;
        vm.reportDatasGrid[1] = vm.reportData2;
        vm.reportDatasGrid[2] = vm.reportData3;

        // GRID
        vm.pageData = 1;
        vm.actionBegin = actionBegin;
        vm.schoolGridSelectedRowData = {};
        vm.columnsAthlete = [
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                width: '60',
                template: '#rareretemplateDataColumn3'
            },
            {
                field: 'SportoloNev',
                headerText: gettextCatalog.getString('AthleteName'),
                textAlign: 'center',
                width: '150',
                template: '#rareretemplateDataColumn1'
            },
            {
                field: 'EredmenySzoveg',
                headerText: gettextCatalog.getString('ResultText'),
                textAlign: 'left',
                width: '350'
            },
            {
                field: 'EredmenyPont',
                headerText: gettextCatalog.getString('ResultScore'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'EredmenyIdo',
                headerText: gettextCatalog.getString('ResultTime'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'EredmenyTavolsag',
                headerText: gettextCatalog.getString('ResultDistance'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: '100'
            },
            {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: '100'
            },
            {
                field: 'DatumTol',
                headerText: gettextCatalog.getString('DateFrom'),
                textAlign: 'center',
                width: '90'
            },
            {field: 'DatumIg', headerText: gettextCatalog.getString('DateTo'), textAlign: 'center', width: '90'},
            {
                field: 'Helyszin',
                headerText: gettextCatalog.getString('Location'),
                textAlign: 'center',
                width: '140'
            },
            {
                field: 'Helyezes',
                headerText: gettextCatalog.getString('Helyezes'),
                textAlign: 'center',
                width: '120'
            },
            {field: 'VersenyLeiras', headerText: gettextCatalog.getString('VersenyLeiras'), width: '250'},
            {
                field: 'Ertekeles',
                headerText: gettextCatalog.getString('CoachRating'),
                textAlign: 'center',
                width: '400'
            },
            {
                field: 'Jellege',
                headerText: gettextCatalog.getString('Jellege'),
                textAlign: 'center',
                width: '250'
            },
            {field: 'Eredmeny', headerText: gettextCatalog.getString('Result'), textAlign: 'center', width: '90'}, //int Edzői értékelés (Eredmeny = -2 => 1;Eredmeny =2=> 5 )
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                textAlign: 'center',
                width: '200'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: '110',
                template: '#rareretemplateDataColumn2'
            },
            {
                headerText: gettextCatalog.getString('Print'),
                textAlign: 'center',
                width: '100',
                template: '#rareretemplateDataColumn4'
            }
        ];
        vm.columnsTeam = [

            {field: 'Link', headerText: '', width: '60', template: '#rareretemplateDataColumn7', textAlign: 'center'},
            {
                field: 'CsapatNev',
                headerText: gettextCatalog.getString('TeamName'),
                width: '150',
                template: '#rareretemplateDataColumn5'
            },
            {field: 'EredmenySzoveg', headerText: gettextCatalog.getString('ResultText'), width: '350'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: '100'},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), width: '100'},
            {field: 'DatumTol', headerText: gettextCatalog.getString('DateFrom'), width: '90'},
            {field: 'DatumIg', headerText: gettextCatalog.getString('DateTo'), width: '90'},
            {field: 'Helyszin', headerText: gettextCatalog.getString('Location'), width: '140'},
            {field: 'Helyezes', headerText: gettextCatalog.getString('AthleteRanking'), width: '120'},
            {field: 'VersenyLeiras', headerText: gettextCatalog.getString('VersenyLeiras'), width: '250'},
            {field: 'Ertekeles', headerText: gettextCatalog.getString('CoachRating'), width: '400'},
            {field: 'Jellege', headerText: gettextCatalog.getString('Jellege'), width: '250'},
            {field: 'Eredmeny', headerText: gettextCatalog.getString('Result'), textAlign: 'center', width: '90'}, //int Edzői értékelés (Eredmeny = -2 => 1;Eredmeny =2=> 5 )
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                textAlign: 'center',
                width: '200'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                width: '110',
                template: '#rareretemplateDataColumn6'
            },
            {field: 'Print', headerText: gettextCatalog.getString('Print'), width: '100', template: '#rareretemplateDataColumn8'}
        ];

        // Public functions
        vm.clearYear = clearYear;
        vm.clearTeam = clearTeam;
        vm.clearSection = clearSection;
        vm.clearAgeGroup = clearAgeGroup;
        vm.clearCoach = clearCoach;
        vm.clearSelectdateFrom = clearSelectdateFrom;
        vm.clearSelectdateTo = clearSelectdateTo;

        vm.openCoach = openCoach;
        vm.radioButtonChecked = radioButtonChecked;
        vm.radioButtonClear = radioButtonClear;
        // Buttons for Grid
        vm.searchTeam = searchTeam; // Grid data change button
        vm.searchAthlete = searchAthlete; // Grid data change button
        vm.openAthlete = openAthlete;
        vm.openTeam = openTeam;

        // sectionDropDown
        vm.sectionDataBound = sectionDataBound; // Public
        vm.sectionSelect = sectionSelect; // Public
        // ageGroupDropDown
        vm.ageGroupDataBound = ageGroupDataBound; // Public
        vm.ageGroupSelect = ageGroupSelect; // Public
        // teamDropDown
        vm.teamsDataBound = teamsDataBound; // Public
        vm.teamsSelect = teamsSelect; // Public
        // coach
        vm.coachDropDownListDataBound = coachDropDownListDataBound;
        vm.coachSelect = coachSelect;
        // date pickers
        vm.selectdateFromChange = selectdateFromChange;
        vm.selectdateToChange = selectdateToChange;
        vm.selectBirthDate = selectBirthDate;

        vm.selectdCoach = '';
        vm.deleteResult = deleteResult;
        vm.open = open;
        vm.printAthleteResult = printAthleteResult;
        vm.printTeamResult = printTeamResult;

        vm.SelectedSectionID = 0;
        vm.SelectedAgeGroupID = 0;
        vm.SelectedCoachID = 0;
        vm.SelectedTeamID = 0;
        vm.isAdmin = false;
        vm.showDelButton = false;
        vm.selectedRowData = {};
        vm.nameChange = nameChange;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsAthlete,
            beforePrint: function (args) {
                $rootScope.beforePrintGrid(args, ['Link', 'Print']);
            },
            printComplete: function (args) {
                $rootScope.printCompleteGrid(args, ['Link', 'Print']);
            },
            rowSelected: function () {
                $timeout(function () {
                    vm.showDelButton = true;
                }, 10);
            },
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var grid2 = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsTeam,
            beforePrint: function (args) {
                $rootScope.beforePrintGrid(args, ['Link', 'Print']);
            },
            printComplete: function (args) {
                $rootScope.printCompleteGrid(args, ['Link', 'Print']);
            },
            rowSelected: function () {
                $timeout(function () {
                    vm.showDelButton = true;
                }, 10);
            },
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid2'), $scope);
                }
            }
        });
        grid2.appendTo('#Grid2');
        grid2.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([
                paramHelper.getParams([], ['raceResultsReport.athleteData', 'teamID']),
                authenticationService.getRight('KMVERSEREDM')
            ]).then(function (results) {
                if (!results[1]) {
                    log.permissionError(true);
                } else {
                    vm.params = results;
                    paramHelper.removeParam('raceResultsReport.athleteData'); // Clear athleteData
                    paramHelper.removeParam('teamID'); // Clear teamID
                    if (results[0]['teamID']) {
                        vm.SelectedTeamID = results[0]['teamID'];
                        getResultTeam();
                        // Clear();
                    } else if (results[0]['raceResultsReport.athleteData']) {
                        vm.athleteData = results[0]['raceResultsReport.athleteData'];
                        vm.athleteData.Nev = results[0]['raceResultsReport.athleteData'].Nev;
                        vm.requestedData.Sportolo.SportoloId = results[0]['raceResultsReport.athleteData'].SportolokId;
                        getActualCoachesList();
                        searchAthlete();
                        //Clear();
                    } else {
                        vm.SelectedSectionID = 0;
                        vm.SelectedAgeGroupID = 0;
                        vm.SelectedCoachID = 0;
                        vm.SelectedTeamID = 0;
                    }
                    $timeout(function () {
                        //angular.element('#birthDate').ejDropDownList('selectItemByValue', new Date(data.ErvenyessegTol).getFullYear() + ' / ' + new Date(data.ErvenyessegIg).getFullYear());
                        vm.birthDate = vm.athleteData.SzuletesiIdo;
                    }, 0);

                    getSections();
                    getAgeGroups();
                    getCoaches();
                    getTeamsList();
                }
            });
        }

        function nameChange() {
            vm.requestedData.Sportolo.SportoloId = null;
        }

        function searchAthlete() {
            vm.searched = 'athlete';
            vm.requestedData.SportoloKereses = true;
            vm.requestedData.Sportolo.SportoloNev = vm.athleteData.Nev;
            vm.requestedData.Szakosztaly.SzakosztalyId = vm.SelectedSectionID;
            vm.requestedData.Korcsoport.KorcsoportId = vm.SelectedAgeGroupID;
            vm.requestedData.Edzo.EdzoId = vm.SelectedCoachID;
            vm.requestedData.Csapat.CsapatId = vm.SelectedTeamID;
            vm.requestedData.Sportolo.SzuletesiIdo = vm.birthDate;
            dataservice.getResultsReports(vm.requestedData)
                .then(function (data) {
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'DatumTol');
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'DatumIg');
                    grid.dataSource = $filter('valueChangeFromObject')(data.itemsList, 'Eredmeny');
                    vm.isAdmin = data.isAdmin;
                });
            angular.element('#hidedContent').css('height', '500px');
            angular.element('#hidedContent2').css('height', '0px');
        }

        function getResultTeam() {
            vm.requestedData.SportoloKereses = false;
            vm.requestedData.Sportolo.SportoloNev = vm.athleteData.Nev;
            vm.requestedData.Szakosztaly.SzakosztalyId = vm.SelectedSectionID;
            vm.requestedData.Korcsoport.KorcsoportId = vm.SelectedAgeGroupID;
            vm.requestedData.Edzo.EdzoId = vm.SelectedCoachID;
            vm.requestedData.Csapat.CsapatId = vm.SelectedTeamID;
            dataservice.getResultsReports(vm.requestedData).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'DatumTol');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'DatumIg');
                grid2.dataSource = $filter('valueChangeFromObject')(data.itemsList, 'Eredmeny');
                vm.isAdmin = data.isAdmin;
            });
            angular.element('#hidedContent').css('height', '0px');
            angular.element('#hidedContent2').css('height', '500px');
        }

        function sectionSelect(args) {
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = 0;
            vm.SelectedSectionID = args.value;
            if (args.value == -1) {
                vm.SelectedSectionID = 0;
            }
            getActualCoachesList();
            getAgeGroups();
            getTeamsList();
            vm.showDelButton = false;
        }

        function ageGroupSelect(args) {
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = args.value;
            if (args.value == -1) {
                vm.SelectedAgeGroupID = 0;
            }
            getActualCoachesList();
            getTeamsList();
            vm.showDelButton = false;
        }

        function coachSelect(args) {
            vm.SelectedCoachID = args.value;
            if (args.value == -1) {
                vm.SelectedCoachID = 0;
            }
            vm.showDelButton = false;
        }

        function teamsSelect(args) {
            vm.SelectedTeamID = args.value;
            if (args.value == -1) {
                vm.SelectedTeamID = 0;
            }
            vm.requestedData.Csapat.CsapatNev = angular.element('#Team').data('ejDropDownList').currentValue;
            angular.element('#coach').ejDropDownList('selectItemByValue', 0);
            vm.requestedData.Edzo.EdzoId = undefined;
            vm.requestedData.Edzo.EdzoNev = undefined;
            vm.showDelButton = false;
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.sectionList.splice(0, 0, all);
                    }
                });
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.SelectedSectionID)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.ageGroupList.splice(0, 0, all);
                    }
                });
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.SelectedSectionID, vm.SelectedAgeGroupID, vm.requestedData.DatumTol, vm.requestedData.DatumIg) // Need the section id (szakosztály)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function getCoaches() { // getCoaches - EdzoFelhasznalokDropdownSelect.  use this, when not yet selected section and age group
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    return vm.coachList;
                }
            );
        }

        function getTeamsList() {
            dataservice.teamsDropDownList(vm.SelectedSectionID, vm.SelectedAgeGroupID, vm.SelectedCoachID)
                .then(function (data) {
                    vm.teamsList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.teamsList.splice(0, 0, all);
                    }
                });
        }

        function selectdateFromChange(args) {
            vm.requestedData.DatumTol = args.value; // DateTime? Dátumtól
            vm.requestedData.DatumTol = args.value;
            getActualCoachesList();
        }

        function selectdateToChange(args) {
            vm.requestedData.DatumIg = args.value; // DateTime? Dátumig
            vm.requestedData.DatumIg = args.value;
            getActualCoachesList();
        }

        function selectBirthDate(args) {
            vm.birthDate = args.value; // DateTime?
            vm.requestedData.Sportolo.SzuletesiIdo = args.value;
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function openTeam(id) {
            $q.all([authenticationService.getRight('MEGTCSOPORT')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([
                        {'teams.sectionId': '0'},
                        {'teams.ageGroupId': '0'},
                        {'teams.team': id}
                    ]);
                    $state.go('athletes.teams.teamMembersList');
                } else {
                    log.permissionError();
                }
            });
        }

        function open(id) {
            if (vm.requestedData.SportoloKereses === true) {
                $q.all([authenticationService.getRight('MEGTEERJEL')]).then(function (results) {
                    if (results[0]) {
                        paramHelper.setParams([{'resultReportAthletes.Id': id}]);
                        $state.go('administration.resultReportAthletes');
                    } else {
                        log.permissionError();
                    }
                });
            } else {
                $q.all([authenticationService.getRight('MEGTCSERJEL')]).then(function (results) {
                    if (results[0]) {
                        paramHelper.setParams([{'resultReportTeam.Id': id}]);
                        $state.go('administration.resultReportTeam');
                    } else {
                        log.permissionError();
                    }
                });
            }
        }

        function radioButtonChecked(args) {
            vm.requestedData.Eredmeny = args.model.value; // Edzo ertekelese - Eredmeny int Edzői értékelés (Eredmeny = -2 => 1; Eredmeny = 2 => 5 )
        }

        function radioButtonClear() {
            vm.requestedData.Eredmeny = undefined;
        }

        function searchTeam() {
            vm.searched = 'team';
            getResultTeam();
            angular.element('#hidedContent').css('height', '0px');
            angular.element('#hidedContent2').css('height', '500px');
        }

        function actionBegin(args) {
            if (args.requestType && (args.requestType === 'paging' || args.requestType === 'sorting')) {
                args.cancel = true;
                args.previousPage = args.currentPage;
            }
        }

        function deleteResult() {
            var csapatEredmeny = undefined;
            var resultReportID = 0;
            vm.selectedRowsInGrid1 = grid.getSelectedRecords();
            vm.selectedRowsInGrid2 = grid2.getSelectedRecords();
            if (!vm.selectedRowsInGrid1[0] && !vm.selectedRowsInGrid2[0]) {
                csapatEredmeny = '';
            } else if (!vm.selectedRowsInGrid2[0] && vm.selectedRowsInGrid1[0]) {
                csapatEredmeny = false;
                resultReportID = vm.selectedRowsInGrid1[0].Id;
            } else if (!vm.selectedRowsInGrid1[0] && vm.selectedRowsInGrid2[0]) {
                csapatEredmeny = true;
                resultReportID = vm.selectedRowsInGrid2[0].Id;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected result report?'),
                text: gettextCatalog.getString('You will not be able to recover the deleted result report!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this result report'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    return dataservice.deleteResultsReport(resultReportID, csapatEredmeny)
                        .then(function () {
                            vm.showDelButton = false;
                            log.successMsg(gettextCatalog.getString('ResultReportDeleted'));
                            swal(gettextCatalog.getString('Deleted!'), '', 'success');
                            if (vm.requestedData.SportoloKereses === true) {
                                searchAthlete();
                            } else {
                                getResultTeam();
                            }
                        });
                }
            });
        }

        function Clear() {
            vm.requestedData = {
                Csapat: {CsapatId: undefined, CsapatNev: undefined}, // Csapat neve
                Szakosztaly: {SzakosztalyId: 0, SzakosztalyNev: undefined},
                Korcsoport: {KorcsoportId: undefined, KorcsoportNev: undefined},
                Edzo: {EdzoId: undefined, EdzoNev: undefined}, // int Edző (UserId)
                Sportolo: {SportoloId: undefined, SportoloNev: undefined, SzuletesiIdo: undefined}, // Sportoló adatai
                EredmenySzoveg: undefined, // Eredmény (szöveg input mező)
                DatumTol: undefined, // DateTime? Dátumtól
                DatumIg: undefined, // DateTime? Dátumig
                Helyszin: undefined, // string Helyszín
                Helyezes: undefined, // string Helyezés
                Ertekeles: undefined, // string Értékelés
                Jellege: undefined, // string Jellege
                Eredmeny: undefined, // int Edzői értékelés (Eredmeny = -2 => 1; Eredmeny = 2 => 5 )
                Foto: undefined, // (megjeleníteni kell kiválaszás esetén)
                SportoloKereses: true
            };
            vm.SelectedSectionID = 0;
            vm.SelectedAgeGroupID = 0;
            vm.SelectedCoachID = 0;
            vm.SelectedTeamID = 0;
        }

        function printAthleteResult(id) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('Id', 'equal', id)).then(function (data) {
                $timeout(function () {
                    vm.reportParameters = {
                        nev: data.result[0].SportoloNev === null ? '' : data.result[0].SportoloNev,
                        szak: data.result[0].SzakosztalyId === null ? '' : data.result[0].SzakosztalyId,
                        kor: data.result[0].KorcsoportId === null ? '' : data.result[0].KorcsoportId,
                        dTol: data.result[0].DatumTol === null ? '' : data.result[0].DatumTol,
                        dIg: data.result[0].DatumIg === null ? '' : data.result[0].DatumIg,
                        eredmeny: data.result[0].Eredmeny === null ? '' : data.result[0].Eredmeny, // int Edzői értékelés (Eredmeny = -2 => 1; Eredmeny = 2 => 5 )
                        eIdo: data.result[0].EredmenyIdo === null ? '' : data.result[0].EredmenyIdo,
                        ePont: data.result[0].EredmenyPont === null ? '' : data.result[0].EredmenyPont,
                        eSzoveg: data.result[0].EredmenySzoveg === null ? '' : data.result[0].EredmenySzoveg, // Eredmény (szöveg input mező)
                        helyezes: data.result[0].Helyezes === null ? '' : data.result[0].Helyezes,
                        ertekeles: data.result[0].Ertekeles === null ? '' : data.result[0].Ertekeles
                    };

                    var dialogObj = angular.element('#printDialogA').data('ejDialog');
                    dialogObj.open();
                }, 100);
            });
        }

        function printTeamResult(id) {
            new ej2.data.DataManager(grid2.dataSource).executeQuery(new ej2.data.Query().where('Id', 'equal', id)).then(function (data) {
                $timeout(function () {
                    vm.reportParameters2 = {
                        nev: data.result[0].CsapatNev === null ? '' : data.result[0].CsapatNev,
                        szak: data.result[0].SzakosztalyId === null ? '' : data.result[0].SzakosztalyId,
                        kor: data.result[0].KorcsoportId === null ? '' : data.result[0].KorcsoportId,
                        dTol: data.result[0].DatumTol === null ? '' : data.result[0].DatumTol,
                        dIg: data.result[0].DatumIg === null ? '' : data.result[0].DatumIg,
                        helyezes: data.result[0].Helyezes === null ? '' : data.result[0].Helyezes,
                        ertekeles: data.result[0].Ertekeles === null ? '' : data.result[0].Ertekeles,
                        id: data.result[0].Id === null ? '' : data.result[0].Id
                    };

                    var dialogObj = angular.element('#printDialogT').data('ejDialog');
                    dialogObj.open();
                }, 1000);
            });
        }

        function teamsDataBound() {
            if (vm.teamsList.length === 1) {
                angular.element('#Team').ejDropDownList('selectItemByValue', vm.teamsList[0].value);
                vm.requestedData.Csapat.CsapatId = vm.teamsList[0].value;
            } else {
                angular.element('#Team').ejDropDownList('selectItemByValue', vm.SelectedTeamID);
                vm.requestedData.Csapat.CsapatId = vm.SelectedTeamID;
            }
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroup').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.requestedData.Korcsoport.KorcsoportId = vm.ageGroupList[0].value;
            } else if (vm.athleteData.Korcsoport > 0) {
                angular.element('#ageGroup').ejDropDownList('selectItemByValue', vm.athleteData.Korcsoport);
                vm.requestedData.Korcsoport.KorcsoportId = vm.athleteData.Korcsoport;
            }
        }

        function coachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.requestedData.Edzo.EdzoId = vm.coachList[0].value;
            } else if (vm.athleteData.JelenlegiEdzo > 0) {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.athleteData.JelenlegiEdzo);
            }
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                //angular.element('#section').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.requestedData.Szakosztaly.SzakosztalyId = vm.sectionList[0].value;
            } else if (vm.athleteData.IdSzakosztaly > 0) {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.athleteData.IdSzakosztaly);
                vm.requestedData.Szakosztaly.SzakosztalyId = vm.athleteData.IdSzakosztaly;
            }
        }

        function clearYear() {
            vm.selectedBirthDate = undefined;
            angular.element('#birthDate').ejDatePicker({value: new Date('')});
        }

        function clearTeam() {
            vm.SelectedTeamID = undefined;
            angular.element('#Team').ejDropDownList({selectedIndex: -1});
        }

        function clearSection() {
            vm.SelectedSectionID = undefined;
            angular.element('#section').ejDropDownList({selectedIndex: -1});
        }

        function clearAgeGroup() {
            vm.SelectedAgeGroupID = undefined;
            angular.element('#ageGroup').ejDropDownList({selectedIndex: -1});
        }

        function clearCoach() {
            vm.SelectedCoachID = undefined;
            angular.element('#coach').ejDropDownList({selectedIndex: -1});
        }

        function clearSelectdateFrom() {
            vm.selectdateFrom = undefined;
            angular.element('#selectdateFrom').ejDatePicker({value: new Date('')});
            getActualCoachesList();
        }

        function clearSelectdateTo() {
            vm.selectdateTo = undefined;
            angular.element('#selectdateTo').ejDatePicker({value: new Date('')});
            getActualCoachesList();
        }
    }
})();

