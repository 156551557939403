(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports.annualPresenceReportsByTrainers')
        .controller('AnnualPresenceReportsByTrainersController', AnnualPresenceReportsByTrainersController);

    function AnnualPresenceReportsByTrainersController(log, dataservice, $q, $rootScope, gettextCatalog, $scope, authenticationService, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualPresenceReportsByTrainers');

        //Évek (2010-től az aktuális évig)
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev);
        }
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear();
        vm.selectedMonth = 0;

        //Hónapok
        vm.months = [
            {text: gettextCatalog.getString('January'), value: 0},
            {text: gettextCatalog.getString('February'), value: 1},
            {text: gettextCatalog.getString('March'), value: 2},
            {text: gettextCatalog.getString('April'), value: 3},
            {text: gettextCatalog.getString('May'), value: 4},
            {text: gettextCatalog.getString('June'), value: 5},
            {text: gettextCatalog.getString('July'), value: 6},
            {text: gettextCatalog.getString('August'), value: 7},
            {text: gettextCatalog.getString('September'), value: 8},
            {text: gettextCatalog.getString('October'), value: 9},
            {text: gettextCatalog.getString('November'), value: 10},
            {text: gettextCatalog.getString('December'), value: 11}
        ];

        //Kiválasztott év (alapból az aktuális)
        vm.selectedCoach = {};
        vm.coachList = [];
        vm.selectedSection = 0;
        vm.sectionDataBound = [];
        vm.selectedAgeGroup = 0;
        vm.ageGroupList = [];
        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'Sorszam', headerText: gettextCatalog.getString('RowNumber'), textAlign: 'center', width: 120},
            {field: 'VersenyzoNeve', headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 250},
            {
                headerText: gettextCatalog.getString('January'), textAlign: 'center', columns: [
                    // column: 'JanEdzes, JanJelen, JanHianyzik,JanSzazalek',
                    {
                        field: 'JanEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'JanJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JanHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JanSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('February'), textAlign: 'center', columns: [
                    // column: 'FebEdzes, FebJelen, FebHianyzik,FebSzazalek',
                    {
                        field: 'FebEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'FebJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'FebHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'FebSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('March'), textAlign: 'center', columns: [
                    // column: 'MarcEdzes, MarcJelen, MarcHianyzik,MarcSzazalek',
                    {
                        field: 'MarcEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'MarcJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'MarcHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'MarcSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('April'), textAlign: 'center', columns: [
                    // column: 'AprEdzes, AprJelen, AprHianyzik,AprSzazalek',
                    {
                        field: 'AprEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'AprJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'AprHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'AprSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('May'), textAlign: 'center', columns: [
                    // column: 'MajEdzes, MajJelen, MajHianyzik,MajSzazalek',

                    {
                        field: 'MajEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'MajJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'MajHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'MajSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('June'), textAlign: 'center', columns: [
                    // column: 'JunEdzes, JunJelen, JunHianyzik,JunSzazalek',
                    {
                        field: 'JunEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'JunJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JunHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JunSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('July'), textAlign: 'center', columns: [
                    // column: 'JulEdzes, JulJelen, JulHianyzik,JulSzazalek',
                    {
                        field: 'JulEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'JulJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JulHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'JulSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('August'), textAlign: 'center', columns: [
                    //column: 'AugFlo, AugJelen, AugHianyzik,AugSzazalek',
                    {
                        field: 'AugEdzes',
                        headerText: gettextCatalog.getString('EdzesSzam'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'AugJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'AugHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'AugSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('September'), textAlign: 'center', columns: [
                    // column: 'SzeptFlo, SzeptJelen, SzeptHianyzik,SzeptSzazalek',
                    {field: 'SzeptEdzes', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                    {
                        field: 'SzeptJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'SzeptHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'SzeptSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('October'), textAlign: 'center', columns: [
                    // column: 'OktEdzes, OktJelen, OktHianyzik,OktSzazalek',
                    {field: 'OktEdzes', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                    {
                        field: 'OktJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'OktHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'OktSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('November'), textAlign: 'center', columns: [
                    // column: 'NovEdzes, NovJelen, NovHianyzik,NovSzazalek',
                    {field: 'NovEdzes', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                    {
                        field: 'NovJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'NovHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'NovSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('December'), textAlign: 'center', columns: [
                    // column: 'DecEdzes, DecJelen, DecHianyzik,DecSzazalek',
                    {field: 'DecEdzes', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                    {
                        field: 'DecJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'DecHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'DecSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('12month'), textAlign: 'center', columns: [
                    // column: 'OsszEdzes, OsszJelen, OsszHianyzik,OsszSzazalek',
                    {
                        field: 'OsszEdzes',
                        headerText: gettextCatalog.getString('Sum'),
                        textAlign: 'center',
                        width: 120
                    },
                    {
                        field: 'OsszJelen',
                        headerText: gettextCatalog.getString('Present'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'OsszHianyzik',
                        headerText: gettextCatalog.getString('NonPresent'),
                        textAlign: 'center',
                        width: 100
                    },
                    {
                        field: 'OsszSzazalek',
                        headerText: gettextCatalog.getString('JelenSzazalek'),
                        textAlign: 'center',
                        width: 120
                    }
                ]
            }
        ];

        // Public functions
        vm.coachSelect = coachSelect;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupSelect = ageGroupSelect;

        vm.goToNewAthlete = goToNewAthlete;
        vm.reportSelect = reportSelect;

        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.coachDataBound = coachDataBound;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowExcelExport: true,
            //frozenColumns: 3,
            locale: 'hu-HU',
            gridLines: 'Both',
            toolbar: $rootScope.toolbarItemsWithSearch,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [authenticationService.getRight('KMEVESJELSP')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'aprbt.selectedSection',
                'aprbt.selectedAgeGroup',
                'aprbt.selectedCoach',
                'annualPresenceReports.year',
                'annualPresenceReports.month'
            ])];
            $q.all(promises).then(function (results) {
                if (results[0]['aprbt.selectedSection']) {
                    vm.selectedSection = results[0]['aprbt.selectedSection'];
                }
                if (results[0]['aprbt.selectedAgeGroup']) {
                    vm.selectedAgeGroup = results[0]['aprbt.selectedAgeGroup'];
                }
                if (results[0]['aprbt.selectedCoach']) {
                    vm.selectedCoach = results[0]['aprbt.selectedCoach'];
                }
                if (results[0]['annualPresenceReports.year']) {
                    vm.selectedYear = results[0]['annualPresenceReports.year'];
                }
                if (results[0]['annualPresenceReports.month']) {
                    vm.selectedMonth = results[0]['annualPresenceReports.month'];
                }
            });
        }

        function getCoaches() {
            return dataservice.coachDropDownList(vm.selectedSection, vm.selectedAgeGroup, new Date(vm.selectedYear, vm.selectedMonth, 1)).then(
                function (data) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    vm.coachList = data.itemsList;
                }
            );
        }

        function coachSelect(args) {
            vm.selectedCoach = args.value;
        }

        function getSections() {
            return dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionSelect(args) {
            vm.selectedSection = args.value;
            getAgeGroups();
            getCoaches();
        }

        function getAgeGroups() {
            return dataservice.ageGroupSelectYear(0, vm.selectedSection, vm.selectedYear)
                .then(function (data) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupSelect(args) {
            vm.selectedAgeGroup = args.value;
            getCoaches();
        }

        function goToNewAthlete(athleteId) {
            if (athleteId == 0) {
                return;
            }
            if (!vm.MODSPORTOLO) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'athleteModify.Id': athleteId}]);
            $state.go('athletes.athletesManagement.athleteModify.dataSheet');
        }

        function reportSelect() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('FillTheRequiredFields');
                return;
            }
            if (vm.selectedYear && vm.selectedMonth && vm.selectedSection && vm.selectedAgeGroup && vm.selectedCoach) {
                paramHelper.setParams([
                    {'annualPresenceReports.year': vm.selectedYear},
                    {'annualPresenceReports.month': vm.selectedMonth},
                    {'aprbt.selectedSection': vm.selectedSection},
                    {'aprbt.selectedAgeGroup': vm.selectedAgeGroup},
                    {'aprbt.selectedCoach': vm.selectedCoach}
                ]);
            }
            dataservice.getAnnualPresenceReportByTrainersReport(vm.selectedYear, vm.selectedMonth, vm.selectedSection, vm.selectedAgeGroup, '', vm.selectedCoach)
                .then(function (data) {
                    vm.columns2 = [];
                    vm.columns2.push(vm.columns[0]);
                    vm.columns2.push(vm.columns[1]);
                    vm.columns2.push(vm.columns[2]);
                    var j = (parseInt(vm.selectedMonth) + 3);
                    for (var i = 0; i < 12; i++) {
                        vm.columns2.push(vm.columns[j++]);
                        j = j === 12 + 3 ? 3 : j;
                    }
                    vm.columns2.push(vm.columns[15]);
                    grid.columns = vm.columns2;
                    grid.refreshColumns();
                    grid.dataSource = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.selectedSection > 0) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            } else {
                if (vm.sectionList.length === 1) {
                    angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                    vm.selectedSection = vm.sectionList[0].value;
                } else {
                    angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
                }
            }
        }

        function ageGroupDataBound() {
            if (vm.selectedAgeGroup > 0) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
            } else {
                if (vm.ageGroupList.length === 1) {
                    angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                    vm.selectedAgeGroup = vm.ageGroupList[0].value;
                } else {
                    angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
                }
            }
        }

        function coachDataBound() {
            if (vm.selectedCoach > 0) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.selectedCoach);
            } else {
                if (vm.coachList.length === 1) {
                    angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                    vm.selectedCoach = vm.coachList[0].value;
                } else {
                    angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.selectedCoach);
                }
            }
        }
    }
})();
