(function () {
    'use strict';

    angular
        .module('app.reports.sportSpecificReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.sportSpecificReport',
                config: {
                    url: '/sportSpecificReport',
                    templateUrl: 'app/reports/sportSpecificReport/sportSpecificReport.html',
                    controller: 'SportSpecificReportController',
                    controllerAs: 'vm',
                    title: 'SportSpecificReport',
                    settings: {
                        navId: 2194,
                        level: 2,
                        order: 19,
                        orderTitle_hu: 'Sportágspecifikus felmérő kimutatás',
                        orderTitle_en: 'Sport Specific Test Report',
                        parentId: 4,
                        content: 'SportSpecificReport',
                        activatorPermission: '"MEGTSPFELM"'
                    }
                }
            }
        ];
    }
})();
