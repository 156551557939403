(function () {
    'use strict';

    angular
        .module('app.athleteDashboard')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athleteDashboard',
                config: {
                    url: '/athleteDashboard',
                    templateUrl: 'app/athleteDashboard/athleteDashboard.html',
                    controller: 'AthleteDashboardController',
                    controllerAs: 'vm',
                    title: 'AthleteDashboard',
                    settings: {
                        navId: 13,
                        level: 1,
                        order: 9,
                        parentId: 0,
                        content: 'AthleteDashboard',
                        permission: ['6']
                    }
                }
            }
        ];
    }
})();
