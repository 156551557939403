(function () {
    'use strict';

    angular
        .module('app.core').config(function (/*$httpProvider*/) {
            // delete $httpProvider.defaults.headers.common['X-Requested-With'];
        })
        .factory('dataservice', dataservice);

    function dataservice($http, DOMAIN) {
        var service = {
            range: range,
            getURL: getURL,
            changePassword: changePassword,
            login: login,
            getHaveToPay: getHaveToPay,
            logout: logout,
            parentsAthletes: parentsAthletes,
            quickCompetitionResult: quickCompetitionResult,
            getPieronTest: getPieronTest,
            getAvailableLanguages: getAvailableLanguages,
            sectionDropDownList: sectionDropDownList,
            ageGroupDropDownList: ageGroupDropDownList,
            teamsDropDownList: teamsDropDownList,
            getAthletesForEducationalFeeRegister: getAthletesForEducationalFeeRegister,
            teamList: teamList,
            savePieronTest: savePieronTest,
            getUserGroupList: getUserGroupList,
            updateTagdijfizetesek: updateTagdijfizetesek,
            ageGroupDropDownListForSearch: ageGroupDropDownListForSearch,
            modifyEvent: modifyEvent,
            usersListSelect: usersListSelect,
            getCheck: getCheck,
            coachDropDownList: coachDropDownList,
            getCheckExportPrintList: getCheckExportPrintList,
            KoltsegtervOsszesitoExcelExport: KoltsegtervOsszesitoExcelExport,
            addUserGroup: addUserGroup,
            getUpAndDownPlayers: getUpAndDownPlayers,
            HealthReport: HealthReport,
            getAthletesFromFeeRegister: getAthletesFromFeeRegister,
            getAnnualSportServiceFeeByCoach: getAnnualSportServiceFeeByCoach,
            getOldCompetitivePlanByUserId: getOldCompetitivePlanByUserId,
            athletesSelect2: athletesSelect2,
            getDuplicatedAthletes: getDuplicatedAthletes,
            deleteMonthlyTrainingPlan: deleteMonthlyTrainingPlan,
            saveAnthropometry: saveAnthropometry,
            getAthletesForFeeArrears: getAthletesForFeeArrears,
            checkPaymentsSave: checkPaymentsSave,
            deleteHealthMainType: deleteHealthMainType,
            deleteHealthSubType: deleteHealthSubType,
            HealthMainTypeManager: HealthMainTypeManager,
            HealthSubTypeManager: HealthSubTypeManager,
            healthMainTypesList: healthMainTypesList,
            healthSubTypesList: healthSubTypesList,
            sectionDropDownListAll: sectionDropDownListAll,
            getSectionLeaderListBox: getSectionLeaderListBox,
            getEducationalFeePaymentsNumbers: getEducationalFeePaymentsNumbers,
            sectionDropDownListWithFees: sectionDropDownListWithFees,
            sectionDropDownListWithGroupStatus: sectionDropDownListWithGroupStatus,
            getAgeGroupTreeView: getAgeGroupTreeView,
            getAnnualSeasonPlanArrears: getAnnualSeasonPlanArrears,
            removeEvent: removeEvent,
            savePerformanceMotivationSurveyEval: savePerformanceMotivationSurveyEval,
            editUserGroup: editUserGroup,
            getOldAnthropometryTest: getOldAnthropometryTest,
            saveTeam: saveTeam,
            getAthletesEV: getAthletesEV,
            updateOktatasiDijfizetesek: updateOktatasiDijfizetesek,
            savePerformanceMotivationSurvey: savePerformanceMotivationSurvey,
            getPerformanceMotivationSurvey: getPerformanceMotivationSurvey,
            selectSchools: selectSchools,
            getOldWeeklyPlanByUserId: getOldWeeklyPlanByUserId,
            getWeeklyTrainingPlans: getWeeklyTrainingPlans,
            getAnnualCompetitiveReports: getAnnualCompetitiveReports,
            getClassMasters: getClassMasters,
            getClassMasterContact: getClassMasterContact,
            getMonthlyAttendanceReports: getMonthlyAttendanceReports,
            getTrainingAttendances: getTrainingAttendances,
            getMonthlyCompetitivePlans: getMonthlyCompetitivePlans,
            parentsAthleteAttendanceList: parentsAthleteAttendanceList,
            getGymTeachers: getGymTeachers,
            getGymTeacherContact: getGymTeacherContact,
            getMonthlyTrainingPlanList: getMonthlyTrainingPlanList,
            addSchool: addSchool,
            getSectionResources: getSectionResources,
            getAnnualSportServiceFeeByOneSection: getAnnualSportServiceFeeByOneSection,
            getAgeGroupResources: getAgeGroupResources,
            editSchool: editSchool,
            getAnnualEducationalFeeBySection: getAnnualEducationalFeeBySection,
            getAnnualEducationalFeeByOneSection: getAnnualEducationalFeeByOneSection,
            searchAthlete: searchAthlete,
            searchUser: searchUser,
            deleteSchool: deleteSchool,
            addUser: addUser,
            getAthleteForAnthropometry: getAthleteForAnthropometry,
            saveEvent: saveEvent,
            modifyUser: modifyUser,
            modifyUserMin: modifyUserMin,
            getTeamMembersList: getTeamMembersList,
            modifyTeam: modifyTeam,
            getPossibleTeamMembersList: getPossibleTeamMembersList,
            addTeamMemberToTeam: addTeamMemberToTeam,
            getUserGroupDropDownList: getUserGroupDropDownList,
            getFullUserGroupList: getFullUserGroupList,
            deleteTeamMember: deleteTeamMember,
            getAnnualSportServiceFeeBySection: getAnnualSportServiceFeeBySection,
            getAnnualEducationalFeeByCoach: getAnnualEducationalFeeByCoach,
            deleteTeam: deleteTeam,
            deleteTeamsPlayers: deleteTeamsPlayers,
            getTeam: getTeam,
            getAnnualEducationalFeeByAthletes: getAnnualEducationalFeeByAthletes,
            ageGroupList: ageGroupList,
            saveDailyAttendance: saveDailyAttendance,
            sendLocalException: sendLocalException,
            getResultsReportsAthlete: getResultsReportsAthlete,
            DeleteExercise: DeleteExercise,
            SimilarAthleteExist: SimilarAthleteExist,
            getExerciseList: getExerciseList,
            getExercise: getExercise,
            HetiEdzesTervJovahagyas: HetiEdzesTervJovahagyas,
            GetPaints: GetPaints,
            HaviEdzesTervJovahagyas: HaviEdzesTervJovahagyas,
            getServiceFeePaymentsNumbers: getServiceFeePaymentsNumbers,
            getAgeGroupMembersList: getAgeGroupMembersList,
            getAwayBudgetPlanList: getAwayBudgetPlanList,
            KoltsegtervOsszesitoKimutatas: KoltsegtervOsszesitoKimutatas,
            getAgeGroup: getAgeGroup,
            saveExercise: saveExercise,
            saveAgeGroup: saveAgeGroup,
            modifyAgeGroup: modifyAgeGroup,
            modifyPaint: modifyPaint,
            copyAllAgeGroup: copyAllAgeGroup,
            athletesSelect: athletesSelect,
            parentsAthleteAttendance: parentsAthleteAttendance,
            savePaint: savePaint,
            getPaint: getPaint,
            getAthletesForDailyPayments2: getAthletesForDailyPayments2,
            getUserData: getUserData,
            getUserDataMin: getUserDataMin,
            coachSelect: coachSelect,
            ageGroupSelect: ageGroupSelect,
            monthlyAttendanceSelect: monthlyAttendanceSelect,
            getAccessRights: getAccessRights,
            saveMonthlyAttendance: saveMonthlyAttendance,
            getNewAthletesInSelectedYearMonth: getNewAthletesInSelectedYearMonth,
            messageSave: messageSave,
            getUserGroupTypes: getUserGroupTypes,
            monthlyAttendanceAcceptByLeader: monthlyAttendanceAcceptByLeader,
            getAthleteData: getAthleteData,
            getAthleteDataMin: getAthleteDataMin,
            getAthletesForDailyPayments: getAthletesForDailyPayments,
            transferAthletes: transferAthletes,
            getEvents: getEvents,
            getAnnualSportServiceFeeByAthletes: getAnnualSportServiceFeeByAthletes,
            getLastMonthlyAttendanceTrainingLocationAndTime: getLastMonthlyAttendanceTrainingLocationAndTime,
            getAthletesForMonthlyAttendance: getAthletesForMonthlyAttendance,
            monthlyAttendanceSelectById: monthlyAttendanceSelectById,
            getAthletesCountForMonthlyAttendance: getAthletesCountForMonthlyAttendance,
            getMonthlyAttendanceList: getMonthlyAttendanceList,
            getUserUnreadedMessages: getUserUnreadedMessages,
            unreadedMessagesViewed: unreadedMessagesViewed,
            messageDelete: messageDelete,
            messageDeleteSent: messageDeleteSent,
            getUnAcceptedMonthlyAttendance: getUnAcceptedMonthlyAttendance,
            getUnAcceptedMonthlyCompetitionPlan: getUnAcceptedMonthlyCompetitionPlan,
            getUnAcceptedWeeklyCompetitionPlan: getUnAcceptedWeeklyCompetitionPlan,
            getUnAcceptedYearlyCompetitionPlan: getUnAcceptedYearlyCompetitionPlan,
            saveTenesseeSelfImageScale: saveTenesseeSelfImageScale,
            getPsychologyTests: getPsychologyTests,
            uploadWartegg: uploadWartegg,
            saveWartegg: saveWartegg,
            getTenesseeSelfImageScaleById: getTenesseeSelfImageScaleById,
            saveTenesseeSelfImageScaleEval: saveTenesseeSelfImageScaleEval,
            getMonthlyCompetitiveReports: getMonthlyCompetitiveReports,
            getWarteggTestById: getWarteggTestById,
            getWeeklyTrainingPlanList: getWeeklyTrainingPlanList,
            SaveAwayBudgetPlan: SaveAwayBudgetPlan,
            getIdegenbeliKoltsegvetesiTervEdzoList: getIdegenbeliKoltsegvetesiTervEdzoList,
            getIdegenbeliKoltsegvetesiTervSportoloList: getIdegenbeliKoltsegvetesiTervSportoloList,
            getWeeklyTravelList: getWeeklyTravelList,
            getWeeklyTrainingList: getWeeklyTrainingList,
            getAthletes: getAthletes,
            saveExitAthletes: saveExitAthletes,
            saveNewAthlete: saveNewAthlete,
            getAnthropometryListForPrinting: getAnthropometryListForPrinting,
            modifyAthlete: modifyAthlete,
            getSystemSettings: getSystemSettings,
            saveSystemSettings: saveSystemSettings,
            getUserGroupRights: getUserGroupRights,
            setUserGroupRight: setUserGroupRight,
            weeklyTrainingPlanSelectByIdNew: weeklyTrainingPlanSelectByIdNew,
            saveWeeklyTrainingPlanNew: saveWeeklyTrainingPlanNew,
            getFilesList: getFilesList,
            getResultsReports: getResultsReports,
            deleteFiles: deleteFiles,
            getCompetitiveMonthlyPlanList: getCompetitiveMonthlyPlanList,
            monthlyPlanSelectById: monthlyPlanSelectById,
            saveMonthlyCompetitionPlan: saveMonthlyCompetitionPlan,
            monthlyCompetitionPlanAcceptByLeader: monthlyCompetitionPlanAcceptByLeader,
            getCompetitiveMonthlyPlanItems: getCompetitiveMonthlyPlanItems,
            sectionIndividualOrTeam: sectionIndividualOrTeam,
            getFeeDatas: getFeeDatas,
            saveFeeDatas: saveFeeDatas,
            getAthletesForMeteOut: getAthletesForMeteOut,
            meteOutFee: meteOutFee,
            closeYear: closeYear,
            getLastClosedYear: getLastClosedYear,
            getLinks: getLinks,
            saveLink: saveLink,
            deleteLink: deleteLink,
            athletesBySection: athletesBySection,
            getUnPaidFees: getUnPaidFees,
            getUnPaidFeesTrainer: getUnPaidFeesTrainer,
            getMessages: getMessages,
            GetMessageGroups: GetMessageGroups,
            messageReaded: messageReaded,
            sendMessage: sendMessage,
            SikerMobileFreeSendNewMessage: SikerMobileFreeSendNewMessage,
            SikerMobileFreeSendMessage: SikerMobileFreeSendMessage,
            annualSeasonPlanReportSelectById: annualSeasonPlanReportSelectById,
            annualSeasonPlanReportSelect: annualSeasonPlanReportSelect,
            getTrainerEmailContact: getTrainerEmailContact,
            annualSeasonPlanReportMembersDataByIdSelect: annualSeasonPlanReportMembersDataByIdSelect,
            coachAthletesByAgeGroupForAnnualSeasonPlan: coachAthletesByAgeGroupForAnnualSeasonPlan,
            annualSeasonPlanDeleteMembers: annualSeasonPlanDeleteMembers,
            saveAnnualSeasonPlanMembers: saveAnnualSeasonPlanMembers,
            saveAnnualSeasonPlanHead: saveAnnualSeasonPlanHead,
            annualSeasonPlanReportMembersPastSelect: annualSeasonPlanReportMembersPastSelect,
            annualSeasonPlanReportMembersPastDelete: annualSeasonPlanReportMembersPastDelete,
            annualSeasonPlanReportMembersPastRefresh: annualSeasonPlanReportMembersPastRefresh,
            saveAnnualSeasonPlanMembersPast: saveAnnualSeasonPlanMembersPast,
            annualSeasonPlanReportBestResultsList: annualSeasonPlanReportBestResultsList,
            saveAnnualSeasonPlanBestResults: saveAnnualSeasonPlanBestResults,
            annualSeasonPlanReportBestResultsSelect: annualSeasonPlanReportBestResultsSelect,
            annualSeasonPlanReportObjectivesSelect: annualSeasonPlanReportObjectivesSelect,
            saveAnnualSeasonPlanCapitalObjectives: saveAnnualSeasonPlanCapitalObjectives,
            deleteFileAnnualSeasonPlan: deleteFileAnnualSeasonPlan,
            getAnnualSeasonPlanListByTrainer: getAnnualSeasonPlanListByTrainer,
            annualSeasonPlanCompetitionPlansAndObjectivesSelect: annualSeasonPlanCompetitionPlansAndObjectivesSelect,
            saveAnnualSeasonPlanCompetitionPlansAndObjectives: saveAnnualSeasonPlanCompetitionPlansAndObjectives,
            deleteAnnualSeasonPlanCompetitionPlansAndObjectives: deleteAnnualSeasonPlanCompetitionPlansAndObjectives,
            deleteAnnualSeasonPlanCompetitionPlansAndObjective: deleteAnnualSeasonPlanCompetitionPlansAndObjective,
            annualSeasonPlanList: annualSeasonPlanList,
            dailyEducationalFeesPerCoach: dailyEducationalFeesPerCoach,
            dailySportServiceFeesPerCoach: dailySportServiceFeesPerCoach,
            annualSeasponPlanTableListSelect: annualSeasponPlanTableListSelect,
            annualSeasponPlanTableListSelect2: annualSeasponPlanTableListSelect2,
            saveAnnualSeasonPlanTable: saveAnnualSeasonPlanTable,
            getAthleteTransferList: getAthleteTransferList,
            getFelmerokDatumaiDropDownList: getFelmerokDatumaiDropDownList,
            leavingAthletesBySection: leavingAthletesBySection,
            joinedAthletesBySection: joinedAthletesBySection,
            listAthletes: listAthletes,
            athletesByCoach: athletesByCoach,
            leavingAthletesByCoach: leavingAthletesByCoach,
            joinedAthletesByCoach: joinedAthletesByCoach,
            activeAthletesByAge: activeAthletesByAge,
            activeAthletesBornAtSpecificYear: activeAthletesBornAtSpecificYear,
            activeAthletesByMonth: activeAthletesByMonth,
            activeAthletesInSectionByMonth: activeAthletesInSectionByMonth,
            athletesNotPresentOnMonthlyAttendance: athletesNotPresentOnMonthlyAttendance,
            ActiveAthletesInASpecificMonth: ActiveAthletesInASpecificMonth,
            LeavingAthletesInASpecificMonth: LeavingAthletesInASpecificMonth,
            JoinedAthletesInASpecificMonth: JoinedAthletesInASpecificMonth,
            leavingAthletesFromASpecificCoach: leavingAthletesFromASpecificCoach,
            joinedAthletesToASpecificCoach: joinedAthletesToASpecificCoach,
            getAnnualPresenceReportByTrainersReport: getAnnualPresenceReportByTrainersReport,
            getAnnualPresenceReportBySectionsReport: getAnnualPresenceReportBySectionsReport,
            getAnnualPresenceReportBySportSchoolReport: getAnnualPresenceReportBySportSchoolReport,
            activeAthletesByCoach: activeAthletesByCoach,
            deleteWeeklyTrainingPlan: deleteWeeklyTrainingPlan,
            getAthleteMoveStory: getAthleteMoveStory,
            athleteDropDownListSelect: athleteDropDownListSelect,
            getCharacterOfACompetitionList: getCharacterOfACompetitionList,
            saveResultReportAthletes: saveResultReportAthletes,
            resultReportAthletesSelectById: resultReportAthletesSelectById,
            deleteResultsReport: deleteResultsReport,
            searchMemberList: searchMemberList,
            resultReportAthletesOlderReports: resultReportAthletesOlderReports,
            teamDropDownListSelect: teamDropDownListSelect,
            teamAthletesListSelect: teamAthletesListSelect,
            getCoachUsers: getCoachUsers,
            getEdzoKorcsoportjai: getEdzoKorcsoportjai,
            getTeamResultAthleteData: getTeamResultAthleteData,
            EredmenyJelentoAtjatszoSportoloi: EredmenyJelentoAtjatszoSportoloi,
            saveResultReportTeams: saveResultReportTeams,
            resultReportTeamSelectById: resultReportTeamSelectById,
            saveNewLink: saveNewLink,
            activeAthletesByBirthYear: activeAthletesByBirthYear,
            VersenyTervHaviDeleteById: VersenyTervHaviDeleteById,
            monthlyPlanSelectByDate: monthlyPlanSelectByDate,
            ujHaviVersenyeztetesiTerv: ujHaviVersenyeztetesiTerv,
            saveReEnteringAthletes: saveReEnteringAthletes,
            getAthletesForWeeklyTrainingPlan: getAthletesForWeeklyTrainingPlan,
            GetAwayBudgetPlan: GetAwayBudgetPlan,
            deleteUserGroup: deleteUserGroup,
            userGroupList: userGroupList,
            getTrainingVisitReport: getTrainingVisitReport,
            getSentMessages: getSentMessages,
            getExpiringDocuments: getExpiringDocuments,
            getAbsenceReport: getAbsenceReport,
            saveFelmeroElemFej: saveFelmeroElemFej,
            saveFelmeroElem: saveFelmeroElem,
            getSystemSettingsValue: getSystemSettingsValue,
            getFelmeroElemFejList: getFelmeroElemFejList,
            getFelmeroElemList: getFelmeroElemList,
            felmeroElemFejDropDownList: felmeroElemFejDropDownList,
            felmeroFejLista: felmeroFejLista,
            FelmeroMentes: FelmeroMentes,
            FelmeroLetrehozasa: FelmeroLetrehozasa,
            getFelmeroById: getFelmeroById,
            getSportagSpecReport: getSportagSpecReport,
            deleteFelmeroElemFej: deleteFelmeroElemFej,
            deleteFelmero: deleteFelmero,
            FelmeroTipusMasolas: FelmeroTipusMasolas,
            getSectionHasSectionLeaderRight: getSectionHasSectionLeaderRight,
            getSectionHasCoachRight: getSectionHasCoachRight,
            getOldMonthlyPlanByUserId: getOldMonthlyPlanByUserId,
            monthlyTrainingPlanSelectById: monthlyTrainingPlanSelectById,
            createLoginForAthlete: createLoginForAthlete,
            createLoginForParent: createLoginForParent,
            getAthleteidBelongsToAthleteUser: getAthleteidBelongsToAthleteUser,
            getUsersBelongingToAthlete: getUsersBelongingToAthlete,
            athleteAndParentDropDownList: athleteAndParentDropDownList,
            assignAthleteToParentUser: assignAthleteToParentUser,
            unlinkUserFromAthlete: unlinkUserFromAthlete,
            getParentAndAthleteUsers: getParentAndAthleteUsers,
            UsersUserGroup: UsersUserGroup,
            getUserIdsOfGroup: getUserIdsOfGroup,
            getUserId: getUserId,
            getAthletesBodyStat: getAthletesBodyStat,
            getAthletesOfUser: getAthletesOfUser,
            saveMonthlyTrainingPlan: saveMonthlyTrainingPlan,
            saveHealthData: saveHealthData,
            GetHealth: GetHealth,
            resultChartSectionDropDown: resultChartSectionDropDown,
            resultChartAgeGroupDropDown: resultChartAgeGroupDropDown,
            resultChartVersenyszamDropDown: resultChartVersenyszamDropDown,
            resultDateChart: resultDateChart,
            resultChart1: resultChart1,
            resultChart2: resultChart2,
            resultChart3: resultChart3,
            SzuloCsoportokDropDownList: SzuloCsoportokDropDownList,
            SportoloCsoportokDropDownList: SportoloCsoportokDropDownList,
            getNamesByIds: getNamesByIds,
            Cimjegyzek: Cimjegyzek,
            CimjegyzekMobil: CimjegyzekMobil,
            KedvezmenyNevek: KedvezmenyNevek,
            OktatasiKedvezmenyNevek: OktatasiKedvezmenyNevek,
            KedvezmenyNevekMentes: KedvezmenyNevekMentes,
            modifyHealthData: modifyHealthData,
            getReducedSportServiceFeeTypes: getReducedSportServiceFeeTypes,
            getReducedEducationalFeeTypes: getReducedEducationalFeeTypes,
            getAthleteFees: getAthleteFees,
            getParentNames: getParentNames,
            loadTrainingDiaryByMonthlyAttendanceId: loadTrainingDiaryByMonthlyAttendanceId,
            saveTrainingDiary: saveTrainingDiary,
            getTrainingDiaryReports: getTrainingDiaryReports,
            monthlyAttendanceRefreshAthleteData: monthlyAttendanceRefreshAthleteData,
            getLog: getLog,
            getImageOfUser: getImageOfUser,
            saveSystemSettingsValue: saveSystemSettingsValue,
            EsemenyekAttolteseHetiEdzestervre: EsemenyekAttolteseHetiEdzestervre,
            AtjatszoSportoloLista: AtjatszoSportoloLista,
            GetPaintList: GetPaintList,
            getParentNotifications: getParentNotifications,
            getParentNotifications2: getParentNotifications2,
            ErtesitestLatta: ErtesitestLatta,
            AcceptCSVPayment: AcceptCSVPayment,
            KoltesegvetesiTervExcelExport: KoltesegvetesiTervExcelExport,
            KoltesegvetesiTervVeglegesites: KoltesegvetesiTervVeglegesites,
            KoltesegvetesiTervLezaras: KoltesegvetesiTervLezaras,
            KoltesegvetesiTervJovahagyas: KoltesegvetesiTervJovahagyas,
            WeeklyTrainingPlaces: WeeklyTrainingPlaces,
            GenerateRegNum: GenerateRegNum,
            assetTypeList: assetTypeList,
            AssetManager: AssetManager,
            AssetsByType: AssetsByType,
            assetInventoryByType: assetInventoryByType,
            assetInventoryByPerson: assetInventoryByPerson,
            DeleteUndeleteAssets: DeleteUndeleteAssets,
            AthletesInSection: AthletesInSection,
            UsersInGroup: UsersInGroup,
            AssetIssue: AssetIssue,
            AssetTakeBack: AssetTakeBack,
            IssuedAssets: IssuedAssets,
            getSectionData: getSectionData,
            editSection: editSection,
            getSectionLeaders: getSectionLeaders,
            CSVSettings: CSVSettings,
            CSVPreprocessSelectHeader: CSVPreprocessSelectHeader,
            EdzesFejLista: EdzesFejLista,
            EdzesSablon: EdzesSablon,
            ExpiringDocuments: ExpiringDocuments,
            getTrainingPracticeAutoComplete: getTrainingPracticeAutoComplete,
            getExercises: getExercises,
            IdegenKoltsegTervBuszTulaj: IdegenKoltsegTervBuszTulaj,
            IdegenKoltsegTervBusz: IdegenKoltsegTervBusz,
            saveTrainingPlan: saveTrainingPlan,
            getParentDataPOST: getParentDataPOST,
            getTrainingPlanList: getTrainingPlanList,
            EszkozIgenyLista: EszkozIgenyLista,
            GetTrainingPlanById: GetTrainingPlanById,
            ageGroupSelectYear: ageGroupSelectYear,
            ageGroupSelectBetweenDates: ageGroupSelectBetweenDates,
            getCoachContant: getCoachContant,
            deleteAwayBudgetPlan: deleteAwayBudgetPlan,
            SzakosztalyRuhai: SzakosztalyRuhai,
            DeleteTrainingPlan: DeleteTrainingPlan,
            noEducationalFee: noEducationalFee,
            getMonthlyAttendanceAutoSave: getMonthlyAttendanceAutoSave,
            setMonthlyAttendanceAutoSave: setMonthlyAttendanceAutoSave,
            CopyTimeAndPlaceFromWeeklyPlan: CopyTimeAndPlaceFromWeeklyPlan,
            SetPushNotificationSettings: SetPushNotificationSettings,
            SetPushNotificationFromWeb: SetPushNotificationFromWeb,
            HetiEdzestervLegregebbiHet: HetiEdzestervLegregebbiHet,
            getCompetitionPlaces: getCompetitionPlaces,
            GDPRExitAthlete: GDPRExitAthlete,
            GetUnreadMessageCount: GetUnreadMessageCount,
            FirebaseSubscribeToTopic: FirebaseSubscribeToTopic,
            FirebaseUnsubscribeFromTopic: FirebaseUnsubscribeFromTopic,
            GetRecipientsBySubjectId: GetRecipientsBySubjectId,
            SikerMobileFreeDeleteMessages: SikerMobileFreeDeleteMessages,
            SikerMobileFreeLeaveConversation: SikerMobileFreeLeaveConversation,
            SendSystemMessage: SendSystemMessage,
            deletePaint: deletePaint,
            DeleteMessageImage: DeleteMessageImage
        };

        var ds = this; // jshint ignore: line

        return service;

        function SikerMobileFreeLeaveConversation(targyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeLeaveConversation',
                params: {
                    targyId: targyId
                }
            };
            return $http(ds.req);
        }

        function DeleteMessageImage(imgPath) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileDeleteImage',
                data: imgPath
            };
            return $http(ds.req);
        }

        function deletePaint(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeletePaint',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function SendSystemMessage(recipientID, messageBody) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SystemMessage',
                params: {
                    recipient: recipientID,
                    message: messageBody
                }
            };
            return $http(ds.req);
        }

        function FirebaseSubscribeToTopic(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FirebaseSubscribeToTopic',
                data: data
            };
            return $http(ds.req);
        }

        function FirebaseUnsubscribeFromTopic(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FirebaseUnsubscribeFromTopic',
                data: data
            };
            return $http(ds.req);
        }

        function GetRecipientsBySubjectId(subjectId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CimjegyzekBySubjectId',
                params: {
                    subjectId: subjectId
                }
            };
            return $http(ds.req);
        }

        function GetUnreadMessageCount() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetUnreadMessageCount'
            };
            return $http(ds.req);
        }

        function GDPRExitAthlete(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GDPRExitAthlete',
                params: {
                    sportoloId: sportoloId
                }
            };
            return $http(ds.req);
        }

        function HetiEdzestervLegregebbiHet() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HetiEdzestervLegregebbiHet'
            };
            return $http(ds.req);
        }

        function SetPushNotificationSettings(data) {
            console.log(data);
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SetPushNotificationSettings',
                data: data
            };
            return $http(ds.req);
        }

        function SetPushNotificationFromWeb(data) {
            console.log('SetPushNotificationFromWeb', data);
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SetPushNotificationFromWeb',
                data: data
            };
            return $http(ds.req);
        }

        function CopyTimeAndPlaceFromWeeklyPlan(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CopyTimeAndPlaceFromWeeklyPlan',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function deleteAwayBudgetPlan(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'deleteAwayBudgetPlan',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function getCoachContant(edzoid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getCoachContant',
                params: {
                    edzoid: edzoid
                }
            };
            return $http(ds.req);
        }

        function noEducationalFee() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NoEducationalFee'
            };
            return $http(ds.req);
        }

        function EszkozIgenyLista(EdzesFejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EszkozIgenyLista',
                params: {
                    EdzesFejId: EdzesFejId
                }
            };
            return $http(ds.req);
        }

        function getParentDataPOST(parent, arg) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzuloAdatok',
                data: {
                    parent: parent,
                    arg: arg
                }
            };
            return $http(ds.req);
        }

        function IdegenKoltsegTervBuszTulaj() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IdegenKoltsegTervBuszTulaj'
            };
            return $http(ds.req);
        }

        function IdegenKoltsegTervBusz() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IdegenKoltsegTervBusz'
            };
            return $http(ds.req);
        }

        function EdzesSablon(EdzesFejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzesSablon',
                params: {
                    EdzesFejId: EdzesFejId
                }
            };
            return $http(ds.req);
        }

        function EdzesFejLista(szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzesFejLista',
                params: {
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function CSVPreprocessSelectHeader(file, headerRow, enc) {
            if (!enc) {
                enc = 'NULL';
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CSVPreprocessSelectHeader',
                params: {
                    file: file,
                    headerRow: headerRow,
                    enc: enc
                }
            };
            return $http(ds.req);
        }

        function CSVSettings(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CSVSettings',
                data: data
            };
            return $http(ds.req);
        }

        function getSectionLeaders() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getSectionLeaders'
            };
            return $http(ds.req);
        }

        function editSection(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'editSection',
                data: data
            };
            return $http(ds.req);
        }

        function getSectionData(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getSectionData',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function IssuedAssets(sportolo, edzo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IssuedAssets',
                params: {
                    sportolo: sportolo,
                    edzo: edzo
                }
            };
            return $http(ds.req);
        }

        function AssetTakeBack(assets, megjegyzes) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AssetTakeBack',
                data: {
                    assets: assets,
                    megjegyzes: megjegyzes
                }
            };
            return $http(ds.req);
        }

        function AssetIssue(assets, edzo, sportolo, megjegyzes) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AssetIssue',
                data: {
                    assets: assets,
                    edzo: edzo,
                    sportolo: sportolo,
                    megjegyzes: megjegyzes
                }
            };
            return $http(ds.req);
        }

        function AthletesInSection(section) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AthletesInSection',
                params: {
                    section: section
                }
            };
            return $http(ds.req);
        }

        function UsersInGroup(usergroup) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UsersInGroup',
                params: {
                    usergroup: usergroup
                }
            };
            return $http(ds.req);
        }

        function AssetsByType(assetType, filter) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AssetsByType',
                params: {
                    assetType: assetType,
                    filter: filter
                }
            };
            return $http(ds.req);
        }

        function DeleteUndeleteAssets(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteUndeleteAssets',
                data: data
            };
            return $http(ds.req);
        }

        function AssetManager(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AssetManager',
                data: data
            };
            return $http(ds.req);
        }

        function assetTypeList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'assetTypeList'
            };
            return $http(ds.req);
        }

        function GenerateRegNum() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GenerateRegNum'
            };
            return $http(ds.req);
        }

        function WeeklyTrainingPlaces() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'WeeklyTrainingPlaces'
            };
            return $http(ds.req);
        }

        function KoltesegvetesiTervLezaras(tervid, close) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltesegvetesiTervLezaras',
                params: {
                    tervid: tervid,
                    close: close
                }
            };
            return $http(ds.req);
        }

        function KoltesegvetesiTervVeglegesites(tervid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltesegvetesiTervVeglegesites',
                params: {
                    tervid: tervid
                }
            };
            return $http(ds.req);
        }

        function KoltesegvetesiTervJovahagyas(tervid, szint) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltesegvetesiTervJovahagyas',
                params: {
                    tervid: tervid,
                    szint: szint
                }
            };
            return $http(ds.req);
        }

        function KoltesegvetesiTervExcelExport(tervid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltesegvetesiTervExcelExport',
                params: {
                    tervid: tervid
                }
            };
            return $http(ds.req);
        }

        function KoltsegtervOsszesitoExcelExport(szakosztalyId, korosztalyId, edzoId, dateFrom, dateTo, onlyStartDate) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltsegtervOsszesitoExcelExport',
                params: {
                    szakosztalyId: szakosztalyId,
                    korosztalyId: korosztalyId,
                    edzoId: edzoId,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    onlyStartDate: onlyStartDate,
                }
            };
            return $http(ds.req);
        }

        function AcceptCSVPayment(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AcceptCSVPayment',
                data: data
            };
            return $http(ds.req);
        }

        function SaveAwayBudgetPlan(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveAwayBudgetPlan',
                data: data
            };
            return $http(ds.req);
        }

        function ErtesitestLatta(ertesitesid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ErtesitestLatta',
                params: {
                    ertesitesid: ertesitesid
                }
            };
            return $http(ds.req);
        }

        function GetAwayBudgetPlan(tervId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAwayBudgetPlan',
                params: {
                    tervId: tervId
                }
            };
            return $http(ds.req);
        }

        function getParentNotifications(fromDate, toDate, currentListSize, moreNewer, moreOlder, load, size) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getParentNotifications',
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                    currentListSize: currentListSize,
                    moreNewer: moreNewer,
                    moreOlder: moreOlder,
                    load: load,
                    size: size
                }
            };
            return $http(ds.req);
        }

        function getParentNotifications2(fromDate, toDate, currentListSize, moreNewer, moreOlder, load, size) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getParentNotifications2',
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                    currentListSize: currentListSize,
                    moreNewer: moreNewer,
                    moreOlder: moreOlder,
                    load: load,
                    size: size
                }
            };
            return $http(ds.req);
        }

        function GetPaintList(section) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetPaintList',
                params: {
                    section: section
                }
            };
            return $http(ds.req);
        }

        function GetPaints(sectionId, ageGroupId, coachId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetPaints',
                params: {
                    sectionId: sectionId,
                    ageGroupId: ageGroupId,
                    coachId: coachId
                }
            };
            return $http(ds.req);
        }

        function getImageOfUser(userid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getImageOfUser',
                params: {
                    userid: userid
                }
            };
            return $http(ds.req);
        }

        function getLog(table, pk, tol, ig) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getLog',
                params: {
                    table: table,
                    pk: pk,
                    tol: tol,
                    ig: ig
                }
            };
            return $http(ds.req);
        }

        function loadTrainingDiaryByMonthlyAttendanceId(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KepzesiNaploBetoltesIdAlapjan',
                params: {'id': id}
            };
            return $http(ds.req);
        }

        function EsemenyekAttolteseHetiEdzestervre(edzo, korosztaly, hetfo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EsemenyekAttolteseHetiEdzestervre',
                params: {
                    edzo: edzo,
                    korosztaly: korosztaly,
                    hetfo: hetfo
                }
            };
            return $http(ds.req);
        }

        function getParentNames() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzuloNevek',
            };
            return $http(ds.req);
        }

        function KedvezmenyNevekMentes(language, k1, k2, k3, k4, k5, ok1, ok2, ok3, ok4, ok5) {
            if (!k1) {
                k1 = '';
            }
            if (!k2) {
                k2 = '';
            }
            if (!k3) {
                k3 = '';
            }
            if (!k4) {
                k4 = '';
            }
            if (!k5) {
                k5 = '';
            }
            if (!ok1) {
                ok1 = '';
            }
            if (!ok2) {
                ok2 = '';
            }
            if (!ok3) {
                ok3 = '';
            }
            if (!ok4) {
                ok4 = '';
            }
            if (!ok5) {
                ok5 = '';
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KedvezmenyNevekMentes',
                params: {
                    language: language,
                    k1: k1,
                    k2: k2,
                    k3: k3,
                    k4: k4,
                    k5: k5,
                    ok1: ok1,
                    ok2: ok2,
                    ok3: ok3,
                    ok4: ok4,
                    ok5: ok5
                }
            };
            return $http(ds.req);
        }

        function OktatasiKedvezmenyNevek(language) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OktatasiKedvezmenyNevek',
                params: {
                    language: language
                }
            };
            return $http(ds.req);
        }

        function getReducedSportServiceFeeTypes(language) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetReducedSportServiceFeeTypes',
                params: {
                    language: language
                }
            };
            return $http(ds.req);
        }

        function getReducedEducationalFeeTypes(language) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetReducedEducationalFeeTypes',
                params: {
                    language: language
                }
            };
            return $http(ds.req);
        }

        function KedvezmenyNevek(language) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KedvezmenyNevek',
                params: {
                    language: language
                }
            };
            return $http(ds.req);
        }

        function SportoloCsoportokDropDownList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloCsoportokDropDownList',
            };
            return $http(ds.req);
        }

        function SzuloCsoportokDropDownList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzuloCsoportokDropDownList',
            };
            return $http(ds.req);
        }

        function resultChart3(sportolo, versenyszam, mettol, meddig, szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChart3',
                params: {
                    sportolo: sportolo,
                    versenyszam: versenyszam,
                    mettol: mettol,
                    meddig: meddig,
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function resultChart2(sportolo, versenyszam, mettol, meddig, szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChart2',
                params: {
                    sportolo: sportolo,
                    versenyszam: versenyszam,
                    mettol: mettol,
                    meddig: meddig,
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function resultChart1(sportolo, versenyszam, mettol, meddig, szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChart1',
                params: {
                    sportolo: sportolo,
                    versenyszam: versenyszam,
                    mettol: mettol,
                    meddig: meddig,
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function resultDateChart(sportolo, versenyszam, mettol, meddig, szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultDateChart',
                params: {
                    sportolo: sportolo,
                    versenyszam: versenyszam,
                    mettol: mettol,
                    meddig: meddig,
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function resultChartVersenyszamDropDown(sportolo, szakosztaly, korosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChartVersenyszamDropDownList',
                params: {
                    sportolo: sportolo,
                    szakosztaly: szakosztaly,
                    korosztaly: korosztaly
                }
            };
            return $http(ds.req);
        }

        function resultChartAgeGroupDropDown(sportolo, szakosztaly, versenyszam) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChartAgeGroupDropDownList',
                params: {
                    sportolo: sportolo,
                    szakosztaly: szakosztaly,
                    versenyszam: versenyszam
                }
            };
            return $http(ds.req);
        }

        function resultChartSectionDropDown(sportolo, korosztaly, versenyszam) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ResultChartSectionDropDownList',
                params: {
                    sportolo: sportolo,
                    korosztaly: korosztaly,
                    versenyszam: versenyszam
                }
            };
            return $http(ds.req);
        }

        function getAthletesBodyStat(sportoloid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloTestmeretStatisztika',
                params: {sportoloid: sportoloid}
            };
            return $http(ds.req);
        }

        function getUserId() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getUserId',
            };
            return $http(ds.req);
        }

        function UsersUserGroup(userid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportTagja',
                params: {userid: userid}
            };
            return $http(ds.req);
        }

        function getParentAndAthleteUsers(section, agegroup) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokhozKapcsolodoFelhasznalokOsszes',
                params: {
                    section: section,
                    agegroup: agegroup
                }
            };
            return $http(ds.req);
        }

        function unlinkUserFromAthlete(userid, sportoloid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UnlinkUserFromAthlete',
                params: {
                    userid: userid,
                    sportoloid: sportoloid
                }
            };
            return $http(ds.req);
        }

        function athleteAndParentDropDownList(role) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzuloEsSportoloUserDropDownList',
                params: {role: role}
            };
            return $http(ds.req);
        }

        function getUsersBelongingToAthlete(sportoloid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolohozKapcsolodoFelhasznalok',
                params: {sportoloid: sportoloid}
            };
            return $http(ds.req);
        }

        function getAthletesOfUser() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getAthletesOfUser'
            };
            return $http(ds.req);
        }

        function getAthleteidBelongsToAthleteUser() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getAthleteidBelongsToAthleteUser'
            };
            return $http(ds.req);
        }

        function assignAthleteToParentUser(parentuserid, sportoloid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AssignAthleteToParentUser',
                params: {
                    parentuserid: parentuserid,
                    sportoloid: sportoloid
                }
            };
            return $http(ds.req);
        }

        function createLoginForParent(parentUserName, parentPassword, parent, athleteId, group) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CreateLoginForParent',
                params: {
                    parentUserName: parentUserName,
                    parentPassword: parentPassword,
                    parent: parent,
                    athleteId: athleteId,
                    group: group
                }
            };
            return $http(ds.req);
        }

        function createLoginForAthlete(athleteUserName, athletePassword, athleteId, group) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CreateLoginForAthlete',
                params: {
                    athleteUserName: athleteUserName,
                    athletePassword: athletePassword,
                    athleteId: athleteId,
                    group: group
                }
            };
            return $http(ds.req);
        }

        function FelmeroTipusMasolas(fefid, targetsection, name) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroTipusMasolas',
                params: {
                    fefid: fefid,
                    targetsection: targetsection,
                    name: name
                }
            };
            return $http(ds.req);
        }

        function FelmeroMentes(arr, ffid, fefid, section, agegroup, coach, edzoertekeles, date) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroMentes',
                data: {
                    'data': arr,
                    'ffid': ffid,
                    'fefid': fefid,
                    'section': section,
                    'agegroup': agegroup,
                    'coach': coach,
                    'edzoertekeles': edzoertekeles,
                    'date': date
                }
            };
            return $http(ds.req);
        }

        function FelmeroLetrehozasa(coach, agegroup, fefid, date) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroLetrehozasa',
                params: {
                    coach: coach,
                    agegroup: agegroup,
                    fefid: fefid,
                    date: date
                }
            };
            return $http(ds.req);
        }

        function getSportagSpecReport(fefid, agegroup, section, fromdate, todate) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportagSpecReport',
                params: {
                    fefid: fefid,
                    agegroup: agegroup,
                    section: section,
                    fromdate: fromdate,
                    todate: todate
                }
            };
            return $http(ds.req);
        }

        function getFelmeroById(FFID) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroBetoltese',
                params: {
                    FFID: FFID
                }
            };
            return $http(ds.req);
        }

        function deleteFelmeroElemFej(fefid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemFejTorles',
                params: {
                    fefid: fefid
                }
            };
            return $http(ds.req);
        }

        function deleteFelmero(ffid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroTorles',
                params: {
                    ffid: ffid
                }
            };
            return $http(ds.req);
        }

        function saveFelmeroElemFej(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemFejLetrehozasa',
                data: data
            };
            return $http(ds.req);
        }

        function getFelmeroElemFejList(section) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemFejLista',
                params: {
                    section: section
                }
            };
            return $http(ds.req);
        }

        function saveFelmeroElem(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemLetrehozasa',
                data: data
            };
            return $http(ds.req);
        }

        function getFelmeroElemList(fefid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemLista',
                params: {
                    fefid: fefid
                }
            };
            return $http(ds.req);
        }

        function getAbsenceReport(section, agegroup, coach, tol, ig) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'Hianyzasok',
                params: {
                    section: section,
                    agegroup: agegroup,
                    coach: coach,
                    tol: tol,
                    ig: ig
                }
            };
            return $http(ds.req);
        }

        function getExpiringDocuments(section, agegroup, coach, to, spc, pp, pid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'LejaroDokumentumok',
                params: {
                    section: section,
                    agegroup: agegroup,
                    coach: coach,
                    to: to,
                    spc: spc,
                    pp: pp,
                    pid: pid,
                    sportoloid: 0
                }
            };
            return $http(ds.req);
        }

        function userGroupList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportokDropDown',
            };
            return $http(ds.req);
        }

        function deleteUserGroup(userGroupId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportTorlese',
                params: {
                    userGroupId: userGroupId
                }
            };
            return $http(ds.req);
        }

        function monthlyPlanSelectByDate(dateyear, datemonth, szakosztalyId, edzoId, newplan, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviByDate',
                params: {
                    dateyear: dateyear,
                    datemonth: datemonth,
                    szakosztalyId: szakosztalyId,
                    edzoId: edzoId,
                    newplan: newplan, // Ha uj tervet akarok létrehozni akkor nem dob hibaüzenetet ha az adott hónapra nincs még versenyterv
                    egyeni: egyeni
                }
            };
            return $http(ds.req);
        }

        function ujHaviVersenyeztetesiTerv(date, szakosztalyId, edzoId, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UjHaviVersenyeztetesiTerv',
                params: {
                    date: date,
                    szakosztalyId: szakosztalyId,
                    edzoId: edzoId,
                    egyeni: egyeni
                }
            };
            return $http(ds.req);
        }

        function activeAthletesByBirthYear(sectionId, year, gender) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AktivSportolokSzuletesievEsSzakosztalySzerint',
                params: {
                    'sectionId': sectionId,
                    'year': year,
                    'gender': gender
                }
            };
            return $http(ds.req);
        }

        function getAthleteMoveStory(SportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloMozgasa',
                params: {
                    'SportoloId': SportoloId
                }
            };
            return $http(ds.req);
        }

        function VersenyTervHaviDeleteById(id, SzakosztalyId, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviDeleteById',
                params: {
                    'id': id,
                    'SzakosztalyId': SzakosztalyId,
                    'egyeni': egyeni
                }
            };
            return $http(ds.req);
        }

        function activeAthletesByCoach(coachId, sectionId, dateFrom, gender) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoAktivakSzama_Sportolok',
                params: {
                    'edzoId': coachId,
                    'szakosztalyId': sectionId,
                    'datumTol': dateFrom,
                    'neme': gender
                }
            };
            return $http(ds.req);
        }

        function joinedAthletesToASpecificCoach(edzoId, datumTol, datumIg, gender) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoBelepokSzama_Sportolok',
                params: {
                    'edzoId': edzoId,
                    'datumTol': datumTol,
                    'datumIg': datumIg,
                    'neme': gender
                }
            };
            return $http(ds.req);
        }

        function leavingAthletesFromASpecificCoach(edzoId, datumTol, datumIg, gender) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoKilepokSzama_Sportolok',
                params: {
                    'edzoId': edzoId,
                    'datumTol': datumTol,
                    'datumIg': datumIg,
                    'neme': gender
                }
            };
            return $http(ds.req);
        }

        function JoinedAthletesInASpecificMonth(honap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamanakAlakulasa_BelepettSportolok',
                params: {
                    'honap': honap
                }
            };
            return $http(ds.req);
        }

        function LeavingAthletesInASpecificMonth(honap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamanakAlakulasa_KilepettSportolok',
                params: {
                    'honap': honap
                }
            };
            return $http(ds.req);
        }

        function ActiveAthletesInASpecificMonth(honap, datumig, szakosztalyId, neme) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamanakAlakulasaSzakosztalyonkent_sportolok',
                params: {
                    'honap': honap,
                    'datumig': datumig,
                    'szakosztalyId': szakosztalyId,
                    'gender': neme
                }
            };
            return $http(ds.req);
        }

        function athletesNotPresentOnMonthlyAttendance(datumTol, datumIg, sectionId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletinNemSzereploSportolok',
                params: {
                    'szakosztalyId': sectionId,
                    'tol': datumTol,
                    'ig': datumIg
                }
            };
            return $http(ds.req);
        }

        function activeAthletesInSectionByMonth(datumTol, datumIg, SzakosztalyNeve, sectionId) {
            if (!sectionId) {
                sectionId = -1;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamanakAlakulasaSzakosztalyonkent',
                params: {
                    'datumTol': datumTol,
                    'datumIg': datumIg,
                    'sectionId': sectionId
                }
            };
            return $http(ds.req);
        }

        function activeAthletesByMonth(datumTol, datumIg) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamanakAlakulasa',
                params: {
                    'datumTol': datumTol,
                    'datumIg': datumIg
                }
            };
            return $http(ds.req);
        }

        function activeAthletesBornAtSpecificYear(year, sectionId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokEvszamSzerint',
                params: {
                    'ev': year,
                    'szakosztalyId': sectionId
                }
            };
            return $http(ds.req);
        }

        function activeAthletesByAge(date, sectionId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzuletesiIdoAlapjan',
                params: {
                    'datum': date,
                    'szakosztaly': sectionId
                }
            };
            return $http(ds.req);
        }

        function joinedAthletesByCoach(dateFrom, dateTo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'BelepoSportolokSzamaEdzonkent',
                params: {
                    'dateFrom': dateFrom,
                    'dateTo': dateTo
                }
            };
            return $http(ds.req);
        }

        function leavingAthletesByCoach(dateFrom, dateTo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KilepoSportolokSzamaEdzonkent',
                params: {
                    'dateFrom': dateFrom,
                    'dateTo': dateTo
                }
            };
            return $http(ds.req);
        }

        function athletesByCoach(date) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokSzamaEdzonkent',
                params: {
                    'datum': date
                }
            };
            return $http(ds.req);
        }

        function listAthletes(dateFrom, dateTo, state, sectionId, gender) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportolokListaSzakosztalyonkent',
                params: {
                    'dateFrom': dateFrom,
                    'dateTo': dateTo,
                    'state': state,
                    'sectionId': sectionId,
                    'gender': gender
                }
            };
            return $http(ds.req);
        }

        function leavingAthletesBySection(queryDateFrom, queryDateTo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyKilepokSzama',
                params: {
                    'datum': queryDateFrom,
                    'datum2': queryDateTo,
                }
            };
            return $http(ds.req);
        }

        function joinedAthletesBySection(queryDateFrom, queryDateTo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyBelepokSzama',
                params: {
                    'datum': queryDateFrom,
                    'datum2': queryDateTo,
                }
            };
            return $http(ds.req);
        }

        function range(start, stop, step) {
            if (stop == null) {
                stop = start || 0;
                start = 0;
            }
            step = step || 1;

            if (start <= stop) {
                var length = Math.max(Math.ceil((stop - start) / step), 0);
                var range = Array(length);

                for (var idx = 0; idx <= length; idx++, start += step) {
                    range[idx] = start;
                }
            } else {
                var length = Math.max(Math.ceil((start - stop) / step), 0);
                var range = Array(length);

                for (var idx = 0; idx <= length; idx++, start -= step) {
                    range[idx] = start;
                }
            }

            return range;
        }

        function getURL() {
            return DOMAIN.baseURL + DOMAIN.webService;
        }

        function changePassword(oldPwd, newPwd) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ChangePassword',
                data: {
                    'oldPassword': oldPwd,
                    'newPassword': newPwd
                }
            };
            return $http(ds.req);
        }

        function login(loginName, password, language) {
            ds.req = {
                type: 'apiTOKEN',
                url: DOMAIN.baseURL + 'Token',
                data: $.param({
                    grant_type: 'password',
                    username: loginName,
                    password: password,
                    language: language,
                })
            };
            return $http(ds.req);
        }

        function logout() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'Logout'
            };
            return $http(ds.req);
        }

        function getHaveToPay() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaveToPay'
            };
            return $http(ds.req);
        }

        function getAvailableLanguages() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AvailableLanguages'
            };
            return $http(ds.req);
        }

        function sectionDropDownList(edzoId, osszesSzakosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDropDownList',
                params: {edzoId: edzoId, osszesSzakosztaly: osszesSzakosztaly}
            };
            return $http(ds.req);
        }

        function sectionDropDownListWithGroupStatus(edzoId, osszesSzakosztaly) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDropDownListWithGroupStatus',
                params: {edzoId: edzoId, osszesSzakosztaly: osszesSzakosztaly}
            };
            return $http(ds.req);
        }

        function sectionDropDownListAll() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDropDownListAll'
            };
            return $http(ds.req);
        }

        function sectionDropDownListWithFees(edzoId) {
            if (!edzoId) {
                edzoId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDijDropDownLista',
                params: {edzoId: edzoId}
            };
            return $http(ds.req);
        }

        function getDuplicatedAthletes(inactivesToo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DuplikaltFelhasznalokSelect',
                params: {inactivesToo: inactivesToo}
            };
            return $http(ds.req);
        }

        function getAthletesFromFeeRegister(sectionId, coachId, year, months) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAthletesForFeeRegister',
                params: {sectionId: sectionId, year: year, coachId: coachId, months: months}
            };
            return $http(ds.req);
        }

        function getAthletesForEducationalFeeRegister(sectionId, coachId, year, months) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAthletesForEducationalFeeRegister',
                params: {sectionId: sectionId, year: year, coachId: coachId, months: months}
            };
            return $http(ds.req);
        }

        function ageGroupDropDownList(szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorosztalyokDropDownList',
                params: {'szakosztalyId': szakosztalyId}
            };
            return $http(ds.req);
        }

        function getIdegenbeliKoltsegvetesiTervEdzoList(szakosztalyId, korosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetIdegenbeliKoltsegvetesiTervEdzoList',
                params: {szakosztalyId: szakosztalyId, korosztalyId: korosztalyId}
            };
            return $http(ds.req);
        }

        function getAwayBudgetPlanList(szakosztalyId, korosztalyId, edzoId, dateFrom, dateTo, onlyStartDate, type, versenyHely) {
            if (!onlyStartDate) {
                onlyStartDate = false;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IdegenbeliKoltsegvetesiTervLista',
                params: {
                    szakosztalyId: szakosztalyId,
                    korosztalyId: korosztalyId,
                    edzoId: edzoId,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    onlyStartDate: onlyStartDate,
                    type: type,
                    versenyHely: versenyHely
                }
            };
            return $http(ds.req);
        }

        function ageGroupDropDownListForSearch(sectionId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorosztalyokDropDownListForSearch',
                params: {'sectionId': sectionId}
            };
            return $http(ds.req);
        }

        function HealthReport(dateFrom, dateTo, szakosztalyId, korcsoportId, edzoId, fotipus, altipus) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HealthReport',
                params: {
                    'fotipus': fotipus,
                    'altipus': altipus,
                    'dateFrom': dateFrom,
                    'dateTo': dateTo,
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'edzoId': edzoId
                }
            };
            return $http(ds.req);
        }

        function getUpAndDownPlayers(szakosztalyId, korcsoportId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AtjatszoSportolokSelect',
                params: {'szakosztalyId': szakosztalyId, 'korcsoportId': korcsoportId, 'edzoId': edzoId}
            };
            return $http(ds.req);
        }

        function getMonthlyCompetitivePlans(tol, ig, szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviVersenyeztetesiTervLeadasokSelect',
                params: {'szakosztalyId': szakosztalyId, 'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getMonthlyAttendanceReports(tol, ig, szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VezetoiHaviJelenletikSelect',
                params: {'szakosztalyId': szakosztalyId, 'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getTrainingDiaryReports(tol, ig, szakosztalyId, edzoId, korosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviKepzesiNaplokSelect',
                params: {
                    'tol': tol,
                    'ig': ig,
                    'szakosztalyId': szakosztalyId,
                    'edzoId': edzoId,
                    'korosztalyId': korosztalyId
                }
            };
            return $http(ds.req);
        }

        function getAnnualCompetitiveReports(tol, ig, szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VezetoiEvesVersenyeztetesiTervekSelect',
                params: {'szakosztalyId': szakosztalyId, 'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getMonthlyCompetitiveReports(tol, ig, szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VezetoiHaviVersenyeztetesiTervekSelect',
                params: {'szakosztalyId': szakosztalyId, 'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getWeeklyTrainingPlans(tol, ig, szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VezetoiHetiEdzestervekSelect',
                params: {'szakosztalyId': szakosztalyId, 'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getTrainingAttendances(tol, ig, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzeslatogatasListaSelect',
                params: {'tol': tol, 'edzoId': edzoId, 'ig': ig}
            };
            return $http(ds.req);
        }

        function getUserGroupTypes() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetUserGroupTypes'
            };
            return $http(ds.req);
        }

        function teamsDropDownList(sectionId, ageGroupId, coachId) {
            if (sectionId === undefined) {
                sectionId = 0;
            }
            if (ageGroupId === undefined) {
                ageGroupId = 0;
            }
            if (coachId === undefined) {
                coachId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TeamsDropDownList',
                params: {
                    'sectionId': sectionId,
                    'ageGroupId': ageGroupId,
                    'coachId': coachId
                }
            };
            return $http(ds.req);
        }

        function deleteTeamImg(csapatId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteTeamImg',
                params: {'csapatId': csapatId}
            };
            return $http(ds.req);
        }

        function ageGroupList(szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorosztalyokList',
                params: {'szakosztalyId': szakosztalyId}
            };
            return $http(ds.req);
        }

        function getAgeGroupMembersList(szakosztaly, korcsoport) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorosztalySportoloi',
                params: {
                    'szakosztaly': szakosztaly,
                    'korcsoport': korcsoport
                }
            };
            return $http(ds.req);
        }

        function getServiceFeePaymentsNumbers(szakosztalyId, datum) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiTagdijfizetesekSorszamok',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'datum': datum
                }
            };
            return $http(ds.req);
        }

        function getEducationalFeePaymentsNumbers(szakosztalyId, datum) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiOktatasiDijFizetesekSorszamok',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'datum': datum
                }
            };
            return $http(ds.req);
        }

        function getCheck(checkNumber) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsekkBefizetesBetolt',
                params: {'azonosito': checkNumber}
            };
            return $http(ds.req);
        }

        function getCheckExportPrintList(sectionId, coachId, dateFromValue, dateToValue) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsekkNyomtatasLista',
                params: {
                    'SzakosztalyId': sectionId,
                    'EdzoId': coachId,
                    'datumTol': dateFromValue,
                    'datumIg': dateToValue
                }
            };
            return $http(ds.req);
        }

        function getAgeGroup(korcsoport) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorosztalyLekerese',
                params: {'id': korcsoport}
            };
            return $http(ds.req);
        }

        function coachDropDownList(szakosztalyId, korosztalyId, datumtol, datumig) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoDropDownList',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korosztalyId,
                    datumtol: datumtol,
                    datumig: datumig
                }
            };
            return $http(ds.req);
        }

        function getAnthropometryListForPrinting(from, to) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TomegesAntropometriaNyomtatas',
                params: {
                    'from': from,
                    'to': to
                }
            };
            return $http(ds.req);
        }

        function teamList(szakosztalyId, korosztalyId, inactive) {
            if (angular.isUndefined(korosztalyId) || korosztalyId === '') {
                korosztalyId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatList',
                params: {'szakosztalyId': szakosztalyId, 'korosztalyId': korosztalyId, 'inactive': inactive}
            };
            return $http(ds.req);
        }

        function getUserGroupList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportokSelect'
            };
            return $http(ds.req);
        }

        function saveTeam(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveTeam',
                data: formData
            };
            return $http(ds.req);
        }

        function getIdegenbeliKoltsegvetesiTervSportoloList(szakosztalyId, AgeGroups) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetIdegenbeliKoltsegvetesiTervSportoloList',
                data: {szakosztalyId: szakosztalyId, AgeGroups: AgeGroups}
            };
            return $http(ds.req);
        }

        function saveExercise(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveExercise',
                data: formData
            };
            return $http(ds.req);
        }

        function saveAgeGroup(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportMentes',
                data: formData
            };
            return $http(ds.req);
        }

        function saveAnthropometry(ant, fileData, fileName) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AntropometriaMentes',
                data: {
                    ant: ant,
                    fileData: fileData,
                    fileName: fileName
                }
            };
            return $http(ds.req);
        }

        function savePerformanceMotivationSurvey(athleteId, answers) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SavePerformanceMotivationSurvey',
                data: {
                    answers: answers,
                    sportoloId: athleteId
                }
            };
            return $http(ds.req);
        }

        function savePieronTest(sportoloId, answers, seen, tNumber, wrongAnswersNumber) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SavePieronTest',
                data: {
                    sportoloId: sportoloId,
                    answers: answers,
                    n: seen,
                    tNumber: tNumber,
                    wrongAnswersNumber: wrongAnswersNumber
                }
            };
            return $http(ds.req);
        }

        function savePerformanceMotivationSurveyEval(surveyId, szoveg) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SavePerformanceMotivationSurveyEval',
                data: {
                    surveyId: surveyId,
                    szoveg: szoveg
                }
            };
            return $http(ds.req);
        }

        function getPerformanceMotivationSurvey(surveyId, noText) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetPerformanceMotivationSurvey',
                params: {surveyId: surveyId, noText: noText}
            };
            return $http(ds.req);
        }

        function getPieronTest(surveyId, noText) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetPieronTest',
                params: {surveyId: surveyId, noText: noText}
            };
            return $http(ds.req);
        }

        function getAthletesForDailyPayments(szakosztalyId, edzoId, datum, sorszam) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiTagdijFizetok',
                params: {datum: datum, szakosztalyId: szakosztalyId, edzoId: edzoId, sorszam: sorszam}
            };
            return $http(ds.req);
        }

        function getAthletesForDailyPayments2(szakosztalyId, edzoId, datum, sorszam) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiOktatasiDijFizetok',
                params: {szakosztalyId: szakosztalyId, datum: datum, edzoId: edzoId, sorszam: sorszam}
            };
            return $http(ds.req);
        }

        function modifyAgeGroup(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportModositasa',
                data: formData
            };
            return $http(ds.req);
        }

        function addUser(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloMentes',
                data: formData
            };
            return $http(ds.req);
        }

        function searchAthlete(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloKereses/Kereses',
                data: formData
            };
            return $http(ds.req);
        }

        function searchUser(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloKereses',
                data: formData
            };
            return $http(ds.req);
        }

        function searchMemberList(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TaglistaKereses',
                data: formData
            };
            return $http(ds.req);
        }

        function modifyUser(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloModositas',
                data: formData
            };
            return $http(ds.req);
        }

        function modifyUserMin(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloModositasMin',
                data: formData
            };
            return $http(ds.req);
        }

        function getAgeGroupTreeView() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokKorosztalyokTreeViewLekeres'
            };
            return $http(ds.req);
        }

        function getSystemSettings(valtozo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetSystemSettings',
                params: {valtozo: valtozo}
            };
            return $http(ds.req);
        }

        function getSystemSettingsValue(valtozo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetSystemSettingsValue',
                params: {valtozo: valtozo}
            };
            return $http(ds.req);
        }

        function SzakosztalyRuhai(section, section2) {
            if (!section2) {
                section2 = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyRuhai',
                params: {section: section, section2: section2}
            };
            return $http(ds.req);
        }

        function saveSystemSettings(valtozo, ertek) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveSystemSettings',
                data: {valtozo: valtozo, ertek: ertek}
            };
            return $http(ds.req);
        }

        function saveSystemSettingsValue(valtozo, ertek) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveSystemSettingsValue',
                data: {valtozo: valtozo, ertek: ertek}
            };
            return $http(ds.req);
        }

        function getSectionLeaderListBox() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokVezetovelLekeres'
            };
            return $http(ds.req);
        }

        function copyAllAgeGroup(forrasSzakosztalyId, celSzakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportMasolas',
                params: {'forrasSzakosztalyId': forrasSzakosztalyId, 'celSzakosztalyId': celSzakosztalyId}
            };
            return $http(ds.req);
        }

        function getAnnualSportServiceFeeBySection(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijBySzakosztalySelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function getAnnualEducationalFeeBySection(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OktatasiDijBySzakosztalySelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function getAnnualSportServiceFeeByOneSection(szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijByEgySzakosztaly',
                params: {'szakosztaly': szakosztalyId}
            };
            return $http(ds.req);
        }

        function getAnnualEducationalFeeByOneSection(szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OktatasiDijByEgySzakosztaly',
                params: {'szakosztaly': szakosztalyId}
            };
            return $http(ds.req);
        }

        function getAnnualSportServiceFeeByCoach(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijByEdzoSelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function getAnnualEducationalFeeByCoach(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OktatasiDijByEdzoSelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function getAnnualSportServiceFeeByAthletes(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijBySportoloSelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function getAnnualEducationalFeeByAthletes(year) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OktatasiDijBySportoloSelect',
                params: {'year': year}
            };
            return $http(ds.req);
        }

        function modifyTeam(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ModifyTeam',
                data: formData
            };
            return $http(ds.req);
        }

        function getUserGroupDropDownList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportokDropDownList'
            };
            return $http(ds.req);
        }

        function getFullUserGroupList(group) {
            if (!group) {
                group = 'all';
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportokList',
                params: {'group': group}
            };
            return $http(ds.req);
        }

        function getTeamMembersList(teamId, date) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatSportoloi',
                params: {csapatId: teamId, date: date}
            };
            return $http(ds.req);
        }

        function getPossibleTeamMembersList(teamId, szakosztalyId, korosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'csapatLehetsegesSportoloi',
                params: {csapatId: teamId, korcsoportId: korosztalyId, szakosztalyId: szakosztalyId}
            };
            return $http(ds.req);
        }

        function addTeamMemberToTeam(sportoloId, csapatId) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatSportoloOsszerendeles',
                data: {
                    CsapatId: csapatId,
                    SportoloId: sportoloId
                }
            };
            return $http(ds.req);
        }

        function deleteTeamMember(teamId, sportoloIds) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatSportoloTorles',
                data: {
                    CsapatId: teamId,
                    SportoloIdk: sportoloIds
                }
            };
            return $http(ds.req);
        }

        function deleteTeam(teamId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsoportTorlese',
                params: {Id: teamId}
            };
            return $http(ds.req);
        }

        function deleteTeamsPlayers(teamId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatOsszesSportoloTorles',
                params: {csapatId: teamId}
            };
            return $http(ds.req);
        }

        function getTeam(teamId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatLekerese',
                params: {csapatId: teamId}
            };
            return $http(ds.req);
        }

        function usersListSelect() {
            ds.req = {
                type: 'apiGET',
                withCredentials: true,
                url: DOMAIN.baseURL + DOMAIN.webService + 'felhasznalokListaSelect',
                data: {}
            };
            return $http(ds.req);
        }

        function addUserGroup(csoport) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportHozzaadas',
                data: csoport
            };
            return $http(ds.req);
        }

        function editUserGroup(csoport) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportModositas',
                data: csoport
            };
            return $http(ds.req);
        }

        function selectSchools() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IskolakSelect'
            };
            return $http(ds.req);
        }

        function getAthletes(szakosztalyId, edzoId, sportoloId) {
            if (sportoloId === null || sportoloId === undefined) {
                sportoloId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getAthletes',
                params: {szakosztalyId: szakosztalyId, edzoId: edzoId, sportoloId: sportoloId}
            };
            return $http(ds.req);
        }

        function getAthletesEV(szakosztalyId, edzoId, sportoloId, csakAktiv) {
            if (sportoloId === null || sportoloId === undefined) {
                sportoloId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getAthletes',
                params: {szakosztalyId: szakosztalyId, edzoId: edzoId, sportoloId: sportoloId, csakAktiv: csakAktiv}
            };
            return $http(ds.req);
        }

        function athletesSelect(szakosztalyId, korcsoportId, onlyInactives) {
            if (!szakosztalyId) {
                szakosztalyId = 0;
            }
            if (!korcsoportId || korcsoportId == 'Összes') {
                korcsoportId = 0;
            }
            onlyInactives = (onlyInactives === true);
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'szakosztalySportoloi2',
                dataFilter: function (data) {
                    return data.replace(/"\\\/(Date\([0-9-]+\))\\\/"/gi, 'new $1');
                },
                params: {'szakosztaly': szakosztalyId, 'inactive': onlyInactives, 'korcsoport': korcsoportId}
            };
            return $http(ds.req);
        }

        function athletesSelect2(szakosztalyId, korcsoportId) {
            if (!korcsoportId || korcsoportId == 'Összes') {
                korcsoportId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GyorsEredmenyjelentoSportoloSelect',
                params: {'szakosztaly': szakosztalyId, 'korcsoport': korcsoportId}
            };
            return $http(ds.req);
        }

        function addSchool(school) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IskolaHozzaadas',
                data: school
            };
            return $http(ds.req);
        }

        function editSchool(newSchool) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IskolaModositas',
                data: newSchool
            };
            return $http(ds.req);
        }

        function transferAthletes(sportolok, edzoId, szakosztalyId, korosztalyId, datum, korcsoportValtasKell) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TransferAthletes',
                data: {
                    sportolok: sportolok,
                    edzoId: edzoId,
                    szakosztalyId: szakosztalyId,
                    korosztalyId: korosztalyId,
                    datum: datum,
                    korcsoportValtasKell: korcsoportValtasKell
                }
            };
            return $http(ds.req);
        }

        function quickCompetitionResult(sportolok) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GyorsEredmenyjelentoSportoloMentes',
                data: {
                    sportolok: sportolok
                }
            };
            return $http(ds.req);
        }

        function deleteSchool(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'IskolaTorles',
                params: {id: id}
            };
            return $http(ds.req);
        }

        function getUserData(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloSelect',
                params: {userId: id}
            };
            return $http(ds.req);
        }

        function getUserDataMin(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloSelectMin',
                params: {userId: id}
            };
            return $http(ds.req);
        }

        function getAthleteForAnthropometry(id, felmeres) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getAthleteForAnthropometry',
                params: {id: id, felmeres: felmeres}
            };
            return $http(ds.req);
        }

        function getOldCompetitivePlanByUserId(edzoId, vtId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoKorabbiHaviVersTervei',
                params: {
                    'edzoId': edzoId,
                    'vtId': vtId
                }
            };
            return $http(ds.req);
        }

        function getOldAnthropometryTest(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'RegiAntropometriaFelmeresekSelect',
                params: {sportoloId: sportoloId}
            };
            return $http(ds.req);
        }

        function coachSelect() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoFelhasznalokDropDownSelect',
                data: {}
            };
            return $http(ds.req);
        }

        function ageGroupSelect(edzoId, szakosztalyId, datum) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportokEdzoSzakosztalyDropDownSelect',
                params: {'szakId': szakosztalyId, 'edzoId': edzoId, 'dt': datum}
            };
            return $http(ds.req);
        }

        function ageGroupSelectYear(edzoId, szakosztalyId, year) {
            if (!szakosztalyId) {
                szakosztalyId = 0;
            }
            if (!edzoId) {
                edzoId = 0;
            }
            if (!year) {
                year = moment().year;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportokEdzoSzakosztalyDropDownSelectYear',
                params: {'szakId': szakosztalyId, 'edzoId': edzoId, 'year': year}
            };
            return $http(ds.req);
        }

        function ageGroupSelectBetweenDates(edzoId, szakosztalyId, from, to) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorcsoportokEdzoSzakosztalyDropDownSelectBetweenDates',
                params: {'szakId': szakosztalyId, 'edzoId': edzoId, 'from': from, 'to': to}
            };
            return $http(ds.req);
        }

        function getAthleteFees(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAthleteFees',
                params: {'sportoloId': sportoloId}
            };
            return $http(ds.req);
        }

        function getAthletesForFeeArrears(szakosztalyId, edzoId, ev) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAthletesForFeeArrears',
                params: {'year': ev, 'sectionId': szakosztalyId, 'coachId': edzoId}
            };
            return $http(ds.req);
        }

        function monthlyAttendanceSelect(edzoId, szakosztalyId, korosztalyId, ev, honap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiLekeres',
                params: {
                    'kivEdzo': edzoId,
                    'kivSzakosztaly': szakosztalyId,
                    'kivKorcsoport': korosztalyId,
                    'kivEv': ev,
                    'kivHonap': honap
                }

            };
            return $http(ds.req);
        }

        function getGymTeachers(IskolaNeve) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TestneveloAutoComple',
                params: {'IskolaNeve': IskolaNeve}
            };
            return $http(ds.req);
        }

        function getGymTeacherContact(testnevelo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TestneveloElerhetosege',
                params: {'testnevelo': testnevelo}
            };
            return $http(ds.req);
        }

        function getClassMasters(IskolaNeve) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OsztalyfonokAutoComplete',
                params: {'IskolaNeve': IskolaNeve}
            };
            return $http(ds.req);
        }

        function getClassMasterContact(classmaster) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'OsztalyfonokElerhetosege',
                params: {'classmaster': classmaster}
            };
            return $http(ds.req);
        }

        function monthlyAttendanceSelectById(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiLekeresById',
                params: {'id': id}
            };
            return $http(ds.req);
        }

        function saveDailyAttendance(attendance) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiJelenletiMentes',
                data: attendance
            };
            return $http(ds.req);
        }

        function saveMonthlyAttendance(attendance) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiMentes',
                data: attendance
            };
            return $http(ds.req);
        }

        function saveTrainingDiary(tetelek, jelenletiid, keszult) {
            var data = {};
            data.edzesmunka = tetelek;
            data.JelenletiFejId = jelenletiid;
            data.keszult = keszult;
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KepzesiNaploMentes',
                data: data
            };
            return $http(ds.req);
        }

        function getAthleteData(athleteId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'sportoloSelect',
                params: {'sportoloId': athleteId}
            };
            return $http(ds.req);
        }

        function getAthleteDataMin(athleteId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloSelectMin',
                params: {'sportoloId': athleteId}
            };
            return $http(ds.req);
        }

        function monthlyAttendanceRefreshAthleteData(fejid) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiSportoloAdatokFrissites',
                params: {'fejid': fejid}
            };
            return $http(ds.req);
        }

        function getNewAthletesInSelectedYearMonth(hj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UjJatekosokKivHonapbanSelect',
                data: hj
            };
            return $http(ds.req);
        }

        function monthlyAttendanceAcceptByLeader(idMA) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'haviJelenletiJovahagyas',
                data: idMA
            };
            return $http(ds.req);
        }

        function HaviEdzesTervJovahagyas(id) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviEdzesTervJovahagyas',
                data: id
            };
            return $http(ds.req);
        }

        function HetiEdzesTervJovahagyas(id) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HetiEdzesTervJovahagyas',
                data: id
            };
            return $http(ds.req);
        }

        function messageSave(uzenet, cimzettekId, sectionId) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetMentes',
                data: {
                    uzenet: uzenet,
                    cimzettekId: cimzettekId,
                    sectionId: sectionId
                }
            };
            return $http(ds.req);
        }

        function getAnnualSeasonPlanArrears() {
            //Datum=JSON.stringify(Datum);
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiLemaradasok'
            };
            return $http(ds.req);
        }

        function getLastMonthlyAttendanceTrainingLocationAndTime(edzoId, kivSzakosztaly, kivKorcsoport) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiUtolsoEdzesAdatok',
                params: {'edzoId': edzoId, 'kivSzakosztaly': kivSzakosztaly, 'kivKorcsoport': kivKorcsoport}
            };
            return $http(ds.req);
        }

        function getAthletesForMonthlyAttendance(edzoId, kivSzakosztaly, kivKorcsoport, kivHonap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiEdzoJatekosaiKivHonapban',
                params: {
                    'edzoId': edzoId,
                    'kivSzakosztaly': kivSzakosztaly,
                    'kivKorosztaly': kivKorcsoport,
                    'kivHonap': kivHonap
                }
            };
            return $http(ds.req);
        }

        function getAthletesCountForMonthlyAttendance(edzoId, szakosztalyId, korcsoportId, tol, ig) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiSzakosztalySportoloi',
                params: {
                    'edzoId': edzoId,
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'tol': tol,
                    'ig': ig
                }
            };
            return $http(ds.req);
        }

        function getMonthlyAttendanceList(edzoId, tol, ig, lezart) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletikLista',
                params: {'kivEdzo': edzoId, 'tol': tol, 'ig': ig, 'lezart': lezart}
            };
            return $http(ds.req);
        }

        function getUserUnreadedMessages() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'felhasznaloOlvasatlanUzenetei'
            };
            return $http(ds.req);
        }

        function unreadedMessagesViewed(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetOlvasottaTesz',
                params: {'uzenetId': id}
            };
            return $http(ds.req);
        }

        function messageDelete(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetTorlese',
                params: {'uzenetId': id}
            };
            return $http(ds.req);
        }

        function messageDeleteSent(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetTorleseElkuldottekbol',
                params: {'uzenetId': id}
            };
            return $http(ds.req);
        }

        function getUnAcceptedMonthlyAttendance() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiSzakvezAlairni'
            };
            return $http(ds.req);
        }

        function getUnAcceptedMonthlyCompetitionPlan() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviAlairni'
            };
            return $http(ds.req);
        }

        function getUnAcceptedWeeklyCompetitionPlan() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHetiAlairni'
            };
            return $http(ds.req);
        }

        function getUnAcceptedYearlyCompetitionPlan() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervEvesAlairni'
            };
            return $http(ds.req);
        }

        function getLinks() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HasznosLinkek'
            };
            return $http(ds.req);
        }

        function saveLink(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HasznosLinkekMentes',
                data: data
            };
            return $http(ds.req);
        }

        function saveNewLink(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HasznosLinkekUj',
                data: data
            };
            return $http(ds.req);
        }

        function deleteLink(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HasznosLinkTorles',
                data: data
            };
            return $http(ds.req);
        }

        function getEvents(datum, nezet, sectionId, training, ageGroupId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetEvents',
                params: {training: training, datum: datum, nezet: nezet, sectionId: sectionId, ageGroupId: ageGroupId}
            };
            return $http(ds.req);
        }

        function saveEvent(appointment) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveEvent',
                data: appointment
            };
            return $http(ds.req);
        }

        function modifyEvent(appointment) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ModifyEvent',
                data: appointment
            };
            return $http(ds.req);
        }

        function removeEvent(Id, year, month, day) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'RemoveEvent',
                data: {
                    Id: Id,
                    year: year,
                    month: month,
                    day: day
                }
            };
            return $http(ds.req);
        }

        function getSectionResources() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetSectionResources'
            };
            return $http(ds.req);
        }

        function getAgeGroupResources() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAgeGroupResources'
            };
            return $http(ds.req);
        }

        function saveTenesseeSelfImageScale(athleteId, answers) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveTenesseeSelfImageScale',
                data: {
                    answers: answers,
                    sportoloId: athleteId
                }
            };
            return $http(ds.req);
        }

        function getTenesseeSelfImageScaleById(surveyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getTenesseeSelfImageScaleById',
                params: {surveyId: surveyId}
            };
            return $http(ds.req);
        }

        function saveTenesseeSelfImageScaleEval(surveyId, szoveg) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveTenesseeSelfImageScaleEval',
                data: {surveyId: surveyId, szoveg: szoveg}
            };
            return $http(ds.req);
        }

        function saveWartegg(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveWartegg',
                params: {sportoloId: sportoloId}
            };
            return $http(ds.req);
        }

        function uploadWartegg(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UploadWartegg',
                data: data
            };
            return $http(ds.req);
        }

        function getPsychologyTests(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getPsychologyTests',
                params: {sportoloId: sportoloId}
            };
            return $http(ds.req);
        }

        function getWarteggTestById(testId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getWarteggTestById',
                params: {surveyId: testId}
            };
            return $http(ds.req);
        }

        function getWeeklyTrainingPlanList(edzoId, szakosztalyId, korosztalyId, ev, text) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HetiEdzestervekLista',
                params: {
                    'edzoId': edzoId,
                    'szakId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'ev': ev,
                    'text': text
                }
            };
            return $http(ds.req);
        }

        function getMonthlyTrainingPlanList(edzoId, szakosztalyId, korosztalyId, ev, text) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviEdzestervekLista',
                params: {
                    'edzoId': edzoId,
                    'szakId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'ev': ev,
                    'text': text
                }
            };
            return $http(ds.req);
        }

        function saveExitAthletes(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveExitAthletes',
                data: datas
            };
            return $http(ds.req);
        }

        function saveNewAthlete(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveNewAthlete',
                data: datas
            };
            return $http(ds.req);
        }

        function modifyAthlete(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ModifyAthlete',
                data: datas
            };
            return $http(ds.req);
        }

        function getAccessRights() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'JogosultsagokSelect'
            };
            return $http(ds.req);
        }

        function getAthletesForWeeklyTrainingPlan(edzoId, korosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetAthletesForWeeklyTrainingPlan',
                params: {edzoId: edzoId, korosztalyId: korosztalyId}
            };
            return $http(ds.req);
        }

        function getUserGroupRights(groupId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsoportJogosultsagokSelect',
                params: {'csoportId': groupId}
            };
            return $http(ds.req);
        }

        function setUserGroupRight(groupId, rightId, value) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsoportJogosultsagokSelect',
                data: {
                    csoportId: groupId,
                    jogId: rightId,
                    ertek: value
                }
            };
            return $http(ds.req);
        }

        function weeklyTrainingPlanSelectByIdNew(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getHetiEdzestervByIdNew',
                params: {'id': id}
            };
            return $http(ds.req);
        }

        function saveWeeklyTrainingPlanNew(formData, gridData, createEvents, mindenki, checkedItems) {
            formData.gridData = gridData;
            formData.createEvents = createEvents;
            formData.mindenki = mindenki;
            formData.checkedItems = checkedItems;
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HetiEdzestervMentesNew',
                data: formData
            };
            return $http(ds.req);
        }

        function getFilesList(table, tableId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getFilesList',
                params: {
                    table: table,
                    tableId: tableId
                }
            };
            return $http(ds.req);
        }

        function deleteFiles(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FileRemove',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function getCompetitiveMonthlyPlanList(coachId, sectionId, selectedYear) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviLista',
                params: {
                    edzoId: coachId,
                    szakosztalyId: sectionId,
                    ev: selectedYear
                }
            };
            return $http(ds.req);
        }

        function monthlyPlanSelectById(id, szakosztalyId, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviById',
                params: {
                    id: id,
                    szakosztalyId: szakosztalyId,
                    egyeni: egyeni
                }
            };
            return $http(ds.req);
        }

        function saveMonthlyCompetitionPlan(cplan) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviMentes',
                data: cplan
            };
            return $http(ds.req);
        }

        function updateTagdijfizetesek(tabla, sorszamadassal) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UpdateTagdijfizetesek',
                data: {tabla: tabla, sorszamadassal: sorszamadassal}
            };
            return $http(ds.req);
        }

        function checkPaymentsSave(tabla) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsekkBefizetesMentes',
                data: {tabla: tabla}
            };
            return $http(ds.req);
        }

        function updateOktatasiDijfizetesek(tabla, sorszamadassal) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UpdateOktatasiDijfizetesek',
                data: {tabla: tabla, sorszamadassal: sorszamadassal}
            };
            return $http(ds.req);
        }

        function monthlyCompetitionPlanAcceptByLeader(id, idSzakosztaly, tipus) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviJovahagyas',
                params: {
                    idVt: id,
                    idSzakosztaly: idSzakosztaly,
                    tipus: tipus
                }
            };
            return $http(ds.req);
        }

        function getCompetitiveMonthlyPlanItems(coachId, sectionId, date, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyTervHaviTetelekLista',
                params: {
                    edzoId: coachId,
                    szakosztalyId: sectionId,
                    datum: date,
                    tipus: egyeni
                }
            };
            return $http(ds.req);
        }

        function getResultsReports(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EredmenyJelentokLista',
                data: data
            };
            return $http(ds.req);
        }

        function getResultsReportsAthlete(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EredmenyJelentokListaAthlete',
                data: data
            };
            return $http(ds.req);
        }

        function savePaint(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SavePaint',
                data: data
            };
            return $http(ds.req);
        }

        function modifyPaint(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ModifyPaint',
                data: data
            };
            return $http(ds.req);
        }

        function getPaint(data) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetPaint',
                params: data
            };
            return $http(ds.req);
        }

        function deleteResultsReport(resultReportID, csapatEredmeny) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyEredmenyTorlese',
                params: {
                    'Id': resultReportID,
                    'csapatEredmeny': csapatEredmeny
                }
            };
            return $http(ds.req);
        }

        function sectionIndividualOrTeam(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyEgyeniVagyCsapat',
                params: {
                    szakosztalyId: id
                }
            };
            return $http(ds.req);
        }

        function getFeeDatas(sectionId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDijak',
                params: {sectionId: sectionId}
            };
            return $http(ds.req);
        }

        function saveFeeDatas(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalyokDijak/Save',
                data: formData
            };
            return $http(ds.req);
        }

        function meteOutFee(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijKiszabas',
                data: formData,
                timeout: 1800000
            };
            return $http(ds.req);
        }

        function getAthletesForMeteOut(fromDate) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TagdijUjSportolok',
                params: {
                    'naptol': fromDate
                }
            };
            return $http(ds.req);
        }

        function closeYear(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'Evzaras',
                data: formData
            };
            return $http(ds.req);
        }

        function getLastClosedYear() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getUtolsoEvzaras'
            };
            return $http(ds.req);
        }

        function athletesBySection(queryDate) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzakosztalySportolokSzama',
                params: {
                    'datum': queryDate
                }
            };
            return $http(ds.req);
        }

        function getUnPaidFees() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getSzakosztalyEdzoHatralekosai'
            };
            return $http(ds.req);
        }

        function getUnPaidFeesTrainer() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getEdzoTagdijHatralekosai'
            };
            return $http(ds.req);
        }

        function getMessages(subjectId, pageNumber, itemsPerPage) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeGetMessages',
                params: {
                    subjectId: subjectId,
                    pageNumber: pageNumber,
                    itemsPerPage: itemsPerPage
                }
            };
            return $http(ds.req);
        }

        function getSentMessages() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getFelhasznaloElkuldottUzenetei'
            };
            return $http(ds.req);
        }

        function GetMessageGroups(pageNumber, itemsPerPage, onlyUnread) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeGetMessageGroups',
                params: {
                    pageNumber: pageNumber,
                    itemsPerPage: itemsPerPage,
                    onlyUnread: onlyUnread
                }
            };
            return $http(ds.req);
        }

        function messageReaded(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetOlvasottaTesz',
                params: {
                    uzenetId: id
                }
            };
            return $http(ds.req);
        }

        function sendMessage(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'UzenetKuldes',
                data: obj
            };
            return $http(ds.req);
        }

        function SikerMobileFreeSendMessage(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeSendMessage',
                data: obj
            };
            return $http(ds.req);
        }

        function SikerMobileFreeSendNewMessage(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeSendNewMessageDesktop',
                data: obj
            };
            return $http(ds.req);
        }

        function getNamesByIds(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CimzettLista',
                data: obj
            };
            return $http(ds.req);
        }

        function parentsAthletes() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SzuloSportoloiSelect'
            };
            return $http(ds.req);
        }

        function parentsAthleteAttendance(sportoloId, date) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HianyzasIgazolasa',
                params: {
                    sportoloId: sportoloId,
                    date: date
                }
            };
            return $http(ds.req);
        }

        function parentsAthleteAttendanceList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HianyzasokSzulo'
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportSelectById(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervFejSelectById',
                params: {'id': id}
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportSelect(szakosztalyId, korosztalyId, edzoId, ev, egyeni) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervFejSelect',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'edzoId': edzoId,
                    'ev': ev,
                    'egyeni': egyeni
                }
            };
            return $http(ds.req);
        }

        function getTrainerEmailContact(edzo) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloElerhetosegeiSelect',
                params: {userId: edzo}
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportMembersDataByIdSelect(fejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervFejTagokAdataiById',
                params: {
                    'fejId': fejId
                }
            };
            return $http(ds.req);
        }

        function coachAthletesByAgeGroupForAnnualSeasonPlan(edzoId, szakosztalyId, korcsoportId, versenyeztetesiTervFejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoSportoloiKorcsoportSelect',
                params: {
                    'edzoId': edzoId,
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'versenyeztetesiTervFejId': versenyeztetesiTervFejId
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanDeleteMembers(versenyeztetesiTervFejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTagokTorles',
                params: {
                    'fejId': versenyeztetesiTervFejId
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanMembers(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTagokAdataiMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanHead(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervFejMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportMembersPastSelect(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTagokMultjaSelectById',
                params: {
                    'fejId': id
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportMembersPastDelete(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTagokMultTorles',
                params: {
                    'fejId': id
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportMembersPastRefresh(id, szakosztalyId, korcsoportId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTagokMultLekeres',
                params: {
                    'fejId': id,
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'edzoId': edzoId
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanMembersPast(data) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTagokMultjaMentes',
                data: data
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportBestResultsList(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervErdemenyekSelect',
                params: {
                    'fejId': id
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanBestResults(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervKiemelkedoEredmenyekMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportBestResultsSelect(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KiemelkEredmSelect',
                params: {
                    'versFejId': id
                }
            };
            return $http(ds.req);
        }

        function dailyEducationalFeesPerCoach(edzoId, datumTol, datumIg) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiOktDijBefizetesekEdzonkent',
                params: {
                    'edzoId': edzoId,
                    'datumTol': datumTol,
                    'datumIg': datumIg
                }
            };
            return $http(ds.req);
        }

        function dailySportServiceFeesPerCoach(edzoId, datumTol, datumIg) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'NapiSportSzolgDijBefizetesekEdzonkent',
                params: {
                    'edzoId': edzoId,
                    'datumTol': datumTol,
                    'datumIg': datumIg
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanReportObjectivesSelect(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervCelkituzesek',
                params: {
                    'fejId': id
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanCapitalObjectives(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervCelkituzesekMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function deleteFileAnnualSeasonPlan(file, host) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervCsatoltFileTorles',
                params: {
                    'file': file,
                    'host': host
                }
            };
            return $http(ds.req);
        }

        function getAnnualSeasonPlanListByTrainer(edzoId, vtId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoKorabbiEvesVersenyeztetesiTervei',
                params: {
                    'edzoId': edzoId,
                    'vtId': vtId
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanCompetitionPlansAndObjectivesSelect(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervVersTervekSelect',
                params: {
                    'fejId': id
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanCompetitionPlansAndObjectives(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervVersTervekMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function deleteAnnualSeasonPlanCompetitionPlansAndObjectives(fejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervVersTervekTorles',
                params: {
                    'fejId': fejId
                }
            };
            return $http(ds.req);
        }

        function deleteAnnualSeasonPlanCompetitionPlansAndObjective(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervVersenyTervTorles',
                params: {
                    'id': id
                }
            };
            return $http(ds.req);
        }

        function annualSeasonPlanList(ev) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervListSelect',
                params: {
                    'ev': ev
                }
            };
            return $http(ds.req);
        }

        function annualSeasponPlanTableListSelect(fejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTablazatSelect',
                params: {
                    'fejId': fejId
                }
            };
            return $http(ds.req);
        }

        function annualSeasponPlanTableListSelect2(edzo, korcsoport, honap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTablazatSelect2',
                params: {
                    'edzo': edzo,
                    'korcsoport': korcsoport,
                    'honap': honap
                }
            };
            return $http(ds.req);
        }

        function saveAnnualSeasonPlanTable(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'VersenyeztetesiTervTablazatMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function getAthleteTransferList(szakosztalyId, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KimutatasAtigazolasLista',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'edzoId': edzoId
                }
            };
            return $http(ds.req);
        }

        function getFelmerokDatumaiDropDownList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmerokDatumaiDropDownList'
            };
            return $http(ds.req);
        }

        function getAnnualPresenceReportByTrainersReport(ev, honap, szakosztalyId, korosztalyId, korosztalyNev, edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'JelenletiEvesKimutatasEdzonkent',
                params: {
                    'ev': ev,
                    honap: honap,
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'korosztalyNev': korosztalyNev,
                    'edzoId': edzoId
                }
            };
            return $http(ds.req);
        }

        function getAnnualPresenceReportBySectionsReport(ev, honap, szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'JelenletiEvesKimutatasSzakosztalyonkent',
                params: {
                    'ev': ev,
                    honap: honap,
                    'szakosztalyId': szakosztalyId
                }
            };
            return $http(ds.req);
        }

        function getAnnualPresenceReportBySportSchoolReport(ev, honap) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'JelenletiEvesKimutatasSportiskolankent',
                params: {
                    ev: ev,
                    honap: honap
                }
            };
            return $http(ds.req);
        }

        function deleteWeeklyTrainingPlan(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HetiEdzestervTorles',
                params: {
                    'id': id
                }
            };
            return $http(ds.req);
        }

        function deleteMonthlyTrainingPlan(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviEdzestervTorles',
                params: {
                    'id': id
                }
            };
            return $http(ds.req);
        }

        function athleteDropDownListSelect(szakosztalyId, korosztalyId, sportolo1, sportolo2) {
            if (sportolo1 === undefined) {
                sportolo1 = 0;
            }
            if (sportolo2 === undefined) {
                sportolo2 = 0;
            }

            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloDropDownList',
                params: {
                    'szakosztaly': szakosztalyId,
                    'korcsoport': korosztalyId,
                    'sportoloId1': sportolo1,
                    'sportoloId2': sportolo2
                }
            };
            return $http(ds.req);
        }

        function getCharacterOfACompetitionList(csapat, szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EredmenyJelentokJellegeiList',
                params: {
                    'csapat': csapat,
                    'szakosztalyId': szakosztalyId
                }
            };
            return $http(ds.req);
        }

        function saveResultReportAthletes(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloEredmenyJelentoMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function resultReportAthletesSelectById(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SportoloEredmenyJelentoById',
                params: {
                    'eredmenyId': id
                }
            };
            return $http(ds.req);
        }

        function resultReportAthletesOlderReports(szakosztalyId, korcsoportId, egyeni) {
            if (!szakosztalyId) {
                return;
            }
            if (!korcsoportId) {
                korcsoportId = -1;
            }

            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KorabbiEredmenyJelentok',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'egyeni': egyeni
                }
            };
            return $http(ds.req);
        }

        function teamDropDownListSelect(szakosztalyId, korosztalyId, csapat) {
            if (!csapat) {
                csapat = 0;
            }
            if (!korosztalyId) {
                korosztalyId = 0;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatDropDownList',
                params: {
                    'szakosztaly': szakosztalyId,
                    'korcsoport': korosztalyId,
                    'csapat': csapat
                }
            };
            return $http(ds.req);
        }

        function getTrainingVisitReport(year, sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KSportoloEdzeslatogatas',
                params: {
                    'ev': year,
                    'sportoloId': sportoloId
                }
            };
            return $http(ds.req);
        }

        function teamAthletesListSelect(csapatId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'csapatSportoloiLista',
                params: {
                    'csapatId': csapatId
                }
            };
            return $http(ds.req);
        }

        function getCoachUsers(szakosztalyIdk) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloEdzok',
                params: {
                    'szakId': szakosztalyIdk
                }
            };
            return $http(ds.req);
        }

        function getEdzoKorcsoportjai(edzoId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EdzoKorcsoportjaiDropDownSelect',
                params: {
                    'edzoId': edzoId
                }
            };
            return $http(ds.req);
        }

        function AtjatszoSportoloLista(szakosztalyId, korcsoportId, edzoId, exceptTeam) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'AtjatszoSportoloLista',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korcsoportId': korcsoportId,
                    'edzoId': edzoId,
                    'exceptTeam': exceptTeam
                }
            };
            return $http(ds.req);
        }

        function getTeamResultAthleteData(sportoloId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatEredmenySportoloAdatai',
                params: {
                    'sportoloId': sportoloId
                }
            };
            return $http(ds.req);
        }

        function EredmenyJelentoAtjatszoSportoloi(csapatId, userId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EredmenyJelentoAtjatszoSportoloi',
                params: {
                    'csapatId': csapatId,
                    'userId': userId
                }
            };
            return $http(ds.req);
        }

        function saveResultReportTeams(obj) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatEredmenyJelentoMentes',
                data: obj
            };
            return $http(ds.req);
        }

        function resultReportTeamSelectById(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CsapatEredmenyJelentoById',
                params: {
                    'eredmenyId': id
                }
            };
            return $http(ds.req);
        }

        function saveReEnteringAthletes(sportolo) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveReEnteringAthletes',
                data: sportolo
            };
            return $http(ds.req);
        }

        function felmeroElemFejDropDownList(szakosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroElemFejDropDownList',
                params: {
                    'szakosztalyId': szakosztalyId,
                }
            };
            return $http(ds.req);
        }

        function felmeroFejLista(szakosztalyId, korosztalyId, edzoId, felmeroId, sportoloid, datefrom, dateto) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelmeroFejLista',
                params: {
                    'section': szakosztalyId,
                    'ageGroup': korosztalyId,
                    'coach': edzoId,
                    'test': felmeroId,
                    'sportoloid': sportoloid,
                    'datefrom': datefrom,
                    'dateto': dateto
                }
            };
            return $http(ds.req);
        }

        function getSectionHasSectionLeaderRight(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportSzakosztalyVezetoe',
                params: {
                    'felhCsopId': id
                }
            };
            return $http(ds.req);
        }

        function getSectionHasCoachRight(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'FelhasznaloCsoportEdzoje',
                params: {
                    'felhCsopId': id
                }
            };
            return $http(ds.req);
        }

        function getOldWeeklyPlanByUserId(idEdzo, id) {
            if (id == undefined) {
                id = -1;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getOldWeeklyPlanByUserId',
                params: {
                    'IdEdzo': idEdzo,
                    'Id': id
                }
            };
            return $http(ds.req);
        }

        function getOldMonthlyPlanByUserId(idEdzo, id) {
            if (id == undefined) {
                id = -1;
            }
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getOldMonthlyPlanByUserId',
                params: {
                    'IdEdzo': idEdzo,
                    'Id': id
                }
            };
            return $http(ds.req);
        }

        function monthlyTrainingPlanSelectById(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getHaviEdzestervById',
                params: {'id': id}
            };
            return $http(ds.req);
        }

        function saveMonthlyTrainingPlan(formData) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviEdzestervMentes',
                data: formData
            };
            return $http(ds.req);
        }

        function Cimjegyzek(usersof) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'Cimjegyzek',
                params: {
                    'usersof': usersof
                }
            };
            return $http(ds.req);
        }

        function CimjegyzekMobil() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'CimjegyzekMobil'
            };
            return $http(ds.req);
        }

        function getUserIdsOfGroup(usersof, ids) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getUserIdsOfGroup',
                data: {
                    'usersof': usersof,
                    'ids': ids
                }
            };
            return $http(ds.req);
        }

        function GetHealth(id, dateFrom, dateTo) {
            if (dateFrom === null || dateFrom === '' || dateFrom === undefined) {
                dateFrom = '1900-01-01';
            }
            if (dateTo === null || dateTo === '' || dateTo === undefined) {
                dateTo = '1900-01-01';
            }

            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetHealth',
                params: {
                    'athleteId': id,
                    'dateFrom': dateFrom,
                    'dateTo': dateTo
                }
            };
            return $http(ds.req);
        }

        function saveHealthData(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SaveHealthData',
                data: datas
            };
            return $http(ds.req);
        }

        function deleteHealthMainType(datas) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteHealthTypes',
                params: {
                    'mainTypeId': datas.mainTypeId,
                    'subTypeId': null
                }
            };
            return $http(ds.req);
        }

        function deleteHealthSubType(datas) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteHealthTypes',
                params: {
                    'mainTypeId': null,
                    'subTypeId': datas.subTypeId
                }
            };
            return $http(ds.req);
        }

        function SimilarAthleteExist(data) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SimilarAthleteExist',
                params: data
            };
            return $http(ds.req);
        }

        function HealthMainTypeManager(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HealthTypeManager',
                data: {
                    'foTipusId': datas.Id,
                    'foTipus': datas.Nev
                }
            };
            return $http(ds.req);
        }

        function HealthSubTypeManager(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HealthTypeManager',
                data: {
                    'foTipusId': datas.FotipusId,
                    'alTipusId': datas.Id,
                    'alTipus': datas.AltipusNev
                }
            };
            return $http(ds.req);
        }

        function modifyHealthData(datas) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ModifyHealthData',
                data: datas
            };
            return $http(ds.req);
        }

        function healthMainTypesList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HealthMainTypes'
            };
            return $http(ds.req);
        }

        function healthSubTypesList() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HealthSubTypes'
            };
            return $http(ds.req);
        }

        function monthlyAttendanceActualDaySelect(szakosztalyId, korosztalyId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'HaviJelenletiLekeresAktualisNap',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId
                }

            };
            return $http(ds.req);
        }

        function ExpiringDocuments() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'ExpiringDocuments'
            };
            return $http(ds.req);
        }

        function DeleteExercise(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteExercise',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function getWeeklyTravelList(szakosztalyId, korosztalyId, edzoId, dateFrom, dateTo, onlyStartDate, showOrder, busOwner) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'WeeklyTravelList',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'edzoId': edzoId,
                    'dateFrom': dateFrom,
                    'dateTo': dateTo,
                    'onlyStartDate': onlyStartDate,
                    'showOrder': showOrder,
                    'busOwner': busOwner
                }
            };
            return $http(ds.req);
        }

        function getWeeklyTrainingList(szakosztalyId, korosztalyId, edzoId, dateFrom, dateTo, showWeeks, helyszin) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'WeeklyTrainingList',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'edzoId': edzoId,
                    'dateFrom': dateFrom,
                    'dateTo': dateTo,
                    'showWeeks': showWeeks,
                    'helyszin': helyszin
                }
            };
            return $http(ds.req);
        }

        function KoltsegtervOsszesitoKimutatas(szakosztalyId, korosztalyId, edzoId, dateFrom, dateTo, onlyStartDate) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'KoltsegtervOsszesitoKimutatas',
                params: {
                    'szakosztalyId': szakosztalyId,
                    'korosztalyId': korosztalyId,
                    'edzoId': edzoId,
                    'dateFrom': dateFrom,
                    'dateTo': dateTo,
                    'onlyStartDate': onlyStartDate
                }
            };
            return $http(ds.req);
        }

        function assetInventoryByType(eszkTipusId, szabad, aktiv, osszes) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EszkozNyilvantartas',
                params: {
                    'eszkTipusId': eszkTipusId,
                    'szabad': szabad,
                    'aktiv': aktiv,
                    'all': osszes
                }
            };
            return $http(ds.req);
        }

        function assetInventoryByPerson(user, athlete, sectionsId, ageGropu) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'EszkozNyilvantartasSzemely',
                params: {
                    'felhasznalo': user,
                    'sportolo': athlete,
                    'szakosztalyId': sectionsId,
                    'korosztalyId': ageGropu
                }
            };
            return $http(ds.req);
        }

        function getTrainingPracticeAutoComplete() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'TrainingPracticeAutoComplete',
            };
            return $http(ds.req);
        }

        function getExercise(id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetExercise',
                params: {
                    id: id
                }
            };
            return $http(ds.req);
        }

        function getExerciseList(data) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetExerciseList',
                params: data
            };
            return $http(ds.req);
        }

        function getExercises(sectionId, ageGroupId, coachId, bodyPart, exercisesType, nature, outfit, difficulty, necessaryArea, video, pattern, designer, time, nyilvanos) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getExercises',
                params: {
                    'szakosztalyId': sectionId,
                    'korosztalyId': ageGroupId,
                    'edzoId': coachId,
                    'testresz': bodyPart,
                    'tipus': exercisesType,
                    'jellege': nature,
                    'felszereles': outfit,
                    'nehezseg': difficulty,
                    'szuksegesTerulet': necessaryArea,
                    'video': video,
                    'mintaKep': pattern,
                    'kifesto': designer,
                    'time': time,
                    'nyilvanos': nyilvanos
                }

            };
            return $http(ds.req);
        }

        function saveTrainingPlan(obj) {
            //CoachId, TrainingName, SectionId, AgeGroupId, Location, Public, Data
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'saveTrainingPlan',
                data: obj
            };
            return $http(ds.req);
        }

        function getTrainingPlanList(sectionId, ageGroupId, coachId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'getTrainingPlanList',
                params: {
                    'szakosztalyId': sectionId,
                    'korosztalyId': ageGroupId,
                    'edzoId': coachId
                }
            };
            return $http(ds.req);
        }

        function GetTrainingPlanById(Id) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetTrainingPlanById',
                params: {
                    'Id': Id
                }
            };
            return $http(ds.req);
        }

        function DeleteTrainingPlan(EdzesFejId) {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'DeleteTrainingPlan',
                params: {
                    'EdzesFejId': EdzesFejId
                }
            };
            return $http(ds.req);
        }

        function sendLocalException(error) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'LogClientSideException',
                data: {
                    error: error
                }
            };
            return $http(ds.req);
        }

        function getMonthlyAttendanceAutoSave() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'MonthlyAttendanceAutoSave'
            };
            return $http(ds.req);
        }

        function setMonthlyAttendanceAutoSave(autoSave) {
            ds.req = {
                type: 'apiPOST',
                url: DOMAIN.baseURL + DOMAIN.webService + 'MonthlyAttendanceAutoSave',
                data: autoSave
            };
            return $http(ds.req);
        }

        function getCompetitionPlaces() {
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'GetCompetitionPlaces'
            };
            return $http(ds.req);
        }

        function SikerMobileFreeDeleteMessages(targyId) {
            console.log('targyId', targyId);
            ds.req = {
                type: 'apiGET',
                url: DOMAIN.baseURL + DOMAIN.webService + 'SikerMobileFreeDeleteMessages',
                params: {
                    'targyId': targyId
                }
            };
            return $http(ds.req);
        }
    }
})();
