(function () {
    'use strict';

    angular
        .module('app.administration.assetManagement')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.assetManagement',
                config: {
                    url: '/assetManagement',
                    templateUrl: 'app/administration/assetManagement/assetManagement.html',
                    controller: 'AssetManagementController',
                    controllerAs: 'vm',
                    title: 'AssetManagement',
                    settings: {
                        navId: 2132,
                        level: 2,
                        order: 13,
                        orderTitle_hu: 'Eszköznyilvántartó',
                        orderTitle_en: 'AssetManagement',
                        parentId: 2,
                        content: 'AssetManagement',
                        activatorPermission: '"ESZKNYILVMEGT"'
                    }
                }
            }
        ];
    }
})();
