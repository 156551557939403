(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athletesAssets')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.athletesManagement.athleteModify.athletesAssets',
                config: {
                    url: '/AthletesAssets',
                    templateUrl: 'app/athletes/athletesManagement/athleteModify/athletesAssets/athletesAssets.html',
                    controller: 'AthletesAssetsController',
                    controllerAs: 'vm',
                    title: 'AthletesAssets'
                }
            }
        ];
    }
})();
