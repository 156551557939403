(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage3')
        .controller('AnnualSeasonPlanReportPage3Controller', AnnualSeasonPlanReportPage3Controller);

    function AnnualSeasonPlanReportPage3Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $state, $timeout) {
        var vm = this; $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanReport');
        vm.columns = [];
        vm.columnsPopup = [];
        vm.egyeni = false;
        vm.editable = true;
        vm.disable = false;
        vm.annualSeasonPlanReportId = 0;
        vm.selectedRowIndexPopup = -1;
        vm.selectedRowRecords;

        vm.head = {};
        vm.data = [];
        vm.dataPopup = [];

        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];

        // functions
        vm.goToNextPage = goToNextPage;
        vm.goToPreviousPage = goToPreviousPage;
        vm.newAthletesNewRow = newAthletesNewRow;
        vm.oldAthletesNewRow = oldAthletesNewRow;
        vm.search = search;
        vm.search2 = search2;
        vm.closeOutstandingResultsWindow = closeOutstandingResultsWindow;
        vm.selectOutstandingResults = selectOutstandingResults;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;

        initGrid();

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithDelete,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false, allowDeleting: true, showDeleteConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            rowSelected: function() {
                grid.editSettings.allowDeleting = true;
                vm.selectedRowRecords = grid.getSelectedRecords();
                document.getElementById('oldNew').removeAttribute('disabled');
            },
            rowDeselected: function() {
                grid.editSettings.allowDeleting = false;
                document.getElementById('oldNew').setAttribute('disabled', 'disabled');
            },
            actionBegin: function() {
                grid.editSettings.allowDeleting = false;
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var gridSearch = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false},
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsPopup,
            selectedRowIndex: vm.selectedRowIndexPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        gridSearch.appendTo('#GridPopup');
        gridSearch.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridSearch);
        };

        activate();

        function activate() {
            vm.jumpedPage = '3 - ' + gettextCatalog.getString('OutstandingResults');
            vm.defaultJumpPage = '3 - ' + gettextCatalog.getString('OutstandingResults');
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id']),
                authenticationService.getRight('EDZO'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('TECHIGAZGATO'),
                authenticationService.getRight('EVVERSTALAIR1'),
                authenticationService.getRight('EVVERSTALAIR2'),
                authenticationService.getRight('UJEVVERST'),
                authenticationService.getRight('MEGTSPORTLIST'),
                authenticationService.getRight('MEGTSPORTLIST'),
                authenticationService.getRight('MEGTEVVERST')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.EVVERSTALAIR1 = results[5];
                    vm.EVVERSTALAIR2 = results[6];
                    vm.UJEVVERST = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTSPORTLIST = results[9];
                    vm.MEGTEVVERST = results[10];
                    if (!vm.MEGTEVVERST) {
                        log.permissionError(true);
                        return;
                    }
                    if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                        vm.annualSeasonPlanReportId = results[0]['AnnualSeasonPlanReport.Id'];
                        paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                        dataservice.annualSeasonPlanReportSelectById(vm.annualSeasonPlanReportId)
                            .then(function (data) {
                                vm.head = data;
                                vm.egyeni = data.Egyeni;
                                if (data.EdzoLezarta) {
                                    vm.editable = false;
                                    vm.disable = true;
                                } else {
                                    vm.editable = true;
                                    vm.disable = false;
                                }

                                initGrid();
                                dataservice.annualSeasonPlanReportBestResultsList(vm.annualSeasonPlanReportId)
                                    .then(function (data) {
                                        vm.data = data.itemsList;
                                        grid.dataSource = vm.data;
                                        grid.editSettings.allowEditing = vm.editable;
                                        grid.editSettings.allowDeleting = vm.editable;
                                    });
                            });
                    } else {
                        $rootScope.back();
                    }
                });
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function SaveResults() {
            if (vm.editable) {
                grid.editModule.endEdit();
            }

            if (vm.data.length === 0) {
                vm.data.push({IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId});
            }
            dataservice.saveAnnualSeasonPlanBestResults(vm.data).then(function () {
                log.successMsg('SaveCompleted');
            });
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '3 ') {
                return;
            }
            if (vm.editable) {
                grid.editModule.endEdit();
                SaveResults();
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                    case '1 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                        break;
                    case '2 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                        break;
                    case '3 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                        break;
                    case '4 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                        break;
                    case '5 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                        break;
                    case '6 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                        break;
                }
            }, 100);
        }

        function initGrid() {
            vm.columns = [];
            vm.columns = [
                {
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Sorszam',
                    headerText: gettextCatalog.getString('RowNumber'),
                    textAlign: 'center',
                    width: 40,
                    allowEditing: false
                },
                {
                    field: 'VersenyzoNeve',
                    headerText: gettextCatalog.getString('VersenyzoNeve'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: vm.editable,
                    visible: vm.egyeni
                },
                {
                    field: 'EsemenyNeve',
                    headerText: gettextCatalog.getString('EsemenyNeve'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: vm.editable
                },
                {
                    field: 'EsemenyIdopontja',
                    headerText: gettextCatalog.getString('EsemenyIdopontja'),
                    textAlign: 'center',
                    width: 75,
                    allowEditing: vm.editable
                },
                {
                    field: 'EsemenyHelye',
                    headerText: gettextCatalog.getString('EsemenyHelye'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable
                },
                {
                    field: 'ElertHelyezes',
                    headerText: gettextCatalog.getString('ElertHelyezes'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable
                },
                {
                    field: 'KiemelkSportolo',
                    headerText: gettextCatalog.getString('KiemelkSportolo'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable,
                    visible: !vm.egyeni
                }
            ];

            vm.columnsPopup = [];
            vm.columnsPopup = [
                {
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Helyezes',
                    headerText: gettextCatalog.getString('Helyezes'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'Helyszin',
                    headerText: gettextCatalog.getString('Helyszin'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'DatumTol',
                    headerText: gettextCatalog.getString('DatumTol'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'DatumIg',
                    headerText: gettextCatalog.getString('DatumIg'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'CsapatSportolo',
                    headerText: gettextCatalog.getString('CsapatSportolo'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                }
            ];
        }

        function goToNextPage() {
            if (vm.editable) {
                grid.editModule.endEdit();
                SaveResults();
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
            }, 100);
        }

        function goToPreviousPage() {
            if (vm.editable) {
                grid.editModule.endEdit();
                SaveResults();
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
            }, 100);
        }

        function newAthletesNewRow() {
            grid.editModule.endEdit();
            var sorszam = 0;
            for (var i = 0; i < vm.data.length; i++) {
                if (vm.data[i].Sorszam > sorszam) {
                    sorszam = vm.data[i].Sorszam;
                }
            }
            vm.data.push({
                Id: (vm.data.length + 1) * -1,
                Sorszam: sorszam + 1,
                IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                ElertHelyezes: '',
                EsemenyHelye: '',
                EsemenyIdopontja: '',
                EsemenyNeve: '',
                KiemelkSportolo: '',
                VersenyzoNeve: ''
            });
            grid.dataSource = vm.data;
        }

        function oldAthletesNewRow() {
            grid.editModule.endEdit();
            vm.data.push({
                Id: (vm.data.length + 1) * -1,
                Sorszam: vm.selectedRowRecords[0].Sorszam,
                IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                ElertHelyezes: '',
                EsemenyHelye: '',
                EsemenyIdopontja: '',
                EsemenyNeve: '',
                KiemelkSportolo: '',
                VersenyzoNeve: ''
            });
            grid.dataSource = vm.data;
        }

        function search() {
            vm.same = false;
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();

            dataservice.annualSeasonPlanReportBestResultsSelect(vm.annualSeasonPlanReportId)
                .then(function (args) {
                    vm.dataPopup = [];
                    vm.dataPopup = args.itemsList;
                    gridSearch.dataSource = vm.dataPopup;
                });
        }

        function search2() {
            vm.same = true;
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();

            dataservice.annualSeasonPlanReportBestResultsSelect(vm.annualSeasonPlanReportId)
                .then(function (args) {
                    vm.dataPopup = [];
                    vm.dataPopup = args.itemsList;
                    gridSearch.dataSource = vm.dataPopup;
                });
        }

        function closeOutstandingResultsWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectOutstandingResults(same) {
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    var sorszam = 0;
                    for (var i = 0; i < vm.data.length; i++) {
                        if (vm.data[i].Sorszam > sorszam) {
                            sorszam = vm.data[i].Sorszam;
                        }
                    }
                    if (!same) {
                        sorszam++;
                    }
                    vm.data.push(
                        {
                            Id: -1 * vmi.Id,
                            Sorszam: sorszam,
                            IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                            ElertHelyezes: vmi.Helyezes,
                            EsemenyHelye: vmi.Helyszin,
                            EsemenyIdopontja: (vmi.DatumTol ? vmi.DatumTol : '') + (vm.Datumig ? '-' + vm.DatumIg : ''),
                            EsemenyNeve: vmi.EsemenyNeve,
                            VersenyzoNeve: vmi.CsapatSportolo
                        });
                    grid.dataSource = vm.data;
                }
            }
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }
    }
})();
