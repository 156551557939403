(function () {
    'use strict';

    angular
        .module('app.reports.absenceReport')
        .controller('AbsenceReportController', AbsenceReportController);

    function AbsenceReportController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        vm.params = [];
        $rootScope.title = gettextCatalog.getString('AbsenceReport');
        vm.searchSPC = true;
        vm.searchPP = true;
        vm.sectionList = [];
        vm.coachList = [];
        vm.search = search;
        vm.openCoach = openCoach;
        vm.openAthlete = openAthlete;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDropDownListDataBound = coachDropDownListDataBound;
        vm.coachSelect = coachSelect;
        vm.selectdateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        vm.selectdateTo = new Date();
        vm.fromDateSelect = fromDateSelect;
        vm.toDateSelect = toDateSelect;
        vm.SelectedSectionID = -1;
        vm.SelectedCoachID = -1;

        vm.columns = [
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Athlete'),
                headerTextAlign: 'center',
                textAlign: 'left',
                width: '120',
                template: '#abretemplateDataColumn1'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                headerTextAlign: 'center',
                textAlign: 'left',
                width: '120',
                template: '#abretemplateDataColumn2'
            },
            {
                field: 'OsszEdzesekSzama',
                headerText: '*' + gettextCatalog.getString('SumNumberOfTrainings'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'JelenletSzazalek',
                headerText: '*' + gettextCatalog.getString('AttendancePercent'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '130'
            },
            {
                field: 'EdzesekSzama',
                headerText: gettextCatalog.getString('NumberOfTrainings'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '105'
            },
            {
                field: 'HianyzasokSzama',
                headerText: gettextCatalog.getString('NumberOfAbsence'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '130'
            },
            {
                field: 'IgazoltHianyzasokSzama',
                headerText: gettextCatalog.getString('ProvenNumberOfAbsence'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'BetegsegekSzama',
                headerText: gettextCatalog.getString('NumberOfSickness'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '140'
            },
            {
                field: 'SerulesekSzama',
                headerText: gettextCatalog.getString('NumberOfInjuries'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '120'
            },
            {
                field: 'VersenyekSzama',
                headerText: gettextCatalog.getString('NumberOfCompetition'),
                headerTextAlign: 'center',
                textAlign: 'center',
                width: '125'
            }
        ];
        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $timeout(function () {
                vm.selectdateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
                vm.selectdateTo = new Date();
                $scope.$apply();
            });
            var promises = [authenticationService.getRight('HIANYZASMEGT')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            })
                .catch(function () {
                    log.permissionError(true);
                });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'absenceReport.sectionId',
                'absenceReport.coachId',
                'absenceReport.selectdateFrom',
                'absenceReport.selectdateTo',
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.SelectedSectionID = results[0]['absenceReport.sectionId'] ? results[0]['absenceReport.sectionId'] : vm.SelectedSectionID;
                vm.SelectedCoachID = results[0]['absenceReport.coachId'] ? results[0]['absenceReport.coachId'] : vm.SelectedCoachID;
                vm.selectdateFrom = results[0]['absenceReport.selectdateFrom'] ? results[0]['absenceReport.selectdateFrom'] : vm.selectdateFrom;
                vm.selectdateTo = results[0]['absenceReport.selectdateTo'] ? results[0]['absenceReport.selectdateTo'] : vm.selectdateTo;
            });
        }

        function search() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('FillTheRequiredFields');
                return;
            }
            paramHelper.setParams([
                {'absenceReport.sectionId': vm.SelectedSectionID},
                {'absenceReport.coachId': vm.SelectedCoachID},
                {'absenceReport.selectdateFrom': vm.selectdateFrom},
                {'absenceReport.selectdateTo': vm.selectdateTo},
            ]);
            dataservice.getAbsenceReport(vm.SelectedSectionID, 0, vm.SelectedCoachID, vm.selectdateFrom, vm.selectdateTo)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function sectionSelect(args) {
            getActualCoachesList(args.value);
            vm.showDelButton = false;
        }

        function coachSelect(args) {
            vm.SelectedCoachID = args.value;
            if (args.value == -1) {
                vm.SelectedCoachID = 0;
            }
            vm.showDelButton = false;
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.sectionList.splice(0, 0, all);
                    }
                });
        }

        function getActualCoachesList(SelectedSectionID) {
            return dataservice.coachDropDownList(SelectedSectionID, 0, vm.selectdateFrom, vm.selectdateTo)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function coachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.SelectedCoachID);
            }
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.SelectedSectionID);
            }
        }

        function fromDateSelect() {
            getActualCoachesList(vm.SelectedSectionID);
        }

        function toDateSelect() {
            getActualCoachesList(vm.SelectedSectionID);
        }
    }
})();

