(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanList')
        .controller('AnnualSeasonPlanListController', AnnualSeasonPlanListController);

    function AnnualSeasonPlanListController($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $state) {
        var vm = this; $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanList');
        vm.columns = [];
        //Évek (2010-től az aktuális évig)
        vm.yearList = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.yearList.push(ev + ' / ' + (ev + 1));
        }
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear() + ' / ' + (new Date().getFullYear() + 1);

        vm.yearDataBound = yearDataBound;
        vm.yearSelect = yearSelect;
        vm.goToNewAgeGroup = goToNewAgeGroup;
        vm.goToNewCoach = goToNewCoach;
        vm.goToAnnualSeasonPlan = goToAnnualSeasonPlan;

        vm.columns = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },

            {
                field: 'Link',
                width: 60,
                headerText: '',
                textAlign: 'center',
                allowEditing: false,
                template: '#ansepllitemplateDataColumn6'
            },
            {
                field: 'EgyeniTerv',
                headerText: gettextCatalog.getString('Egyeni'),
                textAlign: 'center',
                allowEditing: false,
                editType: ej.Grid.EditingType.Boolean,
                template: '#ansepllitemplateDataColumn1'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'KorosztalyNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                allowEditing: false,
                template: '#ansepllitemplateDataColumn2'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#ansepllitemplateDataColumn3'
            },
            {
                field: 'EdzoLezarta',
                headerText: gettextCatalog.getString('Closed'),
                textAlign: 'center',
                allowEditing: false,
                editType: ej.Grid.EditingType.Boolean,
                template: '#ansepllitemplateDataColumn4'
            },
            {
                field: 'SzakosztalyvezetoJovahagyta',
                headerText: gettextCatalog.getString('LeaderAccepted'),
                textAlign: 'center',
                allowEditing: false,
                editType: ej.Grid.EditingType.Boolean,
                template: '#ansepllitemplateDataColumn5'
            }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelected: function (args) {
                vm.selectedRowId = args.data.Id;
                vm.delEnabled = vm.MODHETIEDZTERV;
                $scope.$apply();
            },
            rowDeselected: function () {
                vm.delEnabled = false;
                vm.selectedRowId = -1;
                $scope.$apply();
            },
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id', 'AnnualSeasonPlanList.Year']),
                authenticationService.getRight('EDZO'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('TECHIGAZGATO'),
                authenticationService.getRight('MEGTKOROSZT'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('MEGTEVVERST')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.MEGTKOROSZT = results[5];
                    vm.MEGTFELH = results[6];
                    vm.MEGTEVVERST = results[7];
                    vm.selectedYear = results[0]['AnnualSeasonPlanList.Year'];
                    if (!vm.MEGTEVVERST) {
                        log.permissionError(true);
                        return;
                    }
                    if (!vm.selectedYear) {
                        vm.selectedYear = new Date().getFullYear() + ' / ' + (new Date().getFullYear() + 1);
                    }

                    dataservice.annualSeasonPlanList(vm.selectedYear.split('/')[0])
                        .then(function (args) {
                            grid.dataSource = args.itemsList;
                        });
                    paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                });
        }

        // Run after data bounded
        function yearDataBound() {
            if (vm.yearList.length === 1) {
                angular.element('#maEv').ejDropDownList('selectItemByValue', vm.yearList[0].value);
                vm.selectedYear = vm.yearList[0].value;
            } else {
                angular.element('#maEv').ejDropDownList('selectItemByValue', vm.selectedYear);
            }
        }

        // Run after selection
        function yearSelect(args) {
            vm.selectedYear = args.value;
            paramHelper.setParams([{'AnnualSeasonPlanList.Year': vm.selectedYear}]);

            dataservice.annualSeasonPlanList(vm.selectedYear.split('/')[0])
                .then(function (args) {
                    grid.dataSource = args.itemsList;
                });
        }

        function goToNewAgeGroup(args) {
            if (!vm.MEGTKOROSZT) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'ageGroups.ageGroup': args
            }]);
            $state.go('athletes.ageGroups.modifyAgeGroup');
        }

        function goToNewCoach(args) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'newUser.userId': args
            }]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToAnnualSeasonPlan(args) {
            if (!vm.MEGTEVVERST) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'AnnualSeasonPlanReport.Id': args,
                'AnnualSeasonPlanList.Year': vm.selectedYear
            }]);
            $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
        }
    }
})();
