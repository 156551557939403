(function () {
    'use strict';

    angular
        .module('app.settings')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings',
                config: {
                    url: '/settings',
                    templateUrl: 'app/settings/settings.html',
                    title: 'Settings',
                    settings: {
                        navId: 5,
                        level: 1,
                        order: 5,
                        parentId: 0,
                        content: 'Settings',
                        activatorPermission: ['"MEGTEDZVALT"', '"MEGTEDZVALT"', '"MEGTSPFELM"', '"EGESZSMEGT"', '"EditSection"', '"AddSchool"', '"editSchool"', '"UJISKOLA"',
                                              '"BEALLITAS"', '"MEGTFELHCSOP"', '"MEGTFELH"', '"MEGTFELHJOG"', '"SPTARTMEGT"']
                    }
                }
            }
        ];
    }
})();
