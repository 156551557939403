(function () {
    'use strict';

    angular
        .module('app.athletes.teams.addTeam')
        .controller('AddTeamController', AddTeamController);

    function AddTeamController(log, $state, dataservice, $q, DOMAIN, gettextCatalog, $scope, paramHelper, authenticationService, $rootScope, validatorHelper, $timeout) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('AddTeam');

        vm.MEGTCSOPORT = false;
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        // Default values
        vm.noImg = DOMAIN.baseURL + DOMAIN.userImage;
        vm.photoUrl = vm.noImg;
        vm.formData = {
            FileData: null,
            FileName: DOMAIN.userImage,
            TeamName: undefined,
            Comment: undefined,
            SectionId: undefined,
            AgeGroupId: undefined,
            CoachId: undefined,
            ActiveTeam: true
        };

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        // Public functions
        vm.saveTeam = saveTeam;
        vm.saveTeamAndAddMembers = saveTeamAndAddMembers;
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.deleteImg = deleteImg;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.simpleSave = simpleSave;

        activate();

        function activate() {
            authenticationService.getRight('UJCSOPORT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['teams.sectionId', 'teams.ageGroupId']), authenticationService.getRight('MEGTCSOPORT')]).then(function (results) {
                        vm.formData.SectionId = results[0]['teams.sectionId'];
                        vm.formData.AgeGroupId = results[0]['teams.ageGroupId'];
                        $timeout(function () {
                            vm.MEGTCSOPORT = results[1];
                        }, 0);
                        angular.element('#ActiveTeam').ejCheckBox({checked: vm.formData.ActiveTeam});
                        getSections();
                    });
                }
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#SectionId').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.formData.SectionId = vm.sectionList[0].value;
            } else {
                angular.element('#SectionId').ejDropDownList('selectItemByValue', vm.formData.SectionId);
            }
        }

        function sectionSelect(args) {
            vm.formData.SectionId = args.value;
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.formData.SectionId)
                .then(function (data) {
                    vm.formData.CoachId = undefined;
                    vm.coachList = [];
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#AgeGroupId').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.formData.AgeGroupId = vm.ageGroupList[0].value;
            } else {
                angular.element('#AgeGroupId').ejDropDownList('selectItemByValue', vm.formData.AgeGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.formData.AgeGroupId = args.value;
            getCoaches();
        }

        function getCoaches() {
            return dataservice.coachDropDownList(vm.formData.SectionId, vm.formData.AgeGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#CoachId').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.formData.CoachId = vm.coachList[0].value;
            }
        }

        function coachSelect(args) {
            vm.formData.CoachId = args.value;
        }

        function saveTeam(simpleSave) {
            if (angular.element('#FileName').val() !== '' && vm.cropper.croppedImage !== null) {
                vm.formData.FileData = vm.cropper.croppedImage;
                vm.formData.FileName = angular.element('#FileName').val();
            }
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('RequiredFieldsError');
            } else {
                dataservice.saveTeam(vm.formData)
                    .then(function (data) {
                        log.successMsg('TeamSaved');
                        paramHelper.setParams([{'teams.sectionId': vm.formData.SectionId}, {'teams.ageGroupId': vm.formData.AgeGroupId}]);
                        if (simpleSave) {
                            $state.go('athletes.teams.teamList');
                        } else {
                            paramHelper.setParams([{'teams.team': data.res}]);
                            $state.go('athletes.teams.teamMembersList');
                        }
                    }, function (response) {
                        validatorHelper.validate(response, $scope.form);
                    });
            }
        }

        function simpleSave() {
            saveTeam(true);
        }

        function saveTeamAndAddMembers() {
            saveTeam(false);
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteTeamImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheTeamImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#FileName').val('');
                    vm.photoUrl = vm.noImg;
                    $scope.$apply();
                }
            });
        }
    }
})();
