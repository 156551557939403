(function () {
    'use strict';

    angular
        .module('app.search.athletes.simple')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'search.athletes.simple',
                config: {
                    url: '/simple',
                    templateUrl: 'app/search/athletes/simple/simple.html',
                    controller: 'SimpleController',
                    controllerAs: 'vm',
                    title: 'SearchAthletesSimple',
                    settings: {
                        navId: 21170,
                        level: 2,
                        order: 1,
                        orderTitle_hu: 'Sportoló kereső',
                        orderTitle_en: 'Athlete Search',
                        parentId: 170,
                        content: 'SearchAthletesSimple',
                        activatorPermission: '"SPORTOLOKERESO"'
                    }
                }
            }
        ];
    }
})();
