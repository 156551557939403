(function () {
    'use strict';

    angular
        .module('app.business.monthlyAttendanceArrears')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.monthlyAttendanceArrears',
                config: {
                    url: '/monthlyAttendanceArrears',
                    templateUrl: 'app/business/monthlyAttendanceArrears/monthlyAttendanceArrears.html',
                    controller: 'MonthlyAttendanceArrearsController',
                    controllerAs: 'vm',
                    title: 'MonthlyAttendanceReportArrears',
                    settings: {
                        navId: 293,
                        level: 2,
                        order: 9,
                        orderTitle_hu: 'Havi jelenléti összesítő lemaradások',
                        orderTitle_en: 'Monthly Attendance Report Arrears',
                        parentId: 3,
                        content: 'MonthlyAttendanceReportArrears',
                        activatorPermission: '"MEGTHAVIJELLEM"'
                    }
                }
            }
        ];
    }
})();
