(function () {
    'use strict';

    angular
        .module('app.administration')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration',
                config: {
                    title: 'Administration',
                    url: '/administration',
                    templateUrl: 'app/administration/administration.html',
                    settings: {
                        navId: 2,
                        level: 1,
                        order: 2,
                        parentId: 0,
                        content: 'Administration',
                        activatorPermission: ['"MEGTEVVERST"', '"ESZKNYILVMEGT"', '"MEGTIDEGENKOLTSTERV"', '"MEGTHAVIVERST"', '"MEGTHAVIJEL"', '"MEGTHAVIEDZTERV"',
                                              '"UJEERJEL"', '"MEGTEERJEL"', '"MEGTCSERJEL"', '"MEGTSPFELM"', '"MEGTKPFELM"', '"MEGTEDZESSABLON"', '"MEGTHETIEDZTERV"']
                    }
                }
            }
        ];
    }
})();
