(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage6')
        .controller('AnnualSeasonPlanReportPage6Controller', AnnualSeasonPlanReportPage6Controller);

    function AnnualSeasonPlanReportPage6Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line
        var printOpened = false;
        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanReport');
        vm.columns = [];
        vm.stackedHeaderRows = [];
        vm.egyeni = false;
        vm.editable = true;
        vm.disable = false;
        vm.closed = false;
        vm.annualSeasonPlanReportId = 0;
        vm.rejectAttendanceText = '';
        vm.reportParameters = {};

        vm.head = {};
        vm.data = [];
        vm.months = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];
        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];

        // functions
        vm.goToPreviousPage = goToPreviousPage;
        vm.gotoMainPage = gotoMainPage;
        vm.savePlan = savePlan;
        vm.print = print;
        vm.refreshCompetition = refreshCompetition;
        vm.closePlan = closePlan;
        vm.rejectPlan = rejectPlan;
        vm.acceptPlan = acceptPlan;
        vm.rejectAttendance = rejectAttendance;
        vm.closerejectingWindow = closerejectingWindow;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;
        vm.cellEdit = cellEdit;
        vm.cellsave = cellsave;

        vm.tableEditorTemplate = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_StrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: 'C',
                    customCharacter: 'xX',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                    readOnly: !vm.editable
                });
            }
        };

        vm.tableEditorTemplateNumX = {
            create: function () {
                elem = document.createElement('input');
                elem.addEventListener('focus', function () {
                    this.select();
                });
                return elem;
            },
            read: function () {
                return gridMaskEdit.value;
            },
            destroy: function () {
                gridMaskEdit.destroy();
            },
            write: function (args) {
                gridMaskEdit = new ej2.inputs.MaskedTextBox({
                    minWidth: '20px',
                    mask: '>C',
                    customCharacters: {C: 'xX0123456789'},
                    value: args.rowData !== undefined ? (args.rowData[args.column.field]).toString().trim() : '',
                    readOnly: !vm.editable
                });
                gridMaskEdit.appendTo(elem);
            }
        };

        vm.tableEditorTemplateNum = {
            create: function () {
                elem = document.createElement('input');
                elem.addEventListener('focus', function () {
                    this.select();
                });
                return elem;
            },
            read: function () {
                return gridMaskEdit.value;
            },
            destroy: function () {
                gridMaskEdit.destroy();
            },
            write: function (args) {
                gridMaskEdit = new ej2.inputs.MaskedTextBox({
                    minWidth: '20px',
                    mask: '>C',
                    customCharacters: {C: '0123456789'},
                    value: args.rowData !== undefined ? (args.rowData[args.column.field]).toString().trim() : '',
                    readOnly: !vm.editable
                });
                gridMaskEdit.appendTo(elem);
            }
        };

        vm.smallColumnWidth = 40;
        vm.columns = [{
            headerText: gettextCatalog.getString('FelkHetSzam') + '<br>(1-52)',
            textAlign: 'center',
            columns: [{
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true,
                width: vm.smallColumnWidth
            },
                {
                    field: 'HetSzam',
                    headerText: gettextCatalog.getString('WeekNumber'),
                    textAlign: 'center',
                    width: 50,
                    customAttributes: {class: 'orientationcss'},
                    allowEditing: false
                },
                {
                    field: 'FelkHetSzam',
                    headerText: gettextCatalog.getString('PreparationWeekNumber'),
                    textAlign: 'center',
                    width: 50,
                    type: 'number',
                    customAttributes: {class: 'orientationcss'},
                    allowEditing: vm.editable
                },
                {
                    field: 'Datum',
                    headerText: gettextCatalog.getString('Date'),
                    textAlign: 'center',
                    allowEditing: false,
                    width: 140
                }
            ]
        },
            {
                headerText: gettextCatalog.getString('FelkIdJelleg') + '<br>(X)',
                textAlign: 'center',
                columns: [{
                    field: 'FelkIdJellTiszta',
                    headerText: gettextCatalog.getString('FelkeszulesiIdoTiszta'),
                    textAlign: 'center',
                    width: vm.smallColumnWidth,
                    customAttributes: {class: 'orientationcss', padding: 0},
                    allowEditing: vm.editable
                },
                    {
                        field: 'FelkIdJellVegyes',
                        headerText: gettextCatalog.getString('FelkeszulesiIdoVegyes'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss'},
                        allowEditing: vm.editable
                    },
                    {
                        field: 'FelkIdJellVerseny',
                        headerText: gettextCatalog.getString('FelkeszulesiIdoVerseny'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss'},
                        allowEditing: vm.editable
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('HetiEdzesSzam') + '<br>(0-21)',
                textAlign: 'center',
                columns: [{
                    field: 'HetiEdzesSzamAlt',
                    headerText: gettextCatalog.getString('HetiEdzesSzamAltalanos'),
                    textAlign: 'center',
                    width: vm.smallColumnWidth,
                    customAttributes: {class: 'orientationcss', id: 'annualseason'},
                    editType: 'numericedit',
                    edit: {params: {decimals: 2, showSpinButton: false, min: 0, max: 21}},
                    allowEditing: vm.editable
                },
                    {
                        field: 'HetiEdzesSzamSpSpec',
                        headerText: gettextCatalog.getString('HetiEdzesSzamSportagSpecifikus'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        editType: 'numericedit',
                        edit: {params: {decimals: 2, showSpinButton: false, min: 0, max: 21}},
                        allowEditing: vm.editable
                    },
                    {
                        field: 'HetiEdzesSzamOsszesen',
                        headerText: gettextCatalog.getString('HetiEdzesSzamOsszesen'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        readOnly: true
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('HetiTerheles') + '<br>(X, 0-9)',
                textAlign: 'center',
                columns: [{
                    field: 'HetiTerhelesMagas',
                    headerText: gettextCatalog.getString('HetiTerhelesMagas'),
                    textAlign: 'center',
                    width: vm.smallColumnWidth,
                    customAttributes: {class: 'orientationcss', id: 'annualseason'},
                    edit: vm.tableEditorTemplateNumX,
                    allowEditing: vm.editable
                },
                    {
                        field: 'HetiTerhelesKozepes',
                        headerText: gettextCatalog.getString('HetiTerhelesKozepes'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNumX,
                        allowEditing: vm.editable
                    },
                    {
                        field: 'HetiTerhelesAlacsony',
                        headerText: gettextCatalog.getString('HetiTerhelesAlacsony'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNumX,
                        allowEditing: vm.editable
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('HetiEdzesJell') + '<br>(X, 0-9)',
                textAlign: 'center',
                columns: [{
                    field: 'HetiEdzMunkaTechnikai',
                    headerText: gettextCatalog.getString('HetiEdzesMunkaTechnikai'),
                    textAlign: 'center',
                    width: vm.smallColumnWidth,
                    customAttributes: {class: 'orientationcss', id: 'annualseason'},
                    edit: vm.tableEditorTemplateNumX,
                    allowEditing: vm.editable
                },
                    {
                        field: 'HetiEdzMunkaKepFejl',
                        headerText: gettextCatalog.getString('HetiEdzesMunkaKepessegFejlesztes'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNumX,
                        allowEditing: vm.editable
                    },
                    {
                        field: 'HetiEdzMunkaKieg',
                        headerText: gettextCatalog.getString('HetiEdzesMunkaKiegeszito'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNumX,
                        allowEditing: vm.editable
                    },
                    {
                        field: 'HetiEdzMunkaEgyeb',
                        headerText: gettextCatalog.getString('HetiEdzesMunkaEgyeb'),
                        textAlign: 'center',
                        width: vm.smallColumnWidth,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNumX,
                        allowEditing: vm.editable
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('Felmeresek') + '<br>(0-9)',
                textAlign: 'center',
                columns: [{
                    field: 'FelmeresekAlt',
                    headerText: gettextCatalog.getString('GeneralSurveys'),
                    textAlign: 'center',
                    width: 45,
                    customAttributes: {class: 'orientationcss', id: 'annualseason'},
                    edit: vm.tableEditorTemplateNum,
                    allowEditing: vm.editable
                },
                    {
                        field: 'FelmeresekSpSpec',
                        headerText: gettextCatalog.getString('SportagSpecifikusFelmeresek'),
                        textAlign: 'center',
                        width: 45,
                        customAttributes: {class: 'orientationcss', id: 'annualseason'},
                        edit: vm.tableEditorTemplateNum,
                        allowEditing: vm.editable
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('Edzotaborozas'),
                textAlign: 'center',
                columns: [{
                    field: 'EdzTabIdopont',
                    headerText: gettextCatalog.getString('EdzotaborozasIdopont'),
                    textAlign: 'center',
                    allowEditing: vm.editable,
                    width: 90,
                    customAttributes: {id: 'annualseason'}
                },
                    {
                        field: 'EdzTabHely',
                        headerText: gettextCatalog.getString('EdzotaborozasHely'),
                        textAlign: 'center',
                        allowEditing: vm.editable,
                        customAttributes: {id: 'annualseason'},
                        width: 150
                    },
                    {
                        field: 'EdzTabLetszam',
                        headerText: gettextCatalog.getString('EdzotaborozasLetszam'),
                        textAlign: 'center',
                        allowEditing: vm.editable,
                        customAttributes: {id: 'annualseason'},
                        width: 80
                    }
                ]
            },
            {
                headerText: gettextCatalog.getString('Verseny'),
                textAlign: 'center',
                columns: [{
                    field: 'Verseny',
                    headerText: gettextCatalog.getString('Verseny'),
                    textAlign: 'center',
                    allowEditing: vm.editable,
                    customAttributes: {id: 'annualseason'},
                    width: 100
                }]
            }
        ];

        activate();

        var gridMaskEdit;
        var elem;
        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: ['ExcelExport'],
            allowExcelExport: true,
            height: 325,
            allowPdfExport: true,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            allowTextWrap: true,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            cellSave: cellsave,
            cellEdit: cellEdit
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function activate() {
            vm.jumpedPage = '6 - ' + gettextCatalog.getString('YearlyTablePlan');
            vm.defaultJumpPage = '6 - ' + gettextCatalog.getString('YearlyTablePlan');
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id']),
                    authenticationService.getRight('EDZO'),
                    authenticationService.getRight('ADMIN'),
                    authenticationService.getRight('SZAKOSZTVEZ'),
                    authenticationService.getRight('TECHIGAZGATO'),
                    authenticationService.getRight('EVVERSTALAIR1'),
                    authenticationService.getRight('EVVERSTALAIR2'),
                    authenticationService.getRight('UJEVVERST'),
                    authenticationService.getRight('MEGTSPORTLIST'),
                    authenticationService.getRight('MEGTEVVERST')
                ])
                .then(function (results) {
                    {
                        vm.EDZO = results[1];
                        vm.ADMIN = results[2];
                        vm.SZAKOSZTVEZ = results[3];
                        vm.TECHIGAZGATO = results[4];
                        vm.EVVERSTALAIR1 = results[5];
                        vm.EVVERSTALAIR2 = results[6];
                        vm.UJEVVERST = results[7];
                        vm.MEGTSPORTLIST = results[8];
                        vm.MEGTEVVERST = results[9];
                        if (!vm.MEGTEVVERST) {
                            log.permissionError(true);
                            return;
                        }
                        if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                            vm.annualSeasonPlanReportId = results[0]['AnnualSeasonPlanReport.Id'];
                            paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                            dataservice.annualSeasonPlanReportSelectById(vm.annualSeasonPlanReportId)
                                .then(function (data) {
                                    vm.head = data;
                                    vm.egyeni = data.Egyeni;
                                    if (data.EdzoLezarta) {
                                        vm.editable = false;
                                        vm.disable = true;
                                    } else {
                                        vm.editable = true;
                                        vm.disable = false;
                                    }
                                    vm.closed = (data.SzakosztalyvezetoJovahagyta == null || data.SzakosztalyvezetoJovahagyta === true) && !vm.EVVERSTALAIR1;

                                    dataservice.annualSeasponPlanTableListSelect(vm.annualSeasonPlanReportId)
                                        .then(function (data) {
                                            vm.data = data.itemsList;
                                            grid.dataSource = vm.data;
                                            if (vm.data.length === 0) {
                                                //megkeressük az első hétfőt augusztusban
                                                var datum = new Date(vm.head.ErvenyessegTol);
                                                while (datum.getDay() > 1 || datum.getDay() === 0) {
                                                    datum.setDate(datum.getDate() + 1);
                                                }
                                                for (var i = 1; i <= 52; i++) {
                                                    var datumstr = vm.months[datum.getMonth()] + '.' + datum.getDate() + '. - ';
                                                    //vasárnap
                                                    datum.setDate(datum.getDate() + 6);
                                                    datumstr += vm.months[datum.getMonth()] + '.' + datum.getDate() + '.';
                                                    //hétfő
                                                    datum.setDate(datum.getDate() + 1);
                                                    var obj = {
                                                        Datum: datumstr,
                                                        EdzTabHely: '',
                                                        EdzTabIdopont: '',
                                                        EdzTabLetszam: '',
                                                        FelkHetSzam: '',
                                                        FelkIdJellTiszta: '',
                                                        FelkIdJellVegyes: '',
                                                        FelkIdJellVerseny: '',
                                                        FelmeresekAlt: '',
                                                        FelmeresekSpSpec: '',
                                                        HetiEdzesSzamAlt: '',
                                                        HetiEdzesSzamOsszesen: '',
                                                        HetiEdzesSzamSpSpec: '',
                                                        HetiEdzMunkaEgyeb: '',
                                                        HetiEdzMunkaKepFejl: '',
                                                        HetiEdzMunkaKieg: '',
                                                        HetiEdzMunkaTechnikai: '',
                                                        HetiTerhelesAlacsony: '',
                                                        HetiTerhelesKozepes: '',
                                                        HetiTerhelesMagas: '',
                                                        HetSzam: i,
                                                        IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                                                        Verseny: '',
                                                        Id: -1 * i
                                                    };
                                                    vm.data.push(obj);
                                                }
                                            }
                                        });
                                });
                        } else {
                            $rootScope.back();
                        }
                    }
                });
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '6 ') {
                return;
            }

            if (vm.editable) {
                grid.endEdit();
                dataservice.saveAnnualSeasonPlanHead(vm.head)
                    .then(function () {
                        dataservice.saveAnnualSeasonPlanTable(vm.data)
                            .then(function () {
                                log.successMsg('SaveCompleted');
                            });
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                    case '1 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                        break;
                    case '2 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                        break;
                    case '3 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                        break;
                    case '4 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                        break;
                    case '5 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                        break;
                    case '6 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                        break;
                }
            }, 100);
        }

        function goToPreviousPage() {
            grid.endEdit();
            dataservice.saveAnnualSeasonPlanHead(vm.head).then(function () {
                dataservice.saveAnnualSeasonPlanTable(vm.data).then(function () {
                    paramHelper.setParams([{
                        'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                    }]);
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                });
            });
        }

        function gotoMainPage() {
            $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
        }

        function savePlan() {
            grid.endEdit();
            dataservice.saveAnnualSeasonPlanHead(vm.head)
                .then(function () {
                    dataservice.saveAnnualSeasonPlanTable(vm.data)
                        .then(function () {
                            log.successMsg('SaveSuccessed');
                        });
                });
        }

        function getAccepterCoachById() {
            if (vm.head.SzakosztalyvezetoJovahagytaId === null) {
                return '';
            } else {
                return $q(function (resolve) {
                    dataservice.getUserData(vm.head.SzakosztalyvezetoJovahagytaId)
                        .then(function (args) {
                            resolve(args.retValue.name);
                        });
                });
            }
        }

        function print() {
            $q.all([getAccepterCoachById()])
                .then(function (results) {
                    vm.reportParameters = {
                        Szakosztaly: vm.head.SzakosztalyNeve,
                        Korcsoport: vm.head.KorosztalyNeve,
                        Edzo: vm.head.EdzoNeve,
                        Mobil: vm.head.EdzoMobil === undefined ? '' : vm.head.EdzoMobil,
                        Email: vm.head.EdzoEmail === undefined ? '' : vm.head.EdzoEmail,
                        FejId: vm.head.Id,
                        Jovahagyta: results[0],
                        Keszitette: vm.head.EdzoNeve,
                        techKepzes: ''
                    };
                    if (printOpened) {
                        angular.element('#printDialog').ejDialog('open');
                    } else {
                        printOpened = true;
                        angular.element('#printDialog').ejDialog({
                            height: '99%',
                            width: '99%',
                            enablemodal: 'true',
                            'position-x': '0',
                            'position-y': '0',
                            target: '#mainContent',
                            enableresize: 'false'
                        });
                    }
                });
        }

        function refreshCompetition() {
            dataservice.annualSeasonPlanCompetitionPlansAndObjectivesSelect(vm.annualSeasonPlanReportId)
                .then(function (args) {
                    var dt = new Date(vm.head.ErvenyessegTol);
                    var di = new Date(dt);
                    di.setDate(dt.getDate() + 7);
                    grid.endEdit();
                    for (var j = 0; j < grid.getRows().length; j++) {
                        for (var i = 0; i < args.itemsList.length; i++) {
                            var esDate = new Date((args.itemsList[i].EsemenyIdopont2).replace('. ', '-').replace('. ', '-').replace('.', ''));
                            if (esDate >= dt && esDate < di) {
                                var row = grid.getRowByIndex(j);
                                vm.data[j]['Verseny'] += '; ' + args.itemsList[i].EsemenyNeve;
                            }
                        }
                        dt = new Date(di);
                        di.setDate(dt.getDate() + 7);
                    }
                    grid.dataSource = vm.data;
                    // grid.refresh();
                });
        }

        function closePlan() {
            vm.head.EdzoLezarta = true;
            vm.head.EdzoLezartaDatum = new Date().toDateString();
            dataservice.saveAnnualSeasonPlanHead(vm.head)
                .then(function () {
                    if (!vm.hasErrorAtSave) {
                        vm.editable = false;
                    }
                });
        }

        function rejectPlan() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.open();
        }

        //Felugró ablak 'Mégsem' gomb click
        function closerejectingWindow() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.close();
        }

        //Felugró ablak 'Rendben' gomb click - visszautasítás elmentése
        function rejectAttendance() {
            if (vm.rejectAttendanceText.length > 0) {
                var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
                dialogObj.close();
            } else {
                return;
            }
            var uzi = gettextCatalog.getString('SystemMessage') + '\r\n' + gettextCatalog.getString('The') + ' ' + vm.head.SzakosztalyNeve + ' - ' +
                vm.head.KorosztalyNeve + ' ' + new Date(vm.head.ErvenyessegTol).getFullYear() + '/' + new Date(vm.head.ErvenyessegIg).getFullYear() +
                ' ' + gettextCatalog.getString('AnnualSeasonPlanReportRejected') + '\r\n' + gettextCatalog.getString('Cause') + vm.rejectAttendanceText;
            vm.head.EdzoLezarta = false;
            vm.head.EdzoAlairtaDatum = undefined;
            vm.head.SzakosztalyVezetoJovahagyva = undefined;
            vm.head.SzakosztalyVezetoJovahagytaId = undefined;
            vm.head.SzakosztalyVezetoJovahagyta = false;
            dataservice.saveAnnualSeasonPlanHead(vm.head)
                .then(function () {
                    if (!vm.hasErrorAtSave) {
                        dataservice.messageSave(uzi, [vm.head.IdEdzo], vm.head.IdSzakosztaly).then(function () {
                            vm.editable = true;
                        });
                    }
                });
        }

        function acceptPlan() {
            var uzi = gettextCatalog.getString('SystemMessage') + '\r\n' + gettextCatalog.getString('The') + ' ' + vm.head.SzakosztalyNeve + ' - ' +
                vm.head.KorosztalyNeve + ' ' + new Date(vm.head.ErvenyessegTol).getFullYear() + '/' + new Date(vm.head.ErvenyessegIg).getFullYear() +
                ' ' + gettextCatalog.getString('AnnualSeasonPlanReportAccepted');
            dataservice.messageSave(uzi, [vm.head.IdEdzo], vm.head.IdSzakosztaly).then(function () {
                vm.head.SzakosztalyvezetoJovahagyta = true;
                vm.head.SzakosztalyvezetoJovahagyva = new Date();
                vm.head.SzakosztalyvezetoJovahagytaId = authenticationService.getAuthData().id;
                dataservice.saveAnnualSeasonPlanHead(vm.head)
                    .then(function () {
                        log.successMsg('AnnualSeasonPlanAccepted');
                    });
            });
        }

        function cellsave(args) {
            if (args.columnName === 'HetiEdzesSzamSpSpec') {
                var alt = args.rowData.HetiEdzesSzamAlt;
                if (alt === undefined || alt == null || (alt.trim && alt.trim() === '')) {
                    alt = 0;
                }
                var sp = args.value;
                if (sp === undefined || sp == null || (sp.trim && sp.trim() === '')) {
                    sp = 0;
                }
                alt = checkNumberInterval(alt, 0, 21);
                sp = checkNumberInterval(sp, 0, 21);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamAlt'] = parseInt(alt);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamSpSpec'] = parseInt(sp);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamOsszesen'] = (parseInt(alt) + parseInt(sp));
                grid.refresh();
            } else if (args.columnName === 'HetiEdzesSzamAlt') {
                var sp = args.rowData.HetiEdzesSzamSpSpec;
                if (sp === undefined || sp == null || (sp.trim && sp.trim() === '')) {
                    sp = 0;
                }
                var alt = args.value;
                if (alt === undefined || alt == null || (alt.trim && alt.trim() === '')) {
                    alt = 0;
                }
                alt = checkNumberInterval(alt, 0, 21);
                sp = checkNumberInterval(sp, 0, 21);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamAlt'] = parseInt(alt);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamSpSpec'] = parseInt(sp);
                vm.data[args.rowData.HetSzam - 1]['HetiEdzesSzamOsszesen'] = (parseInt(alt) + parseInt(sp));
                grid.refresh();
            } else {
                vm.data[args.rowData.HetSzam - 1][args.columnName] = args.value;
                // grid.refresh();
            }
        }

        function checkNumberInterval(number, min, max) {
            if (number > max) {
                return max;
            }
            if (number < min) {
                return min;
            }
            return number;
        }

        function cellEdit(args) {
            if (args.columnName === 'HetiEdzesSzamOsszesen') {
                args.cancel = true;
                return;
            }
            if (args.columnName === 'FelkHetSzam') {
                var ssz = 1;
                for (var j = args.rowData.HetSzam; j <= 52; j++) {
                    vm.data[j - 1]['FelkHetSzam'] = ssz++;
                }
                ssz = 52;
                for (var j = args.rowData.HetSzam - 1; j > 0; j--) {
                    vm.data[j - 1]['FelkHetSzam'] = ssz--;
                }
                grid.endEdit();
                args.cancel = true;
            } else if (args.columnName === 'FelkIdJellTiszta') {
                if (args.rowData['FelkIdJellTiszta'] === 'x' || args.rowData['FelkIdJellTiszta'] === 'X') {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellTiszta'] = '';
                } else {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellTiszta'] = 'X';
                }
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVegyes'] = '';
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVerseny'] = '';
                grid.endEdit();
                args.cancel = true;
            } else if (args.columnName === 'FelkIdJellVegyes') {
                if (args.rowData['FelkIdJellVegyes'] === 'x' || args.rowData['FelkIdJellVegyes'] === 'X') {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVegyes'] = '';
                } else {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVegyes'] = 'X';
                }
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellTiszta'] = '';
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVerseny'] = '';
                grid.endEdit();
                args.cancel = true;
            } else if (args.columnName === 'FelkIdJellVerseny') {
                if (args.rowData['FelkIdJellVerseny'] === 'x' || args.rowData['FelkIdJellVerseny'] === 'X') {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVerseny'] = '';
                } else {
                    vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVerseny'] = 'X';
                }
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellTiszta'] = '';
                vm.data[args.rowData['HetSzam'] - 1]['FelkIdJellVegyes'] = '';
                grid.endEdit();
                args.cancel = true;
            }
            /*else if (args.columnName === 'HetiTerhelesMagas') {
                           if (args.rowData['HetiTerhelesMagas'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesMagas', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesMagas', 'X');
                           }
                       } else if (args.columnName === 'HetiTerhelesKozepes') {
                           if (args.rowData['HetiTerhelesKozepes'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesKozepes', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesKozepes', 'X');
                           }
                       } else if (args.columnName === 'HetiTerhelesAlacsony') {
                           if (args.rowData['HetiTerhelesAlacsony'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesAlacsony', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiTerhelesAlacsony', 'X');
                           }
                       } else if (args.columnName === 'HetiEdzMunkaTechnikai') {
                           if (args.rowData['HetiEdzMunkaTechnikai'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaTechnikai', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaTechnikai', 'X');
                           }
                       } else if (args.columnName === 'HetiEdzMunkaKepFejl') {
                           if (args.rowData['HetiEdzMunkaKepFejl'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaKepFejl', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaKepFejl', 'X');
                           }
                       } else if (args.columnName === 'HetiEdzMunkaKieg') {
                           if (args.rowData['HetiEdzMunkaKieg'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaKieg', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaKieg', 'X');
                           }
                       } else if (args.columnName === 'HetiEdzMunkaEgyeb') {
                           if (args.rowData['HetiEdzMunkaEgyeb'] === 'X') {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaEgyeb', '');
                           } else {
                               grid.setCellValue(args.rowData['HetSzam'] - 1, 'HetiEdzMunkaEgyeb', 'X');
                           }
                       }*/
        }
    }
})();
