(function () {
    'use strict';

    angular
        .module('app.sikerMobile')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'sikerMobile',
                config: {
                    url: '/sikerMobile',
                    templateUrl: 'app/sikerMobile/sikerMobile.html',
                    controller: 'SikerMobileController',
                    controllerAs: 'vm',
                    title: 'SikerMobileMenu',
                    // settings: {
                    //     navId: 10,
                    //     level: 1,
                    //     order: 11,
                    //     parentId: 0,
                    //     content: 'SikerMobileMenu',
                    // }
                }
            }
        ];
    }
})();
