(function () {
    'use strict';

    angular
        .module('app.administration.monthlyAttendance')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.monthlyAttendance',
                config: {
                    url: '/monthlyAttendance',
                    templateUrl: 'app/administration/monthlyAttendance/monthlyAttendance.html'
                }
            }
        ];
    }
})();
