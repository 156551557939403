(function () {
    'use strict';

    angular
        .module('app.business.checkPrintingExport')
        .controller('CheckPrintingExportController', CheckPrintingExportController);

    function CheckPrintingExportController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CheckPrintingExport');

        // Create variables
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.coachList = [];

        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth();
        vm.dateFromValue = new Date(y, m, 1);
        vm.dateToValue = new Date(y, m + 1, 0);

        vm.data = [];
        vm.selectedCoach = vm.coachId = 0;
        vm.selectedSection = 0;

        // Grid's variables
        vm.columns = [
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Name'), width: 100,
                template: '#checkprintingexportcolumnTemplate1'
            },
            {
                field: 'NyilvantartasiSzama',
                headerText: gettextCatalog.getString('RegistrationNumber'),
                textAlign: 'center',
                width: 100
            },
            {field: 'CimeIrSzam', headerText: gettextCatalog.getString('ZipCode'), textAlign: 'center', width: 50},
            {field: 'CimeVaros', headerText: gettextCatalog.getString('City'), width: 100},
            {field: 'CimeUtca', headerText: gettextCatalog.getString('Street'), textAlign: 'left', width: 100},
            {
                field: 'BefizetoAzonosito',
                headerText: gettextCatalog.getString('BefizetoAzonosito'),
                textAlign: 'center',
                width: 100
            },
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: 100},
            {
                field: 'Edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'left', width: 100,
                template: '#checkprintingexportcolumnTemplate2'
            },
            {
                field: 'FizetendoOsszeg',
                headerText: gettextCatalog.getString('FizetendoOsszeg'),
                textAlign: 'left',
                width: 100
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.getDatas = getDatas;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        // Activate page
        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['checkPrintingExport.sectionId',
                'checkPrintingExport.dateFrom',
                'checkPrintingExport.coachId',
                'checkPrintingExport.dateTo']), authenticationService.getRight('CSEKKEXPORT')];
            $q.all(promises).then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.defaultsectionId = results[0]['checkPrintingExport.sectionId'];
                    vm.defaultCoachId = results[0]['checkPrintingExport.coachId'];
                    vm.dateFromValue = results[0]['checkPrintingExport.dateFrom'];
                    vm.dateToValue = results[0]['checkPrintingExport.dateTo'];
                    getSections();
                }
            });
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getcoaches() {
            return dataservice.coachDropDownList(vm.sectionId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.coachList = data.itemsList;
            });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.defaultCoachId);
                vm.coachId = vm.defaultCoachId;
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.sectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.defaultsectionId);
                vm.sectionId = vm.defaultsectionId;
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.sectionId = args.value;
            getcoaches();
        }

        function getDatas() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'checkPrintingExport.sectionId': vm.sectionId},
                {'checkPrintingExport.coachId': vm.coachId},
                {'checkPrintingExport.dateFrom': vm.dateFromValue},
                {'checkPrintingExport.dateTo': vm.dateToValue}
            ]);
            return dataservice.getCheckExportPrintList(vm.sectionId, vm.coachId, vm.dateFromValue, vm.dateToValue).then(function (data) {
                vm.athletesList = data.itemsList;
                grid.dataSource = vm.athletesList;
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
