(function () {
    'use strict';

    angular
        .module('app.settings.userRights')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.userRights',
                config: {
                    url: '/userRights',
                    templateUrl: 'app/settings/userRights/userRights.html',
                    controller: 'UserRightsController',
                    controllerAs: 'vm',
                    title: 'UserRights',
                    settings: {
                        navId: 235,
                        level: 2,
                        order: 3,
                        orderTitle_hu: 'Felhasználói csoport jogosultságok',
                        orderTitle_en: 'Group Permissions',
                        parentId: 5,
                        content: 'UserRights',
                        activatorPermission: '"MEGTFELHJOG"'
                    }
                }
            }
        ];
    }
})();
