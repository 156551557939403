(function () {
    'use strict';

    angular
        .module('app.administration.quickCompetitionResults')
        .controller('QuickCompetitionResultsController', QuickCompetitionResultsController);

    function QuickCompetitionResultsController(log, paramHelper, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $compile, $scope, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('QuickCompetitionResults');

        vm.maskObjectTime;
        vm.elem;
        vm.tableTimeEditorTemplate = {
            create: function () {
                var txt = document.createElement('input');
                txt.style.width = '100%';
                txt.style.float = 'left';
                vm.elem = txt;
                vm.elem.addEventListener('focus', function () {
                    this.select();
                });
                return vm.elem;
            },
            read: function () {
                return vm.maskObjectTime.getMaskedValue();
            },
            destroy: function () {
                if (vm.maskObjectTime != null) {
                    vm.maskObjectTime.destroy();
                }
            },
            write: function (args) {
                var s = args.element.id.toString().replace('GridAthletes', '');
                vm.maskObjectTime = new ej2.inputs.MaskedTextBox({
                    minWidth: '100%',
                    mask: '99:99:99.999',
                    value: angular.isDefined(undefined) ? args.rowdata[s] : '',
                    readOnly: !vm.editable
                });
                vm.editedRowId = args.rowData['Id'];
                $compile(args.element[0])($scope);
                vm.maskObjectTime.appendTo(vm.elem);
            }
        };

        vm.maskObject;
        vm.elem;
        vm.dateTimeEditorTemplate =
            {
                create: function () {
                    var txt = document.createElement('input');
                    txt.style.width = '100%';
                    txt.style.float = 'left';
                    vm.elem = txt;
                    vm.elem.addEventListener('focus', function () {
                        this.select();
                    });
                    return vm.elem;
                },
                read: function () {
                    return vm.maskObject.getMaskedValue();
                },
                destroy: function () {
                    if (vm.maskObject != null) {
                        vm.maskObject.destroy();
                    }
                },
                write: function (args) {
                    var s = args.element.id.toString().replace('Grid', '');
                    vm.maskObject = new ej2.inputs.MaskedTextBox({
                        minWidth: '100%',
                        mask: '>0000-00-00',
                        value: args.rowData !== angular.isUndefined ? args.rowData[s] : ''.replace(' ', ''),
                        readOnly: vm.readOnlyAttendance,
                    });
                    vm.editedRowId = args.rowData['Id'];
                    $compile(args.element[0])($scope);
                    vm.maskObject.appendTo(vm.elem);
                }
            };

        vm.maskObject;
        vm.elem;
        vm.ratingEditorTemplate =
            {
                create: function () {
                    var txt = document.createElement('input');
                    txt.style.width = '100%';
                    txt.style.float = 'center';
                    vm.elem = txt;
                    vm.elem.addEventListener('focus', function () {
                        this.select();
                    });
                    return vm.elem;
                },
                read: function () {
                    return vm.maskObject.getMaskedValue();
                },
                destroy: function () {
                    if (vm.maskObject != null) {
                        vm.maskObject.destroy();
                    }
                },
                write: function (args) {
                    var s = args.element.id.toString().replace('Grid', '');
                    vm.maskObject = new ej2.inputs.MaskedTextBox({
                        minWidth: '100%',
                        mask: '>C',
                        customCharacters: {C: '012345'},
                        value: args.rowData !== angular.isUndefined ? args.rowData[s] : ''.replace(' ', ''),
                        readOnly: vm.readOnlyAttendance,
                    });
                    vm.editedRowId = args.rowData['Id'];
                    $compile(args.element[0])($scope);
                    vm.maskObject.appendTo(vm.elem);
                }
            };

        vm.columns = [

            {
                field: 'SportoloId',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'SportoloNev',
                headerText: gettextCatalog.getString('AthleteName'),
                textAlign: 'left',
                width: 150,
                allowEditing: false,
                template: '#qcrname'
            },
            {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'left',
                width: 120,
                allowEditing: false
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('TrainerName'),
                textAlign: 'left',
                width: 120,
                allowEditing: false,
            },
            {
                field: 'VersenyLeiras',
                headerText: gettextCatalog.getString('CompetitionName'),
                textAlign: 'left',
                width: 150,
                allowEditing: true
            },
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                textAlign: 'left',
                width: 100,
                editType: 'datetimepicker',
                allowEditing: true
            },
            {
                field: 'DatumTol',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'left',
                edit: vm.dateTimeEditorTemplate,
                width: 150,
                allowEditing: true
            },
            {
                field: 'Helyszin',
                headerText: gettextCatalog.getString('Helyszin'),
                textAlign: 'left',
                width: 160,
                allowEditing: true
            },
            {
                field: 'Helyezes',
                headerText: gettextCatalog.getString('Helyezes'),
                textAlign: 'left',
                width: 120,
                allowEditing: true
            },
            {
                field: 'EredmenySzoveg',
                headerText: gettextCatalog.getString('EredmenySzoveg'),
                textAlign: 'left',
                width: 160,
                allowEditing: true
            },
            {
                field: 'EredmenyPont',
                headerText: gettextCatalog.getString('EredmenyPontGol'),
                textAlign: 'center',
                editType: 'numericedit',
                width: 160,
                allowEditing: true
            },
            {
                field: 'EredmenyIdo',
                headerText: gettextCatalog.getString('EredmenyIdo'),
                textAlign: 'center',
                edit: vm.tableTimeEditorTemplate,
                width: 160,
                allowEditing: true
            },
            {
                field: 'EredmenyTavolsag',
                headerText: gettextCatalog.getString('EredmenyTavolsag'),
                textAlign: 'center',
                editType: 'numericedit',
                editParams: {decimalPlaces: 2, locale: 'HU-hu'},
                width: 160,
                allowEditing: true
            },
            {
                field: 'EredmenyTavolsagM',
                headerText: gettextCatalog.getString('EredmenyTavolsagM'),
                textAlign: 'center',
                editType: 'numericedit',
                width: 160,
                allowEditing: true
            },
            {
                field: 'Eredmeny',
                headerText: gettextCatalog.getString('Rating'),
                textAlign: 'center',
                editType: 'numericedit',
                edit: {params: {min: 0, max: 5}},
                width: 200,
                allowEditing: true
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            cellSave: cellSave,
        });
        grid.appendTo('#Grid');

        // Public functions
        vm.openAthlete = openAthlete;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.save = save;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('UJEERJEL'), getSections(), paramHelper.getParams([], ['qcr.sectionId', 'qcr.ageGroupId'])];
            $q.all(promises).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                }
                vm.sectionId = results[2]['qcr.sectionId'];
                vm.ageGroupId = results[2]['qcr.ageGroupId'];
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
            }
            angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            // getAthletes();
            getAgeGroups();
            paramHelper.setParams([{'qcr.sectionId': vm.sectionId}]);
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.sectionId)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                vm.ageGroupId = vm.ageGroupList[0].value;
            }
            var index = vm.ageGroupList.map(function (e) {
                return e.value;
            }).indexOf(vm.ageGroupId);
            if (index < 0) {
                index = 0;
            }
            angular.element('#ageGroupDropDown').ejDropDownList('selectItemsByIndices', index);
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getAthletes();
            paramHelper.setParams([{'qcr.ageGroupId': vm.ageGroupId}]);
        }

        function getAthletes() {
            dataservice.athletesSelect2(vm.sectionId, vm.ageGroupId)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function cellSave(args) {
            if (args.columnName === 'EredmenyTavolsag') {
                var item = ej.DataManager(grid.dataSource).executeLocal(new ej.Query().where('SportoloId', 'equal', args.rowData.SportoloId))[0];
                vm.rowIndexUnderEditing = grid.dataSource.indexOf(item);
                grid.dataSource[vm.rowIndexUnderEditing].EredmenyTavolsag = args.value;
                grid.dataSource[vm.rowIndexUnderEditing].EredmenyTavolsagM = (args.value / 100).toFixed(2);
                grid.refresh();
            } else if (args.columnName === 'EredmenyTavolsagM') {
                var item = ej.DataManager(grid.dataSource).executeLocal(new ej.Query().where('SportoloId', 'equal', args.rowData.SportoloId))[0];
                vm.rowIndexUnderEditing = grid.dataSource.indexOf(item);
                grid.dataSource[vm.rowIndexUnderEditing].EredmenyTavolsagM = args.value;
                grid.dataSource[vm.rowIndexUnderEditing].EredmenyTavolsag = Math.round(args.value * 100);
                grid.refresh();
            } else {
                var item = ej.DataManager(grid.dataSource).executeLocal(new ej.Query().where('SportoloId', 'equal', args.rowData.SportoloId))[0];
                vm.rowIndexUnderEditing = grid.dataSource.indexOf(item);
                grid.dataSource[vm.rowIndexUnderEditing][args.columnName] = args.value;
                grid.refresh();
            }
        }

        function save() {
            grid.endEdit();
            grid.editModule.batchSave();
            dataservice.quickCompetitionResult(grid.dataSource).then(function () {
                $rootScope.back();
            });
        }

        function openAthlete(Id) {
            swal({
                title: gettextCatalog.getString('AreYouSureGo'),
                text: gettextCatalog.getString('AreYouSureYouWantToGoToAthlete'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('GoToAthlete')
            }).then(function (isConfirmed) {
                if (isConfirmed.value) {
                    paramHelper.setParams([
                        {'athleteModify.sportoloId': Id}
                    ]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                }
            });
        }
    }
})();
