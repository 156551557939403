(function () {
    'use strict';

    angular
        .module('app.business.annualEducationalFeeByAthletes')
        .controller('AnnualEducationalFeeByAthletesController', AnnualEducationalFeeByAthletesController);

    function AnnualEducationalFeeByAthletesController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualEducationalFeeByAthletes');

        // Create variables
        vm.data = [];
        vm.yearList = dataservice.range(2000, new Date().getFullYear());
        vm.year = new Date().getFullYear();

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'Elvart',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Tenyleges',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                }
            ]
        }];
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {
                field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), width: 100,
                template: '#annualeducationalfeebyathletecolumnTemplate1'
            },
            {
                field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), width: 100,
                template: '#annualeducationalfeebyathletecolumnTemplate2'
            },
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 100},
            {
                field: 'Elvart',
                headerText: gettextCatalog.getString('Elvart'),
                textAlign: 'left',
                width: 100,
                format: 'N0'
            },
            {
                field: 'Tenyleges',
                headerText: gettextCatalog.getString('Tenyleges'),
                textAlign: 'left',
                width: 100,
                format: 'N0'
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.getDatas = getDatas;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('OKTOSSZSP')];
            $q.all(promises).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getDatas();
                }
            });
        }

        function getDatas() {
            dataservice.getAnnualEducationalFeeByAthletes(vm.year).then(function (data) {
                vm.data = data.itemsList;
                grid.dataSource = vm.data;
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
