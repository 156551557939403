(function () {
    'use strict';

    angular .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage2')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.annualSeasonPlan.annualSeasonPlanReportPage2',
                config: {
                    url: '/annualSeasonPlanReportPage2',
                    templateUrl: 'app/administration/annualSeasonPlan/annualSeasonPlanReportPage2/annualSeasonPlanReportPage2.html',
                    controller: 'AnnualSeasonPlanReportPage2Controller',
                    controllerAs: 'vm',
                    title: 'AnnualSeasonPlan'
                }
            }
        ];
    }
})();
