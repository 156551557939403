(function () {
    'use strict';

    angular
        .module('app.layout')
        .directive('htSidebar', htSidebar);

    /* @ngInject */
    function htSidebar() {
        // Opens and closes the sidebar menu.
        // Usage:
        //  <div ht-sidebar">
        //  <div ht-sidebar whenDoneAnimating="vm.sidebarReady()">
        // Creates:
        //  <div ht-sidebar class="sidebar">
        return {
            link: link,
            restrict: 'EA',
            scope: {
                whenDoneAnimating: '&?'
            }
        };

        function link(scope, element) {
            var dropClass = 'dropy';
            var openMobile = 'open';
            var $sidebarInner = element.find('.sidebar-inner');
            var $dropdownElement = element.find('.sidebar-dropdown a');

            element.addClass('sidebar');
            $dropdownElement.click(dropdown);
            scope.$on('autoCloseSideBar', closeSideBar);

            function openSideBar() {
                $sidebarInner.addClass(openMobile);
                $sidebarInner.slideDown(350, scope.whenDoneAnimating);
                $dropdownElement.addClass(dropClass);
            }

            function closeSideBar() {
                $sidebarInner.removeClass(openMobile);
                $sidebarInner.slideUp(350, scope.whenDoneAnimating);
                $dropdownElement.removeClass(dropClass);
            }

            function dropdown(e) {
                e.preventDefault();
                if (!$dropdownElement.hasClass(dropClass)) {
                    openSideBar();
                } else if ($dropdownElement.hasClass(dropClass)) {
                    closeSideBar();
                }
            }
        }
    }
})();
