(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.pieronTest')
        .controller('PieronTestController', PieronTestController);

    function PieronTestController($q, gettextCatalog, dataservice, paramHelper, log, $state, $interval, $rootScope, $scope, authenticationService) {
        var vm = this; $rootScope.vm = vm;

        vm.time = 10;
        vm.timeLeftMinutes = 10;
        vm.timeLeftSeconds = 0;
        vm.lastClicked = {index1: -1, index2: -1, time: Date.Now};
        vm.started = false;
        vm.finished = false;
        vm.questions = [
            [
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'}
            ],
            [
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'}
            ],
            [
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'}
            ],
            [
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'}
            ],
            [
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 6
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'}
            ],
            [// 7
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'}
            ],
            [// 8
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 9
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 10
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'}
            ],
            [// 11
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 12
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'}
            ],
            [// 13
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'}
            ],
            [// 14
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'}
            ],
            [// 15
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 16
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'}
            ],
            [// 17
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 18
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 19
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 20
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'}
            ]
        ];

        vm.answers = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ];

        vm.goodAnswers = [
            [1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1],
            [1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1],
            [1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0],
            [0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0],
            [0, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1],
            [0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 0],
            [0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0],
            [0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1],
            [1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1],
            [1, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0],
            [0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 0, 1],
            [1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 0],
            [0, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0],
            [0, 0, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1],
            [1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 0],
            [0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1],
            [1, 0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1],
            [1, 1, 0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 0, 1],
            [1, 1, 1, 0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 0, 0]
        ];
        vm.answersByMinutes = [];

        vm.stopInterval = stopInterval;
        vm.startInterval = startInterval;
        vm.clicked = clicked;
        vm.timeChanged = timeChanged;
        vm.saveSurvey = saveSurvey;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev']), authenticationService.getRight('PSYCHO')])
                .then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.sportoloId = results[0]['athleteModify.sportoloId'];
                    vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('PieronTest');
                }
            });
        }

        function saveSurvey() {
            dataservice.savePieronTest(vm.sportoloId, vm.answers, vm.seen, vm.tNumber, vm.wrongAnswersNumber)
                .then(function () {
                    log.successMsg('TestSaved');
                    $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                });
        }

        function startInterval() {
            angular.element('#testTime').ejNumericTextbox('disable');
            vm.started = true;
            $scope.$apply();
            vm.startTime = Date.now();
            vm.stopPromise = $interval(function () {
                var temp = new Date(vm.time * 60000 - Date.now() + vm.startTime);
                if (vm.time * 60000 - Date.now() + vm.startTime < 0) {
                    vm.stopInterval();
                } else {
                    vm.timeLeftMinutes = temp.getMinutes();
                    vm.timeLeftSeconds = temp.getSeconds();
                }
            }, 1000);
            vm.minuteTimer = $interval(function () {
                if (vm.time * 60000 - Date.now() + vm.startTime < 0) {
                    stopMinuteTimer();
                } else {
                    vm.answersByMinutes.push({index1: vm.lastClicked.index1, index2: vm.lastClicked.index2});
                }
            }, 60000);
        }

        function stopMinuteTimer() {
            $interval.cancel(vm.minuteTimer);
        }

        function stopInterval() {
            vm.finished = true;
            $scope.$apply();
            vm.finishTime = Date.now();
            $interval.cancel(vm.stopPromise);
            vm.wrongAnswersNumber = 0;
            vm.answersByMinutes.push(vm.lastClicked);
            vm.seen = vm.lastClicked.index1 * 20 + vm.lastClicked.index2 + 1;
            for (var i = 0; i < 20; i++) {
                for (var j = 0; j < 20; j++) {
                    if (vm.lastClicked.index1 > i || vm.lastClicked.index1 === i && vm.lastClicked.index2 >= j) {
                        if (vm.answers[i][j] === 1 && vm.goodAnswers[i][j] === 1) {
                            vm.answers[i][j] = 2;
                        } else if (vm.answers[i][j] === 0 && vm.goodAnswers[i][j] === 1 || vm.answers[i][j] === 1 && vm.goodAnswers[i][j] === 0) {
                            vm.answers[i][j] = -1;
                            vm.wrongAnswersNumber++;
                        }
                    }
                }
            }
            // 3 jó, 4 rossz, 5 semmi
            angular.forEach(vm.answersByMinutes, function (value) {
                if (vm.answers[value.index1][value.index2] === 2) {
                    vm.answers[value.index1][value.index2] = 3;
                } else if (vm.answers[value.index1][value.index2] === -1) {
                    vm.answers[value.index1][value.index2] = 4;
                } else {
                    vm.answers[value.index1][value.index2] = 5;
                }
            });
            vm.tNumber = (vm.seen - vm.wrongAnswersNumber) / vm.seen * 100;
        }

        function clicked(index1, index2) {
            if ((vm.lastClicked.index1 < index1 || vm.lastClicked.index1 === index1 && vm.lastClicked.index2 < index2) && vm.finished === false && vm.started === true) {
                vm.answers[index1][index2] = 1;
                vm.lastClicked.index1 = index1;
                vm.lastClicked.index2 = index2;
            }
        }

        function timeChanged() {
            if (vm.started === false) {
                vm.timeLeftMinutes = vm.time;
            }
        }
    }
})();
