(function () {
    'use strict';

    angular
        .module('app.reports.assetInventoryReports')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.assetInventoryReports',
                config: {
                    url: '/assetInventoryReports',
                    templateUrl: 'app/reports/assetInventoryReports/assetInventoryReports.html',
                    title: 'AssetInventoryReports',
                    settings: {
                        navId: 2224,
                        level: 2,
                        order: 22,
                        orderTitle_hu: 'Eszköz nyilvántartó',
                        orderTitle_en: 'Asset Inventory',
                        parentId: 4,
                        content: 'AssetManagement',
                        activatorPermission: ['"ESZKNYILVMEGT"']
                    }
                }
            }
        ];
    }
})();
