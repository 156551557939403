(function () {
    'use strict';

    angular
        .module('app.business.sportServiceFeeRegister')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.sportServiceFeeRegister',
                config: {
                    url: '/sportServiceFeeRegister',
                    templateUrl: 'app/business/sportServiceFeeRegister/sportServiceFeeRegister.html',
                    controller: 'SportServiceFeeRegisterController',
                    controllerAs: 'vm',
                    title: 'SportServiceFeeRegister',
                    settings: {
                        navId: 223,
                        level: 2,
                        order: 2,
                        orderTitle_hu: 'Sportszolgáltatási díj nyilvántartás',
                        orderTitle_en: 'Sport Service Fee Register',
                        parentId: 3,
                        content: 'SportServiceFeeRegister',
                        activatorPermission: '"MEGTTAGDNYILV"'
                    }
                }
            }
        ];
    }
})();
