(function () {
    'use strict';

    angular
        .module('app.reports.trainingAttendanceReport')
        .controller('TrainingAttendanceReportController', TrainingAttendanceReportController);

    function TrainingAttendanceReportController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $filter, $state, paramHelper, $q) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('TrainingAttendanceReport');

        // Create variables
        vm.dateFromValue = new Date(new Date().getFullYear(), 0, 1);
        vm.dateToValue = new Date(new Date().getFullYear(), 11, 31);
        vm.coachId = 0;
        vm.coachList = [];
        vm.coachDataBound = coachDataBound;
        vm.show = show;
        vm.goToAthlete = goToAthlete;

        vm.columns = [
            {field: 'SportolokID', isPrimaryKey: true, visible: false},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), textAlign: 'left', width: 150,
                template: '#trainingAttendanceReportcolumnTemplate'},
            {field: 'SzuletesiDatum', headerText: gettextCatalog.getString('Birthdate'), textAlign: 'left', width: 150},
            {field: 'EdzesekSzama', headerText: gettextCatalog.getString('Attendance'), textAlign: 'left', width: 150},
            {field: 'JelenletOsszMinden', headerText: gettextCatalog.getString('AllAttendance'), textAlign: 'left', width: 150},
            {field: 'OsszEdzesekSzama', headerText: '*' + gettextCatalog.getString('Trainings'), textAlign: 'left', width: 150},
            {field: 'JelenletSzazalek', headerText: '*' + gettextCatalog.getString('AttendancePercentage'), textAlign: 'left', width: 150}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('EDZLATSTAT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getCoachList();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
            'tar.from',
            'tar.to',
            'tar.coach'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.dateFromValue = results[0]['tar.from'];
                vm.dateToValue = results[0]['tar.to'];
                vm.coachId = results[0]['tar.coach'];
            });
        }

        function getCoachList() {
            return dataservice.coachSelect().then(function (data) {
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'tar.from': vm.dateFromValue},
                {'tar.to': vm.dateToValue},
                {'tar.coach': vm.coachId}
            ]);
            dataservice.getTrainingAttendances(vm.dateFromValue, vm.dateToValue, vm.coachId).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiDatum');
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
