(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.ageGroupsList')
        .controller('AgeGroupsListController', TeamsCtrl);

    function TeamsCtrl(log, dataservice, authenticationService, $q, $state, $scope, gettextCatalog, paramHelper, $rootScope) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('AgeGroups');

        // Dropdownlist's sources
        vm.sectionList = [];
        vm.sectionListCopyTo = [];
        vm.ageGroupList = [];
        // checkbox

        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                width: 150,
                template: '#templateData'
            },
            {
                field: 'megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                textAlign: 'left',
                width: 150
            },
            {
                field: 'aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'left',
                width: 50,
                displayAsCheckBox: true
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Publikus funkciók
        vm.sectionSelect = sectionSelect;
        vm.sectionDestinationDropDownChanged = sectionDestinationDropDownChanged;
        vm.copyAllAgeGroup = copyAllAgeGroup;
        vm.goToAddAgeGroup = goToAddAgeGroup;
        vm.goToModifyAgeGroup = goToModifyAgeGroup;
        vm.goToModifyAgeGroupButton = goToModifyAgeGroupButton;
        vm.goToAgeGroupMembersList = goToAgeGroupMembersList;
        vm.sectionDataBound = sectionDataBound;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTKOROKEZ')]).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MEGTKOROKEZ = result[0];
                    $q.all([authenticationService.getRight('UJKOROKEZ'), authenticationService.getRight('MODKOROKEZ'), paramHelper.getParams([], ['ageGroups.section'])]).then(function (results) {
                        vm.UJKOROKEZ = results[0];
                        vm.MODKOROKEZ = results[1];
                        vm.sectionId = results[2]['ageGroups.section'];
                        getSections();
                    });
                }
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
            paramHelper.setParams([{'ageGroups.section': vm.sectionId}]);
            vm.sectionListCopyTo = [];
            vm.sectionList.forEach(s => {
                if (s.value != vm.sectionId) {
                    vm.sectionListCopyTo.push(s);
                }
            });
        }

        // in modal window
        function sectionDestinationDropDownChanged(args) {
            vm.selectedDestinationSection = args.value;
            angular.element('.sa-error-container').removeClass('show');
            angular.element('.sa-input-error').removeClass('show');
        }

        function getAgeGroups(sectionId) {
            if (!sectionId) {
                sectionId = vm.sectionId;
            }
            return dataservice.ageGroupList(sectionId).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function copyAllAgeGroup() {
            if (vm.UJKOROKEZ && vm.MODKOROKEZ) {
                if (angular.isDefined(vm.sectionId)) {
                    swal({
                        title: gettextCatalog.getString('CopyAllAgeGroup'),
                        html: '<label>' + gettextCatalog.getString('DestinationSection') + '</label><input id="sectionDropDown3" ej-dropdownlist placeholder="' + gettextCatalog.getString('PleaseChoose') + '" style="margin-bottom: 10px;">',
                        showCancelButton: true,
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No'),
                        closeOnConfirm: false,
                        preConfirm: function () {
                            return new Promise(function (resolve, reject) {
                                if (!vm.selectedDestinationSection) {
                                    swal.showValidationError(gettextCatalog.getString('PleaseChooseSection'));
                                    reject(gettextCatalog.getString('PleaseChooseSection'));
                                } else {
                                    resolve();
                                }
                            });
                        }
                    }).then(function (isConfirmed) {
                        if (isConfirmed.value) {
                            dataservice.copyAllAgeGroup(vm.sectionId, vm.selectedDestinationSection).then(function () {
                                getAgeGroups();
                                log.successMsg('AllAgeGroupCopied');
                                swal.close();
                            }, function () {
                            });
                        }
                    });
                    angular.element('#sectionDropDown3').ejDropDownList({
                        dataSource: vm.sectionListCopyTo,
                        change: sectionDestinationDropDownChanged,
                        value: vm.selectedDestinationSection,
                        width: '100%'
                    });
                    getAgeGroups();
                } else {
                    log.errorMsg('YouHaveToSelectTeam');
                }
            } else {
                log.permissionError();
            }
        }

        function goToAddAgeGroup() {
            if (vm.UJKOROKEZ) {
                paramHelper.setParams([{'ageGroups.section': vm.sectionId}]);
                $state.go('athletes.ageGroups.addAgeGroup');
            } else {
                log.permissionError();
            }
        }

        function goToModifyAgeGroupButton() {
            if (vm.MODKOROKEZ || vm.MEGTKOROKEZ) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'ageGroups.ageGroup': selectedRecords[0].id}, {'ageGroups.section': vm.sectionId}]);
                    $state.go('athletes.ageGroups.modifyAgeGroup');
                }
            } else {
                log.permissionError();
            }
        }

        function goToModifyAgeGroup(id) {
            if (vm.MODKOROKEZ || vm.MEGTKOROKEZ) {
                if (id) {
                    console.log(id);
                    paramHelper.setParams([{'ageGroups.ageGroup': id}]);
                    $state.go('athletes.ageGroups.modifyAgeGroup');
                }
            } else {
                log.permissionError();
            }
        }

        function goToAgeGroupMembersList() {
            if (vm.MEGTKOROKEZ) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'ageGroups.ageGroup': selectedRecords[0].id}, {'ageGroups.section': vm.sectionId}]);
                    $state.go('athletes.ageGroups.ageGroupMembersList');
                }
            } else {
                log.permissionError();
            }
        }
    }
})();
