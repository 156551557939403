(function () {
    'use strict';

    angular
        .module('app.settings.systemSettings')
        .controller('SystemSettingsController', SystemSettingsController);

    function SystemSettingsController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('SystemSettings');

        // Create variables
        vm.socialSecurityNumberRequiredValue = false;
        vm.athleteRegistrationNumberRequiredValue = false;

        // Public functions
        vm.languageSelect = languageSelect;
        vm.expDocNotificationSelect = expDocNotificationSelect;
        vm.saveSystemSettings = saveSystemSettings;
        vm.saveSystemSettingValue = saveSystemSettingValue;
        vm.nf1Select = nf1Select;
        //vm.nf2Select = nf2Select; function nf2Select() {}
        vm.nf1databound = nf1databound;
        vm.nf2databound = nf2databound;
        vm.valasztottNyelv = undefined;
        vm.selectedExpDocGroup = 0;
        vm.NYELVEK = [];
        vm.expNotiList = [
            {value: 0, text: gettextCatalog.getString('Administrators')},
            {value: 1, text: gettextCatalog.getString('SectionLeader')},
            {value: 2, text: gettextCatalog.getString('Trainer')},
            {value: 3, text: gettextCatalog.getString('EconomicColleague')}
        ];
        vm.attendanceList = [
            {value: 0, text: gettextCatalog.getString('provenabsenceText')},
            {value: 1, text: gettextCatalog.getString('injuryText')},
            {value: 2, text: gettextCatalog.getString('Verseny')},
            {value: 3, text: gettextCatalog.getString('sicknessText')}
        ];
        vm.nf1list = [
            {value: 1, text: gettextCatalog.getString('Weekly')},
            {value: 2, text: gettextCatalog.getString('EverySecondWeek')},
            {value: 3, text: gettextCatalog.getString('Monthly')},
            {value: 4, text: gettextCatalog.getString('EverySecondMonth')},
            {value: 5, text: gettextCatalog.getString('EveryThirdMonth')}
        ];
        vm.nfWeeklyList = [
            {value: 1, text: gettextCatalog.getString('Monday')},
            {value: 2, text: gettextCatalog.getString('Tuesday')},
            {value: 3, text: gettextCatalog.getString('Wednesday')},
            {value: 4, text: gettextCatalog.getString('Thursday')},
            {value: 5, text: gettextCatalog.getString('Friday')},
            {value: 6, text: gettextCatalog.getString('Saturday')},
            {value: 0, text: gettextCatalog.getString('Sunday')}
        ];
        vm.nfDayList = [];
        for (var d = 1; d <= 31; d++) {
            vm.nfDayList.push({value: d, text: d.toString()});
        }
        vm.nf2list = [];
        vm.nf1 = -1;
        vm.nf2 = -1;
        vm.expNotiChecked = [];
        var JelenletTipus =
        {
            IgazoltHianyzas: 0,
            Serules: 1,
            Verseny: 2,
            Betegseg: 3
        };
        // Default beállítások
        vm.attendanceChecked = [JelenletTipus.IgazoltHianyzas, JelenletTipus.Serules, JelenletTipus.Betegseg];
        vm.languageSelected = false;
        activate();

        function activate() {
            $q.all([authenticationService.getRight('BEALLITAS')]).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    var promises = [
                        getSystemSettings('TAJKOTELEZO'),
                        getSystemSettings('SNYSZKOTELEZO'),
                        getSystemSettings('BEFSSZKULON'),
                        getSystemSettings('FOLAPOLVASATLANOK'),
                        getSystemSettings('FOLAPNYOMTATAS'),
                        getSystemSettings('FOLAPELFOGADASRAVAROJELENLETIK'),
                        getSystemSettings('FOLAPELFOGADASRAVAROHAVITERVEK'),
                        getSystemSettings('FOLAPELFOGADASRAVAROEVESTERVEK'),
                        getSystemSettings('FOLAPKIFIZETETLENTAGDIJAK'),
                        getSystemSettings('FOLAPLINKEK'),
                        getSystemSettings('FOLAPLEJARO'),
                        getSystemSettings('SPORTMINKOTELEZO'),
                        getSystemSettings('SPKORVALTTART'),
                        getSystemSettings('HAVIEDZTERVJOVAHAGYKOT'),
                        getSystemSettings('HETIEDZTERVJOVAHAGYKOT'),
                        getSystemSettings('ESZKSPORTOLOKILEP'),
                        getSystemSettings('ESZKUSERKILEP'),
                        getSystemSettingsValue('LEJARODOCERTESITESTKAPNAK'),
                        getSystemSettings('FOLAPELFOGADASRAVAROHETITERV'),
                        getSystemSettings('NINCSOKTDIJ'),
                        getSystemSettings('INAKTIVMENUREJTES'),
                        getSystemSettingsValue('TAGDIJERTESGYAKORISAG'),
                        getSystemSettingsValue('HETIEDZTURELMI'),
                        getSystemSettingsValue('JELENLETNEKSZAMIT')
                    ];
                    $q.all(promises).then(function (results) {
                        vm.socialSecurityNumberRequiredValue = results[0];
                        vm.athleteRegistrationNumberRequiredValue = results[1];
                        vm.paymentNumberSeparatelyValue = results[2];
                        vm.FOLAPOLVASATLANOK = results[3];
                        vm.FOLAPNYOMTATAS = results[4];
                        vm.FOLAPELFOGADASRAVAROJELENLETIK = results[5];
                        vm.FOLAPELFOGADASRAVAROHAVITERVEK = results[6];
                        vm.FOLAPELFOGADASRAVAROEVESTERVEK = results[7];
                        vm.FOLAPKIFIZETETLENTAGDIJAK = results[8];
                        vm.FOLAPLINKEK = results[9];
                        vm.FOLAPLEJARO = results[10];
                        vm.SPORTMINKOTELEZO = results[11];
                        vm.SPKORVALTTART = results[12];
                        vm.HAVIEDZTERVJOVAHAGYKOT = results[13];
                        vm.HETIEDZTERVJOVAHAGYKOT = results[14];
                        vm.ESZKSPORTOLOKILEP = results[15];
                        vm.ESZKUSERKILEP = results[16];
                        if (results[17]) {
                            vm.expNotiChecked = JSON.parse(results[17]);
                            vm.LEJARODOCERTESITESTKAPNAK = JSON.parse(results[17]);
                        }
                        vm.FOLAPELFOGADASRAVAROHETITERV = results[18];
                        vm.NINCSOKTDIJ = results[19];
                        vm.INAKTIVMENUREJTES = results[20];
                        if (results[21]) {
                            var tagdijErtesites = JSON.parse(results[21]);
                            if (tagdijErtesites) {
                                vm.nf1 = tagdijErtesites.nf1;
                                vm.nf2 = tagdijErtesites.nf2;
                            }
                            angular.element('#nf1').ejDropDownList('selectItemByValue', vm.nf1);
                            angular.element('#nf2').ejDropDownList('selectItemByValue', vm.nf2);
                        }
                        vm.HETIEDZTURELMI = results[22];
                        if (!vm.HETIEDZTURELMI) {
                            vm.HETIEDZTURELMI = 365;
                        }
                        if (results[23]) {
                            vm.attendanceChecked = JSON.parse(results[23]);
                            vm.JELENLETNEKSZAMIT = JSON.parse(results[23]);
                        } else {
                            vm.JELENLETNEKSZAMIT = [JelenletTipus.IgazoltHianyzas, JelenletTipus.Serules, JelenletTipus.Betegseg];
                        }
                        getAvailableLanguages();
                        $timeout(function () {
                            angular.element('#expNotiList').ejListBox({checkedIndices: vm.LEJARODOCERTESITESTKAPNAK});
                            angular.element('#attendanceList').ejListBox({checkedIndices: vm.JELENLETNEKSZAMIT});
                        }, 500);
                    });
                }
            });
        }

        function nf1databound() {
            if (vm.nf1 > 0) {
                angular.element('#nf1').ejDropDownList('selectItemByValue', vm.nf1);
                nf1Select({value: vm.nf1});
            }
        }

        function nf1Select(arg) {
            var ds2 = [];
            switch (arg.value){
                case 1:
                case 2:
                    jQuery.extend(ds2, vm.nfWeeklyList);
                    break;
                case 3:
                case 4:
                case 5:
                    jQuery.extend(ds2, vm.nfDayList);
                    break;
            }
            vm.nf2list = ds2;
        }

        function nf2databound() {
            if (vm.nf2 > 0) {
                angular.element('#nf2').ejDropDownList('selectItemByValue', vm.nf2);
            }
        }

        function languageSelect(arg) {
            vm.languageSelected = true;
            getKedvezmenyNevek(arg.value);
            getOktatasiKedvezmenyNevek(arg.value);
        }

        function expDocNotificationSelect(arg) {
            vm.selectedExpDocGroup = arg.value;
        }

        function getKedvezmenyNevek(language) {
            vm.kedvezmeny1 = undefined;
            vm.kedvezmeny2 = undefined;
            vm.kedvezmeny3 = undefined;
            vm.kedvezmeny4 = undefined;
            vm.kedvezmeny5 = undefined;
            dataservice.KedvezmenyNevek(language).then(function (response) {
                if (response.itemsList.length > 0) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        switch (response.itemsList[i].value) {
                            case 'K1':
                                vm.kedvezmeny1 = response.itemsList[i].text;
                                break;
                            case 'K2':
                                vm.kedvezmeny2 = response.itemsList[i].text;
                                break;
                            case 'K3':
                                vm.kedvezmeny3 = response.itemsList[i].text;
                                break;
                            case 'K4':
                                vm.kedvezmeny4 = response.itemsList[i].text;
                                break;
                            case 'K5':
                                vm.kedvezmeny5 = response.itemsList[i].text;
                                break;
                        }
                    }
                }
            });
        }

        function getOktatasiKedvezmenyNevek(language) {
            vm.kedvezmenyokt1 = undefined;
            vm.kedvezmenyokt2 = undefined;
            vm.kedvezmenyokt3 = undefined;
            vm.kedvezmenyokt4 = undefined;
            vm.kedvezmenyokt5 = undefined;
            dataservice.OktatasiKedvezmenyNevek(language).then(function (response) {
                if (response.itemsList.length > 0) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        switch (response.itemsList[i].value) {
                            case 'OK1':
                                vm.kedvezmenyokt1 = response.itemsList[i].text;
                                break;
                            case 'OK2':
                                vm.kedvezmenyokt2 = response.itemsList[i].text;
                                break;
                            case 'OK3':
                                vm.kedvezmenyokt3 = response.itemsList[i].text;
                                break;
                            case 'OK4':
                                vm.kedvezmenyokt4 = response.itemsList[i].text;
                                break;
                            case 'OK5':
                                vm.kedvezmenyokt5 = response.itemsList[i].text;
                                break;
                        }
                    }
                }
            });
        }

        function saveKedvezmenyNevek() {
            dataservice.KedvezmenyNevekMentes(vm.valasztottNyelv, vm.kedvezmeny1, vm.kedvezmeny2, vm.kedvezmeny3, vm.kedvezmeny4, vm.kedvezmeny5, vm.kedvezmenyokt1, vm.kedvezmenyokt2, vm.kedvezmenyokt3, vm.kedvezmenyokt4, vm.kedvezmenyokt5)
                .then(function () {
                });
        }

        function getAvailableLanguages() {
            dataservice.getAvailableLanguages().then(function (response) {
                vm.NYELVEK = response.itemsList;
            });
        }

        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }

        function getSystemSettingsValue(valtozo) {
            return dataservice.getSystemSettingsValue(valtozo).then(function (response) {
                if (response.hasOwnProperty('retValue')) {
                    return response.retValue;
                }
                return null;
            });
        }

        function saveSystemSettings() {
            var ExpCheckedArray = [];
            $('#expNotiList').ejListBox('getCheckedItems').forEach(function (chk) {
                ExpCheckedArray.push(chk.index);
            });
            vm.LEJARODOCERTESITESTKAPNAK = JSON.stringify(ExpCheckedArray);
            var attendanceArray = [];
            $('#attendanceList').ejListBox('getCheckedItems').forEach(function (chk) {
                attendanceArray.push(chk.index);
            });
            vm.JELENLETNEKSZAMIT = JSON.stringify(attendanceArray);
            vm.TAGDIJERTESGYAKORISAG = JSON.stringify({nf1: vm.nf1, nf2: vm.nf2});
            $q.all([
                saveSystemSetting('TAJKOTELEZO', vm.socialSecurityNumberRequiredValue),
                saveSystemSetting('SNYSZKOTELEZO', vm.athleteRegistrationNumberRequiredValue),
                saveSystemSetting('BEFSSZKULON', vm.paymentNumberSeparatelyValue),
                saveSystemSetting('FOLAPOLVASATLANOK', vm.FOLAPOLVASATLANOK),
                saveSystemSetting('FOLAPNYOMTATAS', vm.FOLAPNYOMTATAS),
                saveSystemSetting('FOLAPELFOGADASRAVAROJELENLETIK', vm.FOLAPELFOGADASRAVAROJELENLETIK),
                saveSystemSetting('FOLAPELFOGADASRAVAROHAVITERVEK', vm.FOLAPELFOGADASRAVAROHAVITERVEK),
                saveSystemSetting('FOLAPELFOGADASRAVAROEVESTERVEK', vm.FOLAPELFOGADASRAVAROEVESTERVEK),
                saveSystemSetting('FOLAPKIFIZETETLENTAGDIJAK', vm.FOLAPKIFIZETETLENTAGDIJAK),
                saveSystemSetting('FOLAPLINKEK', vm.FOLAPLINKEK),
                saveSystemSetting('FOLAPLEJARO', vm.FOLAPLEJARO),
                saveSystemSetting('SPORTMINKOTELEZO', vm.SPORTMINKOTELEZO),
                saveSystemSetting('SPKORVALTTART', vm.SPKORVALTTART),
                saveSystemSetting('HAVIEDZTERVJOVAHAGYKOT', vm.HAVIEDZTERVJOVAHAGYKOT),
                saveSystemSetting('HETIEDZTERVJOVAHAGYKOT', vm.HETIEDZTERVJOVAHAGYKOT),
                saveSystemSetting('ESZKSPORTOLOKILEP', vm.ESZKSPORTOLOKILEP),
                saveSystemSetting('ESZKUSERKILEP', vm.ESZKUSERKILEP),
                saveSystemSettingValue('LEJARODOCERTESITESTKAPNAK', vm.LEJARODOCERTESITESTKAPNAK),
                saveSystemSetting('FOLAPELFOGADASRAVAROHETITERV', vm.FOLAPELFOGADASRAVAROHETITERV),
                saveSystemSetting('NINCSOKTDIJ', vm.NINCSOKTDIJ),
                saveSystemSetting('INAKTIVMENUREJTES', vm.INAKTIVMENUREJTES),
                saveSystemSettingValue('TAGDIJERTESGYAKORISAG', vm.TAGDIJERTESGYAKORISAG),
                saveSystemSettingValue('HETIEDZTURELMI', vm.HETIEDZTURELMI),
                saveSystemSettingValue('JELENLETNEKSZAMIT', vm.JELENLETNEKSZAMIT)
            ]).then(function () {
                    $rootScope.INAKTIVMENUREJTES = vm.INAKTIVMENUREJTES;
                    log.successMsg(gettextCatalog.getString('SavedSuccessful'));
                })
                .catch(function () {
                    log.errorMsg(gettextCatalog.getString('SaveUnsuccessful'));
                });
            if (vm.languageSelected) {
                saveKedvezmenyNevek();
            }
        }

        function saveSystemSetting(valtozo, ertek) {
            return dataservice.saveSystemSettings(valtozo, ertek).then(function () {
            });
        }

        function saveSystemSettingValue(valtozo, ertek) {
            return dataservice.saveSystemSettingsValue(valtozo, ertek).then(function () {
            });
        }
    }
})();
