(function () {
    'use strict';

    angular
        .module('app.reports.absenceReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.absenceReport',
                config: {
                    url: '/absenceReport',
                    templateUrl: 'app/reports/absenceReport/absenceReport.html',
                    controller: 'AbsenceReportController',
                    controllerAs: 'vm',
                    title: 'AbsenceReport',
                    settings: {
                        navId: 2154,
                        level: 2,
                        order: 15,
                        orderTitle_hu: 'Hiányzások',
                        orderTitle_en: 'Absence',
                        parentId: 4,
                        content: 'AbsenceReport',
                        activatorPermission: '"HIANYZASMEGT"'
                    }
                }
            }
        ];
    }
})();
