(function () {
    'use strict';

    angular
        .module('app.administration.weeklyTrainingPlan.weeklyTrainingPlanList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.weeklyTrainingPlan.weeklyTrainingPlanList',
                config: {
                    url: '/weeklyTrainingPlanList',
                    templateUrl: 'app/administration/weeklyTrainingPlan/weeklyTrainingPlanList/weeklyTrainingPlanList.html',
                    controller: 'WeeklyTrainingPlanListController',
                    controllerAs: 'vm',
                    title: 'WeeklyTrainingPlan',
                    settings: {
                        navId: 272,
                        level: 2,
                        order: 7,
                        orderTitle_hu: 'Heti edzésterv',
                        orderTitle_en: 'Weekly Training Plan',
                        parentId: 2,
                        content: 'WeeklyTrainingPlan',
                        activatorPermission: '"MEGTHETIEDZTERV"'
                    }
                }
            }
        ];
    }
})();
