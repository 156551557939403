(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesNotPresentOnMonthlyAttendance')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports.athletesNotPresentOnMonthlyAttendance',
                config: {
                    url: '/athletesNotPresentOnMonthlyAttendance',
                    templateUrl: 'app/reports/statisticalReports/athletesNotPresentOnMonthlyAttendance/athletesNotPresentOnMonthlyAttendance.html',
                    controller: 'AthletesNotPresentOnMonthlyAttendanceController',
                    controllerAs: 'vm',
                    title: 'AthletesNotPresentOnMonthlyAttendance',
                    settings: {
                        navId: 310214,
                        level: 3,
                        order: 10,
                        orderTitle_hu: 'Havi jelenlétin nem szereplő sportolók',
                        orderTitle_en: 'Athletes not Presented on Monthly Attendance Sheet',
                        parentId: 214,
                        content: 'AthletesNotPresentOnMonthlyAttendance',
                        activatorPermission: '"SPJELENLETINELK"'
                    }
                }
            }
        ];
    }
})();
