(function () {
    'use strict';

    angular
        .module('app.administration.exercises.exercisesCreate')
        .controller('ExercisesCreateController', ExercisesCreateController)
        .config(function ($sceProvider) {
            // Completely disable SCE.  For demonstration purposes only!
            // Do not use in new projects or libraries.
            $sceProvider.enabled(false);
        });

    function ExercisesCreateController(log, paramHelper, dataservice, $q, $rootScope, gettextCatalog, authenticationService, DOMAIN, $scope, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ExercisesCreate');

        $scope.files = [];
        $scope.imageDatas = [];

        $scope.imageThumb = DOMAIN.baseURL + DOMAIN.imgBaseUrl + 'MS-thumbnail.png';
        $scope.dustbin = DOMAIN.baseURL + DOMAIN.imgBaseUrl + 'MS-dustbin.png';

        vm.formdata = {
            id: 0,
            bodyParts: '',
            sectionId: 0,
            ageGroupId: 0,
            coachId: 0,
            imageList: [],
            savedImageList: []
        };

        vm.addNewText = gettextCatalog.getString('addNewText');

        vm.save = save;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.publicChanged = publicChanged;
        vm.paintDataBound = paintDataBound;
        vm.paintSelect = paintSelect;
        vm.deleteExistingImg = deleteExistingImg;
        vm.deleteImg = deleteImg;
        vm.makeToThumb = makeToThumb;
        vm.openPaint = openPaint;
        vm.goToCreatePaint = goToCreatePaint;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('UJGYAKORLAT'), authenticationService.getRight('MODGYAKORLAT'), authenticationService.getRight('MEGTGYAKORLAT')]).then(function (results) {
                vm.UJGYAKORLAT = results[0];
                vm.MODGYAKORLAT = results[1];
                vm.MEGTGYAKORLAT = results[2];
                if (vm.UJGYAKORLAT !== true && vm.MODGYAKORLAT !== true && vm.MEGTGYAKORLAT !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['exercises.exerciseId'])]).then(function (results) {
                        vm.exerciseId = results[0]['exercises.exerciseId'];
                        paramHelper.removeParam('exercises.exerciseId');
                        getSections();
                        getTrainingPracticeAutoComplete();
                        if (vm.exerciseId) {
                            getExercise(vm.exerciseId);
                        }
                    });
                }
            });
        }

        function getTrainingPracticeAutoComplete() {
            dataservice.getTrainingPracticeAutoComplete().then(function (data) {
                vm.bodyPartsList = data.data.Testresz;
                vm.typeList = data.data.Tipus;
                vm.individualTeamList = data.data.Jellege;
                vm.assertsList = data.data.Felszerelesek;
            });
        }

        function getExercise(exerciseId) {
            dataservice.getExercise(exerciseId).then(function (result) {
                $('#bodyParts').ejAutocomplete('clearText');
                $('#type').ejAutocomplete('clearText');
                $('#individualTeam').ejAutocomplete('clearText');
                $('#asserts').ejAutocomplete('clearText');
                vm.formdata = result.gyakorlat;

                // YOUTUBE
                vm.formdata.youtubeSeeing = angular.copy(vm.formdata.youtube);

                vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.replace('watch?v=', 'embed/');
                if (vm.formdata.youtubeSeeing.indexOf('&') !== -1) {
                    vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.substring(0, vm.formdata.youtubeSeeing.indexOf('&'));
                }

                vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.split('embed/')[1];

                $('#playerWrapper').empty();
                $('#playerWrapper').append('<div id="player" data-plyr-provider="youtube" data-plyr-embed-id="' + vm.formdata.youtubeSeeing + '"></div>');
                vm.player = new Plyr(document.getElementById('player'));

                vm.youtube = '';
                // YOUTUBE

                vm.thumbnail = false;

                var index = 0;
                vm.formdata.savedImageList.forEach(function (image) {
                    if (vm.formdata.thumbnail == image.fileName) {
                        vm.thumbnailIndex = index;
                    }
                    index++;
                });

                if (vm.formdata.thumbnail) {
                    vm.thumbnail = true;
                }
                if (vm.formdata.thumbnail) {
                    vm.thumbnail = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl + vm.formdata.thumbnail;
                }
                vm.formdata.savedImageList.forEach(function (t) {
                    t.fileUrl = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl + t.fileName;
                });
                angular.element('#public').ejCheckBox({checked: vm.formdata.publikus});
                $('#sectionId').ejDropDownList('selectItemByValue', vm.formdata.sectionId);
                // angular.element('#number').ejDropDownList('selectItemByValue', results[0]['dailySportServiceFeePayments.number']);
                // angular.element('#number').ejDropDownList('selectItemByValue', results[0]['dailySportServiceFeePayments.number']);
                $timeout(function () {
                    $('.gallery-item').magnificPopup({
                        type: 'image',
                        gallery: {
                            enabled: true,
                        }
                    });
                }, 1);
            });
        }

        vm.youtubeChanged = function () {
            if (vm.youtube) {
                // YOUTUBE
                vm.formdata.youtubeSeeing = angular.copy(vm.youtube);
                vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.replace('watch?v=', 'embed/');
                vm.formdata.youtube = vm.formdata.youtubeSeeing;
                if (vm.formdata.youtubeSeeing.indexOf('&') !== -1) {
                    vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.substring(0, vm.formdata.youtubeSeeing.indexOf('&'));
                }

                vm.formdata.youtubeSeeing = vm.formdata.youtubeSeeing.split('embed/')[1];

                $('#playerWrapper').empty();
                $('#playerWrapper').append('<div id="player" data-plyr-provider="youtube" data-plyr-embed-id="' + vm.formdata.youtubeSeeing + '"></div>');
                vm.player = new Plyr(document.getElementById('player'));
                // YOUTUBE
                vm.youtube = '';
            }
        };

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionId').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                $('#sectionId').ejDropDownList('selectItemByValue', vm.formdata.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.formdata.sectionId = args.value;
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.formdata.sectionId)
                .then(function (data) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupId').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupId').ejDropDownList('selectItemByValue', vm.formdata.ageGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.formdata.ageGroupId = args.value;
            getCoaches();
        }

        function getCoaches() {
            return dataservice.coachDropDownList(vm.formdata.sectionId, vm.formdata.ageGroupId).then(function (data) {
                data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachId').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachId').ejDropDownList('selectItemByValue', vm.formdata.coachId);
            }
        }

        function coachSelect(args) {
            vm.formdata.coachId = args.value;
            getPaints();
        }

        function getPaints() {
            return dataservice.GetPaints(vm.formdata.sectionId, vm.formdata.ageGroupId, vm.formdata.coachId)
                .then(function (data) {
                    data.itemsList.unshift({text: gettextCatalog.getString('None'), value: 0});
                    vm.paintList = data.itemsList;
                });
        }

        function paintDataBound() {
            if (vm.formdata.paintId && vm.formdata.paintId > 0) {
                angular.element('#paintId').ejDropDownList('selectItemByValue', vm.formdata.paintId);
            } else {
                angular.element('#paintId').ejDropDownList('selectItemByValue', 0);
            }
        }

        function paintSelect(args) {
            vm.formdata.paintId = args.value;
        }

        function save() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('RequiredFieldsError');
            } else {
                vm.formdata.imageList = [];
                for (var i = 0; i < $scope.files.length; i++) {
                    vm.formdata.imageList.push({
                        fileName: $scope.files[i].name,
                        fileData: $scope.imageDatas[i]
                    });
                }

                /* vagy a getExercise-ban az alábbi sor előtt kell egy
                   angular.element('#bodyParts').ejAutocomplete('clearText');
                   vm.formdata = result.gyakorlat;
                 */
                vm.formdata.bodyParts = angular.element('#bodyParts').ejAutocomplete('getValue');
                vm.formdata.type = angular.element('#type').ejAutocomplete('getValue');
                vm.formdata.individualTeam = angular.element('#individualTeam').ejAutocomplete('getValue');
                vm.formdata.asserts = angular.element('#asserts').ejAutocomplete('getValue');
                return dataservice.saveExercise(vm.formdata).then(function (results) {
                    vm.formdata.id = results.res;
                    vm.formdata.imageList = [];
                    $scope.imageDatas = [];
                    $scope.files = [];
                    log.htmlSuccess(gettextCatalog.getString('Saved'));
                    getExercise(vm.formdata.id);
                });
            }
        }

        function deleteImg(index) {
            $scope.files.splice(index, 1);
            $scope.imageDatas.splice(index, 1);
        }

        function deleteExistingImg(index) {
            if (vm.thumbnailIndex === index) {
                vm.thumbnail = null;
                vm.formdata.thumbnail = null;
            }
            vm.formdata.savedImageList.splice(index, 1);
        }

        function publicChanged(args) {
            vm.formdata.publikus = args.value;
        }

        function makeToThumb(fileIndex) {
            var volt = false;
            $.each(vm.formdata.savedImageList, function (index, item) {
                item.thumbnail = index === fileIndex;
                if (index === fileIndex) {
                    volt = true;
                    vm.thumbnailIndex = fileIndex;
                    vm.thumbnail = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl + item.fileName;
                }
            });
            if (!volt) {
                vm.thumbnail = null;
            }
        }

        function openPaint() {
            var id = $('#paintId').ejDropDownList('getSelectedValue');
            var saved = save();
            if (id > 0 && saved) {
                saved.then(function () {
                    paramHelper.setParams([{'Paint.id': id}, {'exercises.exerciseId': vm.formdata.id}]);
                    $state.go('paint');
                });
            }
        }

        function goToCreatePaint() {
            var saved = save();
            if (saved) {
                saved.then(function () {
                    paramHelper.setParams([{'exercises.exerciseId': vm.formdata.id}]);
                    $state.go('paint');
                });
            }
        }

        vm.deleteVideo = function () {
            vm.formdata.youtube = '';
            if (vm.player) {
                vm.player.destroy();
            }
            $('#player').remove();
        };
    }
})();
