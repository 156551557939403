(function () {
    'use strict';

    angular
        .module('app.sikerMobile')
        .controller('SikerMobileController', SikerMobileController);

    function SikerMobileController($rootScope, gettextCatalog, authenticationService) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('SikerMobileMenu');
        // $rootScope.$broadcast('autoCloseSideBar');
        vm.changeTab = changeTab;
        vm.role = '';
        vm.mainTab = undefined;
        var language = authenticationService.getLanguage();

        var imgAppStore = document.getElementById('AppStore');
        imgAppStore.src = '../../images/NoAppStore_' + language + '.png';

        changeTab('Login');
        function changeTab(tabName) {
            var coach;
            switch (tabName) {
                case 'Coaches':
                    vm.role = 'coach';
                    coach = true;
                    vm.mainTab = 'Coaches';
                    break;
                case 'Parents':
                    vm.role = 'parent';
                    coach = false;
                    vm.mainTab = 'Parents';
                    break;
                case 'Athletes':
                    vm.role = 'athlete';
                    coach = false;
                    vm.mainTab = 'Athletes';
                    break;
                default: break;
            }

            if (!angular.isUndefined(coach) || tabName === 'Login') {
                var mainTablinks = document.getElementsByClassName('mainTablinks');
                for (i = 0; i < mainTablinks.length; i++) {
                    if (mainTablinks[i].title !== tabName) {
                        mainTablinks[i].className = mainTablinks[i].className.replace(' active', '');
                    } else {
                        mainTablinks[i].className = mainTablinks[i].className += ' active';
                    }
                }
            }

            var tablinks = document.getElementsByClassName('tablinks');
            for (i = 0; i < tablinks.length; i++) {
                if (tablinks[i].title !== tabName) {
                    tablinks[i].className = tablinks[i].className.replace(' active', '');
                } else {
                    tablinks[i].className = tablinks[i].className += ' active';
                }

                //childTabs
                if (coach === false && ['Home', 'Schedule', 'Message'].includes(tablinks[i].title)) {
                    tablinks[i].style.display = 'block';
                    if (tablinks[i].title === 'Home') {
                        tablinks[i].className = tablinks[i].className += ' active';
                        tabName = 'Home';
                    }
                } else if (coach === true && ['Attendance', 'Schedule', 'Message'].includes(tablinks[i].title)) {
                    tablinks[i].style.display = 'block';
                    if (tablinks[i].title === 'Attendance') {
                        tablinks[i].className = tablinks[i].className += ' active';
                        tabName = 'Attendance';
                    }
                } else if (angular.isUndefined(coach) && tabName === 'Login') {
                    tablinks[i].style.display = 'none';
                } else if (!angular.isUndefined(coach)) {
                    tablinks[i].style.display = 'none';
                }
            }

            var tabs = document.getElementsByClassName('tabcontent');
            for (var i = 0; i < tabs.length; i++) {
                tabs[i].style.display = 'none';
                if (tabs[i].id === tabName) {
                    tabs[i].style.display = 'block';
                }
            }

            var img = document.getElementById('mobilImage');
            switch (tabName) {
                case 'Home':
                    img.src = '../../images/Mob1' + language + '.png';
                    break;
                case 'Attendance':
                    img.src = '../../images/Mob2' + language + '.png';
                    break;
                case 'Schedule':
                    img.src = '../../images/Mob3' + language + '.png';
                    break;
                case 'Message':
                    switch (vm.role) {
                        case 'athlete':
                            img.src = '../../images/Mob4athlete' + language + '.png';
                            break;
                        case 'parent':
                            img.src = '../../images/Mob4parent' + language + '.png';
                            break;
                        case 'coach':
                            img.src = '../../images/Mob4coach' + language + '.png';
                            break;
                        default:
                            img.src = '../../images/Mob4athlete' + language + '.png';
                            break;
                    }
                    break;
                case 'Login':
                    img.src = '../../images/Mob0' + language + '.png';
                    break;
                default:
            }
        }
    }
})();
