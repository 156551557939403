(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.competitionResultsChart')
        .controller('CompetitionResultsChartController', CompetitionResultsChartController);

    function CompetitionResultsChartController(dataservice, log, authenticationService, $q, $rootScope, gettextCatalog, paramHelper, $filter) {
        var vm = this; $rootScope.vm = vm;

        // Create variables
        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.discipline = '0';
        vm.selectdateFrom = '';
        vm.selectdateTo = new Date();

        vm.data1 = [];
        vm.data2 = [];
        vm.data3 = [];
        vm.data4 = [];

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.disciplineDataBound = disciplineDataBound;
        vm.disciplineSelect = disciplineSelect;
        vm.filter = filter;
        vm.selectdateFromChange = selectdateFromChange;
        vm.selectdateToChange = selectdateToChange;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('KMVERSEREDM')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    loadResults();
                }
            })
            .catch(function () {
                log.permissionError(true);
            });
        }

        function loadResults() {
            var promises = [paramHelper.getParams([], ['athleteModify.sportoloId', 'athleteModify.sportoloNev'])];
            $q.all(promises).then(function (results) {
                vm.sportoloId = results[0]['athleteModify.sportoloId'];
                vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('CompetitionResultsChart');
                getSections();

                angular.element('#Chart1').ejChart({
                        primaryXAxis: {
                            title: {text: 'Verseny'}
                        },
                        primaryYAxis: {
                            title: {text: 'Értékelés'}
                        },
                        commonSeriesOptions: {
                            tooltip: {
                                visible: true
                            }
                        },
                        series: [
                            {
                                points: vm.data1,
                                name: 'Sales',
                                type: 'line',
                                fill: '#358fcc',
                                enableAnimation: true,
                                marker: {
                                    shape: 'circle',
                                    size: {
                                        height: 10, width: 10
                                    },
                                    visible: true
                                },
                                border: {width: 3},
                                tooltip: {format: 'Verseny: #point.VersenyLeiras#<br/> Értékelés : #point.y#'}
                            }
                        ],
                        isResponsive: true,
                        load: loadChart1,
                        title: {text: 'Értékelés'},
                        size: {height: '400'},
                        legend: {visible: false}
                    });

                angular.element('#Chart2').ejChart({
                        primaryXAxis: {
                            title: {text: 'Verseny'}
                        },
                        primaryYAxis: {
                            title: {text: 'Pont'}
                        },
                        commonSeriesOptions: {
                            tooltip: {
                                visible: true
                            }
                        },
                        series: [
                            {
                                points: vm.data2,
                                name: 'Sales',
                                type: 'line',
                                fill: '#628a00',
                                enableAnimation: true,
                                marker: {
                                    shape: 'circle',
                                    size: {
                                        height: 10, width: 10
                                    },
                                    visible: true
                                },
                                border: {width: 3},
                                tooltip: {format: 'Verseny: #point.VersenyLeiras#<br/> Pont : #point.y#'}
                            }
                        ],
                        isResponsive: true,
                        load: loadChart2,
                        title: {text: 'Pont'},
                        size: {height: '400'},
                        legend: {visible: false}
                    });

                angular.element('#Chart3').ejChart({
                        //Initializing Primary X Axis
                        primaryXAxis: {
                            title: {text: 'Verseny'}
                        },

                        //Initializing Primary Y Axis
                        primaryYAxis: {
                            title: {text: 'Távolság'}
                        },

                        //Initializing Common Properties for all the series
                        commonSeriesOptions: {
                            tooltip: {
                                visible: true
                            }
                        },

                        //Initializing Series
                        series: [
                            {
                                points: vm.data3,
                                name: 'Sales',
                                type: 'line',
                                fill: '#dc6f00',
                                enableAnimation: true,
                                marker: {
                                    shape: 'circle',
                                    size: {
                                        height: 10, width: 10
                                    },
                                    visible: true
                                },
                                border: {width: 3},
                                tooltip: {format: 'Verseny: #point.VersenyLeiras#<br/> Távolság : #point.y#'}
                            }
                        ],
                        isResponsive: true,
                        load: loadChart3,
                        title: {text: 'Távolság'},
                        size: {height: '400'},
                        legend: {visible: false}
                    });

                angular.element('#Chart4').ejChart({
                        //Initializing Primary X Axis
                        primaryXAxis: {
                            title: {text: 'Eredmény'},
                            labelFormat: 'mm:ss',
                            valueType: 'datetime'
                        },

                        //Initializing Primary Y Axis
                        primaryYAxis: {
                            title: {text: 'Verseny'},
                            rangePadding: 'additional'
                        },

                        //Initializing Common Properties for all the series
                        commonSeriesOptions: {
                            tooltip: {
                                visible: true
                            }
                        },

                        //Initializing Series
                        series: [
                            {
                                points: vm.data4,
                                name: 'Sales',
                                type: 'line',
                                isTransposed: true,
                                fill: '#b81309',
                                enableAnimation: true,
                                marker: {
                                    shape: 'circle',
                                    size: {
                                        height: 10, width: 10
                                    },
                                    visible: true
                                },
                                border: {width: 3},
                                tooltip: {format: 'Verseny: #point.VersenyLeiras#<br/> Eredmény : #point.x#'}
                            }
                        ],
                        isResponsive: true,
                        load: loadChart4,
                        title: {text: 'Idő'},
                        size: {height: '400'},
                        legend: {visible: false}
                    });
            });
        }

        function getSections() {
            dataservice.resultChartSectionDropDown(vm.sportoloId, vm.ageGroupId, vm.discipline).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            angular.element('#section').ejDropDownList('selectItemByValue', 0);
            vm.sectionId = 0;
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
        }

        function getAgeGroups() {
            dataservice.resultChartAgeGroupDropDown(vm.sportoloId, vm.sectionId, vm.discipline).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            angular.element('#ageGroup').ejDropDownList('selectItemByValue', 0);
            vm.ageGroupId = 0;
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getDiscipline();
        }

        function getDiscipline() {
            dataservice.resultChartVersenyszamDropDown(vm.sportoloId, vm.sectionId, vm.ageGroupId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.disciplineList = data.itemsList;
            });
        }

        function disciplineDataBound() {
            angular.element('#discipline').ejDropDownList('selectItemByValue', '0');
            vm.discipline = '0';
        }

        function disciplineSelect(args) {
            vm.discipline = args.value;
        }

        function selectdateFromChange(args) {
            vm.selectdateFrom = args.value;
        }

        function selectdateToChange(args) {
            vm.selectdateTo = args.value;
        }

        function loadChart1() {
            vm.chart1 = this;
        }

        function loadChart2() {
            vm.chart2 = this;
        }

        function loadChart3() {
            vm.chart3 = this;
        }

        function loadChart4() {
            vm.chart4 = this;
        }

        function filter() {
            dataservice.resultChart1(vm.sportoloId, vm.discipline, vm.selectdateFrom, vm.selectdateTo, vm.sectionId, vm.ageGroupId).then(function (data) {
                vm.data1 = data.itemsList;
                vm.chart1.model.series[0].points = vm.data1;
                if (vm.data1.length === 0) {
                    vm.chart1.model.series[0].marker.visible = false;
                }
                vm.chart1.redraw();
            });
            dataservice.resultChart2(vm.sportoloId, vm.discipline, vm.selectdateFrom, vm.selectdateTo, vm.sectionId, vm.ageGroupId).then(function (data) {
                vm.data2 = data.itemsList;
                vm.chart2.model.series[0].points = vm.data2;
                if (vm.data2.length === 0) {
                    vm.chart2.model.series[0].marker.visible = false;
                }
                vm.chart2.redraw();
            });
            dataservice.resultChart3(vm.sportoloId, vm.discipline, vm.selectdateFrom, vm.selectdateTo, vm.sectionId, vm.ageGroupId).then(function (data) {
                vm.data3 = data.itemsList;
                vm.chart3.model.series[0].points = vm.data3;
                if (vm.data3.length === 0) {
                    vm.chart3.model.series[0].marker.visible = false;
                }
                vm.chart3.redraw();
            });
            dataservice.resultDateChart(vm.sportoloId, vm.discipline, vm.selectdateFrom, vm.selectdateTo, vm.sectionId, vm.ageGroupId).then(function (data) {
                vm.data4 = $filter('dateStringFilter2')(data.itemsList, 'x');
                vm.chart4.model.series[0].points = vm.data4;
                if (vm.data4.length === 0) {
                    vm.chart4.model.series[0].marker.visible = false;
                }
                vm.chart4.redraw();
            });
        }
    }
})();
