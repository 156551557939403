(function () {
    'use strict';

    angular
        .module('app.administration.exercises.exercisesList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.exercises.exercisesList',
                config: {
                    url: '/exercisesList',
                    templateUrl: 'app/administration/exercises/exercisesList/exercisesList.html',
                    controller: 'ExercisesListController',
                    controllerAs: 'vm',
                    title: 'ExercisesList',
                    settings: {
                        navId: 2162,
                        level: 2,
                        order: 16,
                        orderTitle_hu: 'Gyakorlat lista',
                        orderTitle_en: 'Exercises List',
                        parentId: 2,
                        content: 'ExercisesList'
                    }
                }
            }
        ];
    }
})();
