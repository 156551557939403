(function () {
    'use strict';

    angular
        .module('app.reports.assetInventoryReportByUsers')
        .controller('AssetInventoryReportByUsersController', AssetInventoryReportByUsers);

    function AssetInventoryReportByUsers(logger, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, log, $filter, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AssetInventoryReportByUsers');

        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.searchTypeAthlete = false;
        vm.enableAgeGropu = false;
        vm.ageGroupList = [];
        vm.sectionList = [];
        vm.athletecoachList = [
            {value: 'athlete', text: gettextCatalog.getString('Athlete')},
            {value: 'users', text: gettextCatalog.getString('Users')}
        ];
        vm.columns = [
            {field: 'KiadvaId', visible: false},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'center'},
            {field: 'Email', headerText: gettextCatalog.getString('Email'), textAlign: 'center'},
            {field: 'Lakcim', headerText: gettextCatalog.getString('Location'), textAlign: 'center'},
            {field: 'Elerhetosege', headerText: gettextCatalog.getString('Elerhetosege'), textAlign: 'center'},
        ];
        vm.childGridColums = [
            {field: 'EszkozId', visible: false},
            {field: 'KiadvaId', visible: false},
            {field: 'EszkozMegnevezes', headerText: gettextCatalog.getString('Megnevezes'), textAlign: 'center'},
            {field: 'EszkozTipusa', headerText: gettextCatalog.getString('AssetType'), textAlign: 'center'},
            {field: 'EszkozLeiras', headerText: gettextCatalog.getString('Leiras'), textAlign: 'center'},
            {field: 'Azonosito', headerText: gettextCatalog.getString('Azonosito'), textAlign: 'center'},
            {field: 'Meret', headerText: gettextCatalog.getString('Meret'), textAlign: 'center'},
            {field: 'Ar', headerText: gettextCatalog.getString('Ar'), textAlign: 'center'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowSorting: true,
            locale: 'hu-HU',
            columns: vm.columns,
            //pageSettings: $rootScope.pageSettings,
            toolbar: ['Print', 'Search'],
            childGrid: {
                dataSource: [],
                queryString: 'KiadvaId',
                allowPaging: true,
                columns: vm.childGridColums
            },
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.searchTypeSelect = searchTypeSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.searchTypeDateBound = searchTypeDateBound;
        vm.sectionSelect = sectionSelect;
        vm.show = show;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('ESZKNYILVMEGT')]).then(function (data) {
                vm.ESZKNYILVMEGT = data[0];
                if (!vm.ESZKNYILVMEGT) {
                    log.permissionError(true);
                    return;
                }
                getSections();
                setDropDowns();
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'airbu.typeuser',
                'airbu.section',
                'airbu.agegroup'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.searchTypeUser = (results[0]['airbu.typeuser'] === true);
                vm.searchTypeAthlete = (results[0]['airbu.typeuser'] === false);
                vm.sectionId = results[0]['airbu.section'];
                vm.ageGroupId = results[0]['airbu.agegroup'];
            });
        }

        function searchTypeSelect(arg) {
            vm.chooseEnabled = false;
            switch (arg.value) {
                case 'athlete':
                    vm.searchTypeAthlete = true;
                    vm.searchTypeUser = false;
                    vm.dropdownlabel = gettextCatalog.getString('Section');
                    break;
                case 'users':
                    vm.searchTypeAthlete = false;
                    vm.searchTypeUser = true;
                    vm.dropdownlabel = gettextCatalog.getString('UserGroup');
                    break;
            }
        }

        function searchTypeDateBound() {
            if (vm.searchTypeUser) {
                angular.element('#searchType').ejDropDownList('selectItemByValue', vm.athletecoachList[1].value);
                vm.searchTypeAthlete = false;
                vm.searchTypeUser = true;
            } else {
                angular.element('#searchType').ejDropDownList('selectItemByValue', vm.athletecoachList[0].value);
                vm.searchTypeAthlete = true;
                vm.searchTypeUser = false;
            }
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    if (data.itemsList && data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionSelect(arg) {
            vm.sectionId = arg.value;
            vm.enableAgeGropu = true;
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                if (data.itemsList && data.itemsList.length > 1) {
                    data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupId > 0) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            } else {
                if (vm.ageGroupList && vm.ageGroupList.length === 1) {
                    vm.ageGroupId = vm.sectionList[0].value;
                    angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                } else {
                    vm.ageGroupId = 0;
                    angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                }
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function sectionDataBound() {
            if (vm.sectionId > 0) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            } else {
                if (vm.sectionList && vm.sectionList.length === 1) {
                    vm.sectionId = vm.sectionList[0].value;
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                } else {
                    vm.sectionId = 0;
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
                }
            }
        }

        function show() {
            paramHelper.setParams([
                {'airbu.typeuser': (vm.searchTypeUser == true)},
                {'airbu.section': vm.sectionId},
                {'airbu.agegroup': vm.ageGroupId},
            ]);
            dataservice.assetInventoryByPerson(vm.searchTypeUser, vm.searchTypeAthlete, vm.sectionId, vm.ageGroupId).then(function (data) {
                grid.dataSource = data.itemsList[0].Kiknel;
                var childdataSource = data.itemsList[0].EszkozAdatok;
                childdataSource = $filter('dateTimeStringFilter')(childdataSource, 'Kiadva');
                grid.childGrid.dataSource = $filter('dateTimeStringFilter')(childdataSource, 'Visszaveve');
            });
        }
    }
})();
