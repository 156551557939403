(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('idleService', idleService);

    function idleService($rootScope, $timeout, keepalive, AUTH_EVENTS) {
        var idleTimer = null;

        function stopTimer() {
            if (idleTimer) {
                $timeout.cancel(idleTimer);
            }
        }

        function timerExpiring() {
            stopTimer();
            $rootScope.$broadcast(AUTH_EVENTS.sessionTimeout);
        }

        function startTimer() {
            idleTimer = $timeout(timerExpiring, keepalive);
        }

        function resetTimer() {
            stopTimer();
            startTimer();
        }

        startTimer();
        return {
            startTimer: startTimer,
            stopTimer: stopTimer,
            resetTimer: resetTimer
        };
    }
})();
