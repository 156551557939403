(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesByBirthYear')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports.athletesByBirthYear',
                config: {
                    url: '/athletesByBirthYear',
                    templateUrl: 'app/reports/statisticalReports/athletesByBirthYear/athletesByBirthYear.html',
                    controller: 'AthletesByBirthYearController',
                    controllerAs: 'vm',
                    title: 'AthletesByBirthYear',
                }
            }
        ];
    }
})();
