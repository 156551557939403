(function () {
    'use strict';

    angular
        .module('app.administration.monthlyTrainingPlan.monthlyTrainingPlanList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.monthlyTrainingPlan.monthlyTrainingPlanList',
                config: {
                    url: '/monthlyTrainingPlanList',
                    templateUrl: 'app/administration/monthlyTrainingPlan/monthlyTrainingPlanList/monthlyTrainingPlanList.html',
                    controller: 'MonthlyTrainingPlanListController',
                    controllerAs: 'vm',
                    title: 'MonthlyTrainingPlanList',
                    settings: {
                        navId: 282,
                        level: 2,
                        order: 8,
                        orderTitle_hu: 'Havi edzésterv',
                        orderTitle_en: 'Monthly Training Plan',
                        parentId: 2,
                        content: 'MonthlyTrainingPlanList',
                        activatorPermission: '"MEGTHAVIEDZTERV"'
                    }
                }
            }
        ];
    }
})();
