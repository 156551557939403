(function () {
    'use strict';

    angular
        .module('app.business.annualSportServiceFeeByCoach')
        .controller('AnnualSportServiceFeeByCoachController', AnnualSportServiceFeeByCoachController);

    function AnnualSportServiceFeeByCoachController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualSportServiceFeeByCoach');

        // Create variables
        vm.data = [];
        vm.yearList = dataservice.range(2000, new Date().getFullYear());
        vm.year = new Date().getFullYear();

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'Elvart',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Tenyleges',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E1',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T1',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E2',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T2',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E3',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T3',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E4',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T4',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E5',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T5',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E6',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T6',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E7',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T7',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E8',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T8',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E9',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T9',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E10',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T10',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E11',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T11',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E12',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T12',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                }
            ]
        }];

        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), width: 150,
                template: '#annualsportservicefeebycoachcolumnTemplate'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 130},
            {field: 'Elvart', headerText: gettextCatalog.getString('ElvartOssz'), textAlign: 'left', width: 120, format: 'N0'},
            {field: 'Tenyleges', headerText: gettextCatalog.getString('TenylegesOssz'), textAlign: 'left', width: 120, format: 'N0'},
            {field: 'E1', headerText: gettextCatalog.getString('January') + ' E1', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T1', headerText: gettextCatalog.getString('January') + ' T1', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E2', headerText: gettextCatalog.getString('February') + ' E2', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T2', headerText: gettextCatalog.getString('February') + ' T2', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E3', headerText: gettextCatalog.getString('March') + ' E3', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T3', headerText: gettextCatalog.getString('March') + ' T3', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E4', headerText: gettextCatalog.getString('April') + ' E4', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T4', headerText: gettextCatalog.getString('April') + ' T4', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E5', headerText: gettextCatalog.getString('May') + ' E5', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T5', headerText: gettextCatalog.getString('May') + ' T5', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E6', headerText: gettextCatalog.getString('June') + ' E6', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T6', headerText: gettextCatalog.getString('June') + ' T6', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E7', headerText: gettextCatalog.getString('July') + ' E7', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T7', headerText: gettextCatalog.getString('July') + ' T7', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E8', headerText: gettextCatalog.getString('August') + ' E8', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T8', headerText: gettextCatalog.getString('August') + ' T8', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E9', headerText: gettextCatalog.getString('September') + ' E9', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T9', headerText: gettextCatalog.getString('September') + ' T9', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E10', headerText: gettextCatalog.getString('October') + ' E10', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T10', headerText: gettextCatalog.getString('October') + ' T10', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E11', headerText: gettextCatalog.getString('November') + ' E11', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T11', headerText: gettextCatalog.getString('November') + ' T11', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'E12', headerText: gettextCatalog.getString('December') + ' E12', textAlign: 'center', width: 100, format: 'N0'},
            {field: 'T12', headerText: gettextCatalog.getString('December') + ' T12', textAlign: 'center', width: 100, format: 'N0'}
        ];

        // Public functions
        vm.getDatas = getDatas;
        vm.openCoach = openCoach;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTTAGDOED')];
            $q.all(promises).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getDatas();
                }
            });
        }

        function getDatas() {
            dataservice.getAnnualSportServiceFeeByCoach(vm.year).then(function(data) {
                vm.data = data.itemsList;
                grid.dataSource = vm.data;
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
