(function () {
    'use strict';

    angular
        .module('app.reports.awayBudgetPlanSummary')
        .controller('AwayBudgetPlanSummaryController', AwayBudgetPlanSummary);

    function AwayBudgetPlanSummary(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, paramHelper, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AwayBudgetPlanSummary');

        vm.sectionList = [];
        vm.sectionId = undefined;
        vm.ageGroupList = [];
        vm.ageGroupId = undefined;
        vm.coachList = [];
        vm.coachId = undefined;
        vm.colIndex = undefined;
        vm.rowindex = undefined;
        vm.toRange = undefined;
        vm.newRange = undefined;
        vm.fromRange = undefined;
        vm.onlyStartDate = false;
        vm.g = [];
        vm.selectdateFrom = getMonday(new Date(), 0);
        vm.selectdateTo = getMonday(new Date(), 6);
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.onlyStartDateChanged = onlyStartDateChanged;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        vm.show = show;

        vm.columns = [
            {
                visible: false
            },
            {
                field: 'VersenySorszam',
                headerText: gettextCatalog.getString('TervSorszam'),
                width: 90,
                textAlign: 'center'
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                width: 125,
                textAlign: 'center'
            },
            {
                field: 'NevHelyCsopRsz',
                headerText: gettextCatalog.getString('NevHelyCsopRsz'),
                width: 135,
                textAlign: 'center'
            },
            {
                field: 'VersenyKezdesIdopont',
                headerText: gettextCatalog.getString('RaceStartTime'),
                width: 150,
                textAlign: 'center'
            },
            {
                field: 'VersenyBefejezesIdopont',
                headerText: gettextCatalog.getString('RaceEndTime'),
                width: 150,
                textAlign: 'center'
            },
            {
                field: 'Megnevezes',
                headerText: gettextCatalog.getString('Appellation'),
                width: 150,
                textAlign: 'center'
            },
            {
                field: 'UtazasiKoltseg',
                headerText: gettextCatalog.getString('TravelCosts'),
                width: 130,
                textAlign: 'center'
            },
            {
                field: 'Etkezes',
                headerText: gettextCatalog.getString('Meal'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'Szallas',
                headerText: gettextCatalog.getString('Accommodation'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'NevezesiDij',
                headerText: gettextCatalog.getString('EntryFee'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'KikuldetesiKoltseg',
                headerText: gettextCatalog.getString('DelegacyCost'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'Egyeb',
                headerText: gettextCatalog.getString('Other'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'Osszesen',
                headerText: gettextCatalog.getString('Total'),
                width: 120,
                textAlign: 'center'
            },
            {
                field: 'ElolegOsszege',
                headerText: gettextCatalog.getString('AmountOfAdvance'),
                width: 130,
                textAlign: 'center'
            },
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            pageSettings: $rootScope.pageSettings,
            allowTextWrap: true,
            allowSorting: false,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            enableHover: false,
            allowSelection: false,
            columns: vm.columns,
            rowTemplate: '#rowtemplate',
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            },

        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('MEGTIDEGENKOLTSTERV').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'awayBudgetPlanSummary.sectionId',
                'awayBudgetPlanSummary.ageGroupId',
                'awayBudgetPlanSummary.coachId',
                'awayBudgetPlanSummary.onlyStartDate',
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['awayBudgetPlanSummary.sectionId'];
                vm.ageGroupId = results[0]['awayBudgetPlanSummary.ageGroupId'];
                vm.coachId = results[0]['awayBudgetPlanSummary.coachId'];
                if (results[0]['awayBudgetPlanSummary.onlyStartDate']) {
                    $timeout(function () {
                        angular.element('#onlyStartDate').ejCheckBox({checked: results[0]['awayBudgetPlanSummary.onlyStartDate']});
                        $scope.$apply();
                    }, 0);
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    if (data.itemsList && data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList && vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getAgeGroups(args.value);
            getActualCoachesList(0);
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList && vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (vm.ageGroupId) {
                    getActualCoachesList(0);
                }
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(ageGroupId) {
            return dataservice.coachDropDownList(vm.sectionId, ageGroupId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList && vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age coach selected
        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function getMonday(d, plusDay) {
            d = new Date(d);
            var day = d.getDay();
            var diff = d.getDate() - day + (day === 0 ? -6 : 1) + plusDay; // adjust when day is sunday
            return new Date(d.setDate(diff));
        }

        function show() {
            //vm.onlyStartDate = angular.element('#onlyStartDate').ejCheckBox('isChecked');
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'awayBudgetPlanSummary.sectionId': vm.sectionId},
                {'awayBudgetPlanSummary.ageGroupId': vm.ageGroupId},
                {'awayBudgetPlanSummary.coachId': vm.coachId},
                {'awayBudgetPlanSummary.onlyStartDate': vm.onlyStartDate}
            ]);
            dataservice.KoltsegtervOsszesitoKimutatas(vm.sectionId, vm.ageGroupId, vm.coachId, vm.selectdateFrom, vm.selectdateTo, vm.onlyStartDate).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function onlyStartDateChanged(args) {
            $timeout(function () {
                vm.onlyStartDate = args.value;
                $scope.$apply();
            }, 100);
        }
    }
})();
