(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athletesList')
        .controller('AthletesListController', athletesListController);

    function athletesListController(log, dataservice, $q, $state, gettextCatalog, $scope, paramHelper, authenticationService, $filter, $rootScope, $timeout) {
        let vm = this;
        $rootScope.vm = vm; // jshint ignore: line
        this.params = []; // for parameters sending

        vm.userid = authenticationService.getAuthData().id;

        $rootScope.title = gettextCatalog.getString('AthletesList');

        // Permissions
        this.MEGTSPORTLIST = false;
        this.MEGTCSOPORT = false;
        this.MEGTKOROSZT = false;
        this.MODSPORTOLO = false;
        this.UJSPORTOLO = false;
        this.MEGTSPORTLIST = false;
        this.SPORTKILEP = false;
        this.MODFELH = false;
        this.KMVERSEREDM = false;
        this.athleteResultsBtnEnabled = false;
        // Dropdownlist's sources
        this.sectionList = [];
        this.ageGroupList = [];
        this.athleteList = [];
        // Dropdown values
        this.selectedSectionId = '';
        this.selectedAgeGroupId = '';
        this.selectedAgeGroupName = '';
        this.sportoliId = -1;
        // Checkbox variables
        this.checkboxEnabled = false;
        // Grid's details
        this.selectedAthleteExitDate = '';
        this.exitAthleteDateOk = false;
        this.selectedExitDate = undefined;
        this.athleteCompetitionResult = false;
        this.exitAthletesEnabled = true;
        // Grid columns
        this.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Athletes'),
                textAlign: 'left',
                template: '#templateDataRow1',
                width: 150
            },
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: 100},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 100},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'left',
                template: '#templateDataRow2',
                width: 150
            },
            {
                field: 'Tagdij',
                headerText: gettextCatalog.getString('PayedSportServiceFee'),
                textAlign: 'left',
                width: 100
            },
            {
                field: 'Oktatasidij',
                headerText: gettextCatalog.getString('PayedEducationalFee'),
                textAlign: 'left',
                width: 100
            },
            {field: 'Egyenleg', headerText: gettextCatalog.getString('Balance'), textAlign: 'left', width: 100}
        ];

        let grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: this.columns,
            rowDataBound: (args) => {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: (args) => {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = (args) => {
            $rootScope.toolbarClick(args, grid);
        };

        let activate = () => {
            if (!authenticationService.isAuthenticated()) {
                $rootScope.login();
                return;
            }
            Promise.all([paramHelper.getParams([],
                [
                    'athleteList.selectedSectionId',
                    'athleteList.selectedAgeGroupId',
                    'athleteList.inactiveAthletes'
                ]),
                authenticationService.getRight('MEGTSPORTLIST'),
                authenticationService.getRight('MEGTCSOPORT'),
                authenticationService.getRight('MEGTKOROSZT'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('UJSPORTOLO'),
                authenticationService.getRight('SPORTKILEP'),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('KMVERSEREDM')
            ]).then((results) => {
                // Nem épül fel a DOM Three, mire érték adódik
                $timeout(() => {
                    this.MEGTSPORTLIST = results[1];
                    this.MEGTCSOPORT = results[2];
                    this.MEGTKOROSZT = results[3];
                    this.MODSPORTOLO = results[4];
                    this.UJSPORTOLO = results[5];
                    this.SPORTKILEP = results[6];
                    this.MODFELH = results[7];
                    this.KMVERSEREDM = results[8];

                    if (!(this.MEGTSPORTLIST)) {
                        log.permissionError(true);
                        return;
                    }

                    // set selected values for dropDownLists
                    this.params.sectionId = results[0]['athleteList.selectedSectionId'];
                    this.params.selectedAgeGroupId = results[0]['athleteList.selectedAgeGroupId'];
                    // set selected values for checkBox
                    this.params.inactiveAthletes = results[0]['athleteList.inactiveAthletes'];
                    // Permission for delete
                    this.TORLCSOPORT = results[1];
                    // Permission for modify
                    this.MODCSOPORT = results[2];
                    // Set params for checkBox
                    // if (!this.params.inactiveAthletes) {
                    // this.inactiveAthletes = false;
                    // } else {
                    // this.inactiveAthletes = true;
                    // }
                    angular.element('#active').ejCheckBox({checked: this.params.inactiveAthletes});
                    // Get section list
                    getSections();
                }, 0);
            });
        };

        let getSections = () => {
            dataservice.sectionDropDownList(0)
                .then((data) => {
                    this.sectionList = data.itemsList;
                });
        };

        this.sectionDataBound = () => {
            this.selectedSectionId = this.sectionList.length === 1 ? this.sectionList[0].value : this.params.sectionId;
        };

        this.sectionSelect = (args) => {
            this.selectedSectionId = args.value;
            this.checkboxEnabled = true;
            if (angular.isUndefined(this.params.ageGroupId)) {
                //getTeams();
                getAthletes();
            }
            getAgeGroups();
            paramHelper.setParams([{'athleteList.selectedSectionId': this.selectedSectionId}]);
        };

        let getAgeGroups = () => {
            dataservice.ageGroupDropDownList(this.selectedSectionId)
                .then((data) => {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    this.ageGroupList = data.itemsList;
                    if (this.ageGroupList.length == 1) {
                        $timeout(() => {
                            this.ageGroupId = this.ageGroupList[0].value;
                        }, 0);
                    }
                });
        };

        this.ageGroupDataBound = () => {
            if (this.ageGroupList.length === 1) {
                this.selectedAgeGroupId = this.ageGroupList[0].value;
            } else {
                if (this.params.sectionId && this.params.selectedAgeGroupId && parseInt(this.params.sectionId) === parseInt(this.selectedSectionId)) {
                    this.selectedAgeGroupId = this.params.selectedAgeGroupId;
                } else {
                    this.selectedAgeGroupId = 0;
                }
            }
        };

        this.ageGroupSelect = (args) => {
            this.selectedAgeGroupId = args.value;
            //getTeams();
            getAthletes();
            paramHelper.setParams([{'athleteList.selectedAgeGroupId': this.selectedAgeGroupId}]);
        };

        let getAthletes = () => {
            dataservice.athletesSelect(this.selectedSectionId, this.selectedAgeGroupId, this.inactiveAthletes)
                .then((data) => {
                    this.athleteResultsBtnEnabled = false;
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        };

        this.inactiveChange = (args) => {
            this.inactiveAthletes = args.value;
            this.exitAthletesEnabled = !args.value;
            getAthletes();
            paramHelper.setParams([{'athleteList.inactiveAthletes': this.inactiveAthletes}]);
        };

        this.goToUser = (EdzoId) => {
            if (!this.MODFELH) {
                log.errorMsg(gettextCatalog.getString('YouHaveNoPermissionToThisFunction'));
                return;
            }
            paramHelper.setParams([{'newUser.userId': EdzoId}]);
            $state.go('settings.userManagement.modifyUser');
        };

        this.goToNewAthlete = () => {
            if (this.selectedSectionId > 0) {
                // Save data for restore
                this.selectedSectionName = angular.element('#sectionDropDown').data('ejDropDownList').selectedTextValue;
                this.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;

                paramHelper.setParams([
                    {'athleteNew.sportolokId': -1},
                    {'athleteNew.sectionList': this.sectionList},
                    {'athleteNew.selectedSectionId': this.selectedSectionId},
                    {'athleteNew.selectedSectionName': this.selectedSectionName},
                    {'athleteNew.ageGroupList': this.ageGroupList},
                    {'athleteNew.selectedAgeGroupId': this.selectedAgeGroupId},
                    {'athleteNew.selectedAgeGroupName': this.selectedAgeGroupName},
                    {'athleteList.selectedAgeGroupName': this.selectedAgeGroupName}]);
                $state.go('athletes.athletesManagement.athleteNew');
            } else {
                log.errorMsg(gettextCatalog.getString('RequiredSection'));
            }
        };

        this.modifyAthletes = (id) => {
            if (this.MODSPORTOLO || this.MEGTSPORTLIST) {
                this.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                paramHelper.setParams([
                    {'athleteModify.sportoloId': id},
                    {'athleteList.selectedAgeGroupName': this.selectedAgeGroupName}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
            }
        };

        this.modifyAthletesButton = () => {
            if (this.MODSPORTOLO || this.MEGTSPORTLIST) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    this.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                    let selectedAthletes = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([
                        {'athleteModify.sportoloId': selectedAthletes[0].SportolokId},
                        {'athleteModify.sportoloNev': selectedAthletes[0].Nev},
                        {'athleteList.selectedAgeGroupName': this.selectedAgeGroupName}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                }
            } else {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
            }
        };

        let saveExitAthletes = () => {
            let data = {};
            this.athletesId = [];
            let selectedAthletes = $rootScope.getSelectedItems(grid);
            for (let i = 0; i < selectedAthletes.length; i++) {
                this.athletesId.push(selectedAthletes[i].SportolokId);
            }
            data.athletesId = this.athletesId;
            data.selectedExitDate = this.selectedExitDate;
            data.noteExitText = this.noteExitText;
            data.firstSection = true;
            if (!this.SPORTKILEP) {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
                return;
            }
            dataservice.saveExitAthletes(data)
                .then((answer) => {
                    console.log('saveExitAthletes answer', answer);
                    // swal() kiírni az eredményt
                    if (answer.message && answer.message.length > 0) {
                        swal({
                            title: gettextCatalog.getString('ExitAthleteResult'),
                            html: answer.message,
                            type: 'warning',
                            confirmButtonClass: 'btn-danger',
                            confirmButtonText: 'Ok',
                            width: '35%'
                        });
                        var message = answer.message.replace('<br>', '\n').replace(/<[^>]*>/g, '');
                        dataservice.SendSystemMessage(vm.userid, message);
                    } else {
                        log.successMsg('SuccesfulExitAthlete');
                    }
                    getAthletes();
                });
        };

        this.goneAthletesBtn = () => {
            if ($rootScope.checkRowSelection(grid)) {
                let selectedAthletes = $rootScope.getSelectedItems(grid);
                let exitAthletesNames = '';
                for (let i = 0; i < selectedAthletes.length; i++) {
                    exitAthletesNames += selectedAthletes[i].Nev + '; ';
                }

                this.visible = false; // Not need use this for angularJs, because the swa enable appear Error message

                let HTMLtext = `<p id="datepickValidText" style="color: #a94442;" class="ng-hide" ng-show="this.visible">
                                <label>${gettextCatalog.getString('PleasGiveTheStartMonthgoneAthlete')}</label><br></p>
                                <div style="margin-bottom: 10px; width: 95%;" >
                                <label>${gettextCatalog.getString('SelectDategoneAthlete')}<br></label></div>
                                <div style="margin-left: 30%;"><input id="datepick" type="text"></div>
                                <br><label>${gettextCatalog.getString('ExitedAthletesNamesText')}</label><br>
                                <textarea id="exitAthleteNamesTextArea" rows="2" cols="55" style="width: 95%; resize: none; padding: 5px;">${exitAthletesNames}</textarea><br>
                                <br><label>${gettextCatalog.getString('ExitedAthletesText')}</label><br>
                                <textarea id="noteTextArea" rows="4" cols="55" style="width: 95%; resize: none; padding: 5px;"></textarea>`;

                swal({
                    title: gettextCatalog.getString('ExitAthlete'),
                    html: HTMLtext,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Exiting'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                    preConfirm: () => {
                        let preConfOk = false;
                        this.selectedExitDate = angular.element('#datepick').data('ejDatePicker').getValue(); // get value
                        this.noteExitText = angular.element('#noteTextArea').val();
                        return new Promise((resolve) => {
                            if (this.selectedExitDate === null || !this.exitAthleteDateOk) {
                                // Must show the error message!
                                preConfOk = false;
                                swal.showValidationError(gettextCatalog.getString('SelectExitDate')); // Válaszd ki a kiléptetés dátumát!
                            } else {
                                preConfOk = true;
                            }
                            if (this.noteExitText === null || this.noteExitText === '') {
                                // Must show the error message!
                                preConfOk = false;
                                swal.showValidationError(gettextCatalog.getString('SetNoteText')); // Írd be a kiléptetés indokát!
                            } else {
                                preConfOk = true;
                            }
                            resolve();
                        });
                    },
                    closeOnCancel: false
                }).then((confirmed) => {
                    if (confirmed.value) {
                        saveExitAthletes();
                        swal.close();
                        return true;
                    } else {
                        swal(gettextCatalog.getString('Cancelled'), gettextCatalog.getString('notExitAthlete'), 'error');
                    }
                });
                // Set DatePicker
                angular.element('#datepick').ejDatePicker({
                    // set date format
                    dateFormat: 'yyyy-MM-dd',
                    change: (args) => {
                        // Get current date
                        let currentDate = new Date();
                        this.selectedExitDate = new Date(args.value);
                        /*
                        if (this.selectedExitDate.getFullYear() > currentDate.getFullYear()) {
                            // Error, no correct date
                            this.exitAthleteDateOk = false;
                        } else if (this.selectedExitDate.getMonth() > currentDate.getMonth()) {
                            // Error, no correct date
                            this.exitAthleteDateOk = false;
                        } else {
                            this.exitAthleteDateOk = this.selectedExitDate.getDate() === getMonthLastDay(args.value);
                        }
                        */
                        this.exitAthleteDateOk = true;

                        function getMonthLastDay(selectDate) {
                            let date = new Date(selectDate);
                            let y = date.getFullYear();
                            let m = date.getMonth();
                            let lastDay = new Date(y, m + 1, 0).getDate();
                            return date.getDate();
                        }
                    }
                });
            }
        };

        this.competitionResult = () => {
            if ($rootScope.checkRowSelection(grid, true)) {
                this.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                let selectedAthletes = $rootScope.getSelectedItems(grid);
                paramHelper.setParams([{'raceResultsReport.athleteData': selectedAthletes[0]}]);
                $state.go('reports.raceResultsReport');
            }
        };

        // Activate page
        activate();
    }
})();

