/* global Aes */

(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.performanceMotivationTestEvaluation')
        .controller('PerformanceMotivationTestEvaluationController', PerformanceMotivationTestEvaluationController);

    function PerformanceMotivationTestEvaluationController($q, gettextCatalog, dataservice, paramHelper, log, $rootScope, authenticationService) {
        var vm = this; $rootScope.vm = vm;

        vm.questions = [
            gettextCatalog.getString('PerformanceMotivationTestQ1'),
            gettextCatalog.getString('PerformanceMotivationTestQ2'),
            gettextCatalog.getString('PerformanceMotivationTestQ3'),
            gettextCatalog.getString('PerformanceMotivationTestQ4'),
            gettextCatalog.getString('PerformanceMotivationTestQ5'),
            gettextCatalog.getString('PerformanceMotivationTestQ6'),
            gettextCatalog.getString('PerformanceMotivationTestQ7'),
            gettextCatalog.getString('PerformanceMotivationTestQ8'),
            gettextCatalog.getString('PerformanceMotivationTestQ9'),
            gettextCatalog.getString('PerformanceMotivationTestQ10'),
            gettextCatalog.getString('PerformanceMotivationTestQ11'),
            gettextCatalog.getString('PerformanceMotivationTestQ12'),
            gettextCatalog.getString('PerformanceMotivationTestQ13'),
            gettextCatalog.getString('PerformanceMotivationTestQ14'),
            gettextCatalog.getString('PerformanceMotivationTestQ15'),
            gettextCatalog.getString('PerformanceMotivationTestQ16'),
            gettextCatalog.getString('PerformanceMotivationTestQ17'),
            gettextCatalog.getString('PerformanceMotivationTestQ18'),
            gettextCatalog.getString('PerformanceMotivationTestQ19'),
            gettextCatalog.getString('PerformanceMotivationTestQ20'),
            gettextCatalog.getString('PerformanceMotivationTestQ21'),
            gettextCatalog.getString('PerformanceMotivationTestQ22'),
            gettextCatalog.getString('PerformanceMotivationTestQ23'),
            gettextCatalog.getString('PerformanceMotivationTestQ24'),
            gettextCatalog.getString('PerformanceMotivationTestQ25'),
            gettextCatalog.getString('PerformanceMotivationTestQ26'),
            gettextCatalog.getString('PerformanceMotivationTestQ27'),
            gettextCatalog.getString('PerformanceMotivationTestQ28'),
            gettextCatalog.getString('PerformanceMotivationTestQ29'),
            gettextCatalog.getString('PerformanceMotivationTestQ30'),
            gettextCatalog.getString('PerformanceMotivationTestQ31'),
            gettextCatalog.getString('PerformanceMotivationTestQ32'),
            gettextCatalog.getString('PerformanceMotivationTestQ33'),
            gettextCatalog.getString('PerformanceMotivationTestQ34'),
            gettextCatalog.getString('PerformanceMotivationTestQ35'),
            gettextCatalog.getString('PerformanceMotivationTestQ36'),
            gettextCatalog.getString('PerformanceMotivationTestQ37'),
            gettextCatalog.getString('PerformanceMotivationTestQ38'),
            gettextCatalog.getString('PerformanceMotivationTestQ39'),
            gettextCatalog.getString('PerformanceMotivationTestQ40'),
            gettextCatalog.getString('PerformanceMotivationTestQ41')
        ];
        vm.answers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        // Public functions
        vm.saveEval = saveEval;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['psychology.surveyId', 'psychology.mode', 'athleteModify.sportoloNev'], ['psychology.surveyPassword']), authenticationService.getRight('PSYCHO')])
                .then(function (results) {
                    if (results[1] !== true) {
                        log.permissionError(true);
                    } else {
                        vm.surveyId = results[0]['psychology.surveyId'];
                        vm.password = results[0]['psychology.surveyPassword'];
                        vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('PerformanceMotivationTestEvaluation');
                        vm.edit = results[0]['psychology.mode'] === 'edit';
                        if (vm.edit) {
                            getSurveyProtected();
                        } else {
                            getSurvey();
                        }
                    }
                });
        }

        function getSurvey() {
            return dataservice.getPerformanceMotivationSurvey(vm.surveyId, true)
                .then(function (data) {
                    vm.answers = data.kitoltottKerdoiv;
                    vm.sportolo = {};
                    vm.sportolo.nev = data.sportoloNeve;
                    vm.sportolo.szuletesiDatum = data.sportoloSzuletesiDatuma;
                    vm.sportolo.anyjaNeve = data.sportoloAnyjaNeve;
                    vm.kitoltesDatuma = data.kitoltesDatuma;
                    vm.kiertekelesSzam = data.kiertekelesSzam;
                    vm.kiertekelesSzoveg = data.kiertekelesSzoveg;
                    angular.element('#rteSample').ejRTE({value: vm.kiertekelesSzoveg});
                });
        }

        function getSurveyProtected() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValue: vm.password,
                closeOnConfirm: false

            }).then(function (inputValue) {
                    if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                        return false;
                    } else {
                        vm.password = inputValue.value;
                        return dataservice.getPerformanceMotivationSurvey(vm.surveyId, false)
                            .then(function (data) {
                                vm.answers = data.kitoltottKerdoiv;
                                vm.sportolo = {};
                                vm.sportolo.nev = data.sportoloNeve;
                                vm.sportolo.szuletesiDatum = data.sportoloSzuletesiDatuma;
                                vm.sportolo.anyjaNeve = data.sportoloAnyjaNeve;
                                vm.kitoltesDatuma = data.kitoltesDatuma;
                                vm.kiertekelesDatuma = data.kiertekelesDatuma;
                                vm.kiertekelesSzam = data.kiertekelesSzam;
                                vm.kiertekelesSzoveg = data.kiertekelesSzoveg;
                                if (vm.kiertekelesDatuma !== '') {
                                    vm.kiertekelesSzoveg = Aes.Ctr.decrypt(vm.kiertekelesSzoveg, inputValue.value, 256);
                                }
                                angular.element('#rteSample').ejRTE({value: vm.kiertekelesSzoveg});
                                swal.close();
                            });
                    }
                });
        }

        function saveEval() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                inputValue: vm.password,
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(
                function (inputValue) {
                    if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                        return false;
                    } else {
                        vm.password = inputValue.value;
                        paramHelper.setParams([{'psychology.surveyPassword': inputValue.value}]);
                        vm.encryptedHtml = Aes.Ctr.encrypt(angular.element('#rteSample').ejRTE('getHtml'), inputValue.value, 256);
                        dataservice.savePerformanceMotivationSurveyEval(vm.surveyId, vm.encryptedHtml)
                            .then(function () {
                                log.successMsg('TestSaved');
                                $rootScope.back();
                            });
                    }
                });
        }
    }
})();
