(function () {
    'use strict';

    angular
        .module('app.parentDashboard')
        .controller('ParentDashboardController', ParentDashboardController);

    function ParentDashboardController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ParentDashboard');

        vm.athletesofuser = [];
        vm.selectedAthlete = undefined;
        vm.modifyAthleteData = modifyAthleteData;
        vm.athleteidOfAthleteUser = -1;
        vm.SPORTOLO = false;
        vm.SZULO = false;

        activate();

        function activate() {
            $q.all([
                authenticationService.getRight('SPORTOLO'),
                authenticationService.getRight('SZULO')])
                .then(function (results) {
                    if (results[0] || results[1]) {
                        vm.SPORTOLO = results[0];
                        vm.SZULO = results[1];
                        getAthletesOfUser();
                        getAthleteIDofUser();
                        // getParentNotifications(new Date(), new Date(), 0, true, true, 'all', 9999);
                    } else {
                        log.permissionError(true);
                    }
                });
        }

        function modifyAthleteData() {
            if (vm.SZULO) {
                paramHelper.setParams([{'athleteModify.sportoloId': vm.selectedAthlete}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                paramHelper.setParams([{'athleteModify.sportoloId': vm.athleteidOfAthleteUser}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            }
        }

        function getAthletesOfUser() {
            dataservice.getAthletesOfUser()
                .then(function (data) {
                    vm.athletesofuser = data.itemsList;
                });
        }

        function getAthleteIDofUser() {
            dataservice.getAthleteidBelongsToAthleteUser()
                .then(function (data) {
                    vm.athleteidOfAthleteUser = data.data;
                });
        }

        function getParentNotifications(fromDate, toDate, currentListSize, moreNewer, moreOlder, load, size) {
            dataservice.getParentNotifications2(fromDate, toDate, currentListSize, moreNewer, moreOlder, load, size).then(function (data) {
                console.log('getParentNotifications', data);
            });
            dataservice.ErtesitestLatta(-1).then(function (data) {
                console.log('ErtesitestLatta', data);
            });
        }
    }
})();
