(function () {
    'use strict';

    angular
        .module('app.administration.monthlyTrainingPlan.monthlyTrainingPlanReport')
        .controller('MonthlyTrainingPlanReportController', MonthlyTrainingPlanReportController);

    function MonthlyTrainingPlanReportController(dataservice, $q, $rootScope, gettextCatalog, paramHelper, log, $scope, authenticationService, $state, $timeout) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('Monthly Training Plan');

        var printOpened = false;
        vm.trainingPlanId = -1;

        vm.editedMTP = {};

        vm.editedMTP.Hetfo = undefined;
        vm.copyEnabled = false;

        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev);
        }
        //Hónapok
        vm.months = [{
            text: gettextCatalog.getString('January'),
            value: 0
        }, {
            text: gettextCatalog.getString('February'),
            value: 1
        }, {
            text: gettextCatalog.getString('March'),
            value: 2
        }, {
            text: gettextCatalog.getString('April'),
            value: 3
        }, {
            text: gettextCatalog.getString('May'),
            value: 4
        }, {
            text: gettextCatalog.getString('June'),
            value: 5
        }, {
            text: gettextCatalog.getString('July'),
            value: 6
        }, {
            text: gettextCatalog.getString('August'),
            value: 7
        }, {
            text: gettextCatalog.getString('September'),
            value: 8
        }, {
            text: gettextCatalog.getString('October'),
            value: 9
        }, {
            text: gettextCatalog.getString('November'),
            value: 10
        }, {
            text: gettextCatalog.getString('December'),
            value: 11
        }];

        vm.save = save;
        vm.files = openFiles;
        vm.print = showPrint;
        vm.copyFromOldPlan = copyFromOldPlan;
        vm.columnsPopup = [];
        vm.dataPopup = [];
        vm.selectedRowIndexPopup = -1;
        vm.closeEarlierMonthlyPlanWindow = closeEarlierMonthlyPlanWindow;
        vm.selectEarlierMonthlyPlanWindow = selectEarlierMonthlyPlanWindow;
        vm.SectionLeaderApprove = SectionLeaderApprove;

        vm.columnsPopup = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'Makrociklus',
                headerText: gettextCatalog.getString('Makrociklus'),
                textAlign: 'center',
                width: 50,
                allowEditing: false
            },
            {
                field: 'Ev',
                headerText: gettextCatalog.getString('Year'),
                textAlign: 'center',
                width: 50,
                allowEditing: false
            },
            {
                field: 'Honap',
                headerText: gettextCatalog.getString('Month'),
                textAlign: 'center',
                // type: 'date',
                // format: '{0:MMMM}',
                width: 100,
                allowEditing: false
            }
        ];

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false},
            allowTextWrap: true,
            allowScrolling: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 200,
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        gridPopup.appendTo('#GridPopup');
        gridPopup.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridPopup);
        };

        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['monthlyTrainingPlan.edzoId', 'monthlyTrainingPlan.szakosztalyId', 'monthlyTrainingPlan.korosztalyId', 'monthlyTrainingPlan.ev', 'monthlyTrainingPlan.trainingPlanId', 'monthlyTrainingPlan.edzoNev', 'monthlyTrainingPlan.szakosztalyNev', 'monthlyTrainingPlan.korosztalyNev']),
                authenticationService.getRight('UJHAVIEDZTERV'),
                authenticationService.getRight('MODHAVIEDZTERV'),
                authenticationService.getRight('MEGTHAVIEDZTERV'),
                getSystemSettings('HAVIEDZTERVJOVAHAGYKOT'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('HAVIEDZESTERVJOVAHAGY')
            ];
            $q.all(promises).then(function (results) {
                vm.UJHAVIEDZTERV = results[1];
                vm.MODHAVIEDZTERV = results[2];
                vm.MEGTHAVIEDZTERV = results[3];
                vm.HAVIEDZTERVJOVAHAGYKOT = results[4];
                vm.SZAKOSZTVEZ = results[5];
                vm.ADMIN = results[6];
                vm.HAVIEDZESTERVJOVAHAGY = results[7];
                vm.HAVIEDZTERVJOVAHAGY = vm.HAVIEDZTERVJOVAHAGYKOT && (vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HAVIEDZESTERVJOVAHAGY;
                vm.JOVAHAGYOTT = false;
                if (!vm.MEGTHAVIEDZTERV) {
                    log.permissionError(true);
                    return;
                }
                vm.trainingPlanId = results[0]['monthlyTrainingPlan.trainingPlanId'];
                if (angular.isDefined(vm.trainingPlanId) && vm.trainingPlanId !== -1) {
                    dataservice.monthlyTrainingPlanSelectById(vm.trainingPlanId).then(function (data) {
                        vm.editedMTP = data.retValue;
                        vm.editedMTP.IdSzakosztaly = data.retValue.IdSzakosztaly;
                        vm.JOVAHAGYOTT = vm.editedMTP.Jovahagyott;
                        if (vm.JOVAHAGYOTT) {
                            vm.HAVIEDZTERVJOVAHAGY = false;
                        }
                        vm.editedMTP.EdzoNev = results[0]['monthlyTrainingPlan.edzoNev'];
                        vm.editedMTP.SzakosztalyNev = results[0]['monthlyTrainingPlan.szakosztalyNev'];
                        vm.editedMTP.KorosztalyNev = results[0]['monthlyTrainingPlan.korosztalyNev'];
                        vm.copyEnabled = false;
                    });
                } else {
                    $timeout(function () {
                        vm.editedMTP.EdzoNev = results[0]['monthlyTrainingPlan.edzoNev'];
                        vm.editedMTP.SzakosztalyNev = results[0]['monthlyTrainingPlan.szakosztalyNev'];
                        vm.editedMTP.KorosztalyNev = results[0]['monthlyTrainingPlan.korosztalyNev'];
                        vm.editedMTP.Ev = results[0]['monthlyTrainingPlan.ev'];
                        vm.editedMTP.EdzoId = results[0]['monthlyTrainingPlan.edzoId'];
                        vm.editedMTP.IdSzakosztaly = results[0]['monthlyTrainingPlan.szakosztalyId'];
                        vm.editedMTP.IdKorcsoport = results[0]['monthlyTrainingPlan.korosztalyId'];
                        vm.editedMTP.Tartalom = '';
                        vm.copyEnabled = true;
                    }, 0);
                }
            });
        }

        function save() {
            if ((vm.copyEnabled && vm.UJHAVIEDZTERV) || (vm.MODHAVIEDZTERV && !vm.copyEnabled)) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg('FillTheRequiredFields');
                } else {
                    dataservice.saveMonthlyTrainingPlan(vm.editedMTP).then(function (data) {
                        dataservice.monthlyTrainingPlanSelectById(data.retValue).then(function (data) {
                            vm.editedMTP = data.retValue;
                            vm.editedMTP.IdSzakosztaly = data.retValue.IdSzakosztaly;
                            vm.JOVAHAGYOTT = vm.editedMTP.Jovahagyott;
                            if (vm.JOVAHAGYOTT) {
                                vm.HAVIEDZTERVJOVAHAGY = false;
                            }
                            vm.editedMTP.EdzoNev = vm.editedMTP.Edzo;
                            vm.editedMTP.SzakosztalyNev = vm.editedMTP.Szakosztaly;
                            vm.editedMTP.KorosztalyNev = vm.editedMTP.Korosztaly;
                            vm.copyEnabled = false;
                        });
                        log.successMsg('SavedSuccessful');
                        // $rootScope.back();
                    });
                }
            } else {
                log.permissionError();
            }
        }

        function openFiles() {
            paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach},
                {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'monthlyTrainingPlan.ev': vm.selectedYear},
                {'monthlyTrainingPlan.trainingPlanId': vm.editedMTP.Id},
                {'monthlyTrainingPlan.edzoNev': vm.editedMTP.EdzoNev},
                {'monthlyTrainingPlan.szakosztalyNev': vm.editedMTP.SzakosztalyNev},
                {'monthlyTrainingPlan.korosztalyNev': vm.editedMTP.KorosztalyNev},
                {'files.tipus': 'EdzesTervHavi'},
                {'files.tablaId': vm.editedMTP.Id}
            ]);
            $state.go('other.files');
        }

        function showPrint() {
            vm.reportParameters = {
                EvHonap: vm.editedMTP.Ev + '. ' + vm.months[vm.editedMTP.HonapSzama].text,
                Szakosztaly: vm.editedMTP.SzakosztalyNev,
                Korosztaly: vm.editedMTP.KorosztalyNev,
                Edzo: vm.editedMTP.EdzoNev,
                Makrociklus: vm.editedMTP.Makrociklus,
                Tartalom: vm.editedMTP.Tartalom,
                Keszult: moment().format('YYYY. MM. DD'),
                Keszitette: vm.editedMTP.EdzoNev
            };

            if (printOpened) {
                angular.element('#printDialog').ejDialog('open');
            } else {
                printOpened = true;
                angular.element('#printDialog').ejDialog({
                    height: '99%',
                    width: '99%',
                    enablemodal: 'true',
                    'position-x': '0',
                    'position-y': '1',
                    target: '#mainContent',
                    enableresize: 'false'
                });
            }
        }

        function copyFromOldPlan() {
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();
            dataservice.getOldMonthlyPlanByUserId(vm.editedMTP.EdzoId, vm.editedMTP.Id).then(function (args) {
                vm.dataPopup = args.itemsList;
                for (var i = 0; i < vm.dataPopup.length; i++) {
                    vm.dataPopup[i].Honap = vm.months[vm.dataPopup[i].HonapSzama].text;
                }
                gridPopup.dataSource = vm.dataPopup;
            });
        }

        function closeEarlierMonthlyPlanWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectEarlierMonthlyPlanWindow() {
            vm.selectedRowIndexPopup = gridPopup.getSelectedRowIndexes();
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    dataservice.monthlyTrainingPlanSelectById(vmi.Id).then(function (res) {
                        vm.editedMTP.Tartalom = res.retValue.Tartalom;
                        vm.editedMTP.Makrociklus = res.retValue.Makrociklus;
                    });
                    var dialogObj = angular.element('#searchWindow').data('ejDialog');
                    dialogObj.close();
                }
            }
        }

        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }

        function SectionLeaderApprove() {
            dataservice.HaviEdzesTervJovahagyas(vm.editedMTP.Id).then(function () {
                log.successMsg(gettextCatalog.getString('MonthlyTraningPlanApproved'));
                vm.HAVIEDZTERVJOVAHAGY = false;
                vm.JOVAHAGYOTT = true;
                $rootScope.back();
            });
        }
    }
})();
