(function () {
    'use strict';

    angular
        .module('app.business.dailyEducationalFeePayments')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.dailyEducationalFeePayments',
                config: {
                    url: '/dailyEducationalFeePayments',
                    templateUrl: 'app/business/dailyEducationalFeePayments/dailyEducationalFeePayments.html',
                    controller: 'DailyEducationalFeePaymentsController',
                    controllerAs: 'vm',
                    title: 'DailyEducationalFeePayments',
                    settings: {
                        navId: 263,
                        level: 2,
                        order: 6,
                        orderTitle_hu: 'Napi oktatási díj befizetések',
                        orderTitle_en: 'Daily Educational Fee Payments',
                        parentId: 3,
                        content: 'DailyEducationalFeePayments',
                        permission: ['7'],
                        activatorPermission: '"MEGTNAPIOKTD"'
                    }
                }
            }
        ];
    }
})();
