(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.joinedAthletesByCoach')
        .controller('JoinedAthletesByCoachController', JoinedAthletesByCoachController);

    function JoinedAthletesByCoachController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('JoinedAthletesByCoach');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.columns = [{
            field: 'EdzoNeve',
            headerText: gettextCatalog.getString('CoachName'), template: '#joatbycotemplateDataColumn1',
            textAlign: 'left'
        }, {
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section'), template: '#joatbycotemplateDataColumn2',
            textAlign: 'left'
        }, {
            field: 'Szam',
            headerText: gettextCatalog.getString('NumberOfAthletes'), template: '#joatbycotemplateDataColumn3',
            textAlign: 'left'
        }, {
            field: 'SzamFiu',
            headerText: gettextCatalog.getString('NumberOfBoys'), template: '#joatbycotemplateDataColumn4',
            textAlign: 'left'
        }, {
            field: 'SzamLany',
            headerText: gettextCatalog.getString('NumberOfGirls'), template: '#joatbycotemplateDataColumn5',
            textAlign: 'left'
        }];
        vm.DateFrom = new Date();
        vm.DateFrom.setMonth(vm.DateFrom.getMonth() - 1);
        vm.DateTo = new Date();
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;
        vm.openCoach = openCoach;
        vm.openSection = openSection;
        vm.openCoachesJoinedAthletes = openCoachesJoinedAthletes;

        vm.sectionList = [];
        vm.athleteListFull = [];
        vm.showEachSections = false;
        vm.accessibleSections = [];
        vm.checkboxChanged = checkboxChanged;
        vm.checkboxEnabled = true;
        vm.checkboxchangedonce = false;
        vm.checked = false;
        vm.userId = -1;
        vm.isSectionLeader = false;
        vm.isAdmin = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function openCoachesJoinedAthletes(coachId, sectionId, gender2) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzakosztalyId', 'equal', sectionId)).then(function (data) {
                var sectionName = data.result[0].SzakosztalyNeve;
                var coachName = data.result[0].EdzoNeve;
                var gender = gender2 === 0 ? 'allgender' : (gender2 === 1 ? 'male' : 'female');

                var sectionBelongsToUser = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] == sectionId) {
                        sectionBelongsToUser = true;
                        break;
                    }
                }
                if (sectionBelongsToUser || vm.isAdmin) {
                    paramHelper.setParams([
                        {'state': 'joinedAthletesToCoach'},
                        {'dateFrom': vm.DateFrom},
                        {'dateTo': vm.DateTo},
                        {'coachId': coachId},
                        {'coachName': coachName},
                        {'gender': gender}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                } else {
                    log.htmlWarning(gettextCatalog.getString('You do not have permission to access the selected section details!') + '<br><br>' + gettextCatalog.getString(sectionName));
                }
            });
        }

        function openSection(sectionId) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzakosztalyId', 'equal', sectionId)).then(function (data) {
                var sectionName = data.result[0].SzakosztalyNeve;

                var sectionBelongsToUser = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] == sectionId) {
                        sectionBelongsToUser = true;
                        break;
                    }
                }
                if (sectionBelongsToUser || vm.isAdmin) {
                    paramHelper.setParams([
                        {'state': 'joinedAthletes'},
                        {'sectionId': sectionId},
                        {'sectionName': sectionName},
                        {'dateFrom': vm.DateFrom},
                        {'dateTo': vm.DateTo},
                        {'gender': 'allgender'}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                } else {
                    log.htmlWarning(gettextCatalog.getString('You do not have permission to access the selected section details!') + '<br><br>' + gettextCatalog.getString(sectionName));
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getNumbers(dateFrom, dateTo) {
            dataservice.joinedAthletesByCoach(dateFrom, dateTo)
                .then(function (data) {
                    vm.userId = data.userId;
                    vm.isAdmin = data.isAdmin;
                    vm.isSectionLeader = data.isSectionLeader;
                    vm.athleteListFull = data.itemsList;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    if (data.isAdmin == true) {
                        vm.checked = true;
                    }
                    filterAthletes();
                });
        }

        function checkboxChanged(args) {
            vm.showEachSections = args.value;
            filterAthletes();
        }

        function filterAthletes() {
            vm.athleteList = [];
            if (!vm.showEachSections) {
                for (var i = 0; i < vm.athleteListFull.length; i++) {
                    for (var s = 0; s < vm.accessibleSections.length; s++) {
                        if (vm.athleteListFull[i].SzakosztalyId == vm.accessibleSections[s]) {
                            vm.athleteList.push(vm.athleteListFull[i]);
                        }
                    }
                }
            } else {
                vm.athleteList = vm.athleteListFull;
                grid.dataSource = vm.athleteList;
            }
        }

        function dateFromChanged(data) {
            getNumbers(data.value, vm.DateTo);
        }

        function dateToChanged(data) {
            getNumbers(vm.DateFrom, data.value);
        }

        function activate() {
            authenticationService.getRight('KMBELEPEDZO').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getNumbers(vm.DateFrom, vm.DateTo);
                }
            });
        }
    }
})();
