(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.summary')
        .controller('SummaryController', SummaryController);

    function SummaryController($q, log, paramHelper, $state, dataservice, $scope, PSYCHOLOGY, DOMAIN, gettextCatalog, $rootScope, authenticationService) {
        var vm = this;
        $rootScope.vm = vm;

        vm.warteggUrl = DOMAIN.baseURL + DOMAIN.psychologyDocUrl + '/wartegg.png';
        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true},
            {field: 'typeId', visible: false},
            {field: 'type', headerText: gettextCatalog.getString('TestsType'), textAlign: 'left'},
            {field: 'dateOfSurvey', headerText: gettextCatalog.getString('TestsDate'), textAlign: 'left'},
            {
                field: 'documentOfSurvey',
                headerText: gettextCatalog.getString('Test'),
                textAlign: 'left',
                template: '#sutemplateDataColumn1'
            },
            {
                field: 'dateOfAssessment',
                headerText: gettextCatalog.getString('EvaluationsDate'),
                textAlign: 'left',
                template: '#sutemplateDataColumn2'
            },
            {
                field: 'documentOfAssessment',
                headerText: gettextCatalog.getString('Evaluation'),
                textAlign: 'left',
                template: '#sutemplateDataColumn3'
            }
        ];
        vm.open = gettextCatalog.getString('Open');
        vm.noEvaluationForThisTest = gettextCatalog.getString('NoEvaluationForThisTest');

        // Public functions
        vm.goToPerformanceMotivation = goToPerformanceMotivation;
        vm.goToPieron = goToPieron;
        vm.goToTenessee = goToTenessee;
        vm.goToShowFilledTest = goToShowFilledTest;
        vm.addWartegTest = addWartegTest;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [paramHelper.getParams([], [
                'athleteModify.sportoloId',
                'athleteModify.sportoloNev',
                'athleteModify.aktivSportolo']), authenticationService.getRight('PSYCHO')];
            return $q.all(promises).then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.aktiv = results[0]['athleteModify.aktivSportolo'];
                    if (!vm.aktiv) {
                        $('#dropdownMenu').addClass('disabled');
                    }
                    vm.sportoloId = results[0]['athleteModify.sportoloId'];
                    vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology');
                    getPsychologyTests();
                }
            });
        }

        function getPsychologyTests() {
            return dataservice.getPsychologyTests(vm.sportoloId).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function goToShowFilledTest(id, testType, password, documentOfAssessmentBool) {
            if (documentOfAssessmentBool) {
                return;
            }
            if (password) {
                password = 'edit';
            } else {
                password = 'open';
            }
            switch (testType) {
                case PSYCHOLOGY.Wartegg:
                    paramHelper.setParams([{'psychology.psychologyId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.psychology.wartegg');
                    break;
                case PSYCHOLOGY.PerformanceMotivation:
                    paramHelper.setParams([{'psychology.surveyId': id}, {'psychology.mode': password}]);
                    $state.go('athletes.athletesManagement.athleteModify.psychology.performanceMotivationTestEvaluation');
                    break;
                case PSYCHOLOGY.Pieron:
                    paramHelper.setParams([{'psychology.surveyId': id}, {'psychology.mode': password}]);
                    $state.go('athletes.athletesManagement.athleteModify.psychology.pieronTestEvaluation');
                    break;
                case PSYCHOLOGY.Tenessee:
                    paramHelper.setParams([{'psychology.surveyId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScaleEvaluation');
                    break;
                default:
                    break;
            }
        }

        function goToPerformanceMotivation() {
            if (!vm.aktiv) {
                paramHelper.setParams([{'psychology.sportoloId': vm.sportoloId}]);
                $state.go('athletes.athletesManagement.athleteModify.psychology.performanceMotivationTest');
            }
        }

        function goToPieron() {
            if (!vm.aktiv) {
                paramHelper.setParams([{'psychology.sportoloId': vm.sportoloId}]);
                $state.go('athletes.athletesManagement.athleteModify.psychology.pieronTest');
            }
        }

        function goToTenessee() {
            if (!vm.aktiv) {
                paramHelper.setParams([{'psychology.sportoloId': vm.sportoloId}]);
                $state.go('athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScale');
            }
        }

        function addWartegTest() {
            if (!vm.aktiv) {
                return dataservice.saveWartegg(vm.sportoloId).then(function (data) {
                    if (!data) {
                        log.errorMsg(gettextCatalog.getString('ErrorOccurredWhileCreatingTheTest'));
                    } else {
                        getPsychologyTests();
                    }
                });
            }
        }
    }
})();
