(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports.annualPresenceReportsByTrainers')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.annualPresenceReports.annualPresenceReportsByTrainers',
                config: {
                    url: '/annualPresenceReportsByTrainers',
                    templateUrl: 'app/reports/annualPresenceReports/annualPresenceReportsByTrainers/annualPresenceReportsByTrainers.html',
                    controller: 'AnnualPresenceReportsByTrainersController',
                    controllerAs: 'vm',
                    title: 'AnnualPresenceReportsByTrainers',
                    settings: {
                        navId: 31234,
                        level: 3,
                        order: 1,
                        orderTitle_hu: 'Éves jelenléti kimutatás sportolónként',
                        orderTitle_en: 'Annual Attendance Reports By Athletes',
                        parentId: 224,
                        content: 'AnnualPresenceReportsByTrainers',
                        activatorPermission: '"KMEVESJELSP"'
                    }
                }
            }
        ];
    }
})();
