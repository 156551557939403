(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.monthlyAttendanceReports')
        .controller('MonthlyAttendanceReportsController', MonthlyAttendanceReportsController);

    function MonthlyAttendanceReportsController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, paramHelper, $scope, $filter, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MonthlyAttendance');

        // Create variables
        vm.params = [];
        vm.sectionId = -1;
        vm.coachId = -1;
        vm.coachList = [];
        vm.sectionList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 3));
        // Grid's variables
        vm.columns = [
            {field: 'Link', headerText: '', template: '#moatrecolumnTemplate1', width: 60, textAlign: 'center'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 150, template: '#marsection'},
            {field: 'KorosztalyNeve', headerText: gettextCatalog.getString('AgeGroup'), width: 150},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), width: 150, template: '#marcoach'},
            {field: 'Datum', headerText: gettextCatalog.getString('Date'), width: 150},
            {field: 'CsoportLetszam', headerText: gettextCatalog.getString('CsoportLetszam'), width: 150},
            {field: 'Erkezett', headerText: gettextCatalog.getString('Joined'), width: 150},
            {field: 'Tavozott', headerText: gettextCatalog.getString('Departed'), width: 150},
            {field: 'Edzo_Alairta', type: 'boolean', headerText: gettextCatalog.getString('CoachSigned'), width: 150, displayAsCheckBox: true, textAlign: 'center'},
            {field: 'Szakosztalyvezeto_Alairta', type: 'boolean', headerText: gettextCatalog.getString('SectionLeaderSigned'), width: 150, displayAsCheckBox: true, textAlign: 'center'}
        ];

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToMonthlyAttendance = goToMonthlyAttendance;
        vm.openCoach = openCoach;
        vm.clickOnSection = clickOnSection;
        vm.coachSelect = coachSelect;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('VEZETOIHJ').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([authenticationService.getRight('MEGTHAVIJEL'), paramHelper.getParams([], ['monthlyAttendanceReports.coachId', 'monthlyAttendanceReports.sectionId', 'managementReports.monthlyAttendanceReports.dateFrom', 'managementReports.monthlyAttendanceReports.dateTo'])]).then(function (results) {
                        vm.MEGTHAVIJEL = results[0];
                        vm.dateFromValue = results[1]['managementReports.monthlyAttendanceReports.dateFrom'] ? results[1]['managementReports.monthlyAttendanceReports.dateFrom'] : vm.dateFromValue;
                        vm.dateToValue = results[1]['managementReports.monthlyAttendanceReports.dateTo'] ? results[1]['managementReports.monthlyAttendanceReports.dateTo'] : vm.dateToValue;
                        vm.params = results[1];
                    });
                    getSections();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function clickOnSection(id) {
            vm.sectionId = id;
            search();
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.params['monthlyAttendanceReports.sectionId']) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.params['monthlyAttendanceReports.sectionId']);
                vm.params['monthlyAttendanceReports.sectionId'] = null;
            } else if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0, vm.dateFromValue, vm.dateToValue).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.params['monthlyAttendanceReports.coachId']) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.params['monthlyAttendanceReports.coachId']);
                vm.params['monthlyAttendanceReports.coachId'] = null;
            } else if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            search();
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getMonthlyAttendanceReports(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'Datum');
            });

            paramHelper.setParams([
                {
                    'monthlyAttendanceReports.sectionId': vm.sectionId
                },
                {
                    'monthlyAttendanceReports.coachId': vm.coachId
                },
                {'managementReports.monthlyAttendanceReports.dateFrom': vm.dateFromValue},
                {'managementReports.monthlyAttendanceReports.dateTo': vm.dateToValue}
            ]);
        }

        function goToMonthlyAttendance(attendanceId) {
            if (!vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([
                {
                    'monthlyAttendance.id': attendanceId
                }
            ]);
            $state.go('administration.monthlyAttendance.monthlyAttendanceReport');
        }

        function dateFromChange(args) {
            vm.dateFromValue = args.value;
            getActualCoachesList(vm.sectionId);
        }

        function dateToChange(args) {
            vm.dateToValue = args.value;
            getActualCoachesList(vm.sectionId);
        }
    }
})();
