(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesNotPresentOnMonthlyAttendance')
        .controller('AthletesNotPresentOnMonthlyAttendanceController', AthletesNotPresentOnMonthlyAttendanceController);

    function AthletesNotPresentOnMonthlyAttendanceController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AthletesNotPresentOnMonthlyAttendance');

        // Grid's details
        vm.data = [];
        vm.header = [];
        vm.reportParameters = {};
        vm.DateFrom = new Date();
        vm.DateFrom.setMonth(vm.DateFrom.getMonth() - 1);
        vm.DateTo = new Date();
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;
        vm.sectionList = [];
        vm.sectionValue = '';
        vm.selectedSectionId = -1;
        vm.selectedSectionName = '';
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.sectionId = '';
        vm.numberOfMonths = 0;
        vm.isAdmin = false;
        vm.isSectionLeader = false;
        vm.accessibleSections = [];
        vm.goToAthlete = goToAthlete;
        vm.goToCoach = goToCoach;

        vm.columns = [];

        var grid;

        activate();

        function activate() {
            authenticationService.getRight('SPJELENLETINELK').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSections().then(function () {
                        $timeout(function () {
                            if (vm.sectionList.length > 0) {
                                vm.sectionValue = vm.sectionList[0].value;
                            }
                        }, 0);
                    });
                }
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToCoach(coachId) {
            paramHelper.setParams([
                {'newUser.userId': coachId}
            ]);
            $state.go('settings.userManagement.modifyUser');
        }

        function getSections() {
            return dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
                vm.selectedSectionId = vm.sectionId;
            }
        }

        function getNumbers(dateFrom, dateTo, sectionId) {
            if (!sectionId) {
                return;
            }
            if (sectionId == -1) { // -1 jelenti az összes szakosztályt itt, 0-ra keresi az összeset
                sectionId = 0;
                vm.selectedSectionId = 0;
            }
            dataservice.athletesNotPresentOnMonthlyAttendance(dateFrom, dateTo, sectionId)
                .then(function (data) {
                    setColumns();
                    vm.isSectionLeader = data.isSectionLeader;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.data = data.itemsList;
                    vm.header = data.header;
                    vm.isAdmin = data.isAdmin;
                    vm.honapokszama = data.honapokszama;
                    var allSection = {value: -1, text: gettextCatalog.getString('All')};
                    if (vm.isAdmin && vm.sectionList.length > 1 && vm.sectionList[0].value != -1) {
                        vm.sectionList.unshift({value: '-1', text: gettextCatalog.getString('All')});
                    }
                    var columnWidth = 100;
                    for (var i = 0; i < vm.honapokszama; i++) {
                        switch (i) {
                            case 0:
                                vm.columns.push({headerText: data.header.t01, textAlign: 'center', width: columnWidth, field: 'h01'});
                                break;
                            case 1:
                                vm.columns.push({headerText: data.header.t02, textAlign: 'center', width: columnWidth, field: 'h02'});
                                break;
                            case 2:
                                vm.columns.push({headerText: data.header.t03, textAlign: 'center', width: columnWidth, field: 'h03'});
                                break;
                            case 3:
                                vm.columns.push({headerText: data.header.t04, textAlign: 'center', width: columnWidth, field: 'h04'});
                                break;
                            case 4:
                                vm.columns.push({headerText: data.header.t05, textAlign: 'center', width: columnWidth, field: 'h05'});
                                break;
                            case 5:
                                vm.columns.push({headerText: data.header.t06, textAlign: 'center', width: columnWidth, field: 'h06'});
                                break;
                            case 6:
                                vm.columns.push({headerText: data.header.t07, textAlign: 'center', width: columnWidth, field: 'h07'});
                                break;
                            case 7:
                                vm.columns.push({headerText: data.header.t08, textAlign: 'center', width: columnWidth, field: 'h08'});
                                break;
                            case 8:
                                vm.columns.push({headerText: data.header.t09, textAlign: 'center', width: columnWidth, field: 'h09'});
                                break;
                            case 9:
                                vm.columns.push({headerText: data.header.t10, textAlign: 'center', width: columnWidth, field: 'h10'});
                                break;
                            case 10:
                                vm.columns.push({headerText: data.header.t11, textAlign: 'center', width: columnWidth, field: 'h11'});
                                break;
                            case 11:
                                vm.columns.push({headerText: data.header.t12, textAlign: 'center', width: columnWidth, field: 'h12'});
                                break;
                        }
                    }
                    if (!grid) {
                        grid = new ej2.grids.Grid({
                            dataSource: [],
                            toolbar: $rootScope.toolbarItems,
                            allowPaging: true,
                            allowTextWrap: true,
                            allowSorting: true,
                            allowExcelExport: true,
                            locale: 'hu-HU',
                            gridLines: 'Both',
                            pageSettings: $rootScope.pageSettings,
                            columns: vm.columns,
                            rowDataBound: function (args) {
                                $rootScope.compile(args.row, $scope);
                            },
                            actionComplete: function (args) {
                                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                                    $rootScope.compile($('#Grid'), $scope);
                                }
                            }
                        });
                        grid.appendTo('#Grid');
                        grid.toolbarClick = function (args) {
                            $rootScope.toolbarClick(args, grid);
                        };
                    } else {
                        grid.columns = vm.columns;
                        grid.refreshColumns();
                    }
                    grid.dataSource = data.itemsList;
                });
        }

        function sectionSelect(args) {
            vm.selectedSectionId = args.value;
            vm.selectedSectionName = args.text;
            getNumbers(vm.DateFrom, vm.DateTo, vm.selectedSectionId);
        }

        function dateFromChanged(data) {
            getNumbers(data.value, vm.DateTo, vm.selectedSectionId);
        }

        function dateToChanged(data) {
            getNumbers(vm.DateFrom, data.value, vm.selectedSectionId);
        }

        function setColumns() {
            vm.columns = [{
                field: 'MezoSportoloNev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'center',
                width: 100,
                template: '#anpomaathlete'
            }, {
                field: 'MezoSzakosztalyNev',
                headerText: gettextCatalog.getString('Szakosztaly'),
                textAlign: 'center',
                width: 100
            }, {
                field: 'MezoKorcsoportNev',
                headerText: gettextCatalog.getString('Korcsoport'),
                textAlign: 'center',
                width: 100
            }, {
                field: 'MezoEdzoNev',
                headerText: gettextCatalog.getString('Edzo'),
                textAlign: 'center',
                width: 100,
                template: '#anpomacoach'
            }];
        }
    }
})();
