(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.leavingAthletesBySection')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports.leavingAthletesBySection',
                config: {
                    url: '/leavingAthletesBySection',
                    templateUrl: 'app/reports/statisticalReports/leavingAthletesBySection/leavingAthletesBySection.html',
                    controller: 'LeavingAthletesBySection',
                    controllerAs: 'vm',
                    title: 'LeavingAthletesBySection',
                    settings: {
                        navId: 32214,
                        level: 3,
                        order: 2,
                        orderTitle_hu: 'Kilépők száma szakosztályonként',
                        orderTitle_en: 'Number of Left Athletes by Sections',
                        parentId: 214,
                        content: 'LeavingAthletesBySection',
                        activatorPermission: '"KMKILSZAK"'

                    }
                }
            }
        ];
    }
})();
