(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesByYear')
        .controller('AthletesByYearController', AthletesByYearController);

    function AthletesByYearController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthletesByYear');

        vm.sectionList = [];

        vm.columns = [{
            field: 'Nev',
            headerText: gettextCatalog.getString('Name'),
            width: 150,
            textAlign: 'left',
            template: '#atbyyetemplateDataColumn1'
        }, {
            field: 'SzuletesiIdo',
            headerText: gettextCatalog.getString('BirthDate'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Aktiv',
            headerText: gettextCatalog.getString('Active'),
            width: 50,
            textAlign: 'left',
            displayAsCheckBox: true
        }, {
            field: 'EdzoNeve',
            headerText: gettextCatalog.getString('Coach'),
            width: 150,
            textAlign: 'left',
            template: '#atbyyetemplateDataColumn3'
        }, {
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'KorcsoportNeve',
            headerText: gettextCatalog.getString('AgeGroup'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Tagdij',
            headerText: gettextCatalog.getString('PayedSportServiceFee'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Oktatasidij',
            headerText: gettextCatalog.getString('PayedEducationalFee'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Egyenleg',
            headerText: gettextCatalog.getString('Balance'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Megjegyzes',
            headerText: gettextCatalog.getString('Comment'),
            width: 150,
            textAlign: 'left'
        }];

        vm.Date = new Date();
        vm.selectedSection = 0;
        vm.goToAthlete = goToAthlete;
        vm.goToCoach = goToCoach;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTELETKOR').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['year'])]).then(function (results) {
                        vm.params = results[0];
                        getNumbers(vm.params.year, 0);
                    });
                }
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToCoach(coachId) {
            $q.all([authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([
                        {'newUser.userId': coachId}
                    ]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getNumbers(year, sectionID) {
            dataservice.activeAthletesBornAtSpecificYear(year, sectionID)
                .then(function (data) {
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        }
    }
})();
