(function () {
    'use strict';

    angular
        .module('app.settings.schoolManagement.addSchool')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.schoolManagement.addSchool',
                config: {
                    url: '/addSchool',
                    templateUrl: 'app/settings/schoolManagement/addSchool/addSchool.html',
                    controller: 'AddSchoolController',
                    controllerAs: 'vm',
                    title: 'AddSchool',
                }
            }
        ];
    }
})();
