(function () {
    'use strict';

    angular
        .module('app.settings.usersBelongingToAthletes')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.usersBelongingToAthletes',
                config: {
                    url: '/usersBelongingToAthletes',
                    templateUrl: 'app/settings/usersBelongingToAthletes/usersBelongingToAthletes.html',
                    controller: 'UsersBelongingToAthletesController',
                    controllerAs: 'vm',
                    title: 'UsersBelongingToAthletes',
                    settings: {
                        navId: 245,
                        level: 2,
                        order: 4,
                        orderTitle_hu: 'Sportolókhoz tartozó felhasználók',
                        orderTitle_en: 'Users Belonging to Athletes',
                        parentId: 5,
                        content: 'UsersBelongingToAthletes',
                        activatorPermission: '"SPTARTMEGT"'
                    }
                }
            }
        ];
    }
})();
