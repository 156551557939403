(function () {
    'use strict';

    angular
        .module('app.administration.quickCompetitionResults')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.quickCompetitionResults',
                config: {
                    url: '/quickCompetitionResults',
                    templateUrl: 'app/administration/quickCompetitionResults/quickCompetitionResults.html',
                    controller: 'QuickCompetitionResultsController',
                    controllerAs: 'vm',
                    title: 'QuickCompetitionResults',
                    settings: {
                        navId: 2112,
                        level: 2,
                        order: 11,
                        orderTitle_hu: 'Eredményjelentő (gyors)',
                        orderTitle_en: 'Result Report (quick)',
                        parentId: 2,
                        content: 'QuickCompetitionResults',
                        activatorPermission: '"UJEERJEL"'
                    }
                }
            }
        ];
    }
})();
