(function () {
    'use strict';

    angular
        .module('app.core')
        .filter('getIndex', getIndex)
        .filter('startOfWeek', startOfWeek)
        .filter('endOfWeek', endOfWeek)
        .filter('addDays', addDays)
        .filter('stringToMoment', stringToMoment)
        .filter('momentToString', momentToString)
        .filter('dateStringFilter', dateStringFilter)
        .filter('dateStringFilterPromise', dateStringFilterPromise)
        .filter('dateStringFilter2', dateStringFilter2)
        .filter('dateYearMonthStringFilter', dateYearMonthStringFilter)
        .filter('dateTimeStringFilter', dateTimeStringFilter)
        .filter('dateEventFilter', dateEventFilter)
        .filter('datetimeFilter', datetimeFilter)
        .filter('textByValueFromObjFilter', textByValueFromObjFilter)
        .filter('valueChangeFromObject', valueChangeFromObject)
        .filter('dateYearMonthFilter', dateYearMonthFilter)
        .filter('dateYearFilter', dateYearFilter)
        .filter('dateMonthFilter', dateMonthFilter)
        .filter('dateYearMonthLongStrFilter', dateYearMonthLongStrFilter)
        .filter('dateToISOFilter', dateToISOFilter)
        .filter('addImagePathToObject', addImagePathToObject);

    function addDays(authenticationService) {
        return function (sDate, addDays) {
            var lang = authenticationService.getAuthData().language;
            var mDate = null;
            if (!lang) {
                lang = 'hu';
            }
            if (lang == 'hu') {
                var mDate = moment(sDate, 'YYYY.MM.DD.');
            } else if (lang == 'en') {
                var mDate = moment(sDate, 'MM/DD/YYYY');
            }
            if (mDate && mDate['_isValid'] && mDate['_isAMomentObject']) {
                var mOffset = mDate.add(addDays, 'days');
                return mOffset.format('L');
            } else {
                return false;
            }
        };
    }

    function startOfWeek(authenticationService) {
        return function (sDate) {
            var lang = authenticationService.getAuthData().language;
            if (!lang) {
                lang = 'hu';
            }
            var mDate = null;
            if (lang == 'hu') {
                var mDate = moment(sDate, 'YYYY.MM.DD.');
            } else if (lang == 'en') {
                var mDate = moment(sDate, 'MM/DD/YYYY');
            }
            if (mDate && mDate['_isValid'] && mDate['_isAMomentObject']) {
                var mMonday = moment(mDate.startOf('isoweek'));
                return mMonday.format('L');
            } else {
                return false;
            }
        };
    }

    function endOfWeek(authenticationService) {
        return function (sDate) {
            var lang = authenticationService.getAuthData().language;
            if (!lang) {
                lang = 'hu';
            }
            var mDate = null;
            if (lang == 'hu') {
                var mDate = moment(sDate, 'YYYY.MM.DD.');
            } else if (lang == 'en') {
                var mDate = moment(sDate, 'MM/DD/YYYY');
            }
            if (mDate && mDate['_isValid'] && mDate['_isAMomentObject']) {
                var mMonday = moment(mDate.endOf('isoweek'));
                return mMonday.format('L');
            } else {
                return false;
            }
        };
    }

    function stringToMoment(authenticationService) {
        return function (sDate) {
            var lang = authenticationService.getAuthData().language;
            if (!lang) {
                lang = 'hu';
            }
            var mDate = null;
            if (lang == 'hu') {
                var mDate = moment(sDate, 'YYYY.MM.DD.');
            } else if (lang == 'en') {
                var mDate = moment(sDate, 'MM/DD/YYYY');
            }
            if (mDate && mDate['_isValid'] && mDate['_isAMomentObject']) {
                return mDate;
            } else {
                return false;
            }
        };
    }

    function momentToString() {
        return function (mDate) {
            if (mDate && mDate['_isValid'] && mDate['_isAMomentObject']) {
                return mDate.format('L');
            } else {
                return false;
            }
        };
    }

    function getIndex() {
        return function (list, property, findThis) {
            for (var i = 0; i < list.length; i++) {
                if (list[i][property] == findThis) {
                    return i;
                }
            }
            return false;
        };
    }

    function dateStringFilterPromise(authenticationService, $q) {
        return function (input, propertyTime) {
            return $q(function (resolve) {
                var lang = authenticationService.getAuthData().language;
                moment.locale(lang);
                if (arguments.length == 1) {
                    if (input) {
                        return moment(input).format('L');
                    } else {
                        resolve('');
                    }
                } else {
                    angular.forEach(input, function (value, key) {
                        if (!input[key][propertyTime]) {
                            input[key][propertyTime] = '';
                        } else {
                            input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -(input[key][propertyTime].length - 10))).format('L');
                        }
                    });
                    resolve(input);
                }
            });
        };
    }

    function dateStringFilter(authenticationService) {
        return function (input, propertyTime) {
            var lang = authenticationService.getAuthData().language;
            moment.locale(lang);
            if (arguments.length == 1) {
                if (input) {
                    return moment(input).format('L');
                } else {
                    return '';
                }
            } else {
                angular.forEach(input, function (value, key) {
                    if (!input[key][propertyTime]) {
                        input[key][propertyTime] = '';
                    } else {
                        input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -(input[key][propertyTime].length - 10))).format('L');
                    }
                });
                return input;
            }
        };
    }

    function dateYearMonthStringFilter(authenticationService) {
        return function (input, propertyTime) {
            var lang = authenticationService.getAuthData().language;
            moment.locale(lang);
            if (arguments.length == 1) {
                if (input) {
                    return moment(input).format('L');
                } else {
                    return '';
                }
            } else {
                angular.forEach(input, function (value, key) {
                    if (!input[key][propertyTime]) {
                        input[key][propertyTime] = '';
                    } else {
                        input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -(input[key][propertyTime].length - 10))).format('YYYY. MMMM');
                    }
                });
                return input;
            }
        };
    }

    function dateTimeStringFilter(authenticationService) {
        return function (input, propertyTime) {
            var lang = authenticationService.getAuthData().language;
            moment.locale(lang);
            if (arguments.length == 1) {
                if (input) {
                    return moment(input).format('LLL');
                } else {
                    return '';
                }
            } else {
                angular.forEach(input, function (value, key) {
                    if (!input[key][propertyTime]) {
                        input[key][propertyTime] = '';
                    } else {
                        input[key][propertyTime] = moment(input[key][propertyTime]).format('LLL');
                    }
                });
                return input;
            }
        };
    }

    function dateStringFilter2() {
        return function (input, propertyTime) {
            angular.forEach(input, function (value, key) {
                var temp = moment(input[key][propertyTime]);
                if (temp.isValid()) {
                    input[key][propertyTime] = new Date(temp.format('YYYY'), temp.format('M') - 1, temp.format('D'), temp.format('h'), temp.format('m'), temp.format('s'));
                } else {
                    input[key][propertyTime] = '';
                }
            });
            return input;
        };
    }

    function dateEventFilter() {
        return function (input) {
            angular.forEach(input, function (value, key) {
                if (input[key]['Recurrence'] === 'False' || input[key]['Recurrence'] === 'false') {
                    input[key]['Recurrence'] = false;
                } else if (input[key]['Recurrence'] === 'True' || input[key]['Recurrence'] === 'true') {
                    input[key]['Recurrence'] = true;
                } else if (input[key]['Recurrence'] === '1') {
                    input[key]['Recurrence'] = 1;
                }
                if (!input[key]['StartTime']) {
                    input[key]['StartTime'] = '';
                } else {
                    input[key]['StartTime'] = new Date(moment(input[key]['StartTimeString']).format('YYYY'), moment(input[key]['StartTimeString']).format('M') - 1, moment(input[key]['StartTimeString']).format('D'), moment(input[key]['StartTimeString']).format('H'), moment(input[key]['StartTimeString']).format('m'));
                }
                if (!input[key]['EndTime']) {
                    input[key]['EndTime'] = '';
                } else {
                    input[key]['EndTime'] = new Date(moment(input[key]['EndTimeString']).format('YYYY'), moment(input[key]['EndTimeString']).format('M') - 1, moment(input[key]['EndTimeString']).format('D'), moment(input[key]['EndTimeString']).format('H'), moment(input[key]['EndTimeString']).format('m'));
                }
            });
            return input;
        };
    }

    function datetimeFilter(authenticationService) {
        return function (input) {
            var lang = authenticationService.getAuthData().language;
            moment.locale(lang);
            if (input) {
                input = moment(input.slice(0, -9)).format('L');
            }
            return input;
        };
    }

    /**
     * textByValueFromObjFilter()
     *
     * This function find first objet if object's value equal the input value
     * and return the object's text value
     */
    function textByValueFromObjFilter() {
        return function (input, input2) {
            var result = '';
            angular.forEach(input, function (value) {
                if (value.value === input2) {
                    result = value.text;
                }
            });
            return result;
        };
    }

    /**
     * Sportolo result modify
     * Comment
     */
    function valueChangeFromObject() {
        return function (input, input2) {
            var output = [];
            angular.forEach(input, function (value, key) {
                input[key] [input2] = input[key] [input2] + 3;
                output.push(input[key]);
            });
            return output;
        };
    }

    function addImagePathToObject() {
        return function (input, input2, input3) {
            var output = [];
            angular.forEach(input, function (value, key) {
                if (input[key] [input2] != null) {
                    input[key] [input2] = input3 + input[key] [input2];
                } else {
                    input[key] [input2] = input3 + 'default.png';
                }
                output.push(input[key]);
            });
            return output;
        };
    }

    function dateYearMonthFilter() {
        return function (input, propertyTime) {
            angular.forEach(input, function (value, key) {
                if (!input[key][propertyTime]) {
                    input[key][propertyTime] = '';
                } else {
                    input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -9)).format('YYYY-MM');
                }
            });
            return input;
        };
    }

    function dateYearFilter() {
        return function (input, propertyTime) {
            angular.forEach(input, function (value, key) {
                if (!input[key][propertyTime]) {
                    input[key][propertyTime] = '';
                } else {
                    input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -9)).format('YYYY');
                }
            });
            return input;
        };
    }

    function dateMonthFilter() {
        return function (input, propertyTime) {
            angular.forEach(input, function (value, key) {
                if (!input[key][propertyTime]) {
                    input[key][propertyTime] = '';
                } else {
                    input[key][propertyTime] = moment(input[key][propertyTime].slice(0, -9)).format('MM');
                }
            });
            return input;
        };
    }

    function dateYearMonthLongStrFilter(gettextCatalog) {
        return function (input) {
            if (arguments.length == 1) { // TODO new Date v moment
                var d = moment(input);
                var year = d.format('YYYY');
                var month = d.format('M');
                var monthStr = '';
                switch (month) {
                    case '1':
                        monthStr = gettextCatalog.getString('January');
                        break;
                    case '2':
                        monthStr = gettextCatalog.getString('February');
                        break;
                    case '3':
                        monthStr = gettextCatalog.getString('March');
                        break;
                    case '4':
                        monthStr = gettextCatalog.getString('April');
                        break;
                    case '5':
                        monthStr = gettextCatalog.getString('May');
                        break;
                    case '6':
                        monthStr = gettextCatalog.getString('June');
                        break;
                    case '7':
                        monthStr = gettextCatalog.getString('July');
                        break;
                    case '8':
                        monthStr = gettextCatalog.getString('August');
                        break;
                    case '9':
                        monthStr = gettextCatalog.getString('September');
                        break;
                    case '10':
                        monthStr = gettextCatalog.getString('October');
                        break;
                    case '11':
                        monthStr = gettextCatalog.getString('November');
                        break;
                    case '12':
                        monthStr = gettextCatalog.getString('December');
                        break;
                    default:
                        monthStr = '';
                        break;
                }
                input = year + ' ' + monthStr;
                return input;
            }
            return input;
        };
    }

    function dateToISOFilter() {
        return function (input) {
            return moment(input, ['YYYY.MM.DD.', 'MM/DD/YYYY']).format('YYYY-MM-DD');
        };
    }
})();

