(function () {
    'use strict';

    angular
        .module('app.reports.playUpAndDown')
        .controller('PlayUpAndDownController', PlayUpAndDownController);

    function PlayUpAndDownController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('PlayUpAndDown');

        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        vm.show = show;

        vm.columns = [
            {field: 'SportolokID', isPrimaryKey: true, visible: false},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'left',
                width: 150,
                template: '#puadcoach'
            },
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                width: 150,
                template: '#puadath'
            },
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('Birthdate'), textAlign: 'left', width: 150},
            {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                type: 'boolean',
                displayAsCheckBox: true,
                textAlign: 'center',
                width: 150
            },
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 150},
            {field: 'Tagdij', headerText: gettextCatalog.getString('SportServiceFee'), textAlign: 'left', width: 150},
            {
                field: 'Oktatasidij',
                headerText: gettextCatalog.getString('EducationalFee'),
                textAlign: 'left',
                width: 150
            },
            {field: 'Egyenleg', headerText: gettextCatalog.getString('PaidAmount'), textAlign: 'left', width: 150},
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('KMATFELJATSZ').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'puad.section',
                'puad.agegroup',
                'puad.coach'
            ])
            ];
            $q.all(promises).then(function (results) {
                console.log('setDropDowns', results[0]);
                vm.sectionId = results[0]['puad.section'];
                vm.sectionIdValue = results[0]['puad.section'];
                vm.ageGroupId = results[0]['puad.agegroup'];
                vm.ageGroupIdValue = results[0]['puad.agegroup'];
                vm.coachId = results[0]['puad.coach'];
                vm.coachIdValue = results[0]['puad.coach'];
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.coachList = [];
            vm.ageGroupId = -1;
            getAgeGroups();
        }

        // Get age groups' list
        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (!vm.ageGroupId) {
                    getActualCoachesList(0);
                }
            }
        }

        function ageGroupSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(agegroup) {
            if (!agegroup) {
                agegroup = 0;
            }
            return dataservice.coachDropDownList(vm.sectionId, agegroup).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age group selected
        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'puad.section': vm.sectionId},
                {'puad.agegroup': vm.ageGroupId},
                {'puad.coach': vm.coachId}
            ]);
            dataservice.getUpAndDownPlayers(vm.sectionId, vm.ageGroupId, vm.coachId).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
            });
        }
    }
})();
