(function () {
    'use strict';

    angular
        .module('app.settings.ageGroupChange')
        .controller('AgeGroupChangeController', AgeGroupChangeController);

    function AgeGroupChangeController(logger, log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AgeGroupChange');

        // Create variables
        // Dropdownlist's sources
        vm.actualSectionList = [];
        vm.actualCoachList = [];
        vm.sectionList = [];
        vm.coachList = [];
        vm.atletesList = [];
        vm.ageGroupList = [];

        // Grid's variables
        vm.columns = [
            {field: 'Link', type: 'checkbox', textAlign: 'center', width: 55},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', template: '#agegroupchangecolumnTemplate2'},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('CoachName'), textAlign: 'left', template: '#agegroupchangecolumnTemplate1'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left'},
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', format: 'yyyy-MM-dd'},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left'},
            {field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left'},
            {field: 'Tagdij', headerText: gettextCatalog.getString('SportServiceFee'), textAlign: 'left'},
            {field: 'Oktatasidij', headerText: gettextCatalog.getString('SchoolFee'), textAlign: 'left'},
            {field: 'Egyenleg', headerText: gettextCatalog.getString('Balance'), textAlign: 'left'},
            {field: 'Aktiv', headerText: gettextCatalog.getString('Active'), textAlign: 'center', type: 'boolean', template: '#checkboxTemplateAktiv'},
            {field: 'jelenKorcsoportbanMikortolVan', headerText: gettextCatalog.getString('InAgeGroupFromDate'), textAlign: 'left', format: 'yyyy-MM-dd'}
        ];

        // Other variables
        vm.dateValue = new Date();
        vm.date = vm.dateValue;
        vm.athleteData = {};
        vm.actualSectionId = undefined;
        vm.actualCoachId = undefined;
        vm.actualAthleteId = undefined;
        vm.NOACTUALATHLETE = false;
        vm.which = 1;
        vm.backPath = '';

        // Public functions
        vm.actualSectionDataBound = actualSectionDataBound;
        vm.actualSectionSelect = actualSectionSelect;
        vm.actualCoachDataBound = actualCoachDataBound;
        vm.actualCoachSelect = actualCoachSelect;
        vm.dateChange = dateChange;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.transferAthletes = transferAthletes;
        vm.openCoach = openCoach;
        vm.goToAthlete = goToAthlete;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            var promises = [
                authenticationService.getRight('MEGTEDZVALT'),
                authenticationService.getRight('MEGTKORVALT'),
                authenticationService.getRight('UJEDZVALT'),
                paramHelper.getParams([], [
                    'ageGroupChange.athleteData',
                    'ageGroupChange.which'])
            ];
            $q.all(promises).then(function (results) {
                vm.NOACTUALATHLETE = true;

                if (results[3]['ageGroupChange.athleteData'] !== null && angular.isDefined(results[3]['ageGroupChange.athleteData'])) {
                    vm.athleteData = results[3]['ageGroupChange.athleteData'];
                    vm.actualAthleteId = vm.athleteData.SportolokID;
                    vm.backPath = results[3]['ageGroupChange.backPath'];
                    paramHelper.removeParam('ageGroupChange.athleteData'); // Clear athleteData
                } else {
                    vm.actualAthleteId = 0;
                }
                if (angular.isDefined(results[3]['ageGroupChange.which'])) {
                    vm.which = results[3]['ageGroupChange.which'];
                }
                if (results[0] !== true && results[1] !== true) {
                    log.permissionError(true);
                } else if (vm.actualAthleteId > 0) {
                    vm.UJEDZVALT = results[2];
                    if (vm.which == 1) {
                        vm.actualSectionId = vm.athleteData.IdSzakosztaly;
                        vm.actualCoachId = vm.athleteData.JelenlegiEdzo;
                    } else {
                        vm.actualSectionId = vm.athleteData.MasodlagosSzakosztaly;
                        vm.actualCoachId = vm.athleteData.MasodlagosSzakosztalyEdzoId;
                    }
                    athletesSelect();
                    getAgeGroups();
                    vm.NOACTUALATHLETE = false;
                } else {
                    vm.UJEDZVALT = results[2];
                    getActualSections();
                }
            });
        }

        /**
         * Betölti a szakosztályok listáját
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getActualSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.actualSectionList = data.itemsList;
                    if (vm.actualSectionId > 0) {
                        vm.defaultSection = vm.actualSectionId; // Set default section
                        angular.element('#actualSectionDropDown').ejDropDownList({enabled: false});
                        grid.selectRow(0);
                    }
                });
        }

        /**
         * Szakosztályok betöltése után beállítja az alapértelmezett szakosztályt
         * @author Richard Keki
         */
        function actualSectionDataBound() {
            if (vm.actualSectionList.length === 1) {
                angular.element('#actualSectionDropDown').ejDropDownList('selectItemByValue', vm.actualSectionList[0].value);
                vm.actualSectionId = vm.actualSectionList[0].value;
            } else {
                angular.element('#actualSectionDropDown').ejDropDownList('selectItemByValue', vm.defaultSection);
                vm.actualSectionId = vm.defaultSection;
            }
        }

        /**
         * Szakosztály kiválasztásakor fut le és lekéri a szakosztály korosztályait
         * @author Richard Keki
         */
        function actualSectionSelect(args) {
            vm.actualSectionId = args.value;
            vm.actualCoachId = 0;
            getActualCoaches();
            if (vm.NOACTUALATHLETE) {
                athletesSelect();
            }
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getActualCoaches() {
            return dataservice.coachDropDownList(vm.actualSectionId)
                .then(function (data) {
                    vm.actualCoachList = data.itemsList;
                });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function actualCoachDataBound() {
            if (vm.actualCoachList.length === 1) {
                angular.element('#actualCoachDropDown').ejDropDownList('selectItemByValue', vm.actualCoachList[0].value);
                vm.actualCoachId = vm.actualCoachList[0].value;
            } else if (vm.athleteData.JelenlegiEdzo > 0) {
                vm.actualCoachId = vm.athleteData.JelenlegiEdzo;
                angular.element('#actualCoachDropDown').ejDropDownList('selectItemByValue', vm.actualCoachId);
            }
        }

        function actualCoachSelect(args) {
            vm.actualCoachId = args.value;
            if (vm.NOACTUALATHLETE) {
                athletesSelect();
            }
            getAgeGroups();
        }

        function athletesSelect() {
            return dataservice.getAthletes(vm.actualSectionId, vm.actualCoachId, vm.actualAthleteId)
                .then(function (data) {
                    vm.atletesList = $filter('dateStringFilter')(data.itemsList, 'jelenKorcsoportbanMikortolVan');
                    vm.atletesList = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                    grid.dataSource = vm.atletesList;
                });
        }

        function dateChange(args) {
            vm.date = args.value;
        }

        function getAgeGroups() {
            return dataservice.ageGroupSelect(vm.actualCoachId, vm.actualSectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.selectedAgeGroupId = vm.ageGroupList[0].value;
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function transferAthletes() {
            if (vm.UJEDZVALT !== true) {
                log.permissionError();
                return;
            }
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (vm.actualAthleteId > 0) {
                grid.selectRow(0);
            }
            vm.selectedRows = grid.getSelectedRecords();
            if (vm.selectedRows.length !== 0) {
                if (vm.actualSectionId !== 0 && vm.actualCoachId !== 0 && vm.ageGroupId) {
                    swal({
                        title: gettextCatalog.getString('AreYouSureToChangeAgeGroup'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: gettextCatalog.getString('OK'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                        closeOnConfirm: false
                    }).then(function (confirm) {
                        if (confirm.value) {
                            dataservice.transferAthletes(vm.selectedRows, vm.actualCoachId, vm.actualSectionId, vm.ageGroupId, vm.date, true).then(function () {
                                athletesSelect();
                                log.successMsg(gettextCatalog.getString('TransferCompleted'));
                            });
                            swal.close();
                            if (vm.actualAthleteId > 0) {
                                $rootScope.back();
                            }
                        } else {
                            swal.close();
                        }
                    });
                } else {
                    log.errorMsg(gettextCatalog.getString('YouHaveToSelectTransferDestination'), gettextCatalog.getString('NoSelected'));
                }
            } else {
                log.errorMsg(gettextCatalog.getString('YouHaveToSelectLeastAthlete'), gettextCatalog.getString('NoSelectedAthlete'));
            }
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
