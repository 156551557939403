(function () {
    'use strict';

    angular
        .module('app.messageCenter')
        .controller('MessageCenterController', MessageCenterController);

    function MessageCenterController($q, $rootScope, gettextCatalog, dataservice, $scope, log, authenticationService, paramHelper, $state, $timeout, DOMAIN) {
        let vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MessageCenterRecievedMessages');
        $scope.files = [];
        $scope.imageDatas = [];
        //Grid nyomtatás, exportálás nyomógomb
        vm.isBusy = false;
        vm.listPageNumber = 1;
        vm.itemsPerMessage = 24;
        vm.messagePageNumber = 1;
        vm.listItemsPerPage = 24;
        vm.SelectedMessage = {};
        vm.openedMessageTargyId = null;
        vm.SelectedMessageDateSource = null;
        vm.newMessage = false;
        vm.toolbarClick = toolbarClick;
        vm.loadMessageId = -1;
        vm.topicStarter = null;
        vm.messageImages = [];
        vm.recipients = [];
        vm.recipientsId = [];
        vm.recipientsList = [];
        vm.kicked = [];
        vm.tempRec = [];
        vm.Message = '';
        vm.profileImgBaseUrl = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl;
        vm.messageImagesUrl = DOMAIN.baseURL + DOMAIN.messageImagesUrl;
        vm.searchInput = '';
        vm.unreadCount = 0;
        vm.dataMessages = [];
        vm.imageSet = imageSet;
        vm.getRecipients = getRecipients;
        vm.deleteAttachedImg = deleteAttachedImg;
        vm.removeUser = removeUser;
        vm.addUser = addUser;
        vm.selectUser = selectUser;
        vm.addUserDone = addUserDone;
        vm.addUserCancel = addUserCancel;
        vm.deleteImage = deleteImage;
        vm.searchUsers = searchUsers;
        vm.recipientsListSm = recipientsListSm;
        vm.getUnreadMessageCount = getUnreadMessageCount;
        vm.userFilter = userFilter;

        $scope.$on('msgRead', getUnreadMessageCount);

        maxSize();
        getUnreadMessageCount();
        activate();

        function activate() {
            setInterval(WaitForPushNotifications, 500);
            vm.dataMessages = [];
            vm.userid = authenticationService.getAuthData().id;
            dataservice.Cimjegyzek().then(function (data) {
                $timeout(function () {
                    vm.recipientsList = data.itemsList;
                    $scope.$apply();
                }, 0);
            });
            $q.all([paramHelper.getParams([], ['messageTargyId']), authenticationService.getRight('MEGTFELH'), authenticationService.getRight('UZENETMEGT'), authenticationService.getRight('UZENETTORLES')]).then(function (results) {
                vm.MEGTFELH = results[1];
                vm.UZENETMEGT = results[2];
                vm.UZENETTORLES = results[3];
                if (!vm.UZENETMEGT) {
                    log.permissionError(true);
                    return;
                }
                refreshList();
                angular.element(document.querySelector('#message-list')).bind('scroll', function () {
                    let objDiv = jQuery($('#message-list'));
                    if (objDiv[0].scrollTop == objDiv[0].scrollHeight - objDiv[0].clientHeight) {
                        vm.listPageNumber += 1;
                        loadMoreListItem();
                    }
                });
                $timeout(function () {
                    if (vm.openedMessageTargyId > 0) {
                        getMessages(vm.openedMessageTargyId);
                    }
                }, 100);
            });
        }

        function WaitForPushNotifications() {
            var pushnotification = localStorage.getItem('pushnotification');
            if (pushnotification && pushnotification.length > 0) {
                localStorage.removeItem('pushnotification');
                var payload = JSON.parse(pushnotification);
                if (payload.data.page == 'messages') {
                    refreshList();
                    $rootScope.$broadcast('msgRead');
                    if (payload.data.contentid == vm.openedMessageTargyId) {
                        getMessages(vm.openedMessageTargyId);
                        setTimeout(function() {
                            $rootScope.$broadcast('msgRead');
                            $scope.$apply();
                        }, 200);
                    }
                }
            }
        }

        function toolbarClick(id) {
            switch (id) {
                case 'new_msg':
                    vm.newMessage = true;
                    vm.openedMessageTargyId = null;
                    vm.SelectedMessageDateSource = null;
                    addUser();
                    break;
                case 'del_msg':
                    if (!vm.openedMessageTargyId) {
                        log.errorMsg(gettextCatalog.getString('MsgSelectAMessage'));
                        return;
                    }
                    if (vm.topicStarter == vm.userid) {
                        log.errorMsg(gettextCatalog.getString('MsgDelMessagePermissionError'));
                        return;
                    }
                    swal({
                        title: gettextCatalog.getString('DoYouWantDelete'),
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('YesIWantDelete'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                    }).then(function (isConfirm) {
                        if (!vm.UZENETTORLES) {
                            log.errorMsg(gettextCatalog.getString('MsgDelMessagePermissionError'));
                            return;
                        }
                        if (isConfirm.value) {
                            dataservice.SikerMobileFreeDeleteMessages(vm.openedMessageTargyId).then(function () {
                                log.infoMsg('MessageMarkAsDeleted');
                                refreshList();
                            });
                            $timeout(function() {
                                vm.SelectedMessage = {};
                                vm.openedMessageTargyId = null;
                                vm.SelectedMessageDateSource = null;
                                $scope.$apply();
                            }, 0);
                        }
                    });
                    break;
                case 'unsub_msg':
                    dataservice.SikerMobileFreeLeaveConversation(vm.openedMessageTargyId).then(function() {
                        log.infoMsg('UnsubbedFromTopic');
                        refreshList();
                    });
                    break;
            }
        }

        function refreshList() {
            let pageNumber = vm.listPageNumber * vm.listItemsPerPage;
            dataservice.GetMessageGroups(1, pageNumber).then(function(data) {
                vm.dataMessages = data.itemsList;
                if (vm.dataMessages.some(elem => elem.TargyId == vm.openedMessageTargyId)) {
                    getRecipients(vm.openedMessageTargyId);
                }
                getUnreadMessageCount();
            });
        }

        vm.messageClicked = function (index) {
            vm.isBusy = false;
            vm.messagePageNumber = 1;
            vm.SelectedMessage = vm.dataMessages[index];
            vm.openedMessageTargyId = vm.SelectedMessage.TargyId;
            vm.selectedMessageIndex = index;
            addUserCancel();
            vm.recipients = vm.dataMessages[index].Kikkel;

            paramHelper.setParams([{
                'messageTargyId': vm.openedMessageTargyId
            }]);
            if (!vm.SelectedMessage.Olvasott) {
                dataservice.messageReaded(vm.SelectedMessage.Id).then(function () {
                    vm.SelectedMessage.Olvasott = true;
                    $rootScope.$broadcast('msgRead');
                });
            } else {
                setTimeout(function () {
                    $scope.$apply();
                }, 10);
            }
            $scope.files = [];
            $scope.imageDatas = [];
            vm.messageImages = [];
            getMessages(vm.openedMessageTargyId);
            $('.last').scrollTop = $('#message-detail').scrollHeight;
            $('#message-list').removeClass('widenMessages');
        };

        function getMessages(targyId) {
            getRecipients(targyId);
            dataservice.getMessages(targyId).then(function (result) {
                vm.SelectedMessageDateSource = result.itemsList;
                imageSet(vm.SelectedMessageDateSource);
                angular.element(document.querySelector('#message-detail')).bind('scroll', function () {
                    let objDiv = jQuery($('#message-detail'));
                    if (objDiv[0].scrollTop == 0 && vm.isBusy == false) {
                        vm.messagePageNumber += 1;
                        vm.isBusy = true;
                        loadMoreMessage();
                    }
                });
            });
            $('#addFile').val('');
            vm.messageImages = [];
            $scope.files = [];
            $scope.imageDatas = [];
        }

        vm.messageViewed = function (id) {
            dataservice.unreadedMessagesViewed(id).then(function () {
                log.infoMsg('MessageMarkedAsRead');
            });
        };

        vm.messageSend = function () {
            if (!vm.Message && $scope.files.length == 0) {
                vm.Message = '';
                return;
            } else if ($scope.files.length > 0 && vm.Message == '') {
                vm.Message = gettextCatalog.getString('picture') + '(' + $scope.files.length + ')';
            } else {
                vm.Message = vm.Message.trim();
            }

            if (!vm.openedMessageTargyId && !vm.newMessage) {
                log.errorMsg(gettextCatalog.getString('MsgSelectAMessage'));
            } else if (vm.newMessage) {
                if (!vm.Message || vm.Message == '' || vm.Message == null) {
                    if ($scope.files.length > 0) {
                        vm.Message = gettextCatalog.getString('picture') + '(' + $scope.files.length + ')';
                    } else {
                        vm.Message = '';
                        return;
                    }
                }
                if (!vm.tempRec || vm.tempRec.length == 0) {
                    log.errorMsg(gettextCatalog.getString('MsgSelectRecipient'));
                    return;
                }
                $('#addUser').css({'left': '100%'});
                $('#recipient-container').css({'left': '0%'});

                vm.Obj = {};
                vm.Obj.Ids = [];
                vm.Obj.base64_images = [];
                for (let i = 0; i < vm.tempRec.length; i++) {
                    vm.Obj.Ids.push(vm.tempRec[i].usersof);
                }
                if ($scope.files.length > 0) {
                    for (let i = 0; i < $scope.files.length; i++) {
                        vm.Obj.base64_images.push({
                            fileName: $scope.files[i].name,
                            fileData: $scope.imageDatas[i]
                        });
                    }
                }
                vm.Obj.Message = vm.Message;
                dataservice.SikerMobileFreeSendNewMessage(vm.Obj).then(function (result) {
                    log.successMsg('SendSuccessful');
                    vm.Message = null;
                    vm.openedMessageTargyId = result.id;
                    vm.SelectedMessageDateSource = null;
                    $('#addFile').val('');
                    vm.messageImages = [];
                    $scope.files = [];
                    $scope.imageDatas = [];
                    refreshList();
                    addUserCancel();
                    getMessages(result.id);
                });
            } else {
                if (!vm.Message || vm.Message == '' || vm.Message == null) {
                    if ($scope.files.length > 0 && vm.Message == '') {
                        vm.Message = 'Képek (' + $scope.files.length + ')';
                    } else {
                        vm.Message = '';
                        return;
                    }
                }
                if ($scope.files.length > 0) {
                    for (let i = 0; i < $scope.files.length; i++) {
                        vm.messageImages.push({
                            fileName: $scope.files[i].name,
                            fileData: $scope.imageDatas[i]
                        });
                    }
                }
                let parameters = {SubjectId: vm.openedMessageTargyId, Message: vm.Message, base64_images: vm.messageImages, recipients: vm.recipients, kicked: vm.kicked};
                vm.Message = '';
                dataservice.SikerMobileFreeSendMessage(parameters).then(function (response) {
                    if (response.retValue) {
                        log.successMsg(gettextCatalog.getString('SendSuccessful'));
                        getMessages(vm.openedMessageTargyId);
                        $('#addFile').val('');
                        $('#msgImg').css({'height': '0px'});
                        vm.selectedMessageIndex = 0;
                        vm.messageImages = [];
                        $scope.files = [];
                        $scope.imageDatas = [];
                        refreshList();
                    }
                });
            }
        };

        vm.finished = function () {
            let objDiv = $('#message-detail');
            // ne kerülön vissza a
            if (vm.messagePageNumber == 1) {
                objDiv[0].scrollTop = objDiv[0].scrollHeight;
            }
        };

        function loadMoreListItem() {
            dataservice.GetMessageGroups(vm.listPageNumber).then(function (resp) {
                if (resp.itemsList.length == 0) {
                    vm.listPageNumber -= 1;
                    angular.element(document.querySelector('#message-list')).unbind('scroll');
                    return;
                }
                resp.itemsList.forEach(function (item) {
                    vm.dataMessages.push(item);
                });
                $timeout(function () {
                    $scope.$apply();
                }, 0);
            });
        }

        function loadMoreMessage() {
            dataservice.getMessages(vm.openedMessageTargyId, vm.messagePageNumber, vm.itemsPerMessage).then(function (resp) {
                if (resp.itemsList.length == 0) {
                    vm.listPageNumber += 1;
                    angular.element(document.querySelector('#message-detail')).unbind('scroll');
                    return;
                }
                let objDivBefore = $('#message-detail');
                let rowSizeBeforeLoadMore = objDivBefore[0].scrollHeight - objDivBefore[0].clientHeight;
                resp.itemsList.reverse().forEach(function (item) {
                    vm.SelectedMessageDateSource.unshift(item);
                });

                $timeout(function () {
                    vm.isBusy = false;
                    let objDivAfter = $('#message-detail');
                    let rowSizeAfterLoadMore = objDivAfter[0].scrollHeight - objDivAfter[0].clientHeight;
                    objDivAfter[0].scrollTop = rowSizeAfterLoadMore - rowSizeBeforeLoadMore;
                    $scope.$apply();
                }, 0);
                imageSet(vm.SelectedMessageDateSource);
            });
        }

        function imageSet(array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i]['images'] != null) {
                    for (let j = 0; j < array[i]['images'].length; j++) {
                        array[i]['images'][j] = array[i]['images'][j].replace('_thumb', '');
                    }
                }
            }
            $timeout(function () {
                $('.message-wrapper').each(function () {
                    $(this).magnificPopup({
                        delegate: 'a',
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    });
                });
            }, 100);
        }

        function getRecipients(subjectId) {
            dataservice.GetRecipientsBySubjectId(subjectId).then(function (response) {
                vm.recipients = response.itemsList;
                vm.topicStarter = response.admin;
                vm.recipientsId = [];
                vm.kicked = [];
                vm.tempRec = [];
                vm.messageImages = [];
                $scope.files = [];
                $scope.fileData = [];
                vm.newMessage = false;
                for (let i = 0; i < vm.recipients.length; i++) {
                    vm.recipientsId.push(vm.recipients[i].value);
                }
            });
        }

        function deleteAttachedImg(index) {
            $scope.files.splice(index, 1);
            $scope.imageDatas.splice(index, 1);
            if ($scope.files.length == 0) {
                $('#msgImg').css({'height': '0px'});
                maxSize();
                $('#addFile').val('');
            }
        }

        function removeUser(index) {
            if (vm.topicStarter != vm.userid) {
                log.errorMsg(gettextCatalog.getString('MsgModifyRecipientPermission'));
                return;
            }
            if (vm.recipients.length < 2) {
                vm.toolbarClick('del_msg');
                return;
            }
            let id = vm.recipients[index].value;
            let name = vm.recipients[index].text;
            swal({
                title: gettextCatalog.getString('MsgModifyRecipientAsk'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('MsgModifyRecipientYes'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.kicked.push(id);
                    vm.recipientsId.splice(index, 1);
                    let msg = name + ' ' + gettextCatalog.getString('MsgRemovedRecipientFromConv');
                    let parameters = {SubjectId: vm.openedMessageTargyId, Message: msg, recipients: vm.recipientsId, kicked: vm.kicked};
                    dataservice.SikerMobileFreeSendMessage(parameters).then(function (response) {
                        if (response.retValue) {
                            vm.Message = null;
                            vm.kicked = [];
                            $('#msgImg').css({'height': '0px'});
                            maxSize();
                            getMessages(vm.openedMessageTargyId);
                        }
                    });
                }
            });
        }

        function addUser() {
            $('#addUser').css({'left': '0%'});
            $('#recipient-container').css({'left': '-100%'});
            if (window.innerWidth < 768) {
                $('#recipients-wrapper').delay(500).animate({'top': '102px'}, 500);
            }
        }

        function selectUser(index) {
            if (vm.tempRec.includes(index)) {
                vm.tempRec.splice(vm.tempRec.indexOf(index), 1);
            } else {
                vm.tempRec.push(index);
            }
        }

        function addUserDone() {
            if (vm.tempRec.length < 1) {
                addUserCancel();
                return;
            }
            if (!vm.newMessage) {
                swal({
                    title: gettextCatalog.getString('MsgModifyRecipientAsk'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('MsgModifyRecipientYes'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        $('#addUser').css({'left': '100%'});
                        $('#recipient-container').css({'left': '0%'});
                        let msg = '';
                        for (let i = 0; i < vm.tempRec.length; i++) {
                            vm.recipientsId.push(vm.tempRec[i].value);
                            if (vm.tempRec.length - 1 !== i) {
                                msg += vm.tempRec[i].text + ', ';
                            } else {
                                msg += vm.tempRec[i].text + ' ';
                            }
                        }
                        vm.tempRec = [];
                        msg += ' ' + gettextCatalog.getString('MsgRecipientsAdded');
                        let parameters = {SubjectId: vm.openedMessageTargyId, Message: msg, recipients: vm.recipientsId, kicked: vm.kicked};
                        dataservice.SikerMobileFreeSendMessage(parameters).then(function (response) {
                            if (response.retValue) {
                                vm.Message = null;
                                $('#msgImg').css({'height': '0px'});
                                maxSize();
                                getMessages(vm.openedMessageTargyId);
                            }
                        });
                    }
                });
            }
        }

        function addUserCancel() {
            if (window.innerWidth < 768) {
                $('#recipients-wrapper').animate({'top': '-360px'}, 500, function() {
                    $('#addUser').css({'left': '100%'});
                    $('#recipient-container').css({'left': '0'});
                });
            } else {
                $('#addUser').css({'left': '100%'});
                $('#recipient-container').css({'left': '0'});
            }
            $('#addUser div ul').slideUp(750);
            vm.newMessage = false;
            vm.tempRec = [];
            vm.results = [];
            vm.searchInput = '';
        }

        function deleteImage(imgPath, feladoId) {
            if (vm.userid != feladoId) {
                log.errorMsg(gettextCatalog.getString('MsgDeleteImagePermission'));
                return;
            }
            swal({
                title: gettextCatalog.getString('MsgDeleteImageAsk'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.DeleteMessageImage(JSON.stringify(imgPath)).then(function() {
                        getMessages(vm.openedMessageTargyId);
                        refreshList();
                    });
                }
            });
        }

        function searchUsers(key) {
            vm.results = [];
            let regex = new RegExp(key, 'gi');
            vm.recipientsList.forEach(function(item) {
                if (item.text.match(regex)) {
                    vm.results.push(item);
                }
            });
        }

        function maxSize() {
            let height = $('html').height();
            if (window.innerWidth < 768) {
                $('#mainPage-message-wrapper').height(height - 82);
                $('#message-detail').height(height - 175);
                $('#recipients-wrapper').css({'top': '-360px'});
            } else {
                $('#mainPage-message-wrapper').height(height - 50);
                $('#message-detail').height(height - 145);
                $('#recipients-wrapper').css({'top': '0'});
            }
        }

        function recipientsListSm(action) {
            if (action == 'open') {
                $('#recipients-wrapper').animate({'top': '102px'});
            } else {
                $('#recipients-wrapper').animate({'top': '-360px'});
            }
        }

        function getUnreadMessageCount() {
            dataservice.GetUnreadMessageCount().then(function(data) {
                vm.unreadCount = data.res;
            });
        };

        function userFilter(id) {
            return vm.recipients.some(elem => elem.value == id);
        }

        // EVENTEK
        // full címjegyzék eventek
        $('#addUser div').on('click', function() {
            if ($(this).children('ul').css('display') !== 'none') {
                $(this).children('ul').slideUp(750);
            } else {
                $('#addUser div ul').slideUp(750);
                $(this).children('ul').slideDown(750);
            }
        });
        $('#addUser div ul').on('click', function(e) {
            e.stopPropagation();
        });

        // csatolt képek megjelenítése küldés előtt
        $('#addFile').on('change', function() {
            if ($scope.files.length == 0) {
                $('#msgImg').css({'height': '60px'});
            }
        });

        window.addEventListener('resize', () => {
            maxSize();
        });

        window.addEventListener('keypress', (e) => {
            if (e.key == 'Enter') {
                e.preventDefault();
                vm.messageSend();
            }
        });
    }
})();
