(function () {
    'use strict';

    angular
        .module('app.administration.monthlyTrainingPlan.monthlyTrainingPlanReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.monthlyTrainingPlan.monthlyTrainingPlanReport',
                config: {
                    url: '/monthlyTrainingPlanReport',
                    templateUrl: 'app/administration/monthlyTrainingPlan/monthlyTrainingPlanReport/monthlyTrainingPlanReport.html',
                    controller: 'MonthlyTrainingPlanReportController',
                    controllerAs: 'vm'
                }
            }
        ];
    }
})();
