(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('httpInterceptor', httpInterceptor);

    function httpInterceptor(logger, $q, $rootScope, AUTH_EVENTS, DOMAIN, idleService, loader, localStorageService, $injector) {
        function Check(r, type) {
            // console.log('Check',r,type);
            switch (type){
                case 'request':
                    if (r && r.url && r.url.indexOf('LogClientSideException') === -1) {
                        return true;
                    }
                    break;
                case 'response':
                    return true;
                    break;
                case 'responseError':
                    return true;
                    break;
            }
            return false;
        }

        // Szerver oldali hibánál elküldi a kliens oldalon lévő adatokat is
        function LogServerSideException(ServerSideException, $injector) {
            if (!ServerSideException || ServerSideException == null || (ServerSideException && ServerSideException.toString().indexOf('LogClientSideException') !== -1)) {
                return;
            }
            var object = {
                ServerSideException: ServerSideException,
                vm: null,
                stateList: null,
                actualState: null,
                localStorage: null,
                title: null
            };
            var rootScope = $injector.get('$rootScope');
            var LS = $injector.get('localStorageService');
            var keys = LS.keys();
            var LSItems = [];
            keys.forEach(function (element) {
                LSItems.push(element + ': ' + LS.get(element));
            });
            object.localStorage = LSItems;
            object.actualState = rootScope.actualState;
            object.stateList = rootScope.stateList;
            object.title = rootScope.htmlTitle;
            object.ClientSideException = false;
            var cloneVM = $.extend(true, {}, rootScope.vm);
            var cloneVMkeys = Object.keys(cloneVM);
            cloneVMkeys.forEach(function (vmKey) {
                try {
                    JSON.stringify(cloneVM[vmKey]);
                }
                catch (err) {
                    cloneVM[vmKey] = null;
                }
            });
            object.vm = cloneVM;
            var jsonObject = JSON.stringify(object);
            var dataService = $injector.get('dataservice');
            dataService.sendLocalException(jsonObject).then(function () {});
        }

        function processRequest() {
            loader.delayedStart();
        }

        function processResponse() {
            loader.end();
        }

        return {
            request: function (config) {
                if (Check(config, 'request')) {
                    processRequest();
                }
                if (config.type === 'apiGET') {
                    config.method = 'GET';
                    config.headers['Content-Type'] = 'application/json; charset-utf-8';
                    var authData = localStorageService.get('_authData');
                    if (authData) {
                        config.headers.Authorization = 'Bearer ' + authData.access_token;
                    }
                    //config.timeout = 20000;
                } else if (config.type === 'apiPOST') {
                    config.method = 'POST';
                    config.headers['Content-Type'] = 'application/json; charset-utf-8';
                    var authData = localStorageService.get('_authData');
                    if (authData) {
                        config.headers.Authorization = 'Bearer ' + authData.access_token;
                    }
                    //config.timeout = 20000;
                } else if (config.type === 'apiTOKEN') {
                    config.method = 'POST';
                    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                    //config.timeout = 20000;
                }
                if (config.timeout === 'undefined') {
                    config.timeout = 20000;
                }
                return config;
            },
            requestError: function () {
                processResponse();
            },
            response: function (response) {
                // console.log('=== response response ===',response);
                if (response.config.url.indexOf(DOMAIN.webService) >= 0) {
                    idleService.resetTimer();
                }
                if (response.data && response.data.error !== undefined) {
                    if (response.data.message) {
                        logger.info(response.data.message);
                    }
                    if (response.data.validationErrors) {
                        if (Check(response, 'response')) {
                            processResponse();
                        }
                        return $q.reject(response);
                        // return $q.resolve(response);
                    }
                    if (response.data.error !== '') {
                        if (response.data.error) {
                            response.data.error = response.data.error === '' ? 'Unexpected error occurred. Please try again, later.' : response.data.error;
                            logger.error(response.data.error, 'Error', response);
                        } else {
                            logger.error('Unexpected error occurred. Please try again, later.', 'Error', response);
                        }
                        if (Check(response, 'response')) {
                            processResponse();
                        }
                        return $q.reject(response);
                        // return $q.resolve(response);
                    } else {
                        if (Check(response, 'response')) {
                            processResponse();
                        }
                        return $q.resolve(response.data);
                    }
                }
                if (Check(response, 'response')) {
                    processResponse();
                }
                return response || $q.when(response);
            },
            responseError: function (rejection) {
                // console.log('=== responseError rejection ===',rejection);
                if (rejection.config && rejection.config.url.indexOf(DOMAIN.webService) >= 0) {
                    idleService.resetTimer();
                }
                if (rejection.status === 401) {
                    $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
                } else if (rejection.status === 404) {
                    $rootScope.$broadcast();
                } else if (rejection.data) {
                    if (rejection.data.error) {
                        rejection.data.error = rejection.data.error === '' ? 'Unexpected error occurred. Please try again, later.' : rejection.data.error;
                        logger.error(rejection.data.error, 'Error', rejection);
                    } else if (rejection.data.ExceptionMessage) {
                        logger.error(angular.isDefined(rejection.data.ExceptionMessage) ? rejection.data.ExceptionMessage : '', 'Error', rejection);
                    } else if (rejection.data.Message) {
                        logger.error((angular.isDefined(rejection.data.Message) ? rejection.data.Message : '') + ' ' + (angular.isDefined(rejection.data.MessageDetail) ? rejection.data.MessageDetail : ''), 'Error', rejection);
                    } else {
                        logger.error('Unexpected error occurred. Please try again, later.', 'Error', rejection);
                    }
                } else {
                    logger.error('Unexpected error occurred. Please try again, later.', 'Error', rejection);
                }

                if (Check(rejection, 'responseError')) {
                    processResponse();
                }

                if (rejection.status != -1 && (rejection && rejection.data && rejection.data.ExceptionType != 'System.Exception')) {
                    LogServerSideException(rejection.data.ExceptionMessage, $injector);
                }

                return $q.reject(rejection);
                // return $q.resolve(rejection); // Angular > 1.5.8
            }
        };
    }
})();
