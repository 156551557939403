(function () {
    'use strict';

    angular
        .module('app.business.sportServiceFeeArrears')
        .controller('SportServiceFeeArrearsController', SportServiceFeeArrearsController);

    function SportServiceFeeArrearsController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, $scope, authenticationService, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('FeeArrears');

        // Create variables
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.coachList = [];
        vm.yearList = dataservice.range(2000, new Date().getFullYear());
        vm.priceType = [{text: 'NK', value: 'NK'}, {text: 'K', value: 'K'}, {text: 'B', value: 'B'}, {
            text: 'EP',
            value: 'EP'
        }, {text: 'MT', value: 'MT'}];

        vm.selectedCoach = vm.coachId = 0;
        vm.selectedSection = 0;

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'OsszesHatralek',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_1',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_2',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_3',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_4',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_5',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_6',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_7',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_8',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_9',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_10',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_11',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Honap_12',
                    groupCaptionTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0'
                }
            ]
        }];

        vm.rowIndexUnderEditing = -1;
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {
                headerText: gettextCatalog.getString('General'), columns: [
                    {field: 'Ev', headerText: gettextCatalog.getString('Year'), width: 80},
                    {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 150},
                    {
                        field: 'EdzoNeve',
                        headerText: gettextCatalog.getString('CoachName'),
                        textAlign: 'left',
                        width: 150,
                        template: '#feearrearscolumnTemplate2'
                    },
                    {
                        field: 'VersenyzoNeve',
                        headerText: gettextCatalog.getString('Name'),
                        textAlign: 'left',
                        width: 150,
                        template: '#feearrearscolumnTemplate1'
                    },
                    {
                        field: 'Dsi_Igazolas',
                        headerText: gettextCatalog.getString('Regisztracios'),
                        textAlign: 'left',
                        width: 165
                    },
                ], textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('Summary'), columns: [
                    {
                        field: 'OsszesHatralek',
                        headerText: gettextCatalog.getString('SumArrears'),
                        textAlign: 'left',
                        width: 150,
                        format: 'N0'
                    },
                ], textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('Month'), columns: [
                    {field: 'Honap_1', headerText: gettextCatalog.getString('January'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_2', headerText: gettextCatalog.getString('February'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_3', headerText: gettextCatalog.getString('March'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_4', headerText: gettextCatalog.getString('April'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_5', headerText: gettextCatalog.getString('May'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_6', headerText: gettextCatalog.getString('June'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_7', headerText: gettextCatalog.getString('July'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_8', headerText: gettextCatalog.getString('August'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_9', headerText: gettextCatalog.getString('September'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_10', headerText: gettextCatalog.getString('October'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_11', headerText: gettextCatalog.getString('November'), textAlign: 'left', width: 125, format: 'N0'},
                    {field: 'Honap_12', headerText: gettextCatalog.getString('December'), textAlign: 'left', width: 125, format: 'N0'}
                ], textAlign: 'center'
            }
        ];
        var grid = new ej2.grids.Grid({
            columns: vm.columns,
            dataSource: [],
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowGrouping: true,
            groupSettings: {columns: ['SzakosztalyNeve', 'EdzoNeve']},
            toolbar: $rootScope.toolbarItemsWithSearch,
            pageSettings: {pageCount: 5, pageSize: 2000, pageSizes: [4, 8, 20, 100, 2000]},
            editSettings: {allowEditing: false},
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'searching' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.getAthletes = getAthletes;
        vm.yearDataBound = yearDataBound;
        vm.yearSelect = yearSelect;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        // Activate page
        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['sportServiceFeeArrears.sectionId', 'sportServiceFeeArrears.year', 'sportServiceFeeArrears.coachId']), authenticationService.getRight('MEGTTAGDHATR')];
            $q.all(promises).then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.defaultsectionId = results[0]['sportServiceFeeArrears.sectionId'];
                    vm.defaultYear = results[0]['sportServiceFeeArrears.year'];
                    // angular.element('#yearDropDownList').ejDropDownList('selectItemByValue', results[0]['sportServiceFeeArrears.year']);
                    vm.year = vm.selectedYear = results[0]['sportServiceFeeArrears.year'];
                    vm.defaultCoachId = results[0]['sportServiceFeeArrears.coachId'];
                    if (vm.defaultsectionId != null && vm.defaultCoachId != null && vm.year != null) {
                        $timeout(function () {
                            getAthletes(results[0]['sportServiceFeeArrears.sectionId'], results[0]['sportServiceFeeArrears.coachId'], results[0]['sportServiceFeeArrears.year']);
                        }, 100);
                    }
                    getcoaches();
                    getSections();
                }
            });
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getcoaches() {
            return dataservice.coachSelect(vm.sectionId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.coachList = data.itemsList;
            });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.defaultCoachId);
                vm.coachId = vm.defaultCoachId;
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            getSections();
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(vm.coachId, true).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSection = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.defaultsectionId);
                vm.selectedSection = vm.defaultsectionId;
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.ReducedSportServiceFee = vm.sectionList[args.itemId].Tagdij !== null ? vm.sectionList[args.itemId].Tagdij : 0;
            vm.SportServiceFee = vm.sectionList[args.itemId].KedvezmenyesTagdij !== null ? vm.sectionList[args.itemId].KedvezmenyesTagdij : 0;
            vm.sectionId = args.value;
        }

        function yearDataBound() {
            if (vm.yearList.length === 1) {
                angular.element('#yearDropDownList').ejDropDownList('selectItemByValue', vm.yearList[0].value);
                vm.year = vm.yearList[0].value;
            }
            vm.year = new Date().getFullYear();
        }

        function yearSelect(args) {
            vm.year = args.value;
        }

        function getAthletes(sectionId, coachId, year) {
            if (arguments.length == 3) {
                vm.sectionId = sectionId;
                vm.coachId = coachId;
                vm.year = year;
            } else {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                    return;
                }
            }
            paramHelper.setParams([{'sportServiceFeeArrears.sectionId': vm.sectionId}, {'sportServiceFeeArrears.year': vm.year}, {'sportServiceFeeArrears.coachId': vm.coachId}]);
            dataservice.getAthletesForFeeArrears(vm.sectionId, vm.coachId, vm.year).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
