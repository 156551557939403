(function () {
    'use strict';

    angular
        .module('app.settings.editSection')
        .controller('EditSectionController', EditSectionController);

    function EditSectionController(log, $q, paramHelper, dataservice, $rootScope, gettextCatalog, authenticationService, $state, $scope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;

        $rootScope.title = '';
        vm.sectionLeaders = [];
        vm.sectionLeaders2 = [];
        vm.editSectionId = 0;
        vm.leader1DataBound = leader1DataBound;
        vm.leader2DataBound = leader2DataBound;
        vm.save = save;

        vm.section = {
            Aktiv: true,
            Csapat: false,
            Egyeni: false,
            Editable: true,
            SzakosztalyID: -1,
            SzakosztalyNeve: '',
            SzakosztalyVezeto1: '',
            SzakosztalyVezeto2: '',
            SzakosztalyVezetoId1: null,
            SzakosztalyVezetoId2: null,
            Szin: '#FFFFFF',
            SzovegSzin: '#000000',
            RuhaPapucs: false,
            RuhaCipo: false,
            RuhaMelegito: false,
            RuhaFurdokopeny: false,
            RuhaKabat: false,
            RuhaPancelnadrag: false,
            RuhaPolo: false,
            RuhaSort: false,
            RuhaUszonadrag: false
        };

        activate();

        function activate() {
            var promises = [
                paramHelper.getParams([], ['editSection.SectionId']),
                authenticationService.getRight('SZAKOSZTALY'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('ADMIN'),
                getSectionLeaders()
            ];
            $q.all(promises).then(function (results) {
                if (results[1] !== true || (results[2] !== true && results[3] !== true)) {
                    console.log('permissionError');
                    log.permissionError(true);
                    return;
                }
                vm.editSectionLeader = results[2] || results[3];
                vm.editSectionId = results[0]['editSection.SectionId'];
                if (vm.editSectionId > 0) {
                    $rootScope.title = gettextCatalog.getString('EditSection');
                    getSectionData(vm.editSectionId);
                } else {
                    $rootScope.title = gettextCatalog.getString('AddSection');
                }
            });
        }

        function leader1DataBound() {
            if (vm.section.SzakosztalyVezetoId1 > 0) {
                angular.element('#leader1DropDown').ejDropDownList('selectItemByValue', vm.section.SzakosztalyVezetoId1);
            } else {
                angular.element('#leader1DropDown').ejDropDownList('selectItemByValue', vm.sectionLeaders[0].value);
            }
        }

        function leader2DataBound() {
            if (vm.section.SzakosztalyVezetoId2 > 0) {
                angular.element('#leader2DropDown').ejDropDownList('selectItemByValue', vm.section.SzakosztalyVezetoId2);
            } else {
                angular.element('#leader2DropDown').ejDropDownList('selectItemByValue', vm.sectionLeaders2[0].value);
            }
        }

        function getSectionLeaders() {
            dataservice.getSectionLeaders().then(function (response) {
                vm.sectionLeaders = response.itemsList;
                vm.sectionLeaders2 = [];
                vm.sectionLeaders2.push({value: -1, text: gettextCatalog.getString('None')});
                vm.sectionLeaders.forEach(function (section) {
                    vm.sectionLeaders2.push(section);
                });
            });
        }

        function getSectionData(id) {
            dataservice.getSectionData(id).then(function (response) {
                vm.section = response.itemsList[0];
                $timeout(function () {
                    if (!(vm.section.SzakosztalyVezetoId2 > 0)) {
                        angular.element('#leader2DropDown').ejDropDownList('selectItemByValue', -1);
                    }
                }, 333);
            });
        }

        function save() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (!(vm.section.Egyeni || vm.section.Csapat)) {
                log.htmlError(gettextCatalog.getString('PleaseSelectIndividualOrTeamSport'));
                return;
            }
            if (!vm.section.SzakosztalyNeve || vm.section.SzakosztalyNeve.trim().length < 4) {
                log.errorMsg(gettextCatalog.getString('MinimumLength4Character'), gettextCatalog.getString('PleaseTypeSectionName'));
                return;
            }
            dataservice.editSection(vm.section).then(function (result) {
                if (result.data.Error && result.data.Error.length > 0) {
                    if (result.data.Error != null) {
                        log.htmlError(result.data.Error.toString());
                        return;
                    }
                }
                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                $state.go('athletes.sections');
            });
        }
    }
})();
