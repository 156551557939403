(function () {
    'use strict';

    angular
        .module('app.administration.competitiveMonthlyPlan.competitiveMonthlyPlanList')
        .controller('CompetitiveMonthlyPlanListController', CompetitiveMonthlyPlanListController);

    function CompetitiveMonthlyPlanListController(dataservice, log, $q, $state, gettextCatalog, paramHelper, $scope, authenticationService, $filter, $timeout, $rootScope) {
        var vm = this;

        $rootScope.title = gettextCatalog.getString('CompetitiveMonthlyPlanList');
        //grid adatai
        vm.data = [];
        vm.columns = [];

        vm.selectedCoach = undefined;
        vm.coachList = [];
        vm.selectedSection = undefined;
        vm.selectedYear = new Date().getFullYear();
        vm.allowAddNew = {
            team: false,
            individual: false
        };
        vm.sectionList = [];
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev);
        }
        vm.refreshData = refreshData;

        vm.openText = gettextCatalog.getString('Open');

        // Public functions
        vm.coachChanged = coachChanged;
        vm.yearChanged = yearChanged;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionChanged = sectionChanged;
        vm.goToNewUser = goToNewUser;
        vm.goToCompetitiveMonthlyPlan = goToCompetitiveMonthlyPlan;
        vm.goToNewCompetitiveMonthlyPlan = goToNewCompetitiveMonthlyPlan;
        vm.versernytervTorlese = versernytervTorlese;
        var coachId = -1;
        var sectionId = -1;
        var year = -1;

        vm.columns = [{
            field: 'Id',
            visible: false,
            isPrimaryKey: true,
            isIdentity: true
        }, {
            field: 'Link',
            headerText: '',
            template: '#openDeleteColumnTemplate',
            textAlign: 'center',
            allowEditing: false,
            width: 70
        }, {
            field: 'Edzo',
            headerText: gettextCatalog.getString('Coach'),
            textAlign: 'left',
            allowEditing: false,
            template: '#coachColumnTemplate',
            width: 200
        }, {
            field: 'Szakosztaly',
            headerText: gettextCatalog.getString('Section'),
            textAlign: 'left',
            allowEditing: false,
            width: 150
        }, {
            field: 'Datum',
            headerText: gettextCatalog.getString('Date'),
            textAlign: 'left',
            allowEditing: false,
            width: 120
        }, {
            field: 'Tipus',
            headerText: gettextCatalog.getString('Type'),
            textAlign: 'left',
            allowEditing: false,
            width: 120
        }, {
            field: 'Lezart',
            headerText: gettextCatalog.getString('Closed'),
            textAlign: 'center',
            allowEditing: false,
            displayAsCheckBox: true,
            type: 'boolean',
            width: 120
        }, {
            field: 'Elfogadott',
            headerText: gettextCatalog.getString('Accepted'),
            textAlign: 'center',
            allowEditing: false,
            displayAsCheckBox: true,
            type: 'boolean',
            width: 120
        }, {
            field: 'Megjegyzes',
            headerText: gettextCatalog.getString('Megjegyzes'),
            textAlign: 'left',
            allowEditing: false,
            width: 200
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            },
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['competitiveMonthlyPlan.edzoId', 'competitiveMonthlyPlan.szakosztalyId', 'competitiveMonthlyPlan.korosztalyId', 'competitiveMonthlyPlan.year']),
                getCoaches(),
                authenticationService.getRight('UJHAVIVERST'),
                authenticationService.getRight('HAVIVERSTALAIR1'),
                authenticationService.getRight('HAVIVERSTALAIR2'),
                authenticationService.getRight('MEGTHAVIVERST'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('MEGTKOROSZT')
            ];

            return $q.all(promises).then(function (results) {
                angular.element('#maEdzo').ejDropDownList({
                    dataSource: vm.coachList
                });
                if (vm.coachList.length === 1) {
                    vm.selectedCoach = vm.coachList[0].value;
                }
                vm.UJHAVIVERST = results[2];
                vm.HAVIVERSTALAIR1 = results[3];
                vm.HAVIVERSTALAIR2 = results[4];
                vm.MEGTHAVIVERST = results[5];
                vm.MEGTFELH = results[6];
                vm.MEGTKOROSZT = results[7];
                if (!vm.MEGTHAVIVERST) {
                    log.permissionError(true);
                    return;
                }
                if (results[0]['competitiveMonthlyPlan.edzoId']) {
                    vm.selectedCoach = results[0]['competitiveMonthlyPlan.edzoId'];
                }
                if (results[0]['competitiveMonthlyPlan.szakosztalyId']) {
                    vm.selectedSection = results[0]['competitiveMonthlyPlan.szakosztalyId'];
                }
                if (results[0]['competitiveMonthlyPlan.year']) {
                    vm.selectedYear = results[0]['competitiveMonthlyPlan.year'];
                }
                if (vm.selectedCoach || vm.selectedSection || vm.selectedYear) {
                    refreshData();
                }
            });
        }

        function versernytervTorlese(id, SzakosztalyId) {
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected competitive monthly plan?'),
                text: gettextCatalog.getString('You will not be able to recover the plan!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this competitive monthly plan'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    return dataservice.VersenyTervHaviDeleteById(id, SzakosztalyId).then(
                        function () {
                            refreshData();
                        }
                    );
                }
            });
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    return vm.coachList;
                }
            );
        }

        function yearChanged(args) {
            vm.selectedYear = args.value;
            if (vm) {
                refreshData();
            }
            paramHelper.setParams([{'competitiveMonthlyPlan.year': vm.selectedYear}]);
        }

        function coachChanged(args) {
            vm.selectedCoach = args.value;
            vm.sectionList = [];
            vm.ageGroupList = [];
            paramHelper.setParams([{'competitiveMonthlyPlan.edzoId': vm.selectedCoach}]);
            return dataservice.sectionDropDownListWithGroupStatus(vm.selectedCoach).then(
                function (data) {
                    vm.sectionList = data.itemsList;
                }
            );
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSection = vm.sectionList[0].value;
            } else if (vm.selectedSection !== undefined) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function sectionChanged(args) {
            vm.selectedSection = args.value;
            vm.allowAddNew = vm.sectionList.find(function (element) {
                return element.value === args.value;
            });
            vm.ageGroupList = [];
            vm.selectedAgeGroup = 0;
            refreshData();
            paramHelper.setParams([{'competitiveMonthlyPlan.szakosztalyId': vm.selectedSection}]);
        }

        function refreshData() {
            if (vm.selectedSection && vm.selectedCoach !== 0 && vm.selectedYear !== 0) {
                sectionId = vm.selectedSection;
                coachId = vm.selectedCoach;
                year = vm.selectedYear;
            } else {
                return;
            }
            dataservice.getCompetitiveMonthlyPlanList(coachId, sectionId, year).then(
                function (data) {
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Honap');
                    $timeout(function () {
                        vm.data = data.itemsList;
                        grid.dataSource = vm.data;
                        $scope.$apply();
                    }, 0);
                }
            );
        }

        function goToNewUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'competitiveMonthlyPlan.edzoId': vm.selectedCoach
            }, {
                'competitiveMonthlyPlan.szakosztalyId': vm.selectedSection
            }, {
                'newUser.userId': edzoId
            }]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToCompetitiveMonthlyPlan(competitivePlanId) {
            if (!vm.MEGTHAVIVERST) {
                log.permissionError();
                return;
            }

            var elem = vm.data.filter(function (a) {
                return a.Id == competitivePlanId;
            });
            if (elem.length === 0) {
                return;
            }

            paramHelper.setParams([{
                'competitiveMonthlyPlan.edzoId': elem[0].EdzoId
            }, {
                'competitiveMonthlyPlan.edzoNev': elem[0].Edzo
            }, {
                'competitiveMonthlyPlan.szakosztalyNev': elem[0].Szakosztaly
            }, {
                'competitiveMonthlyPlan.szakosztalyId': elem[0].SzakosztalyId
            }, {
                'competitiveMonthlyPlan.competitivePlanId': elem[0].Id
            },
                {'competitiveMonthlyPlan.egyeni': elem[0].Egyeni}
            ]);
            $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport');
        }

        function goToNewCompetitiveMonthlyPlan(arg) {
            if (!vm.UJHAVIVERST) {
                log.permissionError();
                return;
            }
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            var egyeni = (arg == 'egyeni');
            var datum = new Date();
            dataservice.ujHaviVersenyeztetesiTerv(datum, vm.selectedSection, vm.selectedCoach, egyeni).then(function (result) {
                datum = result.data;
                paramHelper.setParams([
                    {'competitiveMonthlyPlan.edzoId': vm.selectedCoach},
                    {'competitiveMonthlyPlan.edzoNev': angular.element('#maEdzo').data('ejDropDownList').getSelectedItem()[0].innerText},
                    {'competitiveMonthlyPlan.szakosztalyNev': angular.element('#maSzakosztaly').data('ejDropDownList').getSelectedItem()[0].innerText},
                    {'competitiveMonthlyPlan.szakosztalyId': vm.selectedSection},
                    {'competitiveMonthlyPlan.competitivePlanId': -1},
                    {'competitiveMonthlyPlan.egyeni': egyeni},
                    {'competitiveMonthlyPlan.datum': datum}
                ]);
                $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport');
            });
        }
    }
})();
