(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports.annualPresenceReportsBySportSchool')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.annualPresenceReports.annualPresenceReportsBySportSchool',
                config: {
                    url: '/annualPresenceReportsBySportSchool',
                    templateUrl: 'app/reports/annualPresenceReports/annualPresenceReportsBySportSchool/annualPresenceReportsBySportSchool.html',
                    controller: 'AnnualPresenceReportsBySportSchoolController',
                    controllerAs: 'vm',
                    title: 'AnnualPresenceReportsBySportSchool',
                    settings: {
                        navId: 32234,
                        level: 3,
                        order: 2,
                        orderTitle_hu: 'Éves jelenléti kimutatás edzőnként',
                        orderTitle_en: 'Annual Attendance Reports by Coaches',
                        parentId: 224,
                        content: 'AnnualPresenceReportsBySportSchool',
                        activatorPermission: '"KMEVESJELSZO"'
                    }
                }
            }
        ];
    }
})();
