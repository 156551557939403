(function () {
    'use strict';

    angular
        .module('app.administration.trainingPlan.trainingPlanCreate')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.trainingPlan.trainingPlanCreate',
                config: {
                    url: '/trainingPlanCreate',
                    templateUrl: 'app/administration/trainingPlan/trainingPlanCreate/trainingPlanCreate.html',
                    controller: 'TrainingPlanCreateController',
                    controllerAs: 'vm',
                    title: 'TrainingPlanCreate'
                }
            }
        ];
    }
})();
