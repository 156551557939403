(function () {
    'use strict';

    angular
        .module('app.business.checkPayment')
        .controller('CheckPaymentController', CheckPaymentController);

    function CheckPaymentController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CheckPayment');

        // Create variables

        vm.data = [];
        vm.dataId = [];
        vm.payment = '0';
        vm.checkNumber = '';
        vm.hasCheck = false;
        vm.selectedRecords = {};

        // Grid's variables
        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'Osszeg',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                }
            ]
        }];
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'Versenyzo', headerText: gettextCatalog.getString('Name'), textAlign: 'left', width: 100},
            {field: 'Edzo', headerText: gettextCatalog.getString('Coach'), width: 100},
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), width: 100},
            {field: 'Osszeg', headerText: gettextCatalog.getString('PaidAmount'), textAlign: 'center', width: 100, format: 'N0'}
        ];

        // Public functions
        vm.getCheck = getCheck;
        vm.pay = pay;
        vm.save = save;
        vm.removePayment = removePayment;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        grid.dataSource = vm.data;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('CSEKKEXPORT')];
            $q.all(promises).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                }
            });
        }

        function getCheck() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getCheck(vm.checkNumber).then(function(data) {
                vm.hasCheck = true;
                vm.actualPayment = data;
                vm.actualPayment.Osszeg = vm.payment;
            });
        }

        function pay() {
            if (vm.dataId.indexOf(vm.actualPayment.Id) === -1) {
                vm.data.push(vm.actualPayment);
                vm.dataId.push(vm.actualPayment.Id);
                grid.refresh();
            }
        }

        function removePayment() {
            var id = grid.getSelectedRecords()[0].Id;
            grid.dataSource = $rootScope.deleteRows(grid);
            var i = vm.dataId.indexOf(id);
            if (i !== -1) {
                vm.dataId.splice(i, 1);
            }
        }

        function save() {
            dataservice.checkPaymentsSave(vm.data).then(function() {
                log.successMsg('Saved');
                vm.data = [];
                vm.dataId = [];
                vm.actualPayment = {};
                vm.hasCheck = false;
            });
        }
    }
})();
