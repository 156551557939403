(function() {
    'use strict';

    angular
        .module('app.reports.statisticalReports.joinedAthletesBySection')
        .controller('JoinedAthletesBySection', JoinedAthletesBySection);

    function JoinedAthletesBySection(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('JoinedAthletesBySection');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.accessibleSections = [];
        vm.columns = [
        {
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section')
        }, {
            field: 'SzamErkezett',
            headerText: gettextCatalog.getString('NumberOfAthletes'), template: '#joatbysetemplateDataColumn1'
        }, {
            field: 'SzamBelepettFiuk',
            headerText: gettextCatalog.getString('NumberOfBoys'), template: '#joatbysetemplateDataColumn2'
        }, {
            field: 'SzamBelepettLanyok',
            headerText: gettextCatalog.getString('NumberOfGirls'), template: '#joatbysetemplateDataColumn3'
        }];
        vm.DateFrom = new Date();
        vm.DateFrom.setMonth(vm.DateFrom.getMonth() - 1);
        vm.DateTo = new Date();
        vm.DateFromChanged = DateFromChanged;
        vm.DateToChanged = DateToChanged;
        vm.openSection = openSection;

        vm.sectionList = [];
        vm.athleteListFull = [];
        vm.showEachSections = false;
        vm.accessibleSections = [];
        vm.checkboxChanged = checkboxChanged;
        vm.checkboxEnabled = true;
        vm.checkboxchangedonce = false;
        vm.checked = false;
        vm.isAdmin = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function checkboxChanged(args) {
            vm.showEachSections = args.value;
            filterSections();
        }

        function filterSections() {
            vm.athleteList = [];
            if (!vm.showEachSections) {
                for (var i = 0; i < vm.athleteListFull.length; i++) {
                    for (var s = 0; s < vm.accessibleSections.length; s++) {
                        if (vm.athleteListFull[i].SzakosztalyId == vm.accessibleSections[s]) {
                            vm.athleteList.push(vm.athleteListFull[i]);
                        }
                    }
                }
            } else {
                vm.athleteList = vm.athleteListFull;
            }
            grid.dataSource = vm.athleteList;
        }

        function openSection(SectionId, gender2) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzakosztalyId', 'equal', SectionId)).then(function (data) {
                var SectionName = data.result[0].SzakosztalyNeve;
                var gender = gender2 === 0 ? 'allgender' : (gender2 === 1 ? 'male' : 'female');

                var sectionBelongsToUser = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] == SectionId) {
                        sectionBelongsToUser = true;
                        break;
                    }
                }
                if (sectionBelongsToUser || vm.isAdmin) {
                    paramHelper.setParams([
                        {'state': 'joinedAthletes'},
                        {'sectionId': SectionId},
                        {'sectionName': SectionName},
                        {'dateFrom': vm.DateFrom},
                        {'dateTo': vm.DateTo},
                        {'gender': gender}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                } else {
                    log.htmlWarning(gettextCatalog.getString('You do not have permission to access the selected section details!') + '<br><br>' + gettextCatalog.getString(SectionName));
                }
            });
        }

        function activate() {
            authenticationService.getRight('KMBELSZAK').then(function(results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getNumbers(vm.DateFrom, vm.DateTo);
                }
            });
        }

        function getNumbers(date, date2) {
            dataservice.joinedAthletesBySection(date, date2)
                .then(function(data) {
                    vm.athleteListFull = data.itemsList;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.isAdmin = data.isAdmin;
                    if (data.isAdmin == true) {
                        vm.checked = true;
                    }
                    filterSections();
                });
        }

        function DateFromChanged(data) {
            getNumbers(data.value, vm.DateTo);
        }

        function DateToChanged(data) {
            getNumbers(vm.DateFrom, data.value);
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function(data) {
                    data.itemsList.unshift({
                        value: '0',
                        text: gettextCatalog.getString('All')
                    });
                    vm.sectionList = data.itemsList;
                });
        }
    }
})();
