(function () {
    'use strict';

    angular
        .module('app.business.dailySportServiceFeesPerCoach')
        .controller('DailySportServiceFeesPerCoachController', modFeesCtrl);

    function modFeesCtrl(log, $rootScope, gettextCatalog, dataservice, authenticationService, $q, $scope, $filter, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        vm.coachDataBound = coachDataBound;
        $rootScope.title = gettextCatalog.getString('DailySportServiceFeesPerCoach');

        vm.coachList = '';
        vm.selectedCoach = '';
        vm.maskObject = {};

        vm.datumIg = new Date();
        vm.datumTol = new Date(new Date().setMonth(new Date().getMonth() - 1));

        // Grid's details
        vm.data = [];
        vm.nonClosedYears = [];
        vm.selectedYear = 0;
        vm.columns = [{
            field: 'Datum',
            headerText: gettextCatalog.getString('Date'),
            textAlign: 'center',
            width: 40,
            allowEditing: false
        }, {
            field: 'osszesFizetett',
            headerText: gettextCatalog.getString('PaidAmount'),
            textAlign: 'center',
            width: 40,
            allowEditing: false,
            format: 'N0'
        }, {
            field: 'AtveteliElismervenySorszam',
            headerText: gettextCatalog.getString('AcknowledgmentOfReceipt'),
            textAlign: 'center',
            width: 60,
            allowEditing: false
        }
        ];
        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'osszesFizetett',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                }
            ]
        }];

        // Public functions
        vm.getFeeDatas = getFeeDatas;
        vm.selectCoach = selectCoach;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTNAPITAGD')]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    getCoaches();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'dssfpc.coach',
                'dssfpc.from',
                'dssfpc.to'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.datumTol = results[0]['dssfpc.from'];
                vm.datumIg = results[0]['dssfpc.to'];
                vm.selectedCoach = results[0]['dssfpc.coach'];
                vm.coachIdValue = results[0]['dssfpc.coach'];
                vm.defaultCoachId = results[0]['dssfpc.coach'];
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.defaultCoachId);
                vm.coachId = vm.defaultCoachId;
            }
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                }
            );
        }

        function getFeeDatas() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('RequiredFieldsError');
            } else {
                paramHelper.setParams([
                    {'dssfpc.coach': vm.selectedCoach},
                    {'dssfpc.from': vm.datumTol},
                    {'dssfpc.to': vm.datumIg}
                ]);
                return dataservice.dailySportServiceFeesPerCoach(vm.selectedCoach, vm.datumTol, vm.datumIg).then(
                    function (data) {
                        vm.data = $filter('dateStringFilter')(data.itemsList, 'Datum');
                        grid.dataSource = vm.data;
                    }
                );
            }
        }

        function selectCoach(arg) {
            vm.selectedCoach = arg.value;
        }
    }
})();
