(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.performanceMotivationTestEvaluation')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.athletesManagement.athleteModify.psychology.performanceMotivationTestEvaluation',
                config: {
                    url: '/performanceMotivationTestEvaluation',
                    templateUrl: 'app/athletes/athletesManagement/athleteModify/psychology/performanceMotivationTestEvaluation/performanceMotivationTestEvaluation.html',
                    controller: 'PerformanceMotivationTestEvaluationController',
                    controllerAs: 'vm',
                    title: 'performanceMotivationTestEvaluation'
                }
            }
        ];
    }
})();
