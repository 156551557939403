(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify')
        .controller('AthleteModifyController', AthleteModifyController);

    function AthleteModifyController(log, logger, dataservice, $q, DOMAIN, $rootScope, gettextCatalog, paramHelper, authenticationService, $state, $timeout, $scope) {
        var vm = this;
        $rootScope.vm = vm;

        vm.athleteMenuOpened = false;
        vm.printMenuOpened = false;

        $rootScope.title = gettextCatalog.getString('AthleteModify');

        vm.athleteMenuItems = [];

        vm.printMenuItems = [];

        var printOpened = false;
        vm.reportParameters = {};
        var printOpenedSchoolRequesting = false;
        vm.reportParametersSchoolRequesting = {};
        var printOpenedTaj = false;
        vm.reportParametersEgeszsegProfil = {};

        // Activate page
        activate();

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId'], ['athleteModify.sportoloNev', 'selectedSectionId', 'selectedAgeGroupId', 'selectedActiveOrInactive', 'selectedPage', 'selectedRow']),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('UJEDZVALT'),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('SPORTKILEP'),
                authenticationService.getRight('ModFizetesMod'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('SPORTOLO'),
                authenticationService.getRight('SZULO'),
                authenticationService.getRight('EGESZSMEGT'),
                authenticationService.getRight('KMVERSEREDM'),
                authenticationService.getRight('MEGTHAVIJEL'),
                authenticationService.getRight('MODTAGDBEALL'),
                authenticationService.getRight('MEGTANTFELM'),
                authenticationService.getRight('MEGTKPFELM'),
                authenticationService.getRight('MEGTSPFELM'),
                authenticationService.getRight('PSYCHO'),
                authenticationService.getRight('KIKERONYOMT'),
                authenticationService.getRight('TESTMERET'),
                authenticationService.getRight('MEGTSPORTBEF'),
                authenticationService.getRight('MODKOROKEZ'),
                authenticationService.getRight('LOG'),
                authenticationService.getRight('DOKLIST'),
                authenticationService.getRight('ESZKNYILVMEGT'),
                authenticationService.getRight('SPORTKILEPVEGLEGES')

            ]).then(function (results) {
                vm.sportoloId = results[0]['athleteModify.sportoloId'];

                vm.ADMIN = results[1];
                vm.UJEDZVALT = results[2];
                vm.MODFELH = results[3];
                vm.SPORTKILEP = results[4];
                vm.ModFizetesMod = results[5];
                vm.MODSPORTOLO = results[6];
                vm.SPORTOLO = results[7];
                vm.SZULO = results[8];
                vm.EGESZSMEGT = results[9];
                vm.KMVERSEREDM = results[10];
                vm.MEGTHAVIJEL = results[11];
                vm.MODTAGDBEALL = results[12];
                vm.MEGTANTFELM = results[13];
                vm.MEGTKPFELM = results[14];
                vm.MEGTSPFELM = results[15];
                vm.PSYCHO = results[16];
                vm.KIKERONYOMT = results[17];
                vm.TESTMERET = results[18];
                vm.MEGTSPORTBEF = results[19];
                vm.MODKOROKEZ = results[20];
                vm.LOG = results[21];
                vm.DOKLIST = results[22];
                vm.ESZKNYILVMEGT = results[23];
                vm.SPORTKILEPVEGLEGES = results[24];

                dataservice.getAthleteDataMin(vm.sportoloId).then(function (data) {
                    vm.SportoloNev = data.Nev;
                    vm.Taj = data.Taj;
                    vm.SzuletesiIdo = data.SzuletesiIdo;
                    vm.Aktiv = data.Aktiv;
                    vm.JelenlegiEdzo = data.JelenlegiEdzo;

                    if (vm.Aktiv) {
                        vm.SPORTBELEPTET = false;
                    } else {
                        vm.SPORTBELEPTET = vm.MODSPORTOLO;
                        vm.SPORTKILEP = false;
                    }

                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('DataSheet'),
                        state: 'DataSheet',
                        enabled: true
                    });
                    vm.printMenuItems.push({
                        name: gettextCatalog.getString('DatasheetPrint'),
                        state: 'DatasheetPrint',
                        enabled: true
                    });
                    if (!vm.SPORTOLO && !vm.SZULO) {
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('RaceResultsReport'),
                            state: 'RaceResultsReport',
                            enabled: vm.KMVERSEREDM
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('TrainingVisit'),
                            state: 'TrainingVisit',
                            enabled: vm.MEGTHAVIJEL
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('Anthropometry'),
                            state: 'Anthropometry',
                            enabled: vm.MEGTANTFELM
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('NewSportSpecificTestList'),
                            state: 'NewSportSpecificTestList',
                            enabled: vm.MEGTSPFELM
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('goneAthlete'),
                            state: 'goneAthlete',
                            enabled: vm.SPORTKILEP
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('EnteringAthlete'),
                            state: 'EnteringAthlete',
                            enabled: vm.SPORTBELEPTET
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('Psychology'),
                            state: 'Psychology',
                            enabled: vm.PSYCHO
                        });
                        vm.printMenuItems.push({
                            name: gettextCatalog.getString('SchoolRequesting'),
                            state: 'SchoolRequesting',
                            enabled: vm.KIKERONYOMT
                        });
                        vm.athleteMenuItems.push({
                            name: gettextCatalog.getString('HealthModul'),
                            state: 'HealthModul',
                            enabled: vm.EGESZSMEGT
                        });
                        vm.printMenuItems.push({
                            name: gettextCatalog.getString('PrintHealth'),
                            state: 'PrintHealth',
                            enabled: vm.EGESZSMEGT
                        });
                    }

                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('CompetitionResultsChart'),
                        state: 'CompetitionResultsChart',
                        enabled: vm.KMVERSEREDM
                    });
                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('BodyStat'),
                        state: 'BodyStat',
                        enabled: vm.TESTMERET
                    });
                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('AthleteFees'),
                        state: 'AthleteFees',
                        enabled: vm.MEGTSPORTBEF
                    });
                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('Log'),
                        state: 'Log',
                        enabled: vm.LOG
                    });
                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('Contracts'),
                        state: 'Contracts',
                        enabled: vm.DOKLIST && vm.MODSPORTOLO
                    });

                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('AthletesAssets'),
                        state: 'AthletesAssets',
                        enabled: (vm.ESZKNYILVMEGT && vm.Aktiv)
                    });
                    vm.athleteMenuItems.push({
                        name: gettextCatalog.getString('GDPRExitAthlete'),
                        state: 'GDPRExitAthlete',
                        enabled: vm.SPORTKILEPVEGLEGES
                    });

                    vm.athleteMenuItems.sort(function (item1, item2) {
                        return item1.name.localeCompare(item2.name);
                    });
                    vm.printMenuItems.sort(function (item1, item2) {
                        return item1.name.localeCompare(item2.name);
                    });

                    paramHelper.setParams([
                        {'athleteModify.sportoloNev': vm.SportoloNev},
                        {'athleteModify.sportoloTaj': vm.Taj},
                        {'athleteModify.sportoloSzuletesiIdo': vm.SzuletesiIdo},
                        {'athleteModify.aktivSportolo': vm.Aktiv}
                    ]);
                });
            });
        }

        vm.openAthleteMenu = function () {
            vm.athleteMenuOpened = !vm.athleteMenuOpened;
            if (vm.athleteMenuOpened) {
                $('#AthleteMenuButton').addClass('is-active');
            } else {
                $('#AthleteMenuButton').removeClass('is-active');
            }

            if (vm.athleteMenuOpened === true) {
                $('#AthletePrintButton').removeClass('is-active');
                vm.printMenuOpened = false;
            }
        };

        vm.openPrintMenu = function () {
            vm.printMenuOpened = !vm.printMenuOpened;
            if (vm.printMenuOpened) {
                $('#AthletePrintButton').addClass('is-active');
            } else {
                $('#AthletePrintButton').removeClass('is-active');
            }

            if (vm.printMenuOpened === true) {
                $('#AthleteMenuButton').removeClass('is-active');
                vm.athleteMenuOpened = false;
            }
        };

        vm.openAthletePage = function (item) {
            if (item.enabled === false) {
                // log.permissionError();
                return;
            }
            vm.openAthleteMenu();
            switch (item.state) {
                case 'DataSheet':
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                    break;
                case 'RaceResultsReport':
                    $state.go('athletes.athletesManagement.athleteModify.raceResultsReport');
                    break;
                case 'TrainingVisit':
                    $state.go('athletes.athletesManagement.athleteModify.trainingVisitReport');
                    break;
                case 'Anthropometry':
                    $state.go('athletes.athletesManagement.athleteModify.anthropometry');
                    break;
                case 'NewSportSpecificTestList':
                    $state.go('athletes.athletesManagement.athleteModify.athletesSportSpecificTestList');
                    break;
                case 'goneAthlete':
                    goToGoneAthleteAllowed();
                    break;
                case 'EnteringAthlete':
                    getSectionsSwal();
                    break;
                case 'Contracts':
                    paramHelper.setParams([{'files.tipus': 'Sportolok'}, {'files.tablaId': vm.sportoloId}]);
                    $state.go('athletes.athletesManagement.athleteModify.files');
                    break;
                case 'Psychology':
                    $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                    break;
                case 'BodyStat':
                    $state.go('athletes.athletesManagement.athleteModify.athletesBodyStat');
                    break;
                case 'HealthModul':
                    $state.go('athletes.athletesManagement.athleteModify.health.healthList');
                    break;
                case 'CompetitionResultsChart':
                    $state.go('athletes.athletesManagement.athleteModify.competitionResultsChart');
                    break;
                case 'AthleteFees':
                    $state.go('athletes.athletesManagement.athleteModify.athleteFees');
                    break;
                case 'Log':
                    $state.go('athletes.athletesManagement.athleteModify.athleteLog');
                    break;
                case 'AthletesAssets':
                    $state.go('athletes.athletesManagement.athleteModify.athletesAssets');
                    break;
                case 'GDPRExitAthlete':
                    GDPRExitAthlete();
                    break;
            }
        };

        vm.printPage = function (item) {
            if (item.enabled === false) {
                return;
            }
            vm.openPrintMenu();
            switch (item.state) {
                case 'DatasheetPrint':
                    datasheetPrint();
                    break;
                case 'SchoolRequesting':
                    schoolRequestingOk();
                    break;
                case 'PrintHealth':
                    testClick();
                    break;
            }
        };

        function goToGoneAthleteAllowed() {
            var TitleString = gettextCatalog.getString(vm.SportoloNev + ' ' + gettextCatalog.getString('ExitAthleteTitlePart'));
            var enteringExitingText = gettextCatalog.getString('SelectDategoneAthlete'); // Válaszd ki a kiléptetés dátumát!
            var reasonEnteringExitingText = gettextCatalog.getString('ExitedAthletesText'); // A kiléptetés indoka
            var confirmButtonText = gettextCatalog.getString('Exiting');

            var HTMLarr = [];

            HTMLarr[0] = '<div style="margin-bottom: 10px; width: 95%;" ><label>';
            HTMLarr[1] = enteringExitingText; // Válaszd ki a kiléptetés vagy visszaléptetés dátumát!
            HTMLarr[2] = '<br></label></div><div style="margin-left: 30%;"><input id="datepick" required type="text"></div>';
            HTMLarr[3] = '<br><label>';
            HTMLarr[4] = reasonEnteringExitingText; // A kiléptetés vagy visszaléptetés indoka
            HTMLarr[5] = '</label><br>';
            HTMLarr[6] = '<textarea id="noteTextArea" rows="4" cols="55"  required style="width: 95%; resize: none; padding: 5px;"></textarea>';

            var HTMLtext = HTMLarr.join('');

            swal({
                title: TitleString,
                html: HTMLtext,
                type: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: confirmButtonText,
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                preConfirm: function () {
                    var preConfOk = true;
                    vm.selectedExitDate = angular.element('#datepick').data('ejDatePicker').getValue();
                    vm.noteExitText = angular.element('#noteTextArea').val();
                    checkExitDate(vm.selectedExitDate);
                    return new Promise(function (resolve) {
                        if (!vm.selectedExitDate || !vm.exitAthleteDateOk) {
                            preConfOk = false;
                            swal.showValidationError(gettextCatalog.getString('SelectExitDate'));
                        }
                        if (vm.noteExitText == null || vm.noteExitText === '') {
                            preConfOk = false;
                            swal.showValidationError(gettextCatalog.getString('SetNoteText'));
                        }
                        resolve();
                    });
                }
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    saveExitAthletes(true);
                    swal.close();
                }
            });

            // Set DatePicker
            angular.element('#datepick').ejDatePicker({
                dateFormat: 'yyyy-MM-dd',
                value: new Date(),
                change: function (args) {
                    checkExitDate(args.value);
                }
            });
        }

        function checkExitDate(args) {
            var currentDate = new Date();
            vm.selectedExitDate = new Date(args);
            vm.exitAthleteDateOk = (vm.selectedExitDate <= currentDate);
            vm.exitAthleteDateOk = true;
        }

        function saveExitAthletes(firstSection) {
            vm.datas = {};
            vm.datas.athletesId = [vm.sportoloId];
            vm.datas.selectedExitDate = vm.selectedExitDate;
            if (!vm.noteExitText) {
                vm.noteExitText = '----';
            }
            vm.datas.noteExitText = vm.noteExitText;
            vm.datas.firstSection = firstSection;
            dataservice.saveExitAthletes(vm.datas).then(function (answer) {
                if (answer.message && answer.message.length > 0) {
                    swal({
                        title: gettextCatalog.getString('ExitAthleteResult'),
                        html: answer.message,
                        type: 'warning',
                        confirmButtonClass: 'btn-danger',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    log.successMsg('SuccesfulExitAthlete');
                }
                $rootScope.back();
                /*
                if (answer.retValue) {
                    swal(gettextCatalog.getString('SuccesfulExitAthleteResult'), '', 'success');
                    $rootScope.back();
                } else {
                    swal(gettextCatalog.getString('FailedExitAthleteResult'), '', 'error');
                }
                */
            });
        }

        function GDPRExitAthlete() {
            dataservice.getAthleteData(vm.sportoloId).then(function (data) {
                if (data.Egyenleg < 0 || data.Egyenleg2 < 0) {
                    log.errorMsg(gettextCatalog.getString('AthleteHasArrear'));
                } else {
                    swal({
                        title: gettextCatalog.getString('FinallyExitAthlete'),
                        text: gettextCatalog.getString('AreYouSureYouWantToFinallyExitSelectedAthlete'),
                        type: 'error',
                        showCancelButton: true,
                        confirmButtonColor: '#e50000',
                        cancelButtonText: gettextCatalog.getString('No'),
                        confirmButtonText: gettextCatalog.getString('Yes')
                    }).then(function (isConfirmed) {
                        if (isConfirmed.value) {
                            dataservice.GDPRExitAthlete(vm.sportoloId).then(function () {
                                $state.go('athletes.athletesManagement.athletesList');
                            });
                        }
                    });
                }
            });
        }

        function getSectionsSwal() {
            dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionListSwal = data.itemsList;
                reEnteringAthlete();
            });
        }

        function reEnteringAthlete() {
            var showAgeGroupSwal = false;
            var TitleString = gettextCatalog.getString(vm.SportoloNev + ' ' + gettextCatalog.getString('EnteringAthlete'));
            var enteringExitingText = gettextCatalog.getString('SelectDateEnteringAthlete'); // Válaszd ki a visszaléptetés dátumát!
            var confirmButtonText = gettextCatalog.getString('Entering');

            var HTMLarr = [];
            HTMLarr[0] = '<label>';
            HTMLarr[1] = enteringExitingText; // Válaszd ki a visszaléptetés dátumát!
            HTMLarr[2] = '<br></label></div><div style="margin-left: 30%;"><input id="datepick" type="text"></div>';
            HTMLarr[3] = '<br>';
            HTMLarr[4] = '<div style="margin-top: 10px;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringSection') +
                '</label><div class="col-lg-6"><input id="sectionDropDownSwal" ej-dropdownlist></div></div></div>';
            HTMLarr[5] = '<div style="margin-top: 10px;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringAgeGroup') +
                '</label><div class="col-lg-6"><input id="ageGroupDropDownSwal" ej-dropdownlist datasource="vm.ageGroupListSwal"></div></div></div>';
            HTMLarr[6] = '<div style="margin-top: 10px;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringCoach') +
                '</label><div class="col-lg-6"><input id="coachDropDownSwal" ej-dropdownlist datasource="vm.coachListSwal"></div></div></div>';
            HTMLarr[7] = '<div ng-show="vm.display" class="ng-hide" id="secondSectionDropDownSwalDiv" style="margin-top: 10px; clear: both;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringSecondSection') +
                '</label><div class="col-lg-6"><input id="secondSectionDropDownSwal" ej-dropdownlist></div></div></div>';
            HTMLarr[8] = '<div ng-show="vm.display" class="ng-hide" id="secondAgeGroupDropDownSwalDiv" style="margin-top: 10px; clear: both;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringSecondAgeGroup') +
                '</label><div class="col-lg-6"><input id="secondAgeGroupDropDownSwal" ej-dropdownlist datasource="vm.secondAgeGroupListSwal"></div></div></div>';
            HTMLarr[9] = '<div ng-show="vm.display" class="ng-hide" id="secondCoachDropDownSwalDiv"  style="margin-top: 10px; clear: both;" ><div class="row"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('ReEnteringSecondCoach') +
                '</label><div class="col-lg-6"><input id="secondCoachDropDownSwal" ej-dropdownlist datasource="vm.secondCoachListSwal"></div></div></div><div class="col-lg-12"></div>';

            var HTMLtext = HTMLarr.join('');

            swal({
                title: TitleString,
                html: HTMLtext,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: confirmButtonText,
                cancelButtonText: gettextCatalog.getString('Cancel'),
                preConfirm: function () {
                    vm.selectedExitDate = angular.element('#datepick').data('ejDatePicker').getValue(); // get value

                    vm.exitAthleteDateOk = (new Date(vm.selectedExitDate).getTime()) <= new Date().getTime();

                    return new Promise(function (resolve) {
                        if (!vm.selectedExitDate || !vm.exitAthleteDateOk) {
                            swal.showValidationError(gettextCatalog.getString('SelectEnteringDate'));
                        }
                        resolve();
                    });
                }
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    saveReEnteringAthletes();
                }
            }, function () {
                swal(gettextCatalog.getString('Cancelled'), gettextCatalog.getString('notEnteringAthlete'), 'error');
            });

            // Set DatePicker
            angular.element('#datepick').ejDatePicker({
                dateFormat: 'yyyy-MM-dd',
                value: new Date(),
                change: function (args) {
                    // Get current date
                    vm.selectedExitDate = new Date(args.value);
                    vm.exitAthleteDateOk = vm.selectedExitDate.getDate() === 1;
                }
            });
            // Section swal
            angular.element('#sectionDropDownSwal').ejDropDownList({
                dataSource: vm.sectionListSwal,
                select: selectSectionDropDownSwal,
                value: vm.athleteDataSwalSzakosztaly,
                databound: sectionSwalDatabound,
                width: '100%'
            });

            function sectionSwalDatabound() {
                if (vm.sectionList.length === 1) {
                    angular.element('#sectionDropDownSwal').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                } else {
                    angular.element('#sectionDropDownSwal').ejDropDownList('selectItemByValue', vm.athleteDataSwalSzakosztaly);
                }
            }

            function selectSectionDropDownSwal(arg) {
                vm.athleteDataSwalSzakosztaly = arg.value;
                showAgeGroupSwal = true; // show ageGroup dropdown
                // Clear
                vm.coachListSwal = [];
                angular.element('#secondSectionDropDownSwalDiv').addClass('ng-hide');
                angular.element('#secondAgeGroupDropDownSwalDiv').addClass('ng-hide');
                angular.element('#secondCoachDropDownSwalDiv').addClass('ng-hide');

                angular.element('#coachDropDownSwal').ejDropDownList({
                    dataSource: vm.coachListSwal,
                });
                getSecondSectionsSwal();
                getAgeGroupsSwal();
            }

            // AgeGroup swal
            angular.element('#ageGroupDropDownSwal').ejDropDownList({
                dataSource: vm.ageGroupListSwal,
                select: ageGroupDropDownSwal,
                value: vm.athleteDataSwalKorcsoport,
                databound: ageGroupSwalDataBound,
                width: '100%'
            });

            function getAgeGroupsSwal() {
                dataservice.ageGroupDropDownList(vm.athleteDataSwalSzakosztaly)
                    .then(function (data) {
                        vm.ageGroupListSwal = data.itemsList;
                        // reFresh
                        angular.element('#ageGroupDropDownSwal').ejDropDownList({
                            dataSource: vm.ageGroupListSwal,
                        });
                    });
            }

            function ageGroupSwalDataBound() {
                if (vm.ageGroupList.length === 1) {
                    angular.element('#ageGroupDropDownSwal').ejDropDownList('selectItemByValue', vm.ageGroupListSwal[0].value);
                } else {
                    angular.element('#ageGroupDropDownSwal').ejDropDownList('selectItemByValue', vm.athleteDataSwalKorcsoport);
                }
            }

            function ageGroupDropDownSwal(arg) {
                vm.athleteDataSwalKorcsoport = arg.value;
                getCoachesSwal();
            }

            // Coach swal
            angular.element('#coachDropDownSwal').ejDropDownList({
                dataSource: vm.coachListSwal,
                select: coachDropDownSwalSelect,
                value: vm.athleteDataSwalEdzo,
                databound: coachSwalDataBound,
                width: '100%'
            });

            function getCoachesSwal() {
                return dataservice.coachDropDownList(vm.athleteDataSwalSzakosztaly, vm.athleteDataSwalKorcsoport) // Kell a section id (szakosztály)
                    .then(function (data) {
                        vm.coachListSwal = data.itemsList;
                        angular.element('#coachDropDownSwal').ejDropDownList({
                            dataSource: vm.coachListSwal,
                        });
                    });
            }

            function coachSwalDataBound() {
                if (vm.ageGroupList.length === 1) {
                    angular.element('#coachDropDownSwal').ejDropDownList('selectItemByValue', vm.coachListSwal[0].value);
                } else {
                    angular.element('#coachDropDownSwal').ejDropDownList('selectItemByValue', vm.athleteDataSwalEdzo);
                }
            }

            function coachDropDownSwalSelect(arg) {
                vm.athleteDataSwalEdzo = arg.value;
                angular.element('#secondSectionDropDownSwalDiv').removeClass('ng-hide');
                angular.element('#secondAgeGroupDropDownSwalDiv').removeClass('ng-hide');
                angular.element('#secondCoachDropDownSwalDiv').removeClass('ng-hide');
            }

            // Second Section swal
            angular.element('#secondSectionDropDownSwal').ejDropDownList({
                dataSource: vm.secondSectionListSwal,
                select: selectSecondSectionDropDownSwal,
                value: vm.secondAthleteDataSwalSzakosztaly,
                databound: sectionSwalDatabound,
                width: '100%'
            });

            function selectSecondSectionDropDownSwal(arg) {
                vm.secondAthleteDataSwalSzakosztaly = arg.value;
                // Clear
                vm.secondCoachListSwal = [];
                angular.element('#secondCoachDropDownSwal').ejDropDownList({
                    dataSource: vm.secondCoachListSwal,
                });

                getsecondAgeGroupsSwal();
            }

            function getSecondSectionsSwal() {
                dataservice.sectionDropDownList(0)
                    .then(function (data) {
                        // itt ki kell venni a listából az elsődleges section-t (vm.athleteDataSwalSzakosztaly)
                        for (var i = 0; i < data.itemsList.length; i++) {
                            if (data.itemsList[i].value === vm.athleteDataSwalSzakosztaly) {
                                data.itemsList.splice(i, 1);
                            }
                        }
                        vm.secondSectionListSwal = data.itemsList;
                        angular.element('#secondSectionDropDownSwal').ejDropDownList({
                            dataSource: vm.secondSectionListSwal,
                        });
                    });
            }

            // second AgeGroup swal
            angular.element('#secondAgeGroupDropDownSwal').ejDropDownList({
                dataSource: vm.secondAgeGroupListSwal,
                select: secondAgeGroupDropDownSwal,
                value: vm.secondAthleteDataSwalKorcsoport,
                databound: secondAgeGroupSwalDataBound,
                width: '100%'
            });

            function getsecondAgeGroupsSwal() {
                dataservice.ageGroupDropDownList(vm.secondAthleteDataSwalSzakosztaly)
                    .then(function (data) {
                        vm.secondAgeGroupListSwal = data.itemsList;
                        // reFresh
                        angular.element('#secondAgeGroupDropDownSwal').ejDropDownList({
                            dataSource: vm.secondAgeGroupListSwal,
                        });
                    });
            }

            function secondAgeGroupSwalDataBound() {
                if (vm.secondAgeGroupList.length === 1) {
                    angular.element('#secondAgeGroupDropDownSwal').ejDropDownList('selectItemByValue', vm.secondAgeGroupListSwal[0].value);
                } else {
                    angular.element('#secondAgeGroupDropDownSwal').ejDropDownList('selectItemByValue', vm.secondAthleteDataSwalKorcsoport);
                }
            }

            function secondAgeGroupDropDownSwal(arg) {
                vm.secondAthleteDataSwalKorcsoport = arg.value;
                getsecondCoachesSwal();
            }

            // Coach swal
            angular.element('#secondCoachDropDownSwal').ejDropDownList({
                dataSource: vm.secondCoachListSwal,
                select: selectSecondCoachDropDownSwal,
                value: vm.secondAthleteDataSwalEdzo,
                databound: secondCoachSwalDataBound,
                width: '100%'
            });

            function selectSecondCoachDropDownSwal(arg) {
                vm.secondAthleteDataSwalEdzo = arg.value;
            }

            function getsecondCoachesSwal() {
                return dataservice.coachDropDownList(vm.secondAthleteDataSwalSzakosztaly, vm.secondAthleteDataSwalKorcsoport) // Kell a section id (szakosztály)
                    .then(function (data) {
                        vm.secondCoachListSwal = data.itemsList;
                        angular.element('#secondCoachDropDownSwal').ejDropDownList({
                            dataSource: vm.secondCoachListSwal,
                        });
                    });
            }

            function secondCoachSwalDataBound() {
                if (vm.secondAgeGroupList.length === 1) {
                    angular.element('#secondCoachDropDownSwal').ejDropDownList('selectItemByValue', vm.secondCoachListSwal[0].value);
                } else {
                    angular.element('#secondCoachDropDownSwal').ejDropDownList('selectItemByValue', vm.secondAthleteDataSwalEdzo);
                }
            }
        }

        function saveReEnteringAthletes() {
            vm.athleteData = {};
            vm.athleteData.IdSzakosztaly = vm.athleteDataSwalSzakosztaly;
            vm.SzakosztalyNev = '';
            vm.athleteData.Korcsoport = vm.athleteDataSwalKorcsoport;
            vm.athleteData.KorcsoportNeve = '';
            vm.athleteData.JelenlegiEdzo = vm.athleteDataSwalEdzo;
            vm.athleteData.JelenlegiEdzoNev = '';
            vm.athleteData.MasodlagosSzakosztaly = vm.secondAthleteDataSwalSzakosztaly;
            vm.athleteData.MasodlagosKorcsoport = vm.secondAthleteDataSwalKorcsoport;
            vm.athleteData.MasodlagosSzakosztalyEdzoId = vm.secondAthleteDataSwalEdzo;
            vm.athleteData.KilepesDatuma = '';
            vm.athleteData.KilepesIndoka = '';

            vm.sendAthleteDatas = {};
            vm.sendAthleteDatas.athletesId = vm.sportoloId;
            vm.sendAthleteDatas.selectedReEnteringDate = vm.selectedExitDate;
            vm.sendAthleteDatas.szakosztalyId = vm.athleteData.IdSzakosztaly;
            vm.sendAthleteDatas.korcsoportId = vm.athleteData.Korcsoport;
            vm.sendAthleteDatas.edzoId = vm.athleteData.JelenlegiEdzo;
            vm.sendAthleteDatas.masodlagosSzakosztalyId = vm.secondAthleteDataSwalSzakosztaly;
            vm.sendAthleteDatas.masodlagosKorcsoportId = vm.secondAthleteDataSwalKorcsoport;
            vm.sendAthleteDatas.masodlagosEdzoId = vm.secondAthleteDataSwalEdzo;

            return dataservice.saveReEnteringAthletes(vm.sendAthleteDatas).then(function () {
                $rootScope.back();
            });
        }

        function datasheetPrint() {
            $timeout(function () {
                vm.reportParameters = {
                    'Szak': '',
                    'Kor': '',
                    'Edzo': vm.JelenlegiEdzo,
                    'SzulHely': '',
                    'Lakcim': '',
                    'IskolaCim': '',
                    'Id': vm.sportoloId,
                    'fenykeput': '',
                    'UgyesebbLab': '',
                    'UgyesebbKez': '',
                    'Neme': '',
                    'Aktiv': vm.Aktiv === true ? gettextCatalog.getString('Yes') : gettextCatalog.getString('No')
                };
            }, 0);
            if (printOpened) {
                angular.element('#printDialogDatasheet').ejDialog('open');
            } else {
                printOpened = true;
                angular.element('#printDialogDatasheet').ejDialog({
                    height: '99%',
                    width: '99%',
                    enablemodal: 'true',
                    'position-x': '0',
                    'position-y': '0',
                    target: '#mainContent',
                    enableresize: 'false'
                });
            }
        }

        function schoolRequestingOk() {
            var HTMLarr = [];

            HTMLarr[0] = '<label><b>' + '</b></label><br><br>';
            HTMLarr[1] = '<label>' + gettextCatalog.getString('PleaseGiveSchoolRequestingDatas') + '</label>';
            HTMLarr[2] = '</label><br></p><div style="margin-bottom: 10px; width: 95%;" ><label>';
            HTMLarr[3] = gettextCatalog.getString('DateInterval');
            HTMLarr[4] = '<br></label></div><div><input id="dateInterval" type="text"></div><br><label>';
            HTMLarr[5] = gettextCatalog.getString('Justification');
            HTMLarr[6] = '<br></label></div><div><input id="justification" type="text"></div>';
            var HTMLtext = HTMLarr.join('');

            $q.all([paramHelper.getParams([], ['schoolRequesting.dateInterval', 'schoolRequesting.justification'])])
                .then(function (results) {
                    if (results[0] != null) {
                        angular.element('#dateInterval').val(results[0]['schoolRequesting.dateInterval']);
                        angular.element('#justification').val(results[0]['schoolRequesting.justification']);
                    }
                });

            swal({
                title: gettextCatalog.getString('SchoolRequesting'),
                html: HTMLtext,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Print'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                preConfirm: function () {
                    var preConfOk = false;
                    vm.dateInterval = angular.element('#dateInterval').val(); // get value
                    vm.justification = angular.element('#justification').val();
                    return new Promise(function (resolve, reject) {
                        if (vm.dateInterval === null || vm.dateInterval === '') {
                            preConfOk = false;
                            reject(gettextCatalog.getString('FillDateInterval'));
                        } else {
                            preConfOk = true;
                        }
                        if (vm.justification === null || vm.justification === '') {
                            preConfOk = false;
                            reject(gettextCatalog.getString('FillJustification'));
                        } else {
                            preConfOk = true;
                        }
                        if (preConfOk) {
                            resolve();
                        }
                    });
                },
                closeOnCancel: false
            }).then(function (confirmed) {
                if (confirmed.value) {
                    vm.reportParametersSchoolRequesting = {
                        'Logo': ' ',
                        'Fejlec': ' ',
                        'SportoloNeve': vm.SportoloNev,
                        'SportoloSzuletesiHelye': '',
                        'SportoloSzuletesiIdeje': '',
                        'SportoloAnyjaNeve': '',
                        'EgyesuletNeve': ' ',
                        'VarosDatum': '',
                        'Idopont': vm.dateInterval,
                        'Indok': vm.justification,
                        'Szoveg': '',
                        'Alairo': '',
                        'SportoloId': vm.sportoloId,
                        'EdzoId': authenticationService.getAuthData().id
                    };
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                    paramHelper.setParams([{
                        'schoolRequesting.dateInterval': vm.dateInterval,
                        'schoolRequesting.justification': vm.justification
                    }]);
                    if (printOpenedSchoolRequesting) {
                        angular.element('#printDialogSchoolRequesting').ejDialog('open');
                    } else {
                        printOpenedSchoolRequesting = true;
                        angular.element('#printDialogSchoolRequesting').ejDialog({
                            height: '99%',
                            width: '99%',
                            enablemodal: 'true',
                            'position-x': '0',
                            'position-y': '1',
                            target: '#mainContent',
                            enableresize: 'false'
                        });
                    }
                    swal.close();
                    return true;
                }
            }).then(function () {
            });
        }

        function testClick() {
            swal({
                title: gettextCatalog.getString('HealthPrintQuestion'),
                input: 'select',
                inputOptions: {
                    '1': gettextCatalog.getString('AllHealth'),
                    '2': gettextCatalog.getString('ActualHealth')
                },
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('OK'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                inputValidator: function (value) {
                    return new Promise(function (resolve) {
                        vm.healtType = value;
                        resolve();
                    });
                }
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.reportParametersEgeszsegProfil = {
                        'SportoloId': vm.sportoloId,
                        'Name': vm.SportoloNev,
                        'SzuletesiIdo': '',
                        'TajSzam': '',
                        'Type': vm.healtType,
                        'Fenykeput': ''
                    };
                    $scope.$apply();

                    if (printOpenedTaj) {
                        angular.element('#printDialogHealth').ejDialog('open');
                    } else {
                        printOpenedTaj = true;
                        angular.element('#printDialogHealth').ejDialog({
                            height: '99%',
                            width: '99%',
                            enablemodal: 'true',
                            'position-x': '0',
                            'position-y': '1',
                            target: '#mainContent',
                            enableresize: 'false'
                        });
                    }
                }
            });
        }
    }
})
();
