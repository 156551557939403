(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.addAgeGroup')
        .controller('AddAgeGroupController', AddAgeGroupController);

    function AddAgeGroupController(log, $state, dataservice, authenticationService, $q, gettextCatalog, $rootScope, paramHelper, $window, $scope) {
        var vm = this; $rootScope.vm = vm;
        vm.params = [];

        // Page title
        $rootScope.title = gettextCatalog.getString('AddAgeGroup');

        // Dropdownlist's sources
        vm.sectionList = [];
        var date = new Date().getFullYear();
        vm.yearList = dataservice.range(date + 5, 1900);
        vm.yearListTo = dataservice.range(date + 10, 1900);

        vm.formData = {
            Color: '#000000',
            ActiveAgeGroup: true,
            SelectedYearFrom: undefined,
            SelectedYearTo: undefined,
            Comment: undefined,
            AgeGroupName: undefined,
            SectionId: undefined
        };

        vm.clearDropdown = clearDropdown;
        vm.sectionSelect = sectionSelect;
        vm.saveAgeGroup = saveAgeGroup;
        vm.yearFromDropDownChanged = yearFromDropDownChanged;
        vm.yearToDropDownChanged = yearToDropDownChanged;
        vm.sectionDataBound = sectionDataBound;
        vm.colorChanged = colorChanged;
        vm.activeCheckboxChanged = activeCheckboxChanged;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('UJKOROKEZ')]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['ageGroups.section'])]).then(function (results) {
                        vm.UJKOROKEZ = true;
                        vm.formData.SectionId = results[0]['ageGroups.section'];
                        angular.element('#activeCheckBox').ejCheckBox({checked: vm.formData.ActiveAgeGroup});
                        getSections();
                    });
                }
            });
        }

        function clearDropdown() {
            vm.formData.SelectedYearTo = null;
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.formData.SectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.formData.SectionId);
            }
        }

        function sectionSelect(args) {
            vm.formData.SectionId = args.value;
        }

        function yearFromDropDownChanged(args) {
            vm.formData.SelectedYearFrom = args.value;
        }

        function yearToDropDownChanged(args) {
            vm.formData.SelectedYearTo = args.value;
        }

        function colorChanged(args) {
            vm.formData.Color = args.value;
        }

        function activeCheckboxChanged(args) {
            vm.formData.ActiveAgeGroup = args.value;
        }

        function saveAgeGroup() {
            if (vm.UJKOROKEZ) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg('FillTheRequiredFields');
                } else {
                    dataservice.saveAgeGroup(vm.formData)
                        .then(function () {
                        log.successMsg('AgeGroupAdded');
                        paramHelper.setParams([{'ageGroups.section': vm.formData.SectionId}]);
                        $state.go('athletes.ageGroups.ageGroupsList');
                    }, function () {
                    });
                }
            } else {
                log.permissionError();
            }
        }
    }
})();
