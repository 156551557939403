(function () {
    'use strict';

    angular
        .module('app.administration.sportSpecificTest.newSportSpecificTestList')
        .controller('NewSportSpecificTestListController', NewSportSpecificTestListController);

    function NewSportSpecificTestListController(log, dataservice, authenticationService, $q, $state, $scope, $rootScope, gettextCatalog, paramHelper, $filter, $timeout) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('NewSportSpecificTestList');

        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.coachId = 0;
        vm.felmeroFejId = 0;
        vm.Highlight = -1;
        vm.dateTo = new Date();
        vm.dateFrom = new Date(vm.dateTo.getFullYear() - 1, vm.dateTo.getMonth(), 1);

        vm.columns = [
            {
                field: 'Id',
                headerText: 'Id',
                textAlign: 'left',
                isPrimaryKey: true,
                visible: false
            },
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                allowEditing: false,
                template: '#sstlOpen',
                width: 60
            },
            {
                field: 'FelmeroNeve',
                headerText: gettextCatalog.getString('TestName'),
                textAlign: 'left',
                allowEditing: false,
                width: 150
            },
            {
                field: 'SzakosztalyNev',
                headerText: gettextCatalog.getString('SectionName'),
                textAlign: 'left',
                allowEditing: false,
                width: 150
            }, {
                field: 'EdzoNev',
                headerText: gettextCatalog.getString('CoachName'),
                textAlign: 'left',
                allowEditing: false,
                width: 150,
                template: '#sstlCoach'
            }, {
                field: 'KorcsoportNev',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'left',
                allowEditing: false,
                width: 150,
            }, {
                field: 'Edzoertekeles',
                headerText: gettextCatalog.getString('CoachRating'),
                textAlign: 'left',
                allowEditing: false,
            }, {
                field: 'Datum',
                headerText: gettextCatalog.getString('Datum'),
                textAlign: 'left',
                allowEditing: false,
                width: 150,
                template: '#sstlTest'
            }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };
        // Publikus funkciók
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;
        vm.newTest = newTest;
        vm.goToTest = goToTest;
        vm.goToUser = goToUser;

        activate();

        function activate() {
            authenticationService.getRight('MEGTSPFELM').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([getSections(),
                        authenticationService.getRight('UJSPFELM'),
                        authenticationService.getRight('MODFELH'),
                        paramHelper.getParams([], [
                            'newSportSpecificTest.sectionId',
                            'newSportSpecificTest.ageGroupId',
                            'newSportSpecificTest.yearId',
                            'newSportSpecificTest.coachId',
                            'newSportSpecificTest.selectedSection'
                        ])
                    ]).then(function (results) {
                        vm.UJSPFELM = results[1];
                        vm.MODFELH = results[2];
                        vm.sectionId = results[3]['newSportSpecificTest.sectionId'];
                        vm.ageGroupId = results[3]['newSportSpecificTest.ageGroupId'];
                        vm.coachId = results[3]['newSportSpecificTest.coachId'];
                    });
                }
            });
        }

        function felmeroFejLista() {
            if (vm.ageGroupId === undefined || vm.ageGroupId === null) {
                vm.ageGroupId = 0;
            }
            if (vm.coachId === undefined || vm.coachId === null) {
                vm.coachId = 0;
            }
            if (vm.testId === undefined || vm.testId === null) {
                vm.testId = 0;
            }
            if (vm.sectionId == 0) {
                vm.Highlight = -1;
            }
            return dataservice.felmeroFejLista(vm.sectionId, vm.ageGroupId, vm.coachId, vm.testId, vm.Highlight, vm.dateFrom, vm.dateTo)
                .then(function (data) {
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Datum');
                    grid.dataSource = data.itemsList;
                });
        }

        function dateFromChange(arg) {
            vm.dateFrom = arg.value;
            felmeroFejLista();
        }

        function dateToChange(arg) {
            vm.dateTo = arg.value;
            felmeroFejLista();
        }

        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                vm.sectionList = data.itemsList;
                vm.sectionList.unshift({value: 0, text: gettextCatalog.getString('All')});
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.coachList = [];
            $timeout(function () {
                felmeroFejLista();
            }, 100);
            getAgeGroups();
            getActualCoachesList();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            vm.coachList = [];
            getActualCoachesList();
            felmeroFejLista();
        }

        // Section dropDownList function Get coach' list
        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.sectionId, vm.ageGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age group selected
        function coachSelect(args) {
            vm.coachId = args.value;
            felmeroFejLista();
        }

        function newTest() {
            if (vm.sectionId == 0) {
                log.errorMsg(gettextCatalog.getString('SelectedSectionCannotBeAll'));
                return;
            }
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (vm.UJSPFELM !== true) {
                log.permissionError();
            } else {
                paramHelper.setParams(
                    [
                        {'sportSpecificTest.sectionId': vm.sectionId},
                        {'newSportSpecificTest.sectionId': vm.sectionId},
                        {'sportSpecificTest.ageGroupId': vm.ageGroupId},
                        {'sportSpecificTest.coachId': vm.coachId},
                        {'sportSpecificTest.felmeroElemFejId': -1},
                        {'sportSpecificTest.felmeroFejId': -1},
                        {'sportSpecificTest.highlight': -1}
                    ]);
                $state.go('administration.sportSpecificTest.newSportSpecificTest');
            }
        }

        function goToTest(SzakosztalyId, KorcsoportId, EdzoId, FelmeroElemFejId, FelmeroFejId) {
            paramHelper.setParams(
                [
                    {'sportSpecificTest.sectionId': SzakosztalyId},
                    {'newSportSpecificTest.sectionId': SzakosztalyId},
                    {'sportSpecificTest.ageGroupId': KorcsoportId},
                    {'sportSpecificTest.coachId': EdzoId},
                    {'sportSpecificTest.felmeroElemFejId': FelmeroElemFejId},
                    {'sportSpecificTest.felmeroFejId': FelmeroFejId},
                    {'sportSpecificTest.highlight': vm.Highlight}
                ]);

            $state.go('administration.sportSpecificTest.newSportSpecificTest');
        }

        function goToUser(EdzoId) {
            if (!vm.MODFELH) {
                log.errorMsg(gettextCatalog.getString('YouHaveNoPermissionToThisFunction'));
                return;
            }
            paramHelper.setParams([{'newUser.userId': EdzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }
    }
})();
