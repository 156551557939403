(function () {
    'use strict';

    angular
        .module('app.settings.systemSettings')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.systemSettings',
                config: {
                    url: '/systemSettings',
                    templateUrl: 'app/settings/systemSettings/systemSettings.html',
                    controller: 'SystemSettingsController',
                    controllerAs: 'vm',
                    title: 'SystemSettings',
                    settings: {
                        navId: 2105,
                        level: 2,
                        order: 10,
                        orderTitle_hu: 'Rendszerbeállítások',
                        orderTitle_en: 'System Settings',
                        parentId: 5,
                        content: 'SystemSettings',
                        activatorPermission: '"BEALLITAS"'
                    }
                }
            }
        ];
    }
})();
