(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('loader', loader);

    function loader($timeout, $rootScope) {
        var delayIn = 500;
        var delayOut = 250;
        var queue = [];
        var timerPromise = null;
        var timerPromiseHide = null;

        return {
            delayedStart: function () {
                queue.push({});
                if (queue.length === 1) {
                    timerPromise = $timeout(function () {
                        if (queue.length) {
                            showLoader();
                        }
                    }, delayIn);
                }
            },
            start: function () {
                queue.push({});
                if (queue.length === 1) {
                    showLoader();
                }
            },
            end: function () {
                queue.pop();
                if (queue.length === 0) {
                    timerPromiseHide = $timeout(function () {
                        if (queue.length === 0) {
                            hideLoader();
                            if (timerPromiseHide) {
                                $timeout.cancel(timerPromiseHide);
                            }
                        }
                    }, delayOut);
                }
            }
        };

        function showLoader() {
            $rootScope.loader = true;
        }

        function hideLoader() {
            if (timerPromise) {
                $timeout.cancel(timerPromise);
            }
            $rootScope.loader = false;
        }
    }
})();
