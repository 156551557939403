(function () {
    'use strict';

    angular
        .module('app.settings.schoolManagement.schoolList')
        .controller('SchoolListController', SchoolListController);

    function SchoolListController(dataservice, logger, authenticationService, gettextCatalog, $rootScope, $q, $state, paramHelper, $scope, $timeout, log) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('SchoolManagement');

        vm.selectedSchoolObject = '';
        vm.data = [];
        vm.editedRow = {};
        vm.editSchoolButtonEnabled = false;

        // Grid's details
        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true},
            {
                field: 'IskolaNeve',
                headerText: gettextCatalog.getString('SchoolsName'),
                textAlign: 'left',
                validationRules: {required: true},
                validationMessage: {required: gettextCatalog.getString('SchoolsNameIsRequired')}
            },
            {field: 'IskolaIrSzam', headerText: 'Ir.szám', textAlign: 'left'},
            {
                field: 'IskolaVaros',
                headerText: gettextCatalog.getString('City'),
                textAlign: 'left',
                validationRules: {required: true},
                validationMessage: {required: gettextCatalog.getString('SchoolsCityIsRequired')}
            },
            {field: 'IskolaUtca', headerText: gettextCatalog.getString('Street'), textAlign: 'left'},
            {field: 'IskolaHazszam', headerText: gettextCatalog.getString('StreetNumber'), textAlign: 'left'},
            {field: 'IskolaIgazgatoNeve', headerText: gettextCatalog.getString('Director'), textAlign: 'left'}];

        // Public functions
        vm.selectedRow = selectedRow;
        vm.EndAdd = EndAdd;
        vm.EndEdit = EndEdit;
        vm.BeginEdit = BeginEdit;
        vm.EndDelete = EndDelete;
        vm.goToAddSchool = goToAddSchool;
        vm.goToEditSchool = goToEditSchool;
        vm.DeleteSchool = DeleteSchool;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowSelected: vm.selectedRow,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([authenticationService.getRight('UJISKOLA')])
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    } else {
                        dataservice.selectSchools()
                            .then(function (data) {
                                vm.data = data.itemsList;
                                grid.dataSource = vm.data;
                            });
                    }
                });
        }

        function goToAddSchool() {
            authenticationService.getRight('UJISKOLA').then(function (results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    $state.go('settings.schoolManagement.addSchool');
                }
            });
        }

        function goToEditSchool() {
            authenticationService.getRight('UJISKOLA').then(function (results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    paramHelper.setParams([{'schoolManagement.editSchool': vm.selectedSchoolObject.data}]);
                    $state.go('settings.schoolManagement.editSchool');
                }
            });
        }

        function DeleteSchool() {
            authenticationService.getRight('UJISKOLA').then(function (results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    swal({title: gettextCatalog.getString('Are you sure you want to delete the selected school?'),
                        text: gettextCatalog.getString('You will not be able to recover this school!'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Yes, delete this school'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }).then(function(isConfirm) {
                        if (isConfirm.value) {
                            return dataservice.deleteSchool(vm.selectedSchoolObject.data.Id).then(function () {
                                log.successMsg(gettextCatalog.getString('SchoolDeleted'));
                                log.htmlSuccess(gettextCatalog.getString('Deleted!'), vm.selectedSchoolObject.data.IskolaNeve + ' ' + gettextCatalog.getString('has been deleted!'));
                                activate();
                            });
                        }
                    });
                }
            });
        }

        function selectedRow(args) {
            vm.selectedSchoolObject = args;
            vm.editSchoolButtonEnabled = args != null;
            $scope.$apply();
        }

        function EndAdd(args) {
            args.data.Id = -1;
            return dataservice.addSchool(args.data).then(function () {
                log.successMsg(gettextCatalog.getString('SchoolSaved'));
            })
                .catch(function () {
                    log.errorMsg(gettextCatalog.getString('UnexpectedError'));
                });
        }

        function BeginEdit(args) {
            vm.editedRow = args.model.currentViewData[args.model.selectedRowIndex];
        }

        function EndEdit(args) {
            return dataservice.editSchool(args.data).then(function () {
                log.successMsg(gettextCatalog.getString('SchoolModified'));
            });
        }

        function EndDelete(args) {
            return dataservice.deleteSchool(args.data.Id).then(function () {
                log.successMsg(gettextCatalog.getString('SchoolDeleted'));
            })
                .catch(function () {
                    log.errorMsg(gettextCatalog.getString('UnexpectedError'));
                });
        }
    }
})();
