(function () {
    'use strict';

    angular
        .module('app.business.modifyFees')
        .controller('modifyFeesController', modFeesCtrl);

    function modFeesCtrl(log, $rootScope, gettextCatalog, dataservice, authenticationService, $q, $scope) {
        var vm = this;
        $rootScope.vm = vm;

        $rootScope.title = gettextCatalog.getString('ModifyFees');

        var elem;
        var checkBoxObj;
        var checkBoxEditorTemplate =
            {
                create: function () {
                    elem = document.createElement('input');
                    return elem;
                },
                read: function () {
                    return checkBoxObj.checked.toString();
                },
                destroy: function () {
                    if (checkBoxObj != null) {
                        checkBoxObj.destroy();
                    }
                },
                write: function (args) {
                    var s = args.element.id.toString().replace('Grid', '');
                    checkBoxObj = new ej2.buttons.CheckBox({
                        checked: args.rowData[s],
                        value: args.rowData[s]
                    });
                    checkBoxObj.appendTo(elem);
                }
            };

        // Grid's details
        vm.toleranceDays = 10;
        vm.ddlYearValue = 0;
        vm.fromDate = new Date((new Date()).getFullYear(), 0, 1);
        vm.onlyNew = false;
        vm.saveData = saveData;
        vm.closeYear = closeYear;
        vm.meteOut = meteOut;
        vm.nonClosedYears = [];
        vm.selectedYear = 0;
        vm.closeYearEnabled = false;
        vm.yearclosed = false;
        vm.yearSelect = yearSelect;

        vm.columns = [{
            field: 'Id',
            visible: false,
            isPrimaryKey: true,
            isIdentity: true
        }, {
            field: 'Nev',
            headerText: gettextCatalog.getString('SectionName'),
            textAlign: 'center',
            width: 200,
            allowEditing: false
        }, {
            field: 'Tagdij',
            headerText: gettextCatalog.getString('Fee'),
            textAlign: 'center',
            width: 100,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesTD',
            headerText: gettextCatalog.getString('ReducedFee'),
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesTD2',
            headerText: gettextCatalog.getString('ReducedFee') + ' 2',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesTD3',
            headerText: gettextCatalog.getString('ReducedFee') + ' 3',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesTD4',
            headerText: gettextCatalog.getString('ReducedFee') + ' 4',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesTD5',
            headerText: gettextCatalog.getString('ReducedFee') + ' 5',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'TDMentessegAdhato',
            headerText: gettextCatalog.getString('FeeFree'),
            textAlign: 'center',
            width: 140,
            type: 'boolean',
            edit: checkBoxEditorTemplate,
            displayAsCheckBox: true,
            allowEditing: true
        }, {
            field: 'Oktatasidij',
            headerText: gettextCatalog.getString('EducationalFee'),
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesOD',
            headerText: gettextCatalog.getString('ReducedEducationalFee'),
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesOD2',
            headerText: gettextCatalog.getString('ReducedEducationalFee') + ' 2',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesOD3',
            headerText: gettextCatalog.getString('ReducedEducationalFee') + ' 3',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesOD4',
            headerText: gettextCatalog.getString('ReducedEducationalFee') + ' 4',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'KedvezmenyesOD5',
            headerText: gettextCatalog.getString('ReducedEducationalFee') + ' 5',
            textAlign: 'center',
            width: 140,
            editTemplate: vm.tableNumericEditorTemplate,
            allowEditing: true
        }, {
            field: 'ODMentessegAdhato',
            headerText: gettextCatalog.getString('EducationalFeeFree'),
            textAlign: 'center',
            width: 140,
            type: 'boolean',
            displayAsCheckBox: true,
            edit: checkBoxEditorTemplate,
            allowEditing: true
        }, {
            field: 'Temaszam',
            headerText: gettextCatalog.getString('ThemeNumber'),
            textAlign: 'center',
            width: 140,
            allowEditing: true
        }, {
            field: 'KoltsegHely',
            headerText: gettextCatalog.getString('FeePlace'),
            textAlign: 'center',
            width: 140,
            allowEditing: true
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            frozenColumns: 2,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.tableNumericEditorTemplate = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_StrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: 'NNN',
                    //customCharacter: 'bBvVsS0123-',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                    readOnly: vm.readOnlyAttendance
                });
            }
        };

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MODTAGDBEALL'), authenticationService.getRight('MEGTTAGDBEALL')]).then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MODTAGDBEALL = results[1];
                    getFeeDatas();
                    getLastClosedYear();
                    getReducedEducationalFeeTypes();
                    getReducedSportServiceFeeTypes();
                }
            });
        }

        function getReducedEducationalFeeTypes() {
            return dataservice.getReducedEducationalFeeTypes(authenticationService.getLanguage()).then(function (data) {
                if (data.itemsList.length >= 5) {
                    vm.columns[10].headerText = data.itemsList[0].text;
                    vm.columns[11].headerText = data.itemsList[1].text;
                    vm.columns[12].headerText = data.itemsList[2].text;
                    vm.columns[13].headerText = data.itemsList[3].text;
                    vm.columns[14].headerText = data.itemsList[4].text;
                    grid.refreshColumns();
                }
            });
        }

        function getReducedSportServiceFeeTypes() {
            return dataservice.getReducedSportServiceFeeTypes(authenticationService.getLanguage()).then(function (data) {
                if (data.itemsList.length >= 5) {
                    vm.columns[3].headerText = data.itemsList[0].text;
                    vm.columns[4].headerText = data.itemsList[1].text;
                    vm.columns[5].headerText = data.itemsList[2].text;
                    vm.columns[6].headerText = data.itemsList[3].text;
                    vm.columns[7].headerText = data.itemsList[4].text;
                    grid.refreshColumns();
                }
            });
        }

        function yearSelect(arg) {
            if (arg.value) {
                vm.closeYearEnabled = true;
            }
        }

        function getFeeDatas() {
            return dataservice.getFeeDatas()
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                    vm.toleranceDays = data.TurelmiIdo;
                });
        }

        function getLastClosedYear() {
            return dataservice.getLastClosedYear().then(function (data) {
                if (data.retValue === 0 || data.retValue === '0') {
                    data.retValue = new Date().getFullYear() - 1;
                }
                var maxYear = new Date().getFullYear() + 1;
                for (var i = data.retValue + 1; i < maxYear; i++) {
                    vm.nonClosedYears.push(i);
                }
                vm.yearclosed = vm.nonClosedYears.length == 0;
            });
        }

        function saveDataReal() {
            if (!vm.MODTAGDBEALL) {
                log.permissionError();
            }
            grid.editModule.batchSave();
            // itt kell mindent elmenteni
            var functionData = {
                itemsList: grid.dataSource,
                TurelmiIdo: vm.toleranceDays
            };
            return dataservice.saveFeeDatas(functionData);
        }

        function saveData() {
            if (!vm.MODTAGDBEALL) {
                log.permissionError();
            } else {
                saveDataReal().then(function () {
                    log.successMsg('SaveFeeDataOK');
                });
            }
        }

        function closeYear() {
            if (!vm.MODTAGDBEALL) {
                log.permissionError();
            } else {
                var functionData = {
                    datum: vm.selectedYear,
                };
                dataservice.closeYear(functionData).then(function () {
                    getLastClosedYear();
                });
            }
        }

        function meteOut() {
            if (!vm.MODTAGDBEALL) {
                log.permissionError();
            } else {
                // ment és kiró
                if (vm.onlyNew) {
                    // csak új sportolóknak
                    dataservice.getAthletesForMeteOut(vm.fromDate).then(function (athletes) {
                        var athleteNames = '';
                        for (var i = 0; i < athletes.itemsList.length; i++) {
                            athleteNames += athletes.itemsList[i] + '<br>';
                        }

                        var HTMLarr = [];
                        HTMLarr[0] = gettextCatalog.getString('');
                        HTMLarr[1] = '<br><label>';
                        HTMLarr[2] = gettextCatalog.getString('AthletesAffectedByMeteOut');
                        HTMLarr[3] = '</label><br>';
                        HTMLarr[4] = '<p style="text-align: left;">';
                        HTMLarr[5] = athleteNames;
                        HTMLarr[6] = '</p><br>';
                        var HTMLtext = HTMLarr.join('');

                        swal({
                            title: gettextCatalog.getString('MeteOutOnlyNewAthletesTitle'),
                            html: HTMLtext,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: gettextCatalog.getString('Yes'),
                            cancelButtonText: gettextCatalog.getString('No')
                        }).then(function (confirmed) {
                            if (confirmed.value) {
                                saveDataReal().then(function () {
                                    var parameters = {
                                        naptol: vm.fromDate,
                                        kivSportolo: '',
                                        csakUjak: 1,
                                        fizModValtas: 0
                                    };
                                    dataservice.meteOutFee(parameters).then(function () {
                                        log.successMsg('MeteOutFeeOK');
                                    });
                                });
                            }
                        });
                    });
                } else {
                    // mindenkinek
                    swal({
                        title: gettextCatalog.getString('MeteOutAllAthletesTitle'),
                        text: gettextCatalog.getString('MeteOutAllAthletesText'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No')
                    }).then(function (confirmed) {
                        if (confirmed.value) {
                            saveDataReal().then(function () {
                                var parameters = {
                                    naptol: vm.fromDate,
                                    kivSportolo: '',
                                    csakUjak: 0,
                                    fizModValtas: 0
                                };
                                dataservice.meteOutFee(parameters).then(function () {
                                    log.successMsg('MeteOutFeeOK');
                                });
                            });
                        }
                    }, function () {
                    });
                }
            }
        }
    }
})();
