(function () {
    'use strict';

    angular
        .module('app.messageCenter')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'messageCenter',
                config: {
                    url: '/messageCenter',
                    templateUrl: 'app/messageCenter/messageCenter.html',
                    controller: 'MessageCenterController',
                    controllerAs: 'vm',
                    title: 'MessageCenter',
                    settings: {
                        navId: 9,
                        level: 1,
                        order: 9,
                        parentId: 0,
                        content: 'MessageCenter',
                        permission: ['0', '1', '2', '3', '4', '5', '6'],
                        activatorPermission: '"UZENETMEGT"'
                    }
                }
            }
        ];
    }
})();
