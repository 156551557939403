(function () {
    'use strict';

    angular
        .module('app.athletes.teams.teamList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.teams.teamList',
                config: {
                    url: '/teamList',
                    templateUrl: 'app/athletes/teams/teamList/teamList.html',
                    controller: 'TeamListController',
                    controllerAs: 'vm',
                    title: 'TeamList',
                    settings: {
                        navId: 221,
                        level: 2,
                        order: 2,
                        orderTitle_hu: 'Csapatok',
                        orderTitle_en: 'Team list',
                        parentId: 1,
                        content: 'TeamList',
                        activatorPermission: '"MEGTCSOPORT"'
                    }
                }
            }
        ];
    }
})();
