(function () {
    'use strict';

    angular
        .module('app.athletes.teams.modifyTeam')
        .controller('ModifyTeamController', ModifyTeamController);

    function ModifyTeamController(log, $state, dataservice, $q, DOMAIN, gettextCatalog, $scope, paramHelper, authenticationService, $rootScope, $timeout) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('ModifyTeam');

        // Dropdownlist's sources
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        // Default values
        vm.noImg = DOMAIN.baseURL + DOMAIN.userImage;
        vm.photoUrl = vm.noImg;
        vm.formData = {
            TeamId: 0,
            FileData: null,
            FileName: 'avatar_2x.png',
            TeamName: undefined,
            Comment: undefined,
            SectionId: undefined,
            AgeGroupId: undefined,
            CoachId: undefined,
            ActiveTeam: true
        };

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        // Public functions
        vm.modifyTeam = modifyTeam;
        vm.modifyTeamAndAddMembers = modifyTeamAndAddMembers;
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.deleteImg = deleteImg;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.simpleModifyTeam = simpleModifyTeam;
        vm.activeCheckboxChanged = activeCheckboxChanged;
        vm.modifyTeamAndAddMembersButtonEnabled = false;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MODCSOPORT'), authenticationService.getRight('MEGTCSOPORT')]).then(function (results) {
                if (results[0] !== true && results[1] !== true) {
                    log.permissionError(true);
                } else {
                    $timeout(function () {
                        vm.MODCSOPORT = results[0];
                    });
                    paramHelper.getParams(['teams.team']).then(function (results) {
                        vm.formData.TeamId = results['teams.team'];
                        getTeam().then(function () {
                            getSections();
                        }, function () {
                            log.errorMsg('CantGetTeam');
                        });
                    });
                }
            });
        }

        function getTeam() {
            return $q(function (resolve) {
                dataservice.getTeam(vm.formData.TeamId).then(function (data) {
                    vm.team = data;
                    vm.formData.TeamName = data.csapatNev;
                    vm.formData.Comment = data.megjegyzes;
                    vm.formData.SectionId = data.szakosztalyId;
                    vm.formData.AgeGroupId = data.korosztalyId;
                    vm.formData.CoachId = data.edzoId;
                    angular.element('#activeCheckBox').ejCheckBox({checked: data.aktiv});
                    vm.formData.ActiveTeam = data.aktiv;
                    if (!data.kep || data.kep == 'avatar_2x.png') {
                        vm.photoUrl = DOMAIN.baseURL + DOMAIN.userImage;
                    } else {
                        vm.photoUrl = DOMAIN.baseURL + DOMAIN.teamPhotosUrl + data.kep;
                    }

                    vm.formData.FileName = data.kep;
                    resolve();
                });
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.formData.SectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.formData.SectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            vm.formData.SectionId = args.value;
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.formData.SectionId)
                .then(function (data) {
                    vm.coachList = [];
                    vm.ageGroupList = data.itemsList;
                });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.formData.AgeGroupId = vm.ageGroupList[0].value;
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.formData.AgeGroupId);
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            vm.formData.AgeGroupId = args.value;
            getCoaches();
        }

        function getCoaches() {
            return dataservice.coachDropDownList(vm.formData.SectionId, vm.formData.AgeGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.formData.CoachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.formData.CoachId);
            }
        }

        /**
         * Edző kiválasztásakor fut le és lekéri a korosztály edzőit
         * @author Richard Keki
         */
        function coachSelect(args) {
            vm.formData.CoachId = args.value;
        }

        function activeCheckboxChanged(args) {
            vm.modifyTeamAndAddMembersButtonEnabled = args.value;
            $timeout(function () {
                $scope.$apply();
            }, 0);
            vm.formData.ActiveTeam = args.value;
        }

        function modifyTeam(simpleSave) {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('RequiredFieldsError');
            } else {
                if (angular.element('#FileName').val() !== '' && vm.cropper.croppedImage !== null) {
                    vm.formData.FileData = vm.cropper.croppedImage;
                    vm.formData.FileName = angular.element('#FileName').val();
                }
                dataservice.modifyTeam(vm.formData)
                    .then(function () {
                        log.successMsg('TeamModified');
                        paramHelper.setParams([{'teams.sectionId': vm.formData.SectionId}, {'teams.ageGroupId': vm.formData.AgeGroupId}]);
                        if (simpleSave) {
                            $state.go('athletes.teams.teamList');
                        } else {
                            $state.go('athletes.teams.teamMembersList');
                        }
                    });
            }
        }

        function simpleModifyTeam() {
            modifyTeam(true);
        }

        function modifyTeamAndAddMembers() {
            modifyTeam(false);
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteTeamImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheTeamImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#FileName').val('');
                    vm.photoUrl = vm.noImg;
                    vm.formData.FileName = 'oldDeleted';
                    $scope.$apply();
                }
            });
        }
    }
})();
