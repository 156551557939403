(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('validatorHelper', validatorHelper);

    function validatorHelper($q) {
        return {
            validate: validate
        };

        function validate(response, form) {
            return $q(function (resolve) {
                if (response.data.validationErrors) {
                    angular.forEach(response.data.validationErrors, function (value, key) {
                        var fieldName = key.substring(key.indexOf('.') + 1, key.length + 1);
                        if (form[fieldName]) {
                            if (value['_errors'][0]['<ErrorMessage>k__BackingField'] === 'required') {
                                form[fieldName].$setValidity('required', false);
                            }
                            if (value['_errors'][0]['<ErrorMessage>k__BackingField'] === 'maxlength') {
                                form[fieldName].$setValidity('maxlength', false);
                            }
                            if (value['_errors'][0]['<ErrorMessage>k__BackingField'] === 'minlength') {
                                form[fieldName].$setValidity('minlength', false);
                            }
                        }
                    });
                    return $q.reject(response);
                }
                resolve(true);
            });
        }
    }
})();
