(function () {
    'use strict';

    angular
        .module('app.other.files')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'other.files',
                config: {
                    url: '/files',
                    templateUrl: 'app/other/files/files.html',
                    controller: 'FilesController',
                    controllerAs: 'vm'
                }
            }
        ];
    }
})();
