(function () {
    'use strict';

    angular
        .module('app.reports.assetInventoryReportByUsers')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.assetInventoryReportByUsers',
                config: {
                    url: '/assetInventoryReportByUsers',
                    templateUrl: 'app/reports/assetInventoryReportByUsers/assetInventoryReportByUsers.html',
                    controller: 'AssetInventoryReportByUsersController',
                    controllerAs: 'vm',
                    title: 'AssetInventoryReportByUsers',
                    settings: {
                        navId: 312224,
                        level: 3,
                        order: 1,
                        orderTitle_hu: 'Eszköz nyilvántartó (Személy)',
                        orderTitle_en: 'Asset Inventory (Person)',
                        parentId: 2224,
                        content: 'AssetInventoryReportByUsers',
                        activatorPermission: '"ESZKNYILVMEGT"'
                    }
                }
            }
        ];
    }
})();
