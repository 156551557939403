(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.annualPresenceReports',
                config: {
                    url: '/annualPresenceReports',
                    templateUrl: 'app/reports/annualPresenceReports/annualPresenceReports.html',
                    title: 'AnnualPresenceReports',
                    settings: {
                        navId: 224,
                        level: 2,
                        order: 2,
                        orderTitle_hu: 'Éves jelenléti kimutatások',
                        orderTitle_en: 'Annual Attendance Reports',
                        parentId: 4,
                        content: 'AnnualPresenceReports',
                        activatorPermission: ['"KMEVESJELEDZO"', '"KMEVESJELSZO"', '"KMEVESJELSP"']
                    }
                }
            }
        ];
    }
})();
