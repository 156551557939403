(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.userList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.userManagement.userList',
                config: {
                    url: '/userList',
                    templateUrl: 'app/settings/userManagement/userList/userList.html',
                    controller: 'UserManagementController',
                    controllerAs: 'vm',
                    title: 'UserManagement',
                    settings: {
                        navId: 215,
                        level: 2,
                        order: 1,
                        orderTitle_hu: 'Felhasználó kezelés',
                        orderTitle_en: 'Editing User',
                        parentId: 5,
                        content: 'UserManagement',
                        activatorPermission: '"MEGTFELH"'
                    }
                }
            }
        ];
    }
})();
