(function () {
    'use strict';

    var core = angular.module('app.core');

    core.config(toastrConfig);

    function toastrConfig(toastr) {
        toastr.options.timeOut = 4000;
        toastr.options.positionClass = 'toast-bottom-right';
    }

    var config = {
        appTitle: 'Siker2',
        environment: 'development', // 'development' vagy 'production'
        partnerLogo: 'images/partnerlogo2.png'
    };

    core.value('config', config);

    core.config(configure);

    function configure(routerHelperProvider, exceptionHandlerProvider, $httpProvider, logProvider) {
        if (config.environment) {
            logProvider.setEnvironment(config.environment);
        }
        //      $compileProvider.debugInfoEnabled(false);
        // $httpProvider.defaults.withCredentials = true;
        exceptionHandlerProvider.configure(config.environment);
        routerHelperProvider.configure({docTitle: config.appTitle + ': '});
        $httpProvider.interceptors.push('httpInterceptor');
    }
})();
