(function () {
    'use strict';

    angular
        .module('blocks.exception')
        .provider('exceptionHandler', exceptionHandlerProvider)
        .config(config);

    /**
     * Must configure the exception handling
     */
    function exceptionHandlerProvider() {
        /* jshint validthis:true */
        this.config = {
            environment: undefined
        };

        this.configure = function (environment) {
            this.config.environment = environment;
        };

        this.$get = function () {
            return {config: this.config};
        };
    }

    function config($provide) {
        $provide.decorator('$exceptionHandler', extendExceptionHandler);
    }

    function extendExceptionHandler($delegate, exceptionHandler, logger, $injector) {
        return function (exception) {
            if (!(exception && typeof exception === 'object')) {
                return;
            }
            var object = {
                vm: null,
                stateList: null,
                actualState: null,
                localStorage: null,
                ClientSideException: null,
                title: null
            };
            var rootScope = $injector.get('$rootScope');
            var LS = $injector.get('localStorageService');
            var keys = LS.keys();
            var LSItems = [];
            keys.forEach(function (element) {
                LSItems.push(element + ': ' + LS.get(element));
            });

            var ClientSideException = {Message: '', stack: ''};
            if (exception.__proto__.hasOwnProperty('toString')) {
                ClientSideException.Message = exception.toString();
            }
            if (exception.hasOwnProperty('stack')) {
                ClientSideException.stack = exception.stack.toString();
            }
            object.localStorage = LSItems;
            object.actualState = rootScope.actualState;
            object.stateList = rootScope.stateList;
            object.title = rootScope.htmlTitle;
            object.ClientSideException = ClientSideException;
            var cloneVM = $.extend(true, {}, rootScope.vm);
            var cloneVMkeys = Object.keys(cloneVM);
            cloneVMkeys.forEach(function (vmKey) {
                try {
                    JSON.stringify(cloneVM[vmKey]);
                }
                catch (err) {
                    cloneVM[vmKey] = null;
                }
            });
            object.vm = cloneVM;

            if ((ClientSideException.Message.indexOf('LogClientSideException') != -1) || (ClientSideException.stack.indexOf('LogClientSideException') != -1)) {
                return;
            }

            var jsonObject = JSON.stringify(object);

            if (exceptionHandler.config.environment !== 'development') {
                var dataService = $injector.get('dataservice');
                dataService.sendLocalException(jsonObject).then(function () {});
            }

            if (exceptionHandler.config.environment === 'development') {
                //logger.error(exception);
                $delegate(exception);
            }
        };
    }
})();
