(function () {
    'use strict';

    angular
        .module('app.business.dailyEducationalFeesPerCoach')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.dailyEducationalFeesPerCoach',
                config: {
                    url: '/dailyEducationalFeesPerCoach',
                    templateUrl: 'app/business/dailyEducationalFeesPerCoach/dailyEducationalFeesPerCoach.html',
                    controller: 'DailyEducationalFeesPerCoachController',
                    controllerAs: 'vm',
                    title: 'DailyEducationalFeesPerCoach',
                    settings: {
                        navId: 283,
                        level: 2,
                        order: 8,
                        orderTitle_hu: 'Napi oktatási díj befizetések edzőnként',
                        orderTitle_en: 'Daily Educational Fee Payments Per Coach',
                        parentId: 3,
                        content: 'DailyEducationalFeesPerCoach',
                        permission: ['7'],
                        activatorPermission: '"MEGTNAPIOKTD"'
                    }
                }
            }
        ];
    }
})();
