(function () {
    'use strict';

    angular
        .module('app.settings.editHealthType')
        .controller('EditHealthTypeController', EditHealthTypeController);

    function EditHealthTypeController($q, paramHelper, gettextCatalog, $state, dataservice, logger, authenticationService, $rootScope, $scope, log, $timeout) {
        var vm = this;
        $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('EditHealthType');

        vm.mainTypeColumns = [
            {field: 'Link', type: 'checkbox', textAlign: 'center', width: 100},
            {field: 'Id', visible: false, isPrimaryKey: true},
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                validationRules: {required: true},
                validationMessage: {required: gettextCatalog.getString('HealthEventType')}
            }
        ];

        var mainTypeGrid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: (window.innerHeight - 550),
            pageSettings: $rootScope.pageSettings.pageSize,
            columns: vm.mainTypeColumns,
            beforePrint: function (args) {
                $rootScope.beforePrintGrid(args, ['Link']);
            },
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(mainTypeGrid, $scope);
                }
            },
            rowSelecting: function (args) {
                if (!args.data) {
                    return;
                }
                mainTypeGrid.clearSelection();
                vm.selectedMainTypeRow = args.data;
                vm.editDeleteMainTypeEnabled = vm.EGESZSMOD;
                vm.addNewSubType = vm.EGESZSMOD;
                vm.editDeleteSubTypeEnabled = false;
                SearchSubTypeBySelectedRow(args.data.Id);
                $timeout(function () {
                    $scope.$apply();
                }, 0);
            },
            rowDeselected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(mainTypeGrid);
                if (rowSelect.length === 0) {
                    vm.editDeleteMainTypeEnabled = false;
                    vm.addNewSubType = false;
                    subTypeGrid.dataSource = [];
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, mainTypeGrid);
            }
        });
        mainTypeGrid.appendTo('#MainTypeGrid');
        mainTypeGrid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, mainTypeGrid);
        };

        vm.subTypeColumns = [
            {field: 'Link', type: 'checkbox', textAlign: 'center', width: 100},
            {field: 'Id', visible: false, isPrimaryKey: true},
            {
                field: 'AltipusNev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                validationRules: {required: true},
                validationMessage: {required: gettextCatalog.getString('HealthEventType')}
            }
        ];

        var subTypeGrid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: (window.innerHeight - 550),
            pageSettings: $rootScope.pageSettings.pageSize,
            columns: vm.subTypeColumns,
            selectionSettings: {type: 'multiple', mode: 'Both'},
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(subTypeGrid, $scope);
                }
            },
            rowSelecting: function (args) {
                subTypeGrid.clearSelection();
                vm.selectedSubTypeRow = args.data;
                vm.editDeleteSubTypeEnabled = vm.EGESZSMOD;
                $timeout(function () {
                    $scope.$apply();
                }, 0);
            },
            rowDeselected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(subTypeGrid);
                if (rowSelect.length === 0) {
                    vm.editDeleteSubTypeEnabled = false;
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, subTypeGrid);
            }
        });
        subTypeGrid.appendTo('#SubTypeGrid');
        subTypeGrid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, subTypeGrid);
        };

        vm.editDeleteMainTypeEnabled = false;
        vm.editDeleteSubTypeEnabled = false;
        vm.addNewSubType = false;
        vm.AddSubType = AddSubType;
        vm.AddMainType = AddMainType;
        vm.DeleteMainType = DeleteMainType;
        vm.DeleteSubType = DeleteSubType;
        vm.EditMainType = EditMainType;
        vm.EditSubType = EditSubType;
        vm.SearchSubTypeBySelectedRow = SearchSubTypeBySelectedRow;

        vm.EGESZSMOD = false;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('EGESZSMEGT'), authenticationService.getRight('EGESZSMOD')]).then(function (data) {
                $timeout(function () {
                    if (!data[0]) {
                        log.permissionError(true);
                    }
                    vm.EGESZSMOD = data[1];
                }, 10);
                if (data[0] || data[1] || data[2]) {
                    getMainType();
                } else {
                    log.permissionError(true);
                }
            });
        }

        function getMainType() {
            vm.editDeleteMainTypeEnabled = false;
            vm.addNewSubType = false;
            dataservice.healthMainTypesList().then(function (data) {
                mainTypeGrid.dataSource = data.itemsList;
            });
        }

        function SearchSubTypeBySelectedRow(arg) {
            dataservice.healthSubTypesList().then(function (data) {
                var subTypes = data.itemsList;
                subTypeGrid.dataSource = [];
                for (var i = 0; i < subTypes.length; i++) {
                    if (subTypes[i].FotipusId === arg) {
                        subTypeGrid.dataSource.push(subTypes[i]);
                    }
                }
            });
        }

        function AddMainType() {
            swal({
                title: gettextCatalog.getString('CreateNewMainTypeTemplate'),
                type: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return;
                }
                if (!inputValue || !inputValue.value || inputValue.value.length < 1) {
                    log.errorMsg(gettextCatalog.getString('AddMainTypeNameEmpty'));
                } else {
                    dataservice.HealthMainTypeManager({Id: -1, Nev: inputValue.value}).then(function (response) {
                        if (response.statusText = 'OK') {
                            log.successMsg(gettextCatalog.getString('saveSuccess'));
                            getMainType();
                        }
                    });
                }
            });
        }

        function EditMainType() {
            swal({
                title: gettextCatalog.getString('EditNewMainTypeTemplate'),
                type: 'info',
                input: 'text',
                inputValue: vm.selectedMainTypeRow.Nev,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.value.length < 1 || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    log.errorMsg(gettextCatalog.getString('AddMainTypeNameEmpty'));
                } else {
                    dataservice.HealthMainTypeManager({
                        Id: vm.selectedMainTypeRow.Id,
                        Nev: inputValue.value
                    }).then(function (response) {
                        if (response.statusText = 'OK') {
                            log.successMsg(gettextCatalog.getString('saveSuccess'));
                        }
                        getMainType();
                    });
                }
            });
        }

        function DeleteMainType() {
            var warningText = '';
            if (subTypeGrid.dataSource.length > 0) {
                warningText = gettextCatalog.getString('This will delete all subType too.');
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected mainType?'),
                type: 'warning',
                html: '<b style="color: red;">' + warningText + '</b>',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this mainType'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.deleteHealthMainType({mainTypeId: vm.selectedMainTypeRow.Id}).then(function () {
                        vm.addNewSubType = false;
                        vm.editDeleteSubTypeEnabled = false;
                        getMainType()();
                    });
                }
            });
        }

        function AddSubType() {
            swal({
                title: gettextCatalog.getString('CreateNeSubTypeTemplate'),
                type: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (!inputValue || inputValue.value.length < 1 || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    log.errorMsg(gettextCatalog.getString('AddSubTypeNameEmpty'));
                } else {
                    dataservice.HealthSubTypeManager({
                        Id: -1,
                        AltipusNev: inputValue.value,
                        FotipusId: vm.selectedMainTypeRow.Id
                    }).then(function () {
                        log.successMsg(gettextCatalog.getString('saveSuccess'));
                        vm.editDeleteSubTypeEnabled = false;
                        SearchSubTypeBySelectedRow(vm.selectedMainTypeRow.Id);
                    });
                }
            });
        }

        function EditSubType() {
            swal({
                title: gettextCatalog.getString('EditSubTypeTemplate'),
                type: 'info',
                input: 'text',
                inputValue: vm.selectedSubTypeRow.AltipusNev,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.value.length < 1 || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    log.errorMsg(gettextCatalog.getString('AddSubTypeNameEmpty'));
                } else {
                    dataservice.HealthSubTypeManager({
                        Id: vm.selectedSubTypeRow.Id,
                        FotipusId: vm.selectedMainTypeRow.Id,
                        AltipusNev: inputValue.value
                    }).then(function () {
                        log.successMsg(gettextCatalog.getString('saveSuccess'));
                        vm.editDeleteSubTypeEnabled = false;
                        SearchSubTypeBySelectedRow(vm.selectedMainTypeRow.Id);
                    });
                }
            });
        }

        function DeleteSubType() {
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected subType?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this subType'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.deleteHealthSubType({subTypeId: vm.selectedSubTypeRow.Id}).then(function () {
                        vm.editDeleteSubTypeEnabled = false;
                        SearchSubTypeBySelectedRow(vm.selectedMainTypeRow.Id);
                    });
                }
            });
        }
    }
})();
