(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.userSettings')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.userManagement.userSettings',
                config: {
                    url: '/userSettings',
                    templateUrl: 'app/settings/userManagement/userSettings/userSettings.html',
                    controller: 'userSettingsController',
                    controllerAs: 'vm',
                    title: 'UserSettings'
                }
            }
        ];
    }
})();
